import React from 'react'
import { useTranslation } from 'react-i18next'
import Badge from '../../Badge'

export const StoreStatus = ({ store }) => {
    const { t } = useTranslation('common')

    return store.retailerId ? (
        <Badge appearance={'success'}>{t('listingStore.table.status.activated')}</Badge>
    ) : (
        <Badge appearance={'warning'}>{t('listingStore.table.status.pending')}</Badge>
    )
}
