import React from 'react'

function IPhoneTasksIcon() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.70274 9.42266H2.79942V10.268C2.79942 11.4148 3.37085 12 4.50216 12H10.2973C11.4228 12 12 11.4089 12 10.268V4.30345C12 3.16256 11.4228 2.57734 10.2973 2.57734H9.20058V1.72611C9.20058 0.591133 8.62338 0 7.49784 0H1.70274C0.571429 0 0 0.57931 0 1.72611V7.69655C0 8.83744 0.571429 9.42266 1.70274 9.42266ZM1.71429 8.68965C1.06782 8.68965 0.715729 8.34089 0.715729 7.65517V1.76749C0.715729 1.08177 1.06782 0.733005 1.71429 0.733005H7.48629C8.12121 0.733005 8.48485 1.08177 8.48485 1.76749V2.57734H4.50216C3.37085 2.57734 2.79942 3.15665 2.79942 4.30345V8.68965H1.71429ZM4.51371 11.267C3.86724 11.267 3.51515 10.9123 3.51515 10.2325V4.33892C3.51515 3.65911 3.86724 3.30443 4.51371 3.30443H10.2857C10.9206 3.30443 11.2843 3.65911 11.2843 4.33892V10.2325C11.2843 10.9123 10.9206 11.267 10.2857 11.267H4.51371Z"
                fill="#454545"
            />
        </svg>
    )
}

export default IPhoneTasksIcon
