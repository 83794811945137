import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ReactElement, useContext, useMemo } from 'react'
import AuthContext from '../contexts/AuthContext'
import { ACCESS_LEVEL, canUserAccess, FUNCTIONALITIES } from '../helpers'

type AccessProps = {
    functionality?: FUNCTIONALITIES
    level?: ACCESS_LEVEL
    children: ReactElement
    redirect?: boolean
    internalUser?: boolean
}

export const getRedirectUrl = (
    user: any,
    functionality?: FUNCTIONALITIES,
    level?: ACCESS_LEVEL,
    internalUser?: boolean
) => {
    const canAccess = !!user && canUserAccess(functionality, level)

    // Test internal user (spaycialists)
    if (internalUser === true && (user.shoppingHubOwnerId || user.backUserHubAssociations.length > 0)) {
        return '/'
    }
    // Test external user (clients)
    else if (internalUser === false && !user.shoppingHubOwnerId && user.backUserHubAssociations.length === 0) {
        return '/'
    }

    if (!canAccess) {
        let redirectTo = '/'
        if (!user) redirectTo = '/login'
        else if (canUserAccess(FUNCTIONALITIES.PROGRAM)) redirectTo = '/programs'
        else if (canUserAccess(FUNCTIONALITIES.AUDIENCE)) redirectTo = '/audience'
        else {
            // Can't access PROGRAM or AUDIENCE => disconnect user
            localStorage.clear()
            return '/login'
        }

        return redirectTo
    }
}

export const canAccess = (user: any, functionality?: FUNCTIONALITIES, level?: ACCESS_LEVEL, internalUser?: boolean) =>
    !getRedirectUrl(user, functionality, level, internalUser)

export default function RequireAuth({
    children,
    functionality,
    level,
    redirect = false,
    internalUser,
}: AccessProps): ReactElement | null {
    let { user } = useContext(AuthContext)
    let location = useLocation()
    const navigate = useNavigate()

    const redirectUrl = useMemo(
        () => getRedirectUrl(user, functionality, level, internalUser),
        [user, functionality, level, navigate]
    )

    if (!redirectUrl) return children
    else if (redirect) return <Navigate to={redirectUrl} state={{ from: location }} replace />
    else return null
}

export function withAuthRequired(
    children: ReactElement,
    functionality?: FUNCTIONALITIES,
    level: ACCESS_LEVEL = ACCESS_LEVEL.READ,
    internalUser?: boolean
) {
    return (
        <RequireAuth functionality={functionality} level={level} redirect internalUser={internalUser}>
            {children}
        </RequireAuth>
    )
}
