import React, { forwardRef, ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { ShoppingHub } from '../data/ShoppingHubFetcher'
import Loading from '../components/Loading'
import { useParams } from 'react-router-dom'
import ErrorManagementContext from './ErrorManagementContext'
import { getStatusFromShoppingHub } from '../utils/ShoppingHubUtils'

// Public
export type WithProgramProps = {
    currentProgram?: any
    setCurrentProgram: (p: any) => void
    onProgramPageNavigated: (id: string) => Promise<void>
    loadedOnce: boolean
}

const ProgramContext = React.createContext<WithProgramProps>({
    setCurrentProgram: () => {},
    onProgramPageNavigated: () => Promise.resolve(),
    loadedOnce: false,
})

export function ProgramProvider({ children }: { children: ReactNode }) {
    const [currentProgram, currentProgramSetter] = useState<any>(null)
    const [loadedOnce, setLoadedOnce] = useState(false)
    const { handleError } = useContext(ErrorManagementContext)

    const loadProgramById = (id: string) => {
        if (currentProgram?.id === id) return Promise.resolve()

        return ShoppingHub.getById(id)
            .then((p) => setCurrentProgram({ ...p, status: getStatusFromShoppingHub(p) }))
            .catch(handleError('errors.program.getById'))
            .finally(() => setLoadedOnce(true))
    }

    const setCurrentProgram = useCallback((program: any) => {
        setLoadedOnce(true)
        currentProgramSetter(program)
    }, [])

    return (
        <ProgramContext.Provider
            value={{
                currentProgram,
                setCurrentProgram,
                onProgramPageNavigated: loadProgramById,
                loadedOnce,
            }}
        >
            {children}
        </ProgramContext.Provider>
    )
}

export function withProgram<T extends WithProgramProps = WithProgramProps>(
    Component: React.ComponentType<T>
): (p: any) => any {
    return forwardRef((props: any, ref: any) => (
        <ProgramContext.Consumer>{(value) => <Component {...props} {...value} ref={ref} />}</ProgramContext.Consumer>
    ))
}

export function ProgramSetter({ children }: { children: ReactElement }) {
    const { onProgramPageNavigated, loadedOnce } = useContext(ProgramContext)
    const { programId } = useParams()

    useEffect(() => {
        if (programId) onProgramPageNavigated(programId)
    }, [onProgramPageNavigated, programId])

    return !loadedOnce ? <Loading /> : children
}

export default ProgramContext
