import React, { useCallback, useEffect, useState, useContext } from 'react'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Address from './Details/Address'
import Analytics from './Details/Analytics'
import Modal from '../../components/Modal'
import { ACCESS_LEVEL, FUNCTIONALITIES, Request } from '../../helpers'
import { withProgram } from '../../contexts/ProgramContext'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../components/ErrorMessage'
import RequireAuth from '../../components/RequireAuth'
import ErrorManagementContext from '../../contexts/ErrorManagementContext'

function StoreDetails({ currentProgram }) {
    const { t } = useTranslation('common')
    const [current] = useState('general')
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useLocation().state || {}
    const { storeId } = useParams()
    const [store, setStore] = useState()
    const { handleError } = useContext(ErrorManagementContext)

    const url = 'backoffice/stores/'

    const getStore = useCallback((uri) => {
        Request.get(uri)
            .then((data) => {
                setStore(data)
            })
            .catch(handleError('errors.stores.list'))
    }, [])

    useEffect(() => {
        if (id) {
            getStore(`${url}${id}`)
        } else {
            getStore(`${url}${storeId}`)
        }
    }, [getStore, id, storeId])

    const closeDeactivateModal = useCallback(() => {
        setShowModal(false)
    }, [])

    const deactivate = useCallback(() => {
        if (!store) {
            return
        }

        closeDeactivateModal()

        Request.patch(`backoffice/stores/${store.storeId}`, { disassociate: true, name: store.storeName })
            .then(() => navigate(`/programs/${currentProgram?.id}/stores`))
            .catch(handleError('errors.stores.deactivate'))
    }, [closeDeactivateModal, navigate, store])

    const goBackHandler = useCallback(() => {
        const historyState = location?.state
        if (historyState && historyState?.prevPathName?.includes('/offers/create/form')) {
            navigate(`${historyState.prevPathName}?offerType=${historyState.offerType}`)
        } else {
            navigate(-1)
        }
    }, [navigate])

    return store ? (
        <div className="p-1 pl-4 h-full">
            <div className="flex gap-8 items-center">
                <BackButton onClick={goBackHandler} />
                <h1 className="flex-1 text-2xl md:ml-6">{t('stores.program.details.store_details')}</h1>

                <RequireAuth functionality={FUNCTIONALITIES.STORE} level={ACCESS_LEVEL.WRITE}>
                    <Button onClick={() => setShowModal(true)} data-cy="deactivate_store_button">
                        {t('stores.program.details.deactivate')}
                    </Button>
                </RequireAuth>
            </div>

            <div className="mb-10 md:flex md:justify-between">
                {/*
                TODO: Restore in future. https://spaycial.atlassian.net/browse/BO2-110
                <Tabs current={current} setCurrent={setCurrent}>
                    <Tab name="general">{t('stores.program.details.general')}</Tab>
                    <Tab name="analytics">{t('stores.program.details.analytics')}</Tab>
                </Tabs> */}
            </div>

            {current === 'general' && <Address store={store} currentProgram={currentProgram} />}
            {current === 'analytics' && <Analytics />}

            <Modal show={showModal} onClose={setShowModal}>
                <div className="flex flex-col gap-8 items-center max-w-lg p-4 text-center">
                    <div>
                        {t(
                            'stores.program.details.are_you_sure_you_want_to_deactivate_this_store_this_could_create_problems_with_current_offers_and_programs'
                        )}
                    </div>
                    <div className="flex gap-8 w-full">
                        <Button appearance="outline" className="flex-1 py-4" onClick={closeDeactivateModal}>
                            {t('stores.program.details.cancel')}
                        </Button>
                        <Button className="flex-1 py-4" onClick={deactivate}>
                            {t('stores.program.details.deactivate')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    ) : null
}

export default withProgram(StoreDetails)
