import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import Checkbox from '../../../components/Checkbox'
import Map from '../../../components/Map'
import Button from '../../../components/Button'
import { Request } from '../../../helpers'
import { withProgram } from '../../../contexts/ProgramContext'
import { PlusIcon } from '../../../components/PlusIcon'
import Modal from '../../../components/Modal'
import StoreFilter from '../../../components/StoreFilter'
import getCountryName from '../../../utils/GetCountryName'
import './Listing.css'
import RestTable from '../../../components/Table'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import useSearchStoresFilters from '../../../hooks/stores/useSearchStoresFilters'

function Listing({ currentProgram }) {
    const { programId } = useParams()
    const {
        tableQueryUrl,
        onPageReceived,
        storesList,
        cities,
        countries,
        searchInput,
        onFilterChanges,
        countryCitiesData,
    } = useSearchStoresFilters({
        shoppingHubId: currentProgram?.id || programId,
    })
    const navigate = useNavigate()
    const { t } = useTranslation('common')
    const [allStoreChecked, setAllStoreChecked] = useState(false)
    const [selectedStores, setSelectedStores] = useState([])
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [singleStoreConfirmationModal, setSingleStoreConfirmationModal] = useState(false)

    const { handleError } = useContext(ErrorManagementContext)

    const isAlreadyLinked = (shoppingHubId) => shoppingHubId === currentProgram?.id
    const rowClass = (shoppingHubId) =>
        isAlreadyLinked(shoppingHubId) ? 'disabled bg-gray-200 opacity-20' : 'cursor-pointer'
    const addOrRemoveSelectedStore = (checked, store) =>
        checked
            ? setSelectedStores([...selectedStores, store])
            : setSelectedStores(selectedStores.filter((s) => s.id !== store.id))

    const getSelectableStores = () => storesList.filter((store) => !isAlreadyLinked(store.shoppingHubId))

    useEffect(() => {
        const selectableStores = getSelectableStores()
        const allStoresAreChecked =
            selectableStores && selectableStores.every(({ id }) => selectedStores.find((store) => store.id === id))
        setAllStoreChecked(allStoresAreChecked)
    }, [selectedStores, storesList])

    const addStoreToProgram = () => {
        const uri = 'backoffice/storeList'
        Request.post(uri, { stores: selectedStores }, { shoppingHubId: currentProgram?.id || programId })
            .then((res) => {
                setSelectedStores([])
                setConfirmationModal(false)
                setSingleStoreConfirmationModal(false)
                navigate(`/programs/${currentProgram?.id || programId}/stores`)
            })
            .catch((err) => {
                setSelectedStores([])
                setConfirmationModal(false)
                setSingleStoreConfirmationModal(false)
                handleError('errors.stores.addStoreToProgram')(err)
            })
    }

    const addOrRemoveAllDisplayedStores = () => {
        const selectableStores = getSelectableStores()
        if (allStoreChecked) {
            // remove displayed stores from selection
            setSelectedStores(selectedStores.filter((store) => !selectableStores.find((s) => s.id === store.id)))
        } else {
            // add displayed stores to selection
            const toAdd = selectableStores.filter((store) => !selectedStores.find((s) => s.id === store.id))
            setSelectedStores([...selectedStores, ...toAdd])
        }
    }

    const columns = [
        {
            title: t('listing_program.table_header_store'),
            className: 'w-1/5 truncate',
            render: (s) => (
                <div data-tip={s.id} data-for={s.name ? `showMoreNameText--${s.id}` : ''}>
                    {s.name || 'N/A'}
                    <ReactTooltip id={`showMoreNameText--${s.id}`}>
                        <span>{s.name}</span>
                    </ReactTooltip>
                </div>
            ),
        },
        {
            title: t('offerCreation.country'),
            className: 'w-1/5 truncate',
            render: (s) => getCountryName(s.country) || 'N/A',
        },
        { title: t('listingStore.tableHeader.city'), className: 'w-1/5 truncate', render: (s) => s.locality || 'N/A' },
        {
            title: t('listingStore.tableHeader.address'),
            className: 'w-1/3 truncate',
            render: (s) => (
                <div data-tip={s.id} data-for={s.formattedAddress ? `showMoreAddressText--${s.id}` : ''}>
                    {s.formattedAddress || 'N/A'}
                    <ReactTooltip id={`showMoreAddressText--${s.id}`}>
                        <span>{s.formattedAddress}</span>
                    </ReactTooltip>
                </div>
            ),
        },
        {
            title: selectedStores.length,
            className: 'text-center w-14',
            render: (s) =>
                isAlreadyLinked(s.shoppingHubId) ? (
                    <Checkbox
                        disabled={true}
                        checked={true}
                        className="cb-already-linked checked:bg-success cursor:none"
                        onChange={() => {}}
                    />
                ) : (
                    <PlusIcon
                        onClick={() => {
                            setSelectedStores([s])
                            setSingleStoreConfirmationModal(true)
                        }}
                    />
                ),
        },
    ]

    const renderSelectionCell = (checked, store) => {
        return (
            !isAlreadyLinked(store.shoppingHubId) && (
                <Checkbox
                    disabled={store.shoppingHubId === currentProgram?.id}
                    checked={!!selectedStores.find((s) => s.id === store.id)}
                    className={rowClass(store.shoppingHubId)}
                    onChange={(checked) => addOrRemoveSelectedStore(checked, store)}
                />
            )
        )
    }

    return (
        <div className="p-1 pl-4 h-full">
            <div className="flex flex-col h-full">
                <div className="md:flex justify-between md:mb-20">
                    <h3 className="text-3xl text-black text-left md:spl-8">{t('listing_stores.stores_list')}</h3>
                    <div className="flex flex-row gap-3 my-4 md:my-0">
                        <Button appearance="outline" onClick={() => navigate(-1)}>
                            {t('listing_stores.exit')}
                        </Button>
                        <Button
                            appearance="outline"
                            onClick={() => navigate(`/programs/${currentProgram?.id || programId}/stores/create/brand`)}
                        >
                            {t('listing_stores.createBrand')}
                        </Button>
                        <Button disabled={!selectedStores.length} onClick={() => setConfirmationModal(true)}>
                            {t('listing_stores.validate')}
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col gap-2 flex-1">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                        <StoreFilter onChange={onFilterChanges} data={countryCitiesData} />
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 flex-1">
                        <div>
                            <div>
                                <RestTable
                                    className="mt-16 md:mt-4 mb-4 table-fixed"
                                    queryBaseUrl={tableQueryUrl}
                                    columns={columns}
                                    selectionType="checkbox"
                                    onDataReceived={onPageReceived}
                                    errorMessageKey="errors.programs.search"
                                    rowClassName={(s) => rowClass(s?.shoppingHubId)}
                                    renderSelectionCell={renderSelectionCell}
                                    onToggleSelectAll={addOrRemoveAllDisplayedStores}
                                    pageSize={10}
                                    tableLayout="fixed"
                                    selectedIds={selectedStores.map((s) => s.id)}
                                />
                            </div>

                            <Modal
                                show={confirmationModal || singleStoreConfirmationModal}
                                onClose={() => {
                                    setConfirmationModal(false)
                                    setSingleStoreConfirmationModal(false)
                                }}
                            >
                                <div className="max-w-md text-center m-auto">
                                    <p>{t('listing_stores.creation.modalConfirmation')}</p>
                                    <div className={'mt-12 flex justify-center'}>
                                        <Button
                                            appearance={'inversed'}
                                            className={'w-1/3'}
                                            onClick={() => {
                                                setSingleStoreConfirmationModal(false)
                                                setConfirmationModal(false)
                                            }}
                                        >
                                            {t('offerCreation.no')}
                                        </Button>
                                        <Button
                                            className={'w-1/3 ml-4'}
                                            onClick={() => {
                                                setConfirmationModal(false)
                                                addStoreToProgram()
                                            }}
                                        >
                                            {t('offerCreation.leaveModal.yes')}
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>

                        <div className="order-first lg:order-last lg:-mt-10">
                            <Map
                                className={'map-store'}
                                info={true}
                                filter={{ search: searchInput, cities: cities, countries: countries }}
                                shoppingHubId={currentProgram?.id || programId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withProgram(Listing)
