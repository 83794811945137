class PasswordValidator {
    static get OWASPSpecialCharacters() {
        return ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
    }

    isLongEnough(password) {
        return password.length >= 8
    }

    hasOneUppercase(password) {
        return !!password.match(/[A-Z]/)
    }

    hasOneLowercase(password) {
        return !!password.match(/[a-z]/)
    }

    hasOneDigit(password) {
        return !!password.match(/\d/)
    }

    hasOneOWASPSpecialCharacter(password) {
        for (let i = 0; i < password.length; i++) {
            if (PasswordValidator.OWASPSpecialCharacters.includes(password[i])) {
                return true
            }
        }

        return false
    }
}

export const Password = new PasswordValidator()
