import { useState } from 'react'
import Tab from '../../../components/Tab'
import Tabs from '../../../components/Tabs'
import AnalyseByCustomer from './AnalyseByCustomer'
import AnalyseByTransaction from './AnalyseByTransaction'
import AnalyseByText from './AnalyseByText'

const Analyze = () => {
    const [currentTab, setCurrentTab] = useState('customer')

    return (
        <div className="p-1 pl-4 h-full flex flex-col">
            <h3 className="text-3xl text-black text-left md:spl-8 mb-10">Let's pinpoint! 📌</h3>

            <Tabs className="my-4 md:my-10" current={`${currentTab}`} setCurrent={setCurrentTab}>
                {tabs.map((t) => (
                    <Tab key={t.key} name={t.key}>
                        {t.label}
                    </Tab>
                ))}
            </Tabs>

            <div className="h-full">
                <div className="flex flex-col h-full">
                    <div className="flex flex-col gap-4 flex-1">
                        {currentTab === 'customer' && <AnalyseByCustomer />}
                        {currentTab === 'transaction' && <AnalyseByTransaction />}
                        {currentTab === 'text' && <AnalyseByText />}
                    </div>
                </div>
            </div>
        </div>
    )
}

const tabs = [
    {
        key: 'customer',
        label: 'By customer ID',
    },
    {
        key: 'transaction',
        label: 'By transaction ID',
    },
    {
        key: 'text',
        label: 'By text',
    },
]

export default Analyze
