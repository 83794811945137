import { useTranslation } from 'react-i18next'
import RestTable, { RestTableRef } from '../../../components/Table'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import CreationModal from './CreationModal'
import { withShoppingHubOwner, WithShoppingHubOwnerProps } from '../../../contexts/ShoppingHubOwnerContext'

const Listing = forwardRef<{}, WithShoppingHubOwnerProps>(({ setShoppingHubOwners: setShosForContext }, ref) => {
    const { t } = useTranslation('common')
    const url = '/backoffice/shoppingHubOwners'
    const [creationModalOpened, setCreationModalOpened] = useState(false)
    const [shoppingHubOwner, setShoppingHubOwner] = useState([])
    const restTableRef = React.createRef<RestTableRef>()

    useImperativeHandle(ref, () => ({
        openCreationModal: () => setCreationModalOpened(true),
    }))

    const columns = [
        {
            title: t('listing_shoppinghubowner.table_header.id'),
            render: (u: any) => u.id,
        },
        { title: t('listing_shoppinghubowner.table_header.name'), render: (u: any) => u.name },
    ]

    return (
        <>
            <div className="flex flex-col gap-4 p-1">
                <div className="overflow-auto">
                    <CreationModal
                        show={creationModalOpened}
                        onClose={() => setCreationModalOpened(false)}
                        shoppingHubOwners={shoppingHubOwner}
                        onCreated={() => restTableRef.current?.refresh()}
                    />

                    <RestTable
                        queryBaseUrl={url}
                        columns={columns}
                        errorMessageKey="errors.programs.search"
                        pagination={false}
                        pageResultsKey={null}
                        ref={restTableRef}
                        onDataReceived={(d: any) => {
                            setShoppingHubOwner(d)
                            setShosForContext(d)
                        }}
                    />
                </div>
            </div>
        </>
    )
})

export default withShoppingHubOwner(Listing)
