import { ACCESS_LEVEL, FUNCTIONALITIES } from './helpers'
import { OfferProvider } from './contexts/OfferContext'
import { SettingsProvider } from './contexts/SettingsContext'
import { Spin } from 'antd'
import * as Tailwind from './../tailwind.config'
import YLogoLoading from './components/Icons/YLogoLoading'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { withAuthRequired } from './components/RequireAuth'
import { Guest } from './layouts/Guest'
import User from './layouts/user/User'
import Login from './pages/Auth/Login'

import ProgramListing from './pages/Program/Listing'
import Audience from './pages/Audience/Audience'
import OfferListing from './pages/Offers/Offers'
import OfferCreationMenu from './pages/Offers/Creation/Menu'
import OfferCreationForm from './pages/Offers/Creation/Form'
import OfferDetail from './pages/Offers/Detail/Detail'
import RegisterBusiness from './pages/Auth/RegisterBusiness/Business'
import RegisterCompany from './pages/Auth/RegisterBusiness/Company'
import RegisterBusinessDetails from './pages/Auth/RegisterBusiness/Details'
import RegisterBusinessLogs from './pages/Auth/RegisterBusiness/Logs'
import RegisterBusinessLoading from './pages/Auth/RegisterBusiness/Loading'
import RegisterUserDetails from './pages/Auth/RegisterUser/Details'
import RegisterUserLogs from './pages/Auth/RegisterUser/Logs'
import RegisterUserCreated from './pages/Auth/RegisterUser/Created'
import PasswordForgotten from './pages/Auth/PasswordForgotten'
import PasswordReset from './pages/Auth/PasswordReset'
import Analytics from './pages/Analytics/Analytics'
import StoreListing from './pages/Stores/Listing'
import StoreCreationListing from './pages/Stores/Creation/Listing'
import StoreCreationBrand from './pages/Stores/Creation/Brand'
import StoreCreationAddress from './pages/Stores/Creation/Address'
import StoreCreationLoading from './pages/Stores/Creation/Loading'
import StoreCreationConfirm from './pages/Stores/Creation/Confirm'
import StoreDetails from './pages/Stores/Details'
import ProgramCreationBenefit from './pages/Program/Creation/Benefit'
import ProgramCreationPurchase from './pages/Program/Creation/Purchase'
import ProgramCreationReward from './pages/Program/Creation/Reward'
import ProgramCreationOperate from './pages/Program/Creation/Operate'
import ProgramCreationLookAndFeel from './pages/Program/Creation/LookAndFeel'
import ProgramCreationSuccess from './pages/Program/Creation/Success'
import ProgramCreationLoading from './pages/Program/Creation/Loading'
import ProgramConfiguration from './pages/Program/Configuration/Configuration'
import { useTranslation } from 'react-i18next'
import Loading from './components/Loading'
import { ProgramSetter } from './contexts/ProgramContext'
import ShopperProfile from './pages/Audience/GlobalAudience/ShopperProfile/ShopperProfile'
import Admin from './pages/Admin/Admin'
import Create from './pages/Pinpoint/Analyze/Create/Create'
import Analyze from './pages/Pinpoint/Analyze/Analyze'
import MyContributions from './pages/Pinpoint/MyContributions/MyContributions'
import Statistics from './pages/Pinpoint/Statistics/Statistics'
import { useEffect } from 'react'
import getEnv from './utils/GetEnv'
import { ShoppingHubOwnerProvider } from './contexts/ShoppingHubOwnerContext'
import ProgramTools from './pages/Program/Tools/Tools'

const mapEnvToPageTitlePrefix: Record<string, string> = {
    development: '[dev] ',
    demo: '[demo] ',
    staging: '[staging] ',
}

function App() {
    const { ready } = useTranslation('common')

    Spin.setDefaultIndicator(
        <YLogoLoading
            width={50}
            border={(Tailwind.theme!.extend!.colors as any).primary}
            color="light-grey"
            percentage={50}
        />
    )

    // Set page title prefix if needed
    useEffect(() => {
        const pageTitlePrefix = mapEnvToPageTitlePrefix[getEnv()]
        if (pageTitlePrefix) {
            document.title = pageTitlePrefix + document.title
        }
    }, [])

    if (!ready) {
        return <Loading />
    }

    return (
        <OfferProvider>
            <SettingsProvider>
                <Routes>
                    {/* Public routes */}
                    <Route
                        path="login"
                        element={
                            <Guest>
                                <Login />
                            </Guest>
                        }
                    />
                    <Route path="register" element={withAuthRequired(<Outlet />, FUNCTIONALITIES.PROFILE)}>
                        <Route path="business/business" element={<RegisterBusiness />} />
                        <Route path="business/company" element={<RegisterCompany />} />
                        <Route path="business/company" element={<RegisterCompany />} />
                        <Route path="business/details" element={<RegisterBusinessDetails />} />
                        <Route path="business/logs" element={<RegisterBusinessLogs />} />
                        <Route path="business/loading" element={<RegisterBusinessLoading />} />
                        <Route path="user/details" element={<RegisterUserDetails />} />
                        <Route path="user/logs" element={<RegisterUserLogs />} />
                        <Route path="user/created" element={<RegisterUserCreated />} />
                    </Route>
                    <Route path="password-forgotten" element={<PasswordForgotten />} />
                    <Route path="password-reset" element={<PasswordReset />} />

                    {/* Private routes (connected) */}
                    <Route
                        path="/"
                        element={withAuthRequired(
                            <ShoppingHubOwnerProvider>
                                <User />
                            </ShoppingHubOwnerProvider>
                        )}
                    >
                        <Route index element={<Navigate to="/programs" replace />} />

                        <Route path="programs" element={withAuthRequired(<Outlet />, FUNCTIONALITIES.PROGRAM)}>
                            <Route index element={<ProgramListing />} />

                            <Route
                                path="create"
                                element={withAuthRequired(<Outlet />, FUNCTIONALITIES.PROGRAM, ACCESS_LEVEL.WRITE)}
                            >
                                <Route path="benefit" element={<ProgramCreationBenefit />} />
                                <Route path="purchase" element={<ProgramCreationPurchase />} />
                                <Route path="reward" element={<ProgramCreationReward />} />
                                <Route path="operate" element={<ProgramCreationOperate />} />
                                <Route path="look-and-feel" element={<ProgramCreationLookAndFeel />} />
                                <Route path="success" element={<ProgramCreationSuccess />} />
                                <Route path="loading" element={<ProgramCreationLoading />} />
                            </Route>

                            <Route
                                path=":programId"
                                element={withAuthRequired(
                                    <ProgramSetter>
                                        <Outlet />
                                    </ProgramSetter>,
                                    FUNCTIONALITIES.PROGRAM
                                )}
                            >
                                <Route
                                    path="configuration"
                                    element={withAuthRequired(<ProgramConfiguration />, undefined, undefined, true)}
                                />
                                <Route
                                    path="tools"
                                    element={withAuthRequired(<ProgramTools />, FUNCTIONALITIES.TOOLS, undefined, true)}
                                />
                                <Route path="audience" element={withAuthRequired(<Outlet />, FUNCTIONALITIES.AUDIENCE)}>
                                    <Route index element={<Audience />} />
                                    <Route path="shopper-profile/:customerId" element={<ShopperProfile />} />
                                </Route>
                                <Route
                                    path="offers"
                                    element={withAuthRequired(<OfferListing />, FUNCTIONALITIES.OFFER)}
                                />

                                <Route
                                    path="offers/create"
                                    element={withAuthRequired(<Outlet />, FUNCTIONALITIES.OFFER, ACCESS_LEVEL.WRITE)}
                                >
                                    <Route index element={<OfferCreationMenu />} />
                                    <Route path="form" element={<OfferCreationForm />} />
                                </Route>

                                <Route
                                    path="offers/:offerId/detail"
                                    element={withAuthRequired(<OfferDetail />, FUNCTIONALITIES.OFFER)}
                                />
                                <Route path="analytics/:boardId" element={<Analytics />} />

                                <Route path="stores" element={withAuthRequired(<Outlet />, FUNCTIONALITIES.STORE)}>
                                    <Route index element={<StoreListing />} />
                                    <Route path=":storeId/details" element={<StoreDetails />} />

                                    <Route
                                        path="create"
                                        element={withAuthRequired(
                                            <Outlet />,
                                            FUNCTIONALITIES.STORE,
                                            ACCESS_LEVEL.WRITE
                                        )}
                                    >
                                        <Route index element={<StoreCreationListing />} />
                                        <Route path="brand" element={<StoreCreationBrand />} />
                                        <Route path="address" element={<StoreCreationAddress />} />
                                        <Route path="loading" element={<StoreCreationLoading />} />
                                        <Route path="confirm" element={<StoreCreationConfirm />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>

                        <Route path="audience" element={withAuthRequired(<Outlet />, FUNCTIONALITIES.AUDIENCE)}>
                            <Route index element={<Audience />} />
                            <Route path="shopper-profile/:customerId" element={<ShopperProfile />} />
                        </Route>

                        <Route
                            path="admin"
                            element={withAuthRequired(<Outlet />, FUNCTIONALITIES.IAM, ACCESS_LEVEL.READ, true)}
                        >
                            <Route index element={<Admin />} />
                        </Route>

                        <Route path="pinpoint" element={withAuthRequired(<Outlet />, FUNCTIONALITIES.PINPOINT)}>
                            <Route index element={<Navigate to="analyze" replace />} />

                            <Route path="analyze" element={<Analyze />}></Route>
                            <Route path="analyze/:transactionId" element={<Create />}></Route>
                            <Route path="my-contributions" element={<MyContributions />}></Route>
                            <Route path="statistics" element={<Statistics />}></Route>
                        </Route>

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Route>

                    {/* Fallback */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </SettingsProvider>
        </OfferProvider>
    )
}

export default App
