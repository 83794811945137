import React, { useContext, useEffect, useMemo, useState } from 'react'
import Label from '../../../components/Label'
import Input, { InputType } from '../../../components/Input'
import { useTranslation } from 'react-i18next'
import Datepicker from '../../../components/Datepicker'
import moment from 'moment-timezone'
import Textarea from '../../../components/Textarea'
import { Offer } from '../../../interfaces/Offer'
import { CurrentProgram } from '../../../interfaces/Program'
import OfferContext from '../../../contexts/OfferContext'
import { useOverlapOtherWelcomeOffer } from '../../../hooks/OfferCreationHook/useOverlapOtherWelcomeOffer'
import FormError from '../../../components/FormError'
import Checkbox from '../../../components/Checkbox'

type OfferUpdateProps = {
    formData: Offer
    editButtonActive: boolean
    onChange: (e: Partial<OfferUpdateProps['formData']>) => void
    hasError: (hasError: boolean) => void
    currentProgram: CurrentProgram
}

/**
 * Form that displays the information regarding a challenge
 * Only the reward value and the endDate of a challenge can be updated
 * @param formData
 * @param editButtonActive
 * @param onChange
 * @param onError
 * @param currentProgram
 * @return {JSX.Element}
 * @constructor
 */
export const Update: React.FC<OfferUpdateProps> = ({
    formData,
    editButtonActive,
    onChange,
    hasError,
    currentProgram,
}) => {
    const { offers } = useContext(OfferContext)
    const [endDate, setEndDate] = useState<Date | string | null>(null)
    const [rewardValue, setRewardValue] = useState<number | string>()
    const [rewardUnit, setRewardUnit] = useState('€')
    const { t } = useTranslation('common')
    const dateFormat = 'MM/DD/YYYY'

    /**
     * The program timezone. Default to Paris (2 cases : currentProgram is loading or the program has no latitude/longitude).
     * @type {string}
     */
    const programTimezone = currentProgram?.timezone || 'Europe/Paris'

    const otherOffers = (offers || ([] as Offer[])).filter((o) => o.id !== formData.id)
    const { overlappedOffer } = useOverlapOtherWelcomeOffer(
        formData.offerType,
        otherOffers,
        formData.startDate,
        endDate || undefined,
        programTimezone
    )

    useEffect(() => {
        hasError(!!overlappedOffer)
    }, [overlappedOffer])

    useEffect(() => {
        if (formData.endDate) {
            setEndDate(new Date(moment(formData.endDate).tz(programTimezone).subtract(1, 'days').format('L')))
        }

        setRewardValue(formData.rewardLabel)
        if (formData.rewardLabel?.match(/%/)) {
            setRewardUnit('%')
        }
    }, [formData, editButtonActive, programTimezone])

    /**
     * Parse the date and display it in the correct date format
     * @return {Date|string}
     */
    const handleDateValue = () => (endDate ? moment(endDate, 'MM/DD/YYYY').toDate() : undefined)

    /**
     * Set the reward value according if the unit is % or a currency
     */
    const updateRewardValue = (e: number) => {
        if (rewardUnit === '%') {
            onChange({ rewardValue: e / 100 })
            if (e > 100) {
                // max value for percent unit
                setRewardValue(100)
                onChange({ rewardValue: 1 })
            } else {
                setRewardValue(e)
            }
        } else {
            onChange({ rewardValue: e })
            setRewardValue(e)
        }
    }

    const offerStarted = useMemo(
        () => moment(formData.startDate).tz(programTimezone).isBefore(),
        [formData.startDate, programTimezone]
    )

    return (
        <div className={'lg:mt-20 grid grid-cols-12 mx-auto gap-3'}>
            <div className={'col-span-12 xl:col-span-11 2xl:col-span-10 grid grid-cols-8 gap-6'}>
                <div className={'col-span-8 lg:col-span-4'}>
                    <Label>{t('offerCreation.name')}</Label>
                    <Input
                        className="mt-3.5"
                        value={formData.teaser || ''}
                        disabled={true}
                        placeholder={formData.teaser || ''}
                        maxLength={25}
                        data-cy="offer_teaser"
                    />
                </div>

                <div className={'col-span-8 lg:col-span-2'}>
                    <Label>{t('offerCreation.from')}</Label>
                    <Datepicker
                        disabled={true}
                        className="mt-3.5"
                        placeholder={moment(formData.startDate).tz(programTimezone).format('DD/MM/YY')}
                        data-cy="offer_start_date"
                    />
                </div>

                <div className={'col-span-8 lg:col-span-2'}>
                    <Label>{t('offerCreation.to')}</Label>
                    <Datepicker
                        minDate={moment(formData.startDate).toDate()}
                        className="mt-3.5"
                        value={handleDateValue()}
                        onChange={(e: string) => {
                            setEndDate(moment(e).format(dateFormat))
                            onChange({ endDate: moment(e).add(1, 'days').format() })
                        }}
                        disabled={formData.offerType === 'welcome_offer' || !editButtonActive}
                        placeholder={t('offerCreation.toDate')}
                        error={!!overlappedOffer && !(formData.offerType === 'welcome_offer' || !editButtonActive)}
                        data-cy="offer_end_date"
                    />
                    {overlappedOffer && (
                        <FormError>
                            {t('offerCreation.error.overlap-existing-welcome-offer', {
                                offerTeaser: overlappedOffer.teaser,
                            })}
                        </FormError>
                    )}
                </div>

                <div className={'col-span-8 lg:col-span-2 grid gap-x-2 gap-y-4'}>
                    <div className="flex flex-col">
                        <Label className="flex justify-between">
                            <div>{t('offerCreation.reward')}</div>
                        </Label>
                        <div className="flex gap-2">
                            <Input
                                type={InputType.NUMBER}
                                className="mt-3.5"
                                value={rewardValue as number}
                                onChange={(e: number) => updateRewardValue(e)}
                                placeholder={formData.rewardLabel || undefined}
                                disabled={!editButtonActive || offerStarted}
                                validateIntegerNumbers={true}
                                data-cy="offer_reward_value"
                            />
                        </div>
                    </div>
                </div>

                {formData.offerType !== 'referral' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>{t('offerCreation.minPurchase')}</Label>
                        <Input
                            disabled={true}
                            type={InputType.NUMBER}
                            className="mt-3.5"
                            value={formData.conditionValue}
                            placeholder={formData.conditionValue?.toString()}
                            data-cy="offer_min_purchase"
                        />
                    </div>
                )}

                {formData.offerType === 'average' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>{t('offerCreation.reward_limit')}</Label>
                        <Input
                            disabled={true}
                            type={InputType.NUMBER}
                            className="mt-3.5"
                            value={formData.rewardCumulatedLimit}
                            placeholder={'200'}
                            data-cy="offer_reward_limit"
                        />
                    </div>
                )}

                {formData.offerType !== 'average' && formData.offerType !== 'referral' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>{t('offerCreation.redeem')}</Label>
                        <Input
                            disabled={true}
                            type={InputType.NUMBER}
                            className="mt-3.5"
                            value={formData.occurrenceLimit}
                            placeholder={formData.occurrenceLimit?.toString()}
                            data-cy="offer_redeem"
                        />
                    </div>
                )}

                {formData.offerType !== 'referral' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>{t('offerCreation.campaignBudget')}</Label>
                        <Input
                            disabled={true}
                            type={InputType.NUMBER}
                            className="mt-3.5"
                            value={formData.cappingLimit}
                            placeholder={formData.cappingLimit?.toString() || '0'}
                            currency={true}
                            data-cy="offer_campaign_budget"
                        />
                    </div>
                )}

                <div className={'col-span-8 lg:col-span-4'}>
                    <Label>{t('offerCreation.description')}</Label>
                    <Textarea
                        disabled={true}
                        className="mt-3.5 h-48"
                        value={formData.conditionLabel}
                        placeholder={t('offerCreation.offerDescription')}
                        maxLength={50}
                        data-cy="offer_description"
                    />
                </div>

                <div className={'col-span-8 lg:col-span-4'}>
                    <Label>{t('offerCreation.displayed')}</Label>
                    <br />
                    <Checkbox
                        onChange={(displayed: boolean) => onChange({ displayed })}
                        checked={formData.displayed}
                        data-cy="offer_displayed"
                        disabled={!editButtonActive}
                    />
                </div>
            </div>
        </div>
    )
}
