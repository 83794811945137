import PropTypes from 'prop-types'

// Modifying the size (either with a padding class, like "p-4", or width/height, like "w-8 h-8") will create a bigger checkbox.
// Yes, the check will automatically be bigger (it's a background image).
function Checkbox({ className = '', onChange, checked = false, disabled = false, ...props }) {
    return (
        <input
            {...props}
            type="checkbox"
            className={[
                'border border-1 focus:ring-transparent checked:bg-primary hover:text-primary focus:text-primary hover:opacity-90',
                disabled ? 'border-gray-300 ' : 'border-black cursor-pointer',
                className,
            ].join(' ')}
            onChange={(e) => onChange(!checked)}
            checked={checked}
            disabled={disabled}
        />
    )
}

Checkbox.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default Checkbox
