import React, { forwardRef, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FUNCTIONALITIES, Request } from '../helpers'
import ErrorManagementContext from './ErrorManagementContext'
import AuthContext from './AuthContext'
import { useNavigate } from 'react-router-dom'
import ProgramContext from './ProgramContext'
import { canAccess } from '../components/RequireAuth'

export type WithShoppingHubOwnerProps = {
    shoppingHubOwners: any[]
    currentShoppingHubOwner: any
    setCurrentShoppingHubOwner: (sho: any) => void
    setShoppingHubOwners: (shos: any[]) => void
}

const ShoppingHubOwnerContext = React.createContext<WithShoppingHubOwnerProps>({
    shoppingHubOwners: [],
    currentShoppingHubOwner: null,
    setCurrentShoppingHubOwner: () => {},
    setShoppingHubOwners: () => {},
})

export function ShoppingHubOwnerProvider({ children }: { children: ReactNode }) {
    const [shoppingHubOwners, setShoppingHubOwners] = useState<any[]>([])
    const [current, setCurrent] = useState<any>()
    const { handleError } = useContext(ErrorManagementContext)
    const { setCurrentProgram } = useContext(ProgramContext)
    const authCtx = useContext(AuthContext)
    const url = '/backoffice/shoppingHubOwners'
    const navigate = useNavigate()
    const firstQuery = useRef(false)

    const refreshShoppingHubOwners = useCallback(() => {
        if (canAccess(authCtx.user, FUNCTIONALITIES.IAM) || canAccess(authCtx.user, FUNCTIONALITIES.HUB_SETUP_INIT)) {
            Request.get<any[]>(url)
                .then((shos) => {
                    setShoppingHubOwners(shos.sort((a, b) => a.name.localeCompare(b.name)))
                    firstQuery.current = true
                })
                .catch((e) => {
                    if (e.statusCode !== 403) return handleError('errors.shoppingHubOwners.get')(e)
                    setShoppingHubOwners([])
                    firstQuery.current = true
                })
        } else {
            setShoppingHubOwners([])
            firstQuery.current = true
        }
    }, [handleError])

    useEffect(() => {
        if (authCtx.user && !firstQuery.current) refreshShoppingHubOwners()
    }, [refreshShoppingHubOwners, authCtx.user])

    return (
        <ShoppingHubOwnerContext.Provider
            value={{
                shoppingHubOwners,
                currentShoppingHubOwner: current,
                setCurrentShoppingHubOwner: (sho) => {
                    setCurrent(sho)
                    setCurrentProgram(null)
                    navigate('/programs')
                },
                setShoppingHubOwners: (shos) => setShoppingHubOwners(shos.sort((a, b) => a.name.localeCompare(b.name))),
            }}
        >
            {children}
        </ShoppingHubOwnerContext.Provider>
    )
}

export function withShoppingHubOwner<T extends WithShoppingHubOwnerProps = WithShoppingHubOwnerProps>(
    Component: React.ComponentType<T>
): (p: any) => any {
    return forwardRef((props: any, ref: any) => (
        <ShoppingHubOwnerContext.Consumer>
            {(value) => <Component {...props} {...value} ref={ref} />}
        </ShoppingHubOwnerContext.Consumer>
    ))
}

export default ShoppingHubOwnerContext
