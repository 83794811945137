import PropTypes from 'prop-types'

function FormError({ children, className = '' }) {
    return <span className={['text-error text-xs italic', className].join(' ')}>{children}</span>
}

FormError.propTypes = {
    className: PropTypes.string,
}

export default FormError
