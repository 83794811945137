import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { ShopperProfileSegment } from '../shopper-profile-behavior-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Tailwind from '../../../../../../../tailwind.config'

type ShoppingHabitsChartProps = {
    segments: ShopperProfileSegment[]
    unselectedSegmentsId: string[]
}

function ShoppingHabitsChart({ segments, unselectedSegmentsId }: ShoppingHabitsChartProps) {
    const { t } = useTranslation('common')

    const data = useMemo(() => {
        const selectedSegments = segments.filter((s) => !unselectedSegmentsId.includes(s.id))
        const visitCountPerDay = [0, 0, 0, 0, 0, 0, 0]

        if (selectedSegments.length === 0)
            return visitCountPerDay.map((_, index) => ({
                name: t('days.' + index),
                value: 0,
            }))

        selectedSegments
            .map((s) => s.shopping_habits)
            .forEach((sh) => sh.forEach((count, index) => (visitCountPerDay[index] += count)))

        return visitCountPerDay.map((count, index) => ({
            name: t('days.' + index),
            value: count,
        }))
    }, [segments, unselectedSegmentsId])

    return (
        <div className="bg-white pt-8 pb-10 2xl:pl-10 xl:pl-8 pl-6 pr-6 2xl:pr-10 xl:pr-8">
            <div className="w-full text-xl font-black mb-6">{t('shopperProfile.shoppingHabits.title')}</div>
            <ResponsiveContainer width="100%" height={350}>
                <BarChart width={600} height={350} data={data} barCategoryGap="30%">
                    <XAxis dataKey="name" />
                    <Tooltip
                        cursor={{ fill: (Tailwind as any).theme.extend.colors.primary + '22' }}
                        content={(d: any) => (
                            <div className="p-6 bg-white border border-primary">
                                <b style={{ color: d.payload[0]?.color }}>
                                    {d.payload[0]?.value} {t('shopperProfile.shoppingHabits.visits')}
                                </b>
                            </div>
                        )}
                    />
                    <Bar dataKey="value" fill={(Tailwind as any).theme.extend.colors.primary} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ShoppingHabitsChart
