import RequireAuth from '../../components/RequireAuth'
import { FUNCTIONALITIES } from '../../helpers'
import { ProgramIcon } from '../../components/Icons/Program'
import { PinpointIcon } from '../../components/Icons/Pinpoint'
import { AudienceIcon } from '../../components/Icons/Audience'
import { useMemo } from 'react'
import NavButton from './NavButton'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type MainMenuProps = {
    showNav: boolean
}

export default function MainMenu({ showNav }: MainMenuProps) {
    const { t } = useTranslation('common')
    const location = useLocation()
    const classes = useMemo(
        () =>
            'flex flex-col relative md:h-auto md:flex-1 transition-all overflow-auto mb-8' +
            (showNav ? 'h-auto' : 'h-0'),
        [showNav]
    )

    return (
        <div className={classes}>
            <div className="mr-3 flex-1 flex flex-col gap-y-2">
                <RequireAuth functionality={FUNCTIONALITIES.PROGRAM}>
                    <NavButton url="programs">
                        <ProgramIcon />
                        {t('menu.user.programs')}
                    </NavButton>
                </RequireAuth>

                <NavButton url="audience">
                    <AudienceIcon />
                    {t('menu.user.audience')}
                </NavButton>

                <RequireAuth functionality={FUNCTIONALITIES.PINPOINT}>
                    <>
                        <NavButton url="pinpoint" active={location.pathname.includes('/pinpoint/')}>
                            <PinpointIcon />
                            {t('menu.user.pinpoint.title')}
                        </NavButton>
                        {location.pathname.includes('/pinpoint/') && (
                            <>
                                <NavLink to={`/pinpoint/analyze`}>
                                    {({ isActive }) => (
                                        <div className={'block ml-8 ' + (isActive ? '' : 'text-black')}>
                                            {t('menu.user.pinpoint.analyse')}
                                        </div>
                                    )}
                                </NavLink>
                                <NavLink to={`/pinpoint/my-contributions`}>
                                    {({ isActive }) => (
                                        <div className={'block ml-8 ' + (isActive ? '' : 'text-black')}>
                                            {t('menu.user.contributions')}
                                        </div>
                                    )}
                                </NavLink>
                                <NavLink to={`/pinpoint/statistics`}>
                                    {({ isActive }) => (
                                        <div className={'block ml-8 ' + (isActive ? '' : 'text-black')}>
                                            {t('menu.user.pinpoint.statistics')}
                                        </div>
                                    )}
                                </NavLink>
                            </>
                        )}
                    </>
                </RequireAuth>
            </div>
        </div>
    )
}
