import React from 'react'

function CampaignLaunch({ width = 10, height = width * 1.6, color = '#454545' }) {
    return (
        <svg width="485" height="366" viewBox="0 0 485 366" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.08"
                d="M242.499 313.191C321.33 313.191 385.235 308.609 385.235 302.957C385.235 297.305 321.33 292.723 242.499 292.723C163.669 292.723 99.7637 297.305 99.7637 302.957C99.7637 308.609 163.669 313.191 242.499 313.191Z"
                fill="black"
            />
            <path d="M286.393 303.977H351.783V287.903H286.393V303.977Z" fill="#D8D8D8" />
            <path d="M281.578 287.904H340.506V271.831H281.578V287.904Z" fill="#E6E6E6" />
            <path
                opacity="0.58"
                d="M323.179 261.91C323.179 261.91 330.915 259.798 332.588 252.599C332.588 252.599 320.621 250.171 320.281 262.554L323.179 261.91Z"
                fill="#FF7700"
            />
            <path
                opacity="0.73"
                d="M322.234 261.097C322.234 261.097 327.63 252.599 322.889 244.574C322.889 244.574 313.783 250.353 317.833 261.121L322.234 261.097Z"
                fill="#FF7700"
            />
            <path
                d="M320.851 261.111C320.851 261.111 317.99 252.091 309.369 250.379C309.369 250.379 307.757 256.23 314.959 261.135L320.851 261.111Z"
                fill="#FF7700"
            />
            <path
                d="M326.453 260.904L324.889 271.636L315.019 271.673L313.564 260.965L326.453 260.904Z"
                fill="#24285B"
            />
            <path d="M138.831 253.072L372.746 253.072V83.0002L138.831 83.0002L138.831 253.072Z" fill="white" />
            <path
                opacity="0.55"
                d="M233.2 170.35L228.229 176.602C222.943 183.24 220.446 191.679 221.269 200.13L222.227 209.842L236.777 196.609L233.2 170.35Z"
                fill="#878787"
            />
            <path
                opacity="0.55"
                d="M268.108 170.35L273.091 176.602C278.392 183.234 280.902 191.674 280.088 200.13L279.142 209.842L264.592 196.609L268.108 170.35Z"
                fill="#878787"
            />
            <path
                opacity="0.55"
                d="M244.015 198.624C244.015 198.624 248.55 225.332 241.093 227.578C233.636 229.824 230.181 217.781 220.335 224.944C210.49 232.106 223.112 240.022 205.725 237.824C188.337 235.627 181.608 244.21 185.694 253.024H314.037C314.037 253.024 320.476 244.853 313.31 239.293C311.752 238.121 309.96 237.297 308.056 236.878C306.153 236.459 304.181 236.455 302.276 236.865C301.75 236.954 301.211 236.926 300.697 236.784C300.183 236.641 299.707 236.387 299.302 236.039C298.897 235.692 298.573 235.259 298.354 234.773C298.135 234.286 298.025 233.757 298.032 233.223C298.105 230.601 297.656 227.602 295.607 225.781C293.182 223.572 288.029 225.878 284.198 228.209C279.846 230.891 274.725 232.04 269.648 231.475C265.198 230.941 260.651 229.472 259.402 225.793C256.771 218.048 258.371 198.102 258.371 198.102L244.015 198.624Z"
                fill="#E6E6E6"
            />
            <path
                d="M250.673 114.941C250.673 114.941 213.934 135.131 236.79 196.596H264.568C287.424 135.131 250.673 114.941 250.673 114.941Z"
                fill="#C1C1C1"
            />
            <path d="M264.567 196.596L263.331 201.306H238.135L236.789 196.037L264.567 196.596Z" fill="#878787" />
            <path
                d="M250.939 160.443C256.149 160.443 260.372 156.214 260.372 150.998C260.372 145.781 256.149 141.553 250.939 141.553C245.729 141.553 241.506 145.781 241.506 150.998C241.506 156.214 245.729 160.443 250.939 160.443Z"
                fill="#E6E6E6"
            />
            <path
                d="M155.551 106.272C158.813 106.272 161.456 103.625 161.456 100.36C161.456 97.0943 158.813 94.4473 155.551 94.4473C152.29 94.4473 149.646 97.0943 149.646 100.36C149.646 103.625 152.29 106.272 155.551 106.272Z"
                fill="#FFD200"
            />
            <path
                d="M172.174 106.272C175.436 106.272 178.079 103.625 178.079 100.36C178.079 97.0943 175.436 94.4473 172.174 94.4473C168.913 94.4473 166.27 97.0943 166.27 100.36C166.27 103.625 168.913 106.272 172.174 106.272Z"
                fill="#FF7700"
            />
            <path
                d="M188.083 106.272C191.344 106.272 193.988 103.625 193.988 100.36C193.988 97.0943 191.344 94.4473 188.083 94.4473C184.821 94.4473 182.178 97.0943 182.178 100.36C182.178 103.625 184.821 106.272 188.083 106.272Z"
                fill="white"
            />
            <path d="M358.489 173.906H325.363V179.491H358.489V173.906Z" fill="white" />
            <path d="M358.489 188.619H325.363V194.204H358.489V188.619Z" fill="white" />
            <path d="M315.044 173.906H305.271V179.491H315.044V173.906Z" fill="#FF7700" />
            <path d="M315.044 188.619H305.271V194.204H315.044V188.619Z" fill="#FFD200" />
            <path
                d="M327.848 161.487C339.895 161.487 349.661 151.709 349.661 139.647C349.661 127.585 339.895 117.807 327.848 117.807C315.801 117.807 306.035 127.585 306.035 139.647C306.035 151.709 315.801 161.487 327.848 161.487Z"
                fill="white"
            />
            <path
                d="M327.848 117.806V139.658L349.2 135.202C349.2 135.202 346.229 117.66 327.848 117.806Z"
                fill="#FFD200"
            />
            <path
                d="M327.848 139.647L315.723 157.857C315.723 157.857 327.945 167.169 342.107 156.218C342.107 156.218 351.807 149.189 349.212 135.252L327.848 139.647Z"
                fill="#FF7700"
            />
            <path
                d="M282.38 238.043L286.648 213.848H256.335L253.704 234.474L239.797 234.668V238.043H282.38Z"
                fill="#FFD200"
            />
            <path d="M258.894 216.641L256.82 234.159H279.397L282.028 216.641H258.894Z" fill="white" />
            <path d="M298.529 237.242H230.689V303.977H298.529V237.242Z" fill="#D8D8D8" />
            <path
                opacity="0.08"
                d="M253.011 237.242C253.011 237.242 234.703 290.052 270.605 303.953H230.52V237.242H253.011Z"
                fill="black"
            />
            <path
                d="M216.298 122.25H157.08C153.705 122.25 150.969 124.989 150.969 128.369V133.953C150.969 137.332 153.705 140.072 157.08 140.072H216.298C219.673 140.072 222.409 137.332 222.409 133.953V128.369C222.409 124.989 219.673 122.25 216.298 122.25Z"
                fill="#C1C1C1"
            />
            <path d="M162.645 127.615H164.9V133.066H168.355V134.826H162.693L162.645 127.615Z" fill="white" />
            <path
                d="M173.702 133.587H171.192L170.841 134.801H168.537L171.229 127.639H173.654L176.346 134.801H174.03L173.702 133.587ZM173.242 132.046L172.453 129.423L171.665 131.997L173.242 132.046Z"
                fill="white"
            />
            <path
                d="M181.378 127.615H183.585V131.852C183.591 132.265 183.526 132.676 183.391 133.066C183.261 133.436 183.05 133.772 182.773 134.05C182.525 134.311 182.223 134.514 181.887 134.644C181.388 134.815 180.863 134.897 180.335 134.887C179.954 134.885 179.574 134.861 179.196 134.814C178.837 134.781 178.488 134.682 178.165 134.523C177.88 134.363 177.625 134.154 177.413 133.904C177.2 133.665 177.039 133.384 176.94 133.078C176.815 132.685 176.746 132.277 176.734 131.864V127.615H178.941V131.986C178.93 132.152 178.954 132.318 179.01 132.475C179.067 132.632 179.155 132.775 179.269 132.896C179.388 133.009 179.529 133.096 179.684 133.152C179.838 133.209 180.002 133.233 180.166 133.224C180.329 133.235 180.492 133.212 180.647 133.158C180.801 133.104 180.942 133.019 181.063 132.908C181.285 132.654 181.398 132.323 181.378 131.986V127.615Z"
                fill="white"
            />
            <path
                d="M185.064 127.615H187.126L189.817 131.573V127.615H191.903V134.778H189.817L187.138 130.845V134.778H185.064V127.615Z"
                fill="white"
            />
            <path
                d="M198.122 131.851L200.062 132.434C199.952 132.925 199.741 133.388 199.443 133.794C199.171 134.153 198.813 134.437 198.401 134.619C197.9 134.819 197.363 134.914 196.824 134.898C196.173 134.925 195.523 134.809 194.921 134.558C194.382 134.289 193.935 133.867 193.636 133.344C193.258 132.68 193.073 131.923 193.102 131.159C193.062 130.671 193.121 130.179 193.275 129.714C193.429 129.249 193.676 128.82 193.999 128.452C194.67 127.898 195.494 127.562 196.36 127.488C197.226 127.414 198.095 127.607 198.849 128.039C199.408 128.46 199.812 129.053 200.001 129.727L198.049 130.152C198.006 129.982 197.932 129.821 197.831 129.678C197.716 129.513 197.562 129.38 197.382 129.29C197.199 129.195 196.994 129.149 196.788 129.156C196.561 129.144 196.334 129.193 196.132 129.297C195.929 129.402 195.758 129.558 195.636 129.751C195.402 130.181 195.297 130.67 195.333 131.159C195.275 131.73 195.403 132.305 195.697 132.798C195.818 132.945 195.973 133.061 196.148 133.137C196.323 133.213 196.513 133.247 196.703 133.235C196.879 133.25 197.056 133.226 197.221 133.165C197.386 133.104 197.537 133.008 197.661 132.883C197.901 132.585 198.06 132.229 198.122 131.851Z"
                fill="white"
            />
            <path
                d="M201.141 127.615H203.348V130.128H205.773V127.615H207.992V134.778H205.761V131.852H203.336V134.754H201.129L201.141 127.615Z"
                fill="white"
            />
            <path d="M217.474 147.33H181.354V168.66H217.474V147.33Z" fill="#FF7700" />
        </svg>
    )
}

export default CampaignLaunch
