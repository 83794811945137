export enum EKpi {
    MEMBERS = 'members',
    /*DISTRIBUTION = 'distribution',
    AVERAGE_BASKET = 'averageBasket',
    PURCHASE_FREQUENCY = 'purchaseFrequency',
    OVERALL_BUDGET = 'overallBudget',*/
}

export enum ETypeKpi {
    ICON,
    DONUT,
}

export type IconData = {
    value: number
    increasing: boolean
}

export type DonutData = {
    [k: string]: number
}

export type KpiData = IconData | DonutData
