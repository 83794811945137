import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { Table } from '../../../../../components/Table'
import { useState } from 'react'
import RedeemRewardModal from './RedeemRewardModal'

type RewardEntry = {
    id: string
    offerName: string
    amount: number
    redeemed: boolean
    redeemDate: Date
}

type RewardsTableProps = {
    history: RewardEntry[]
    refresh: () => void
}

function RewardsTable({ history, refresh }: RewardsTableProps) {
    const { t } = useTranslation('common')
    const [redeemRewardModalOpened, setRedeemRewardModalOpened] = useState(false)
    const [currentReward, setCurrentReward] = useState(null)

    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.rewardsTable.header.offerName')}
                </div>
            ),
            render: (r) => r.Challenge.teaser,
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.rewardsTable.header.completionDate')}
                </div>
            ),
            render: (r) => moment(r.end_date).format('DD/MM/YYYY'),
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.rewardsTable.header.amount')}
                </div>
            ),
            render: (r) => `${r.reward_value}`,
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.rewardsTable.header.redeemDate')}
                </div>
            ),
            render: (r) => (r.redeem_date ? moment(r.redeem_date).format('DD/MM/YYYY') : 'N/A'),
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.rewardsTable.header.action')}
                </div>
            ),
            render: (r) =>
                r.redeemed ? null : (
                    <button
                        onClick={() => {
                            setCurrentReward(r)
                            setRedeemRewardModalOpened(true)
                        }}
                        className="text-xs px-2 py-1 h-8 border border-primary text-primary border-1"
                    >
                        {t(`shopperProfile.rewardsTable.redeem.button`)}
                    </button>
                ),
        },
    ]

    return (
        <>
            <div className="flex flex-row mb-6">
                <div className="flex-1 font-black">
                    <span className="text-xl">{t('shopperProfile.rewardsTable.title')}</span>
                </div>
                <RedeemRewardModal
                    reward={currentReward}
                    show={redeemRewardModalOpened}
                    onClose={() => setRedeemRewardModalOpened(false)}
                    onCreated={() => refresh()}
                />
            </div>

            <Table data={history} columns={columns} pageSize={10} tableLayout="fixed" />
        </>
    )
}

export default RewardsTable
