import { useRef, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import { useTranslation } from 'react-i18next'
import mapMarker from './Icons/Location.png'
import clusteredMapMarker from './Icons/ClusteredMarker.png'
import _ from 'lodash'
import 'mapbox-gl/dist/mapbox-gl.css'
import './Map.css'
import { Request } from '../helpers'

type Filter = {
    cities?: string[]
    countries?: string[]
    id?: string
    search?: string
}

if (process.env.REACT_APP_MAPBOX_API) {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API
} else {
    console.error('process.env.REACT_APP_MAPBOX_API is empty')
}
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-expect-error
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const EUROPE_LONGITUDE = 9
const EUROPE_LATITUTE = 53

function storesToGeoJSON(stores: any) {
    return {
        type: 'geojson',
        data: stores || { type: 'FeatureCollection', features: [] },
        cluster: true,
        clusterRadius: 80,
    }
}

function buildUrl(filter?: Filter, shoppingHubId?: string) {
    if (!shoppingHubId) {
        return
    }
    const queryParams: Record<string, string> = {}
    if (shoppingHubId) {
        queryParams.shoppingHubId = shoppingHubId
    }
    if (filter?.id) {
        queryParams.id = filter.id
    }
    if (filter?.cities?.length) {
        queryParams.cities = filter.cities.toString()
    }
    if (filter?.countries?.length) {
        queryParams.countries = filter.countries.toString()
    }
    if (filter?.search) {
        queryParams.search = filter.search
    }
    const urlSearchParams = new URLSearchParams(queryParams)
    let url = `backoffice/stores/map?${urlSearchParams}`
    return url
}

type MapProps = {
    latitude?: number
    longitude?: number
    zoom?: number
    className?: string
    info?: boolean
    shoppingHubId: string
    filter?: Filter
    isInteractive?: boolean
    isSingleStore?: boolean
}

function Map({
    latitude = EUROPE_LATITUTE,
    longitude = EUROPE_LONGITUDE,
    zoom = 3,
    className,
    info = false,
    shoppingHubId,
    filter,
    isInteractive = true,
    isSingleStore = false,
}: MapProps) {
    const { t } = useTranslation('common')
    const mapContainer = useRef<HTMLDivElement>(null)
    const [stores, setStores] = useState<{
        features?: any[]
    }>({ features: [] })
    const map = useRef<any>()
    const [storesNumber, setStoresNumber] = useState(stores?.features?.length)
    const [isMapReady, setMapReady] = useState(false)
    // TODO stores is not Array
    // @ts-expect-error
    const [countriesNumber, setCountriesNumber] = useState(_.uniqBy(stores, 'country').length)

    let mapInfo = useMemo(
        () =>
            !info ? null : (
                <div className="text-primary text-xl info-map font-bold">
                    {t('listing_stores.stores_number', { count: storesNumber })} |{' '}
                    {t('listing_stores.countries_number', { count: countriesNumber })}
                </div>
            ),
        [info, storesNumber, countriesNumber]
    )

    // Update stores from filters
    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller

        const fetchStores = async () => {
            const url = buildUrl(filter, shoppingHubId)
            if (url) {
                const stores: any = await Request.get(url, {}, signal)
                setStores(stores)
                setStoresNumber(stores?.features?.length)
                setCountriesNumber(_.uniqBy(stores?.features, 'properties.country').length)
            }
        }

        if (!isSingleStore) fetchStores()

        return () => {
            controller.abort()
        }
    }, [filter?.search, filter?.countries, filter?.cities, shoppingHubId])

    // Init map
    useEffect(() => {
        if (map.current) return

        map.current = new mapboxgl.Map({
            container: mapContainer.current as HTMLElement,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [longitude, latitude],
            zoom: zoom,
            interactive: isInteractive,
        })
        map.current?.on('load', () => {
            map.current.addSource('stores', storesToGeoJSON(stores))
            map.current.loadImage(mapMarker, (err?: Error, img?: string) => {
                if (err) return
                if (img) {
                    map.current.addImage('marker', img)
                }
            })
            map.current.loadImage(clusteredMapMarker, (err?: Error, img?: string) => {
                if (err) return
                if (img) {
                    map.current.addImage('clustered_marker', img)
                }
            })
            if (longitude && latitude && isSingleStore) {
                map.current.addSource('points', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [longitude, latitude],
                                },
                            },
                        ],
                    },
                })
                map.current.addLayer({
                    id: 'points',
                    type: 'symbol',
                    source: 'points',
                    layout: {
                        'icon-image': 'marker',
                        'icon-size': 1.5,
                    },
                })
            }
            map.current.addLayer({
                id: 'clustered_stores_icon',
                type: 'symbol',
                source: 'stores',
                filter: ['has', 'point_count'],
                layout: {
                    'icon-image': 'clustered_marker',
                    'icon-size': 1.25,
                    'icon-ignore-placement': true,
                },
            })
            map.current.addLayer({
                id: 'clustered_stores_number',
                type: 'symbol',
                source: 'stores',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-ignore-placement': true,
                },
                paint: {
                    'text-color': '#ffffff',
                },
            })
            map.current.addLayer({
                id: 'unclustered_stores',
                type: 'symbol',
                source: 'stores',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': 'marker',
                    'icon-size': 1.25,
                    'icon-ignore-placement': true,
                },
            })
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
            })
            map.current.on('mouseenter', 'unclustered_stores', (e: any) => {
                map.current.getCanvas().style.cursor = 'pointer'

                const coordinates = e.features[0].geometry.coordinates.slice()
                const description = e.features[0].properties.description

                popup.setLngLat(coordinates).setHTML(description).addTo(map.current)
            })
            if (longitude && latitude && isSingleStore) {
                map.current.on('mouseenter', 'unclustered_stores', () => {
                    map.current.getCanvas().style.cursor = 'pointer'
                    popup.setLngLat([longitude, latitude]).addTo(map.current)
                })
            }
            map.current.on('mouseleave', 'unclustered_stores', () => {
                map.current.getCanvas().style.cursor = ''
                popup.remove()
            })
            if (isInteractive) {
                map.current.on('click', (event: any) => {
                    map.current.flyTo({ center: event.lngLat, zoom: 12 })
                })
            }

            setMapReady(true)
        })
    }, [map.current])

    // Update bounds and zoom
    useEffect(() => {
        if (!isMapReady) return

        const source = map?.current?.getSource('stores')
        source.setData(stores)

        if (stores?.features?.length) {
            const bounds = new mapboxgl.LngLatBounds()

            stores?.features
                .filter((n: any) => isFinite(n.geometry.coordinates[0]) && isFinite(n.geometry.coordinates[1]))
                .forEach((store: any) => {
                    bounds.extend([Number(store.geometry.coordinates[0]), Number(store.geometry.coordinates[1])])
                })

            map.current.fitBounds(bounds, { padding: 75, maxZoom: 6 })
        }
    }, [stores, isMapReady])

    return (
        <div ref={mapContainer} className={['map-container h-full', className].join(' ')}>
            {mapInfo}
        </div>
    )
}

Map.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    zoom: PropTypes.number,
}

export default Map
