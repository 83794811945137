import DropdownInput from '../../../components/DropdownInput'
import Button, { ButtonAppearance } from '../../../components/Button'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Request } from '../../../helpers'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import { PageItem } from '../../../components/Table'

const url = (id: string) => `/backoffice/v3/pinpoint/transactions?transactionId=${id}`
const UUID_REGEX = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)

const AnalyseByTransaction = () => {
    const location = useLocation()
    const locationData = location.state as any
    const [search, setSearch] = useState<string>(locationData?.transactionId || '')
    const navigate = useNavigate()
    const { handleError } = useContext(ErrorManagementContext)

    useEffect(() => {
        if (!!search && search.match(UUID_REGEX)) {
            Request.get<any>(url(search))
                .then((res: PageItem<any>) => {
                    if (res.count > 0) {
                        const t = res.rows[0]
                        navigate(`/pinpoint/analyze/${t.id}`, { state: { transaction: t } })
                    }
                })
                .catch(handleError('errors.pinpoint.byTransactionId'))
        }
    }, [search])

    return (
        <div className="grid grid-cols-1 gap-4">
            <div className="w-full flex justify-between gap-2">
                <div className="2xl:w-1/3 lg:w-3/4 w-full flex gap-2">
                    <DropdownInput
                        onChange={(e) => setSearch(e.value)}
                        options={[]}
                        placeholder={'Transaction id'}
                        value={search}
                        asSearchInput
                        withoutMenu
                        className="h-full"
                        data-cy="search_input"
                    />
                    <Button
                        disabled={false}
                        appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                        onClick={() => setSearch('')}
                        data-cy="search_clear_button"
                    >
                        Clear
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AnalyseByTransaction
