import './Pattern.css'
import { useState } from 'react'

export type PatternSelection = {
    start: number
    end: number
    text: string
}

type PatternProps = {
    label: string
    className?: string
    onSelection: (selection?: PatternSelection) => void
}

const Pattern = ({ label, className, onSelection }: PatternProps) => {
    const [startIndex, setStartIndex] = useState<number>()
    const [endIndex, setEndIndex] = useState<number>()

    const mouseUp = () => {
        const selection = window.getSelection()

        if (!selection || selection.isCollapsed) {
            setStartIndex(undefined)
            setEndIndex(undefined)

            onSelection(undefined)
        } else {
            const start = Math.min(selection.anchorOffset, selection.focusOffset)
            const end = Math.max(selection.anchorOffset, selection.focusOffset)

            const text = label.substring(selection.anchorOffset, selection.focusOffset)

            if (text.trim().length === 0) return

            setStartIndex(start)
            setEndIndex(end)

            onSelection({ start, end, text })
        }
    }

    const mouseDown = () => {
        setStartIndex(undefined)
        setEndIndex(undefined)
    }

    return (
        <div className={'pattern-field cursor-highlight ' + className} onMouseUp={mouseUp} onMouseDown={mouseDown}>
            {startIndex === undefined && label}
            {startIndex !== undefined && endIndex !== undefined && (
                <pre className="m-0">
                    <span className="text-dark/40">{label.substring(0, startIndex)}</span>
                    <span className="selection">{label.substring(startIndex, endIndex)}</span>
                    <span className="text-dark/40">{label.substring(endIndex)}</span>
                </pre>
            )}
        </div>
    )
}

export default Pattern
