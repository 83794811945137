import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function YLogoLoading({
    width = 10,
    height = width * 1.6,
    color = '#454545',
    border = '',
    className = '',
    percentage = 0,
}) {
    const [smoothPercentage, setSmoothPercentage] = useState(percentage)
    const [fillInterval, setFillInterval] = useState(null)

    useEffect(() => {
        let mounted = true
        clearInterval(fillInterval)
        setFillInterval(
            setInterval((e) => {
                if (mounted) {
                    setSmoothPercentage((prev) => {
                        if (prev < percentage) {
                            return prev + 1
                        }
                        if (prev > percentage) {
                            return prev - 1
                        }

                        clearInterval(fillInterval)

                        return prev
                    })
                }
            }, 25)
        )
        return () => (mounted = false)
    }, [percentage])

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <clipPath id="myClip">
                <path
                    id="Y"
                    d="M4.8594 0.451447L5.08179 6.99331V6.99196L5.20848 11.2919H5.31685H5.42665L5.5674 6.98655L5.78979 0.451447H9.70271L8.47535 11.6676H6.61601C7.03389 11.9339 7.35135 12.3223 7.52139 12.7754C7.69144 13.2284 7.70503 13.7219 7.56016 14.183C7.41529 14.644 7.11966 15.048 6.71703 15.3351C6.31439 15.6223 5.82616 15.7773 5.3246 15.7773C4.82303 15.7773 4.33479 15.6223 3.93216 15.3351C3.52952 15.048 3.2339 14.644 3.08904 14.183C2.94417 13.7219 2.95776 13.2284 3.1278 12.7754C3.29785 12.3223 3.61531 11.9339 4.03319 11.6676H2.17244L0.945068 0.451447H4.8594Z"
                    fill={color}
                    stroke={border}
                />
            </clipPath>
            <path
                id="Y"
                d="M4.8594 0.451447L5.08179 6.99331V6.99196L5.20848 11.2919H5.31685H5.42665L5.5674 6.98655L5.78979 0.451447H9.70271L8.47535 11.6676H6.61601C7.03389 11.9339 7.35135 12.3223 7.52139 12.7754C7.69144 13.2284 7.70503 13.7219 7.56016 14.183C7.41529 14.644 7.11966 15.048 6.71703 15.3351C6.31439 15.6223 5.82616 15.7773 5.3246 15.7773C4.82303 15.7773 4.33479 15.6223 3.93216 15.3351C3.52952 15.048 3.2339 14.644 3.08904 14.183C2.94417 13.7219 2.95776 13.2284 3.1278 12.7754C3.29785 12.3223 3.61531 11.9339 4.03319 11.6676H2.17244L0.945068 0.451447H4.8594Z"
                fill={color}
                stroke={border}
                strokeWidth="0.2"
            />

            <g clipPath="url(#myClip)" strokeWidth="6">
                <g transform={'scale(0.07) translate(0,' + (-smoothPercentage * 2 - 50) + ')'}>
                    <g className="transition-all duration-300 ease-in-out delay-150 animate-wave">
                        <path
                            d="M 0,500 C 0,500 0,250 0,250 C 189.86666666666667,263.8666666666667 379.73333333333335,277.73333333333335 530,265 C 680.2666666666667,252.26666666666668 790.9333333333334,212.93333333333334 936,206 C 1081.0666666666666,199.06666666666666 1260.5333333333333,224.53333333333333 1440,250 C 1440,250 1440,500 1440,500 Z"
                            stroke="none"
                            strokeWidth="0"
                            fill={border}
                        />
                        <path
                            transform="translate(1430,0)"
                            d="M 0,500 C 0,500 0,250 0,250 C 189.86666666666667,263.8666666666667 379.73333333333335,277.73333333333335 530,265 C 680.2666666666667,252.26666666666668 790.9333333333334,212.93333333333334 936,206 C 1081.0666666666666,199.06666666666666 1260.5333333333333,224.53333333333333 1440,250 C 1440,250 1440,500 1440,500 Z"
                            stroke="none"
                            strokeWidth="0"
                            fill={border}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

YLogoLoading.propTypes = {
    className: PropTypes.string,
    percentage: (props, name, component) =>
        props[name] >= 0 && props[name] <= 100
            ? null
            : new Error('YLogoLoading : the prop "percentage" must be between 0 and 100'),
}

export default YLogoLoading
