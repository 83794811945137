import PropTypes from 'prop-types'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import './Pagination.css'

function Pagination({ current = 1, total = 1, onChange, className = '', disabled = false }) {
    return (
        <div className={['flex gap-px text-gray-400 select-none', className].join(' ')}>
            <span onClick={(e) => onChange(current - 1)} className="flex items-center bg-gray-100 px-1 cursor-pointer">
                <ChevronLeftIcon className="h-4" />
            </span>
            <div className="flex gap-3 bg-gray-100 items-center px-2 pb-0.5">
                <span>{current}</span>
                <span>/</span>
                <span>{total}</span>
            </div>
            <span
                onClick={(e) => {
                    if (!disabled) onChange(current + 1)
                }}
                className="flex items-center bg-gray-100 px-1 cursor-pointer"
            >
                <ChevronRightIcon className="h-4" />
            </span>
        </div>
    )
}

Pagination.propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

export default Pagination
