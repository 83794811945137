import Input from '../../../components/Input'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Label from '../../../components/Label'
import { useTranslation } from 'react-i18next'
import { withAuth } from '../../../contexts/AuthContext'

const Technical = forwardRef(({ editing, program, user }, ref) => {
    const { t } = useTranslation('common')
    const [apiKey, setApiKey] = useState(program?.apiKey)
    const [googleAnalyticsId, setGoogleAnalyticsId] = useState(program?.googleAnalyticsId)
    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(program?.recaptchaSiteKey)
    const [recaptchaSecret, setRecaptchaSecret] = useState(program?.recaptchaSecret)
    const [zendeskId, setZendeskId] = useState(program?.zendeskId)
    const [externalTokenValidationEndpoint, setExternalTokenValidationEndpoint] = useState(
        program?.externalTokenValidationEndpoint
    )
    const [mgUserId, setMgUserId] = useState(program?.mgUserId)
    const [mgWalletId, setMgWalletId] = useState(program?.mgWalletId)
    const [cashback2Id, setCashback2Id] = useState(program?.cashback2Id)
    const [wallet2Id, setWallet2Id] = useState(program?.wallet2Id)
    const [cookieId, setCookieId] = useState(program?.cookieId)
    const [cookieVersion, setCookieVersion] = useState(program?.cookieVersion)
    // if true, the program integration type is 'api' else `plug&play`
    const isAPIProgram = program.hasExternalFront

    useImperativeHandle(ref, () => ({
        validate,
    }))

    const validate = () => {
        const output = {
            googleAnalyticsId,
            recaptchaSiteKey,
            recaptchaSecret,
            mgUserId,
            mgWalletId,
            cookieId,
            cookieVersion,
        }
        if (isAPIProgram) {
            return {
                ...output,
                apiKey,
                externalTokenValidationEndpoint,
                cashback2Id,
                wallet2Id,
            }
        } else {
            return {
                ...output,
                zendeskId,
            }
        }
    }

    return (
        <>
            {
                <div className="flex flex-col gap-4 p-1 mb-12">
                    <div>
                        <p className="font-bold mt-10">{t('program.configuration.technical.technical_information')}</p>
                        <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6 md:gap-y-10">
                            {isAPIProgram && (
                                <div className="col-span-1">
                                    <Label>{t('program.configuration.technical.api_key')}</Label>
                                    <Input
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={setApiKey}
                                        value={apiKey}
                                        placeholder={t('program.configuration.technical.api_key')}
                                    />
                                </div>
                            )}
                            <div className="col-span-1">
                                <Label>{t('program.configuration.technical.google_analytics')}</Label>
                                <Input
                                    disabled={!editing}
                                    className="mt-3.5"
                                    onChange={setGoogleAnalyticsId}
                                    value={googleAnalyticsId}
                                    placeholder={t('program.configuration.technical.google_analytics')}
                                />
                            </div>
                            {!program.hasExternalFront && (
                                <>
                                    <div className="col-span-1">
                                        <Label>{t('program.configuration.technical.recaptcha_site_key')}</Label>
                                        <Input
                                            disabled={!editing}
                                            className="mt-3.5"
                                            onChange={setRecaptchaSiteKey}
                                            value={recaptchaSiteKey}
                                            placeholder={t('program.configuration.technical.recaptcha_site_key')}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <Label>{t('program.configuration.technical.recaptcha_secret')}</Label>
                                        <Input
                                            disabled={!editing}
                                            className="mt-3.5"
                                            onChange={setRecaptchaSecret}
                                            value={recaptchaSecret}
                                            placeholder={t('program.configuration.technical.recaptcha_secret')}
                                        />
                                    </div>
                                </>
                            )}
                            {!isAPIProgram && (
                                <div className="col-span-1">
                                    <Label>{t('program.configuration.technical.zendesk_id')}</Label>
                                    <Input
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={setZendeskId}
                                        value={zendeskId}
                                        placeholder={t('program.configuration.technical.zendesk_id')}
                                    />
                                </div>
                            )}
                            {isAPIProgram && (
                                <div className="col-span-1">
                                    <Label>{t('program.configuration.technical.token_validation')}</Label>
                                    <Input
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={setExternalTokenValidationEndpoint}
                                        value={externalTokenValidationEndpoint}
                                        placeholder={t('program.configuration.technical.token_validation')}
                                    />
                                </div>
                            )}

                            <div className="col-span-1">
                                <Label>{t('program.configuration.technical.cookie_id')}</Label>
                                <Input
                                    disabled={!editing || !user.isSpaycialUser}
                                    className="mt-3.5"
                                    onChange={setCookieId}
                                    value={cookieId}
                                    placeholder={t('program.configuration.technical.cookie_id')}
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>{t('program.configuration.technical.cookie_version')}</Label>
                                <Input
                                    disabled={!editing || !user.isSpaycialUser}
                                    className="mt-3.5"
                                    onChange={setCookieVersion}
                                    value={cookieVersion}
                                    placeholder={t('program.configuration.technical.cookie_version')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <p className="font-bold">{t('program.configuration.technical.cashback_information')}</p>

                        <div className="mt-10 grid grid-cols-1 xl:grid-cols-3">
                            <div className="grid grid-cols-1 xl:grid-cols-2 col-span-2 gap-x-6 gap-y-10">
                                <div className="col-span-1">
                                    <Label>{t('program.configuration.technical.cashback_1_id_mangopay')}</Label>
                                    <Input
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={setMgUserId}
                                        value={mgUserId}
                                        placeholder={t('program.configuration.technical.cashback_mangopay')}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('program.configuration.technical.wallet_1_id_mangopay')}</Label>
                                    <Input
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={setMgWalletId}
                                        value={mgWalletId}
                                        placeholder={t('program.configuration.technical.wallet_mangopay')}
                                    />
                                </div>
                                {isAPIProgram && (
                                    <div className="col-span-1">
                                        <Label>{t('program.configuration.technical.cashback2_id')}</Label>
                                        <Input
                                            disabled={!editing}
                                            className="mt-3.5"
                                            onChange={setCashback2Id}
                                            value={cashback2Id}
                                            placeholder={t('program.configuration.technical.cashback2_id_placeholder')}
                                        />
                                    </div>
                                )}
                                {isAPIProgram && (
                                    <div className="col-span-1">
                                        <Label>{t('program.configuration.technical.wallet2_id')}</Label>
                                        <Input
                                            disabled={!editing}
                                            className="mt-3.5"
                                            onChange={setWallet2Id}
                                            value={wallet2Id}
                                            placeholder={t('program.configuration.technical.wallet2_id_placeholder')}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default withAuth(Technical)
