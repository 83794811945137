import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import SlideshowArrowLeft from './Icons/SlideshowArrowLeft'
import SlideshowArrowRight from './Icons/SlideshowArrowRight'

function Slideshow({ disabled = false, children }) {
    let properties = {
        prevArrow: (
            <div className="mr-8 cursor-pointer">
                <SlideshowArrowLeft />
            </div>
        ),
        nextArrow: (
            <div className="ml-8 cursor-pointer">
                <SlideshowArrowRight />
            </div>
        ),
    }

    if (disabled) {
        properties = {
            prevArrow: (
                <div className="mr-8 pointer-events-none">
                    <SlideshowArrowLeft color="#CCC" />
                </div>
            ),
            nextArrow: (
                <div className="ml-8 pointer-events-none">
                    <SlideshowArrowRight color="#CCC" />
                </div>
            ),
        }
    }

    return (
        <Slide easing="ease" autoplay={false} canSwipe={false} {...properties}>
            {children}
        </Slide>
    )
}

export default Slideshow
