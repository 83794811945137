type WarningIconProps = {
    className?: string
}

function WarningIcon({ className }: WarningIconProps) {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M15.5654 12.0484L9.0029 0.646037C8.51071 -0.201619 7.22555 -0.228963 6.73337 0.646037L0.170865 12.0484C-0.321322 12.896 0.307584 13.9898 1.3193 13.9898H14.417C15.4287 13.9898 16.0576 12.9234 15.5654 12.0484ZM7.8818 9.66947C8.5654 9.66947 9.13962 10.2437 9.13962 10.9273C9.13962 11.6382 8.5654 12.1851 7.8818 12.1851C7.17087 12.1851 6.62399 11.6382 6.62399 10.9273C6.62399 10.2437 7.17087 9.66947 7.8818 9.66947ZM6.67868 5.15776C6.65133 4.96635 6.8154 4.80229 7.0068 4.80229H8.72946C8.92087 4.80229 9.08493 4.96635 9.05758 5.15776L8.86618 8.87651C8.83883 9.06791 8.70212 9.17729 8.53805 9.17729H7.19821C7.03415 9.17729 6.89743 9.06791 6.87008 8.87651L6.67868 5.15776Z"
                fill="#FF7700"
            />
        </svg>
    )
}

export default WarningIcon
