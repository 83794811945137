import { useEffect, useState } from 'react'
import { withProgram, WithProgramProps } from '../../../../contexts/ProgramContext'
import { useTranslation } from 'react-i18next'
import Tab from '../../../../components/Tab'
import Tabs from '../../../../components/Tabs'
import BackButton from '../../../../components/BackButton'
import { useNavigate, useLocation } from 'react-router-dom'
import Behavior from './Behavior/Behavior'
import InProgramIcon from '../../../../components/Icons/InProgramIcon'
import OutOfProgramIcon from '../../../../components/Icons/OutOfProgramIcon'
import { Customer } from '../../../../interfaces/Customer.'
import PersonalInformation from './PersonalInformation/PersonalInformation'

type ShopperProfileProps = WithProgramProps

function ShopperProfile({ currentProgram }: ShopperProfileProps) {
    const { t } = useTranslation<string>('common')
    const location = useLocation()
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState<string>('tab-1')
    const [customer, setCustomer] = useState<Customer>()
    const customerData = (location?.state as any)?.customer

    const onGoBackHandler = () => {
        if (currentProgram) {
            return navigate(`/programs/${currentProgram?.id}/audience`)
        }

        return navigate('/audience')
    }

    useEffect(() => {
        if (customerData) setCustomer(customerData)
        else onGoBackHandler()
    }, [customerData?.id])

    return (
        <div className="p-1 h-full">
            <div className="flex flex-col">
                <div className="md:flex md:mb-16 flex">
                    <BackButton onClick={() => onGoBackHandler()} />
                    <h3 className="text-3xl text-black text-left md:spl-8 ml-7 inline-flex flex-1">
                        {customer?.firstName && customer?.lastName
                            ? customer?.firstName + ' ' + customer?.lastName
                            : customer?.externalId}{' '}
                    </h3>
                    <button
                        onClick={() => navigate(`/pinpoint/analyze`, { state: { customerId: customer?.id } })}
                        className="text-xs px-2 py-1 h-8 border border-primary text-primary border-1"
                    >
                        New pattern
                    </button>
                </div>
                <div className="md:flex justify-between md:mb-8">
                    <Tabs current={currentTab} setCurrent={setCurrentTab}>
                        {tabs.map((tab) => (
                            <Tab name={tab.key} key={tab.key} data-cy={tab.key} disabled={tab.key === 'tab-3'}>
                                {t(tab.label(currentProgram, t))}
                            </Tab>
                        ))}
                    </Tabs>
                    {currentTab === 'tab-3' && (
                        <div>
                            <div className="inline-flex">
                                <div className="text-grey-600 hover:text-primary text-md inline-flex cursor-pointer">
                                    <InProgramIcon className="h-5 mr-2 hover:text-primary" />
                                    {t('shopperProfile.yourProgram')}
                                </div>
                                <span className="ml-3 mr-3">|</span>
                                <div className="text-grey-600 hover:text-primary text-md inline-flex cursor-pointer">
                                    <OutOfProgramIcon className="h-5 mr-2 hover:text-primary" />
                                    {t('shopperProfile.outOfProgram')}
                                </div>
                            </div>
                            <div className="w-full text-none text-md mt-2 text-right">
                                {t('shopperProfile.periodLast90days')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {currentTab === 'tab-1' && <PersonalInformation />}
            {currentTab === 'tab-2' && <Behavior />}
            {currentTab === 'tab-3' && '360 shopper profile'}
        </div>
    )
}

const tabs = [
    {
        key: 'tab-1',
        label: (_: any, t: any) => t('shopperProfile.tab.personalInformations'),
    },
    {
        key: 'tab-2',
        label: (c: any, t: any) => (c?.name || '') + ' ' + t('shopperProfile.tab.behavior'),
    },
    {
        key: 'tab-3',
        label: (_: any, t: any) => t('shopperProfile.tab.360shopperProfile'),
    },
]

export default withProgram(ShopperProfile)
