import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { Table } from '../../../../../components/Table'
import { useMemo, useState } from 'react'
import SpendPointsModal from './SpendPointsModal'

type PointHistoryEntry = {
    id: string
    type: string
    value: number
    operationDate: Date
}

type PointsHistoryTableProps = {
    history: PointHistoryEntry[]
    refresh: () => void
}

function PointsHistoryTable({ history, refresh }: PointsHistoryTableProps) {
    const { t } = useTranslation('common')
    const [spendPointsModalOpened, setSpendPointsModalOpened] = useState(false)

    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.pointsHistory.header.date')}
                </div>
            ),
            render: (s) => moment(s.operation_date).format('MM/DD/YYYY'),
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.pointsHistory.header.amount')}
                </div>
            ),
            render: (s) => Math.round((s.value + Number.EPSILON) * 100) / 100,
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.pointsHistory.header.type')}
                </div>
            ),
            render: (s) => t(`shopperProfile.pointsHistory.type.${s.type}`),
        },
    ]

    const totalPoints =
        Math.round((useMemo(() => history.reduce((acc, h) => acc + h.value, 0), [history]) + Number.EPSILON) * 100) /
        100
    return (
        <>
            <div className="flex flex-row mb-6">
                <div className="flex-1 font-black">
                    <span className="text-xl">{t('shopperProfile.pointsHistory.title')}</span>
                </div>
                <div className="flex items-center">
                    <span className="italic text-gray-500 mr-2">
                        {t('shopperProfile.pointsHistory.spendPoints.balance', { totalPoints })}
                    </span>

                    <button
                        onClick={() => setSpendPointsModalOpened(true)}
                        className="text-xs px-2 py-1 h-8 border border-primary text-primary border-1"
                    >
                        {t(`shopperProfile.pointsHistory.spendPoints.button`)}
                    </button>
                </div>
                <SpendPointsModal
                    show={spendPointsModalOpened}
                    onClose={() => setSpendPointsModalOpened(false)}
                    onCreated={() => refresh()}
                />
            </div>

            <Table data={history} columns={columns} pageSize={10} tableLayout="fixed" />
        </>
    )
}

export default PointsHistoryTable
