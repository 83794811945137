import { useContext, useState } from 'react'
import Logo from './logo.svg'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import getEnv from '../../utils/GetEnv'
import './User.css'
import AuthContext, { withAuth, WithAuthProps } from '../../contexts/AuthContext'
import { withSettings, WithSettingsProps } from '../../contexts/SettingsContext'
import { withProgram, WithProgramProps } from '../../contexts/ProgramContext'
import MainMenu from './MainMenu'
import ProgramMenu from './ProgramMenu'
import { DownOutlined, MenuOutlined } from '@ant-design/icons'
import AccountDropdown from '../../components/AccountDropdown'
import { useTranslation } from 'react-i18next'
import RequireAuth, { canAccess } from '../../components/RequireAuth'
import { FUNCTIONALITIES } from '../../helpers'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { withShoppingHubOwner, WithShoppingHubOwnerProps } from '../../contexts/ShoppingHubOwnerContext'
import i18n, { I18N_STORAGE_KEY } from '../../i18n'

type UserProps = WithAuthProps & WithSettingsProps & WithProgramProps & WithShoppingHubOwnerProps

const mapEnvToBannerLabel: Record<string, string> = {
    development: 'dev',
    demo: 'demo',
    staging: 'staging',
}

const mapLanguages: Record<string, string> = {
    en: 'English',
    fr: 'Français',
}

export const User = ({
    currentProgram,
    shoppingHubOwners,
    setCurrentShoppingHubOwner,
    currentShoppingHubOwner,
}: UserProps) => {
    const { t } = useTranslation('common')
    const [showNav, setShowNav] = useState(false)
    const [accountDropdownVisible, setAccountDropdownVisible] = useState(false)
    const navigate = useNavigate()
    let { user } = useContext(AuthContext)

    const envBannerText = mapEnvToBannerLabel[getEnv()]
    const envBanner = !envBannerText ? null : (
        <div className="env-banner">
            <div>{envBannerText}</div>
        </div>
    )

    const menu = !currentProgram ? (
        <MainMenu showNav={showNav} />
    ) : (
        <ProgramMenu showNav={showNav} currentProgram={currentProgram} />
    )

    const onLogout = () => {
        localStorage.clear()
        navigate('/login')
    }

    const menuItems: ItemType[] = [
        canAccess(user, FUNCTIONALITIES.IAM, undefined, true) && {
            label: <span data-cy="main_menu_administration">{t('user.layout.administration')}</span>,
            key: '1',
            onClick: () => navigate('/admin'),
        },
        canAccess(user, undefined, undefined, true) && {
            label: <span data-cy="main_menu_change_client">{t('user.layout.changeClient')}</span>,
            key: 2,
            children: [
                ...shoppingHubOwners.map((sho) => ({
                    label: (
                        <div
                            className={currentShoppingHubOwner?.id === sho.id ? 'active' : ''}
                            data-cy={'main_menu_client_' + sho.id}
                        >
                            {sho.name}
                        </div>
                    ),
                    key: sho.id,
                    onClick: () => setCurrentShoppingHubOwner(sho),
                })),
                { type: 'divider' },
                {
                    label: (
                        <div className={!currentShoppingHubOwner?.id ? 'active' : ''} data-cy="main_menu_all_client">
                            {t('user.layout.allClients')}
                        </div>
                    ),
                    onClick: () => setCurrentShoppingHubOwner(undefined),
                    key: 'all-clients',
                },
            ],
        },
        {
            label: <span data-cy="main_menu_change_language">{t('user.layout.changeLanguage')}</span>,
            key: 4,
            children: Object.keys(mapLanguages).map((lng) => ({
                label: (
                    <div className={i18n.language === lng ? 'active' : ''} data-cy={'main_menu_lng_' + lng}>
                        {mapLanguages[lng]}
                    </div>
                ),
                key: lng,
                onClick: () => {
                    i18n.changeLanguage(lng)
                    localStorage.setItem(I18N_STORAGE_KEY, lng)
                },
            })),
        },
        { type: 'divider' },
        {
            label: <span data-cy="main_menu_logout">{t('user.layout.logout')}</span>,
            onClick: onLogout,
            key: 3,
        },
    ].filter((e) => e) as ItemType[]

    return (
        <div className="h-screen flex flex-col md:flex-row">
            <div className="md:h-full flex flex-col md:flex-row md:fixed">
                <div className="flex flex-col gap-2 bg-white md:w-full md:max-w-60">
                    <div className="relative flex flex-col md:h-screen px-4 z-1">
                        <Link to="/" className="relative text-center">
                            <img
                                className={'mt-10 mx-12 h-8 md:mb-8 ' + (envBanner ? 'mb-12 md:mb-16' : 'mb-4 md:mb-8')}
                                src={Logo}
                                alt="Logo"
                            />
                            {envBanner}
                        </Link>

                        <button className="absolute top-10 left-5 md:hidden" onClick={() => setShowNav(!showNav)}>
                            <MenuOutlined className="h-8" />
                        </button>

                        {menu}

                        <AccountDropdown items={menuItems} placement="top" onVisibleChange={setAccountDropdownVisible}>
                            <a onClick={(e) => e.preventDefault()} data-cy="main_menu_account">
                                <div
                                    className={
                                        'flex items-center px-4 py-4 transition-all ' +
                                        (accountDropdownVisible ? 'bg-primary bg-opacity-10' : '')
                                    }
                                >
                                    <svg
                                        className="fill-current inline-block mr-4"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M0.0664062 1.65511C0.0664062 0.740859 0.807265 0 1.72152 0H13.3446C14.2589 0 14.9997 0.740859 14.9997 1.65511V13.2782C14.9997 13.7172 14.8254 14.1382 14.515 14.4486C14.2046 14.759 13.7836 14.9333 13.3446 14.9333H1.72152C1.28255 14.9333 0.861571 14.759 0.551177 14.4486C0.240784 14.1382 0.0664063 13.7172 0.0664062 13.2782V1.65511ZM2.85147 12.4444H12.3839C11.8493 11.6757 11.1366 11.0478 10.3066 10.6144C9.47661 10.181 8.55404 9.95494 7.6177 9.95556C6.68136 9.95494 5.75878 10.181 4.92879 10.6144C4.0988 11.0478 3.38606 11.6757 2.85147 12.4444V12.4444ZM7.53307 8.2963C7.91439 8.2963 8.29198 8.22119 8.64427 8.07527C8.99657 7.92934 9.31667 7.71546 9.5863 7.44582C9.85594 7.17619 10.0698 6.85609 10.2157 6.50379C10.3617 6.1515 10.4368 5.77391 10.4368 5.39259C10.4368 5.01127 10.3617 4.63369 10.2157 4.28139C10.0698 3.9291 9.85594 3.609 9.5863 3.33936C9.31667 3.06973 8.99657 2.85585 8.64427 2.70992C8.29198 2.564 7.91439 2.48889 7.53307 2.48889C6.76296 2.48889 6.02439 2.79481 5.47984 3.33936C4.93529 3.88391 4.62937 4.62248 4.62937 5.39259C4.62937 6.1627 4.93529 6.90127 5.47984 7.44582C6.02439 7.99037 6.76296 8.2963 7.53307 8.2963V8.2963Z" />
                                    </svg>

                                    <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                                        <RequireAuth internalUser>
                                            {currentShoppingHubOwner
                                                ? currentShoppingHubOwner.name
                                                : t('dropUp.account')}
                                        </RequireAuth>

                                        <RequireAuth internalUser={false}>{t('dropUp.account')}</RequireAuth>
                                    </span>
                                    <DownOutlined className="inline-block ml-4" />
                                </div>
                            </a>
                        </AccountDropdown>
                    </div>
                </div>

                <div className="hidden md:flex flex-col bg-background">
                    <div className="w-8 h-1/3 bg-white transform -skew-x-6 origin-top-left shadow-user-firefox-fix" />
                    <div className="w-8 h-1/3 bg-white transform -skew-x-6 origin-top-left shadow-user-firefox-fix" />
                    <div className="w-8 h-1/3 bg-white transform -skew-x-6 origin-top-left shadow-user-firefox-fix" />
                </div>
            </div>
            <div className="md:pl-72 bg-background md:flex-1 py-12 px-4 sm:px-6 md:pr-10 h-full w-full overflow-y-auto">
                <div className="mx-auto md:max-w-2xl lg:max-w-full relative h-full lg:ml-6">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default withShoppingHubOwner(withSettings(withAuth(withProgram(User))))
