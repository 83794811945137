import { useEffect, useState, useMemo } from 'react'
import countryList from 'country-list'
import Label from '../../../components/Label'
import Map from '../../../components/Map'
import Badge from '../../../components/Badge'
import Input from '../../../components/Input'
import { useTranslation } from 'react-i18next'
import { getPlaceInfo } from '../../../helpers/mapBox/getPlaceInfo'
import { StoreStatus } from '../../../components/store/status'

type AddressProps = {
    // TODO: Fix types after merge BO2-409
    store: Record<string, any> & { shoppingHubId: string }
    // TODO: Fix types after merge BO2-409
    currentProgram: Record<string, any>
}

type Data = {
    postcode?: string
    place?: string
}

function Address({ store, currentProgram }: AddressProps) {
    const { t } = useTranslation('common')
    const [placeData, placeDataSetter] = useState<Data>()

    useEffect(() => {
        if (!store?.formattedAddress) {
            return
        }

        getPlaceInfo(store.formattedAddress, {
            center: store.coords,
            limit: 1,
        }).then((info) => {
            if (info && info.features?.length > 0) {
                const feature = info.features[0]

                const postcode = feature?.context.find((n: any) => n.id.startsWith('postcode.'))
                const place = feature?.context.find((n: any) => n.id.startsWith('place.'))

                const data = {
                    postcode: postcode?.text,
                    place: place?.text,
                }

                placeDataSetter(data)
            }
        })
    }, [store])

    const segments = useMemo(() => {
        return store.StoreCategories?.map((item: any) => (
            <Badge key={`${store.storeId}-${item.id}`} className="text-badge whitespace-nowrap">
                {item.name}
            </Badge>
        ))
    }, [store.StoreCategories, store.storeId])

    return (
        store && (
            <div className="grid grid-cols-1 xl:grid-cols-5 gap-8">
                <div className="col-span-2">
                    <div>
                        <Label>{t('stores.details.brand_name')}</Label>
                        <Input className="mt-3.5" value={store.storeName} disabled={true} data-cy="brand_name" />
                    </div>

                    <div className="flex w-full gap-5 mt-3.5">
                        <div className="w-20">
                            <Label>{t('stores.details.street_nb')}</Label>
                            <Input
                                className="mt-3.5"
                                value={store.streetNumber}
                                disabled={true}
                                data-cy="store_street_number"
                            />
                        </div>

                        <div className="flex-1 flex flex-col">
                            <Label className="flex-1">{t('stores.details.street_name')}</Label>
                            <Input className="mt-3.5" value={store.route} disabled={true} data-cy="store_street_name" />
                        </div>
                    </div>

                    <div className="flex w-full gap-5 mt-3.5">
                        <div className="w-1/2">
                            <Label>{t('stores.details.city')}</Label>
                            <Input
                                className="mt-3.5"
                                value={store.cityName || placeData?.place || ''}
                                disabled={true}
                                data-cy="store_city"
                            />
                        </div>

                        <div className="w-1/2">
                            <Label>{t('stores.details.zipCode')}</Label>
                            <Input
                                className="mt-3.5"
                                value={placeData?.postcode || ''}
                                disabled={true}
                                data-cy="store_city_zipcode"
                            />
                        </div>
                    </div>

                    <div className="mt-3.5">
                        <Label>{t('stores.details.country')}</Label>
                        <Input
                            className="mt-3.5"
                            value={(store.country && countryList.getName(store.country)) || ''}
                            disabled={true}
                            data-cy="store_country"
                        />
                    </div>
                </div>

                {store.coords && (
                    <Map
                        shoppingHubId={store.shoppingHubId}
                        className={'map-store col-span-2'}
                        longitude={store.coords[0]}
                        latitude={store.coords[1]}
                        zoom={14}
                        isInteractive={false}
                        isSingleStore={true}
                    />
                )}

                <div>
                    <div className="mb-15">
                        <Label className="block mb-4">{t('stores.details.status')}</Label>
                        <StoreStatus store={store} />
                    </div>

                    {store.shoppingHubProgramName ? (
                        <div className="mb-15">
                            <Label className="block mb-4">{t('stores.details.related_programs')}</Label>
                            <Badge className="text-badge whitespace-nowrap">{store.shoppingHubProgramName}</Badge>
                        </div>
                    ) : null}

                    {segments?.length ? (
                        <div className="mb-15">
                            <Label className="block mb-4">{t('stores.details.segments')}</Label>
                            <div
                                className="flex gap-6"
                                style={{
                                    flexWrap: 'wrap',
                                }}
                            >
                                {segments}
                            </div>
                        </div>
                    ) : null}

                    {currentProgram?.hasExternalFront && store.storeExternalId ? (
                        <div className="mb-15">
                            <Label className="block mb-4">{t('stores.details.store_id')}</Label>
                            <div
                                className="flex gap-6"
                                style={{
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Badge
                                    key={store.storeExternalId}
                                    className="whitespace-normal break-all text-badge"
                                    data-cy="store_external_id"
                                >
                                    {store.storeExternalId}
                                </Badge>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    )
}

export default Address
