type OutOfProgramIconProps = {
    className?: string
}

function OutOfProgramIcon({ className }: OutOfProgramIconProps) {
    return (
        <svg viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M8.0625 9H1.1875C1.01172 9 0.875 9.15625 0.875 9.3125V9.9375C0.875 10.1133 1.01172 10.25 1.1875 10.25H8.0625C8.21875 10.25 8.375 10.1133 8.375 9.9375V9.3125C8.375 9.15625 8.21875 9 8.0625 9ZM8.375 3.375H5.25V2.4375H6.03125C6.10938 2.4375 6.1875 2.37891 6.1875 2.28125V1.34375C6.1875 1.26562 6.10938 1.1875 6.03125 1.1875H5.25V0.40625C5.25 0.328125 5.17188 0.25 5.09375 0.25H4.15625C4.05859 0.25 4 0.328125 4 0.40625V1.1875H3.21875C3.12109 1.1875 3.0625 1.26562 3.0625 1.34375V2.28125C3.0625 2.37891 3.12109 2.4375 3.21875 2.4375H4V3.375H0.875C0.523438 3.39453 0.25 3.66797 0.25 4C0.25 4.05859 0.25 4.13672 0.269531 4.19531L1.69531 8.375H7.53516L8.96094 4.19531C8.98047 4.13672 8.98047 4.05859 8.98047 4C8.98047 3.66797 8.70703 3.39453 8.375 3.375Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default OutOfProgramIcon
