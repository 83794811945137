import React from 'react'

function SlideshowArrowRight({ color = '#000000' }) {
    return (
        <svg width="27" height="69" viewBox="0 0 27 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 1L25.5 34L0.5 68.5" stroke={color} />
        </svg>
    )
}

export default SlideshowArrowRight
