import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Request } from '../../../../../helpers'
import ErrorManagementContext from '../../../../../contexts/ErrorManagementContext'
import YLogoLoading from '../../../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../../../tailwind.config'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Badge from '../../../../../components/Badge'
import PersonalInformationElement from './PersonalInformationElement'
import PointsHistoryTable from './PointsHistoryTable'
import RequireFeature, { EFeature } from '../../../../../utils/feature-utils'
import { withProgram, WithProgramProps } from '../../../../../contexts/ProgramContext'
import RewardsTable from './RewardsTable'
import { RewardTypeEnum } from '../../../../../utils/ShoppingHubUtils'

type PersonalInformationProps = WithProgramProps

function PersonalInformation({ currentProgram }: PersonalInformationProps) {
    const { handleError } = useContext(ErrorManagementContext)
    const { customerId } = useParams<{ customerId: string }>()
    const [customer, setCustomer] = useState<any>()
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation('common')

    const requestCustomerInfo = () => {
        Request.get<any>(`/backoffice/v3/customers/${customerId}`)
            .then((customer) => {
                if (customer.loyaltyPoints) {
                    customer.loyaltyPoints.sort(
                        (a: any, b: any) => new Date(b.operation_date).getTime() - new Date(a.operation_date).getTime()
                    )
                }

                setCustomer(customer)
            })
            .catch(handleError('errors.shopperProfile.personalInformation'))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        requestCustomerInfo()
    }, [customerId, handleError])

    return (
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-3 mt-6">
            {loading ? (
                <YLogoLoading
                    className="m-auto block col-span-10"
                    width={40}
                    border={(Tailwind as any).theme.extend.colors.primary}
                    color="light-grey"
                    percentage={50}
                />
            ) : (
                <>
                    <div className="py-8 px-6 bg-white flex-1 relative">
                        <PersonalInformationElement
                            titleKey="shopperProfile.personalInformation.id"
                            dataToCopy={customer.id}
                        >
                            {customer.id}
                        </PersonalInformationElement>

                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <PersonalInformationElement titleKey="shopperProfile.personalInformation.firstAndLastName">
                                {customer.firstName && customer.lastName
                                    ? `${customer.firstName} ${customer.lastName}`.trim()
                                    : null}
                            </PersonalInformationElement>

                            <PersonalInformationElement titleKey="shopperProfile.personalInformation.memberSince">
                                {customer.createdAt ? moment(customer.createdAt).format('DD/MM/YYYY') : null}
                            </PersonalInformationElement>

                            <PersonalInformationElement
                                titleKey="shopperProfile.personalInformation.email"
                                dataToCopy={customer.email}
                            >
                                {customer.email ?? null}
                            </PersonalInformationElement>

                            <PersonalInformationElement
                                titleKey="shopperProfile.personalInformation.phone"
                                dataToCopy={customer.phone}
                            >
                                {customer.phone ?? null}
                            </PersonalInformationElement>

                            <PersonalInformationElement titleKey="shopperProfile.personalInformation.cashbackMethod">
                                {customer.cashbackMethod.length > 0
                                    ? customer.cashbackMethod.map((cbMethod: string) => (
                                          <Badge className="mr-2 inline-flex" key={cbMethod}>
                                              {t('shopperProfile.personalInformation.' + cbMethod)}
                                          </Badge>
                                      ))
                                    : null}
                            </PersonalInformationElement>

                            <PersonalInformationElement titleKey="shopperProfile.personalInformation.onboardingMethod">
                                {customer.onboardingMethod.length > 0
                                    ? customer.onboardingMethod.map((oMethod: any) => (
                                          <Badge
                                              className="mr-2 inline-flex"
                                              key={oMethod.type}
                                              appearance={oMethod.active ? 'success' : undefined}
                                          >
                                              {t('shopperProfile.personalInformation.' + oMethod.type)}
                                          </Badge>
                                      ))
                                    : null}
                            </PersonalInformationElement>

                            <PersonalInformationElement
                                titleKey="shopperProfile.personalInformation.connectedBanks"
                                noFlex
                            >
                                {customer.banks.length > 0
                                    ? customer.banks.map((bank: any) => (
                                          <Badge
                                              className="mr-2 mb-2 inline-flex"
                                              key={bank.name}
                                              appearance={bank.connected ? 'success' : undefined}
                                          >
                                              {bank.name}
                                          </Badge>
                                      ))
                                    : null}
                            </PersonalInformationElement>
                        </div>
                    </div>

                    <RequireFeature feature={EFeature.LOYALTY_POINTS}>
                        <div className="py-8 px-6 bg-white flex-1">
                            <PointsHistoryTable
                                history={customer.loyaltyPoints}
                                refresh={() => requestCustomerInfo()}
                            />
                        </div>
                    </RequireFeature>

                    {currentProgram.rewardType === RewardTypeEnum.GIFT_CARD ? (
                        <div className="py-8 px-6 bg-white flex-1">
                            <RewardsTable history={customer.rewards} refresh={() => requestCustomerInfo()} />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    )
}

export default withProgram(PersonalInformation)
