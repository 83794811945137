import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Label from '../../../components/Label'
import React, { useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import InputValidation from '../../../components/InputValidation'
import { User } from '../../../data/UserFetcher'
import { Password } from '../../../validators/PasswordValidator'
import InputPassword from '../../../components/InputPassword'
import { withAuth } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'

function Logs(props) {
    const { t } = useTranslation('common')
    const navigate = useNavigate()
    const location = useLocation()
    let businessData = location.state || {}

    const [password, setPassword] = useState(businessData.password || '')
    const [isLoading, setIsLoading] = useState(false)
    const { handleError } = useContext(ErrorManagementContext)

    const validate = ({ password }) => {
        setIsLoading(true)
        User.postAccountUpdate({ password })
            .then(() => navigate('/register/business/loading', { state: businessData }))
            .catch((e) => {
                setIsLoading(false)
                handleError('errors.business.onboarding.step4.update')(e)
            })
    }

    const items = [
        {
            label: '8 ' + t('Spaycial_onboarding_step4.characterMinimumPasswordRequirement'),
            completed: Password.isLongEnough(password),
        },
        {
            label: '1 ' + t('Spaycial_onboarding_step4.uppercaseLetterPasswordRequirement'),
            completed: Password.hasOneUppercase(password),
        },
        {
            label: '1 ' + t('Spaycial_onboarding_step4.lowercaseLetterPasswordRequirement'),
            completed: Password.hasOneLowercase(password),
        },
        {
            label: '1 ' + t('Spaycial_onboarding_step4.digitPasswordRequirement'),
            completed: Password.hasOneDigit(password),
        },
        {
            label: '1 ' + t('Spaycial_onboarding_step4.specialCharacterPasswordRequirement'),
            completed: Password.hasOneOWASPSpecialCharacter(password),
        },
    ]

    return (
        <>
            <p className="text-center text-xs mt-4">{t('Spaycial_onboarding_step4.step')} 4/4</p>
            <div className="flex items-center relative pb-8 md:pb-20 mx-6">
                <BackButton
                    className="absolute lg:left-0"
                    onClick={() => navigate('/register/business/details', { state: businessData })}
                />
                <h3 className={'text-xl md:text-3xl text-primary text-center flex-1 m-0'}>
                    {t('Spaycial_onboarding_step4.title')}
                </h3>
            </div>

            <div className="mx-auto max-w-sm py-8">
                <div>
                    <Label>{t('Spaycial_onboarding_step4.emailField')}</Label>
                    <Input
                        className="mt-3.5"
                        value={props.user.backUserEmail}
                        placeholder={t('Spaycial_onboarding_step4.emailPlaceholder')}
                        disabled={true}
                    />
                </div>

                <div className={'mt-6'}>
                    <div className="flex justify-between items-center">
                        <Label>{t('Spaycial_onboarding_step4.passwordField')}*</Label>
                        <InputValidation items={items} />
                    </div>
                    <InputPassword
                        className="mt-3.5"
                        onChange={(e) => setPassword(e)}
                        value={password}
                        placeholder={t('Spaycial_onboarding_step4.passwordPlaceholder')}
                        data-cy="onboarding_password"
                    />
                </div>

                <Button
                    className={'mt-15 w-full py-4'}
                    disabled={isLoading || items.find((e) => !e.completed)}
                    onClick={() => validate({ password })}
                    data-cy="submit_password_button"
                >
                    {isLoading
                        ? t('Spaycial_onboarding_step4.buttonLoadingLabel')
                        : t('Spaycial_onboarding_step4.buttonLabel')}
                </Button>
            </div>
        </>
    )
}

export default withAuth(Logs)
