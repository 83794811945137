import BackButton from '../../../components/BackButton'
import Button from '../../../components/Button'

function Header({
    buttonAppearance = 'outline',
    buttonLabel = 'Exit',
    hasButton = true,
    onBack,
    onButtonClick,
    children,
    disabled = false,
}) {
    return (
        <div className="flex flex-row justify-between items-end mb-8">
            <BackButton onClick={onBack} className="flex-shrink-0" />
            <div className="flex flex-col items-center px-10">{children}</div>
            {hasButton && (
                <Button appearance={buttonAppearance} onClick={onButtonClick} disabled={disabled}>
                    {buttonLabel}
                </Button>
            )}
        </div>
    )
}

export default Header
