import { useMemo, useState } from 'react'

export type UseSearchStoresFiltersProps = {
    shoppingHubId?: string
    patterns?: boolean
}

export type UseSearchStoresFiltersData = (props: UseSearchStoresFiltersProps) => {
    tableQueryUrl: string
    onPageReceived: (p: any) => any
    storesList: any[]
    cities: any[]
    countries: any[]
    searchInput: string
    onFilterChanges: (countries: any, cities: any, input: any) => void
    countryCitiesData: any
}

const useSearchStoresFilters: UseSearchStoresFiltersData = ({ shoppingHubId, patterns = false }) => {
    const [storesList, setStoresList] = useState([])
    const [countries, setCountries] = useState<any>(null)
    const [cities, setCities] = useState<any>(null)
    const [searchInput, setSearchInput] = useState<any>(null)
    const [lastCitiesDataForSearch, setLastCitiesDataForSeach] = useState<any>(null)
    const [countryCitiesData, setCountryCitiesData] = useState<any>(undefined)

    const tableQueryUrl = useMemo(() => {
        let uri = `backoffice/stores${shoppingHubId ? `?shoppingHubId=${shoppingHubId}` : ''}`
        if (patterns) {
            uri += `${uri.indexOf('?') >= 0 ? '&' : '?'}patterns=true`
        }
        if (countries?.length > 0) {
            uri += `${uri.indexOf('?') >= 0 ? '&' : '?'}country=${countries}`
        }
        if (cities?.length > 0) {
            uri += `${uri.indexOf('?') >= 0 ? '&' : '?'}city=${cities}`
        }
        if (searchInput) {
            uri += `${uri.indexOf('?') >= 0 ? '&' : '?'}search=${encodeURIComponent(searchInput)}`
        }
        return uri
    }, [countries, cities, searchInput?.value, shoppingHubId])

    const onPageReceived = (p: any) => {
        p.rows = p.stores.rows
        p.count = p.stores.count
        setStoresList(p.rows)
        if (!countryCitiesData || lastCitiesDataForSearch !== searchInput) {
            setCountryCitiesData(p.cities)
            setLastCitiesDataForSeach(searchInput)
        }
    }

    return {
        tableQueryUrl,
        onPageReceived,
        storesList,
        cities,
        countries,
        searchInput: searchInput,
        countryCitiesData,
        onFilterChanges: (countries: any, cities: any, input: any) => {
            setCountries(countries)
            setCities(cities)
            setSearchInput(input?.value)
        },
    }
}

export default useSearchStoresFilters
