import { ColumnsType } from 'antd/es/table'
import RestTable from '../../../components/Table'

const url = '/backoffice/patterns/leaderboard'

const Statistics = () => {
    const columns: ColumnsType<any> = [
        {
            title: 'First name',
            render: (b) => b.firstName,
        },
        {
            title: 'Last name',
            render: (b) => b.lastName,
        },
        {
            title: 'Email',
            render: (b) => b.email,
        },
        {
            title: '#',
            render: (b) => b.patternsCreated,
            defaultSortOrder: 'descend',
            key: 'patterns_created',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
    ]

    return (
        <div className="p-1 pl-4 h-full flex flex-col">
            <h3 className="text-3xl text-black text-left md:spl-8 mb-10">Leaderboard</h3>

            <div className="flex flex-col h-full">
                <RestTable queryBaseUrl={url} columns={columns} errorMessageKey="errors.pinpoint.leaderboard" />
            </div>
        </div>
    )
}

export default Statistics
