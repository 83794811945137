import React from 'react'

function IPhoneMenuIcon() {
    return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.00287 7.84532C5.19242 7.84532 5.35325 7.69964 5.35325 7.52158V1.71043L5.33027 0.928057L5.84147 1.42446L6.81792 2.3795C6.8811 2.44424 6.973 2.47662 7.05916 2.47662C7.23722 2.47662 7.38082 2.35252 7.38082 2.18525C7.38082 2.09353 7.34061 2.02878 7.27743 1.96403L5.2556 0.107914C5.16944 0.0269784 5.09477 0 5.00287 0C4.91097 0 4.8363 0.0269784 4.75014 0.107914L2.72832 1.96403C2.65939 2.02878 2.61918 2.09353 2.61918 2.18525C2.61918 2.35252 2.75704 2.47662 2.94084 2.47662C3.027 2.47662 3.12464 2.44424 3.18782 2.3795L4.16427 1.42446L4.67547 0.928057L4.6525 1.71043V7.52158C4.6525 7.69964 4.81333 7.84532 5.00287 7.84532ZM1.69443 12H8.30557C9.42562 12 10 11.4604 10 10.4245V4.91007C10 3.8687 9.42562 3.32914 8.30557 3.32914H6.66858V3.9982H8.28834C8.92591 3.9982 9.28777 4.32194 9.28777 4.94245V10.3867C9.28777 11.0126 8.92591 11.3309 8.28834 11.3309H1.70592C1.06261 11.3309 0.712234 11.0126 0.712234 10.3867V4.94245C0.712234 4.32194 1.06261 3.9982 1.70592 3.9982H3.33716V3.32914H1.69443C0.574383 3.32914 0 3.86331 0 4.91007V10.4245C0 11.4658 0.574383 12 1.69443 12Z"
                fill="#454545"
            />
        </svg>
    )
}

export default IPhoneMenuIcon
