export const getRewardLabel = (rewardValue, rewardUnit) => `${rewardValue} ${rewardUnit.label}`
export const getRewardValue = (rewardValue, rewardUnit) =>
    rewardUnit.value === 'percent' ? parseInt(rewardValue) / 100 : parseInt(rewardValue)
export const displayMultipleOccurrencePerDayToggle = (occurrenceLimit, uncapped) => occurrenceLimit > 1 || uncapped

export const handleRewardValue = (value, rewardUnit, setRewardValue) => {
    if (rewardUnit.label === '%' && value > 100) {
        setRewardValue(100) // Maximum for  % unit
    } else {
        setRewardValue(value)
    }
}

// Checking that all of the inputs are correctly filled in order to enable the "Next" button
export const allInputsFilled = (offerType, data) => {
    if (offerType === 'referral_offer') {
        return !!(
            data.rewardValue &&
            data.teaser &&
            data.conditionLabel &&
            data.startDate &&
            (data.endDate || data.activeEnd)
        )
    }
    if (offerType === 'average_basket') {
        return !!(
            data.rewardValue &&
            data.teaser &&
            data.conditionLabel &&
            data.startDate &&
            data.conditionValue &&
            data.rewardCumulatedLimit &&
            (data.endDate || data.activeEnd)
        )
    }
    if (offerType === 'purchase_frequency_non_cumulative') {
        return !!(
            data.rewardValue &&
            data.teaser &&
            data.conditionLabel &&
            data.startDate &&
            data.conditionValue &&
            data.cappingLimit &&
            data.cappingLimit >= data.rewardValue &&
            (data.uncapped || data.occurrenceLimit) &&
            (data.endDate || data.activeEnd)
        )
    }

    return !!(
        data.rewardValue &&
        data.teaser &&
        data.conditionLabel &&
        data.startDate &&
        data.conditionValue &&
        data.occurrenceLimit &&
        data.cappingLimit &&
        data.cappingLimit >= data.rewardValue &&
        (data.endDate || data.activeEnd)
    )
}
