import { EShopperProfileKpi } from './kpi-types'
import ShopperProfileIconKpi from './ShopperProfileIconKpi'
import { ShopperProfileKpis as KpiType } from '../shopper-profile-behavior-types'
import { useTranslation } from 'react-i18next'

type ShopperProfileKpisProps = {
    kpis: KpiType
}

function ShopperProfileKpis({ kpis }: ShopperProfileKpisProps) {
    const { t } = useTranslation('common')
    const c = t('shopperProfile.currency')

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6 mb-6">
            <ShopperProfileIconKpi
                type={EShopperProfileKpi.AVERAGE_BASKET}
                value={kpis.average_basket_per_shopping_session + c}
                description={t('shopperProfile.kpis.averageBasketPerShoppingSession')}
            />
            <ShopperProfileIconKpi
                type={EShopperProfileKpi.PURCHASE_FREQUENCY}
                value={kpis.monthly_shopping_session}
                description={t('shopperProfile.kpis.monthlyShoppingSession')}
            />
            <ShopperProfileIconKpi
                type={EShopperProfileKpi.OVERALL_BUDGET}
                value={kpis.spent_for_rewards + c}
                description={t('shopperProfile.kpis.spentForRewards', { rewards: kpis.total_reward_value + c })}
            />
            <ShopperProfileIconKpi
                type={EShopperProfileKpi.STORE_WITH_PURCHASE}
                value={kpis.store_with_purchase_per_shopping_session}
                description={t('shopperProfile.kpis.storeWithPurchasePerShoppingSession')}
            />
            <ShopperProfileIconKpi
                type={EShopperProfileKpi.DISTINCT_VISITED_STORE}
                value={kpis.distinct_visited_store}
                description={t('shopperProfile.kpis.distinctVisitedStore')}
            />
            <ShopperProfileIconKpi
                type={EShopperProfileKpi.PURCHASE_INTENT}
                value={kpis.purchase_intent * 100 + '%'}
                description={t('shopperProfile.kpis.purchaseIntent')}
            />
        </div>
    )
}

export default ShopperProfileKpis
