import PropTypes from 'prop-types'
import Button from '../../../components/Button'
import Header from './Header'
import Heading from '../../../components/Heading'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Purchase() {
    const navigate = useNavigate()
    const location = useLocation()
    let programData = location.state || {}
    const { t } = useTranslation('common')
    const validate = (dataRetrievalMethod) => {
        programData = Object.assign(programData, { dataRetrievalMethod })
        navigate('/programs/create/operate', { state: programData })
    }
    const options = [
        {
            id: 1,
            value: ['paymentCard'],
            label: t('create_program.purchase.options.payment_card.label'),
            content: t('create_program.purchase.options.payment_card.content'),
            picto: (
                <svg width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="160" height="120" fill="white" />
                    <path
                        d="M77.6864 37H79.0415C79.1716 36.9995 79.3003 37.0761 79.4199 37.2253C79.5394 37.3744 79.6473 37.5929 79.7368 37.8675C79.8264 38.142 79.8958 38.4668 79.9409 38.8221C79.9859 39.1773 80.0055 39.5555 79.9987 39.9337L79.1399 87.9469C79.1252 88.771 79.0024 89.5472 78.7968 90.1153C78.5912 90.6834 78.3186 91.0001 78.0351 91H77L77.6864 37Z"
                        fill="#24285B"
                    />
                    <path
                        d="M95 56.9235L95.4234 65.4938L103.936 69L109 64.4825L108.434 59.7582L100.769 52L95 56.9235Z"
                        fill="#F4A28C"
                    />
                    <path
                        opacity="0.09"
                        d="M95 56.9235L95.4234 65.4938L103.936 69L109 64.4825L108.434 59.7582L100.769 52L95 56.9235Z"
                        fill="black"
                    />
                    <path
                        d="M107 46.1001C107 46.1001 103.232 41.7126 100.116 39.6628C93.9778 35.6169 91.7522 39.9277 92.0216 42.3153C92.291 44.7029 99.5069 52 99.5069 52L107 46.1001Z"
                        fill="#F4A28C"
                    />
                    <path
                        d="M43.6395 31.6815C42.2841 31.9935 41.08 32.7662 40.2341 33.8668C39.3882 34.9673 38.9533 36.3271 39.004 37.7126L40.9324 89.9818C40.9819 91.3362 41.4858 92.6348 42.3635 93.6699C43.2412 94.705 44.442 95.4168 45.7736 95.6912V95.6912C54.1675 97.4363 62.8325 97.4363 71.2264 95.6912V95.6912C72.558 95.4168 73.7588 94.705 74.6365 93.6699C75.5142 92.6348 76.0181 91.3362 76.0676 89.9818L77.996 37.7126C78.0467 36.3271 77.6118 34.9673 76.7659 33.8668C75.92 32.7662 74.7159 31.9935 73.3606 31.6815V31.6815C63.5812 29.4395 53.4188 29.4395 43.6395 31.6815V31.6815Z"
                        fill="#E6E6E6"
                    />
                    <path d="M73 39H45V57H73V39Z" fill="white" />
                    <path
                        d="M38.8681 38L37.7805 38.1754C37.2638 38.2594 36.7962 38.534 36.468 38.9463C36.1398 39.3586 35.9742 39.8793 36.0033 40.4078L38.7814 89.4931C38.8036 89.9006 38.9794 90.2841 39.2727 90.5644C39.5659 90.8447 39.9542 91.0006 40.3577 91H41L38.8681 38Z"
                        fill="#24285B"
                    />
                    <path d="M51 64H44V67H51V64Z" fill="white" />
                    <path d="M62 64H55V67H62V64Z" fill="white" />
                    <path d="M73 64H65V67H73V64Z" fill="white" />
                    <path d="M51 71H44V75H51V71Z" fill="white" />
                    <path d="M62 71H55V75H62V71Z" fill="white" />
                    <path d="M73 71H65V75H73V71Z" fill="white" />
                    <path d="M51 79H44V82H51V79Z" fill="white" />
                    <path d="M62 79H55V82H62V79Z" fill="white" />
                    <path d="M73 79H65V82H73V79Z" fill="white" />
                    <path d="M51 86H44V90H51V86Z" fill="#FF7700" />
                    <path d="M62 86H55V90H62V86Z" fill="#C1C1C1" />
                    <path d="M73 86H65V90H73V86Z" fill="#FF7700" />
                    <path d="M71 34H47V36H71V34Z" fill="#C1C1C1" />
                    <path
                        d="M49 36V14.3949L51.4451 15.8699L53.8211 14L56.0752 15.8861L58.9427 14L61.2212 16.0795L63.4917 14L65.4047 16.0231L68 14.3949V36H49Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.09"
                        d="M70.3933 36.4905L62.6629 31L53 50.9708L79.2691 70L80 53.9914L70.3933 36.4905Z"
                        fill="black"
                    />
                    <path
                        d="M107.464 46.1764L73.1689 26.7329L60.3771 48.4898L94.6721 67.9333L107.464 46.1764Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M102.744 48.1183L87.3184 39.4127L85.2058 43.0223L100.631 51.7279L102.744 48.1183Z"
                        fill="white"
                    />
                    <path
                        opacity="0.46"
                        d="M74.8165 46.7298L69.3262 43.6171C68.5789 43.1934 67.6234 43.4449 67.1919 44.1787L66.6418 45.1144C66.2104 45.8482 66.4664 46.7865 67.2137 47.2102L72.704 50.3229C73.4513 50.7466 74.4069 50.4952 74.8383 49.7614L75.3884 48.8257C75.8199 48.0919 75.5638 47.1535 74.8165 46.7298Z"
                        fill="white"
                    />
                    <path
                        opacity="0.46"
                        d="M94.8765 58.1018L79.5709 49.4244C78.8236 49.0007 77.868 49.2521 77.4366 49.9859L76.8864 50.9216C76.455 51.6554 76.711 52.5938 77.4583 53.0175L92.7639 61.6949C93.5112 62.1186 94.4668 61.8672 94.8982 61.1334L95.4484 60.1977C95.8798 59.4639 95.6238 58.5255 94.8765 58.1018Z"
                        fill="white"
                    />
                    <path
                        d="M94.2254 76.8585C94.2254 76.8585 86.5436 69.6281 87.0214 61.835C87.2393 58.2545 91.9791 56.872 94.1457 59.7756L94.2296 59.8939C97.0542 63.7965 96.7901 67.8583 102.967 66.2352C109.145 64.6121 101.953 55.5385 101.953 55.5385L107.586 46C107.586 46 114.488 55.3224 116.227 63.3316C117.966 71.3409 116.227 79.7947 116.227 79.7947L100.478 83L94.2254 76.8585Z"
                        fill="#F4A28C"
                    />
                    <path d="M96 80.2337L116.29 76L124 101.374L99.0013 105L96 80.2337Z" fill="#FF7700" />
                    <path
                        opacity="0.3"
                        d="M76 38C77.6568 38 79 36.8807 79 35.5C79 34.1193 77.6568 33 76 33C74.3431 33 73 34.1193 73 35.5C73 36.8807 74.3431 38 76 38Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M79.5 40C80.8807 40 82 38.8807 82 37.5C82 36.1193 80.8807 35 79.5 35C78.1193 35 77 36.1193 77 37.5C77 38.8807 78.1193 40 79.5 40Z"
                        fill="white"
                    />
                </svg>
            ),
        },
        {
            id: 2,
            value: ['scan'],
            label: t('create_program.purchase.options.receipt_scan.label'),
            content: t('create_program.purchase.options.receipt_scan.content'),
            picto: (
                <svg width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="160" height="120" fill="white" />
                    <path
                        d="M108.58 26.2762C108.58 26.2762 112.255 26.5374 114.135 33.4567C116.014 40.3761 115.37 59.6904 115.37 59.6904L99.518 59.437C99.518 59.437 91.6074 30.692 108.58 26.2762Z"
                        fill="#24285B"
                    />
                    <path
                        d="M108.579 26.2761C108.579 26.2761 100.239 29.2789 103.147 52.8016C105.28 70.0347 98.0754 92.851 87.4257 93.1274C76.7759 93.4039 57.1338 92.467 57.1338 92.467C57.1338 92.467 67.3615 65.9722 65.2439 43.7243C64.9184 40.6095 65.2906 37.4614 66.3334 34.5087C67.5764 31.122 69.8858 27.597 74.1826 27.021C82.7607 25.8537 108.579 26.2761 108.579 26.2761Z"
                        fill="#FF7700"
                    />
                    <path
                        d="M51.2329 94.1869L88.8676 93.4189C88.8676 93.4189 83.1284 87.7206 82.7678 73.2137L31 73.9817C31.8873 77.7248 33.2514 81.3382 35.0589 84.7332C36.6224 87.648 38.9604 90.0734 41.8147 91.7417C44.6689 93.41 47.9284 94.2562 51.2329 94.1869V94.1869Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.44"
                        d="M51.2329 94.1869L88.8676 93.4189C88.8676 93.4189 83.1284 87.7206 82.7678 73.2137L31 73.9817C31.8873 77.7248 33.2514 81.3382 35.0589 84.7332C36.6224 87.648 38.9604 90.0734 41.8147 91.7417C44.6689 93.41 47.9284 94.2562 51.2329 94.1869V94.1869Z"
                        fill="white"
                    />
                    <path
                        d="M72.3418 35.0615C72.3418 35.0615 88.3931 33.8789 97.0249 34.6545"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M71.0664 45.452C71.0664 45.452 87.1177 44.277 95.7495 45.045"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M70.9214 56.2496C70.9214 56.2496 86.965 55.0746 95.5968 55.8426"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M69.9004 67.0475C69.9004 67.0475 85.9517 65.8725 94.5835 66.6404"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M115.82 91.5571C122.71 91.5571 128.296 85.9715 128.296 79.0812C128.296 72.1909 122.71 66.6052 115.82 66.6052C108.93 66.6052 103.344 72.1909 103.344 79.0812C103.344 85.9715 108.93 91.5571 115.82 91.5571Z"
                        fill="#FFD200"
                    />
                    <path
                        opacity="0.09"
                        d="M115.82 91.5571C122.71 91.5571 128.296 85.9715 128.296 79.0812C128.296 72.1909 122.71 66.6052 115.82 66.6052C108.93 66.6052 103.344 72.1909 103.344 79.0812C103.344 85.9715 108.93 91.5571 115.82 91.5571Z"
                        fill="black"
                    />
                    <path
                        d="M116.651 88.2298C123.541 88.2298 129.127 82.6441 129.127 75.7538C129.127 68.8635 123.541 63.2778 116.651 63.2778C109.76 63.2778 104.175 68.8635 104.175 75.7538C104.175 82.6441 109.76 88.2298 116.651 88.2298Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M109.582 78.2497C115.324 78.2497 119.978 73.595 119.978 67.8531C119.978 62.1112 115.324 57.4564 109.582 57.4564C103.84 57.4564 99.1851 62.1112 99.1851 67.8531C99.1851 73.595 103.84 78.2497 109.582 78.2497Z"
                        fill="#FFD200"
                    />
                    <path
                        opacity="0.09"
                        d="M109.582 78.2497C115.324 78.2497 119.978 73.595 119.978 67.8531C119.978 62.1112 115.324 57.4564 109.582 57.4564C103.84 57.4564 99.1851 62.1112 99.1851 67.8531C99.1851 73.595 103.84 78.2497 109.582 78.2497Z"
                        fill="black"
                    />
                    <path
                        d="M110.413 75.754C116.155 75.754 120.809 71.0992 120.809 65.3573C120.809 59.6154 116.155 54.9607 110.413 54.9607C104.671 54.9607 100.016 59.6154 100.016 65.3573C100.016 71.0992 104.671 75.754 110.413 75.754Z"
                        fill="#FFD200"
                    />
                </svg>
            ),
        },
        {
            id: 3,
            value: ['paymentCard', 'scan'],
            label: t('create_program.purchase.options.both.label'),
            content: t('create_program.purchase.options.both.content'),
            picto: (
                <svg width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="160" height="120" fill="white" />
                    <path
                        d="M38.7893 36.6636L78.3579 14L93.1167 39.3602L53.5482 62.0238L38.7893 36.6636Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M44.2353 38.9272L62.033 28.7798L64.4703 32.9872L46.6727 43.1346L44.2353 38.9272Z"
                        fill="white"
                    />
                    <path
                        opacity="0.46"
                        d="M76.4566 37.3086L82.7911 33.6803C83.6533 33.1865 84.7558 33.4795 85.2536 34.3349L85.8883 35.4255C86.3861 36.2809 86.0907 37.3747 85.2285 37.8685L78.894 41.4967C78.0318 41.9906 76.9293 41.6975 76.4315 40.8421L75.7967 39.7515C75.2989 38.8962 75.5944 37.8024 76.4566 37.3086Z"
                        fill="white"
                    />
                    <path
                        opacity="0.46"
                        d="M53.3124 50.5642L70.9715 40.4496C71.8338 39.9558 72.9363 40.2488 73.4341 41.1042L74.0688 42.1948C74.5666 43.0502 74.2711 44.1439 73.4089 44.6378L55.7498 54.7524C54.8876 55.2462 53.7851 54.9532 53.2873 54.0978L52.6526 53.0072C52.1548 52.1518 52.4502 51.0581 53.3124 50.5642Z"
                        fill="white"
                    />
                    <path
                        d="M53.8112 72.0836C53.8112 72.0836 62.5172 63.8762 61.9758 55.0299C61.7288 50.9656 56.357 49.3963 53.9015 52.6922L53.8065 52.8265C50.6053 57.2565 50.9045 61.8671 43.9036 60.0247C36.9028 58.1824 45.053 47.8826 45.053 47.8826L38.6696 37.0551C38.6696 37.0551 30.8471 47.6372 28.876 56.7288C26.905 65.8204 28.876 75.4166 28.876 75.4166L46.7249 79.0551L53.8112 72.0836Z"
                        fill="#F4A28C"
                    />
                    <path d="M52 76.8727L28.8115 72.0551L20 100.929L48.5699 105.055L52 76.8727Z" fill="#FF7700" />
                    <path
                        opacity="0.3"
                        d="M75 28.0551C73.3432 28.0551 72 26.488 72 24.5551C72 22.6221 73.3432 21.0551 75 21.0551C76.6569 21.0551 78 22.6221 78 24.5551C78 26.488 76.6569 28.0551 75 28.0551Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M71 30.0551C69.3431 30.0551 68 28.4881 68 26.5551C68 24.6221 69.3431 23.0551 71 23.0551C72.6569 23.0551 74 24.6221 74 26.5551C74 28.4881 72.6569 30.0551 71 30.0551Z"
                        fill="white"
                    />
                    <path d="M120 59.0551L134 59.2693L133.727 62.0551L120 61.7499V59.0551Z" fill="#E6E6E6" />
                    <path
                        d="M126.35 39.0551C126.35 39.0551 128.828 39.2348 130.095 43.9976C131.363 48.7604 130.928 62.0551 130.928 62.0551L120.241 61.8806C120.241 61.8806 114.908 42.0946 126.35 39.0551Z"
                        fill="#24285B"
                    />
                    <path
                        d="M126 39.1051C126 39.1051 120.488 41.1225 122.41 56.9269C123.82 68.5054 119.058 83.835 112.02 84.0208C104.981 84.2065 92 83.577 92 83.577C92 83.577 98.7594 65.7759 97.3599 50.828C97.1448 48.7353 97.3907 46.6202 98.0799 44.6363C98.9014 42.3609 100.428 39.9926 103.267 39.6056C108.937 38.8213 126 39.1051 126 39.1051Z"
                        fill="#FF7700"
                    />
                    <path
                        d="M87.636 85.0524L113 84.5399C113 84.5399 109.132 80.7369 108.889 71.0551L74 71.5676C74.598 74.0657 75.5174 76.4773 76.7355 78.7431C77.7892 80.6884 79.3649 82.3071 81.2886 83.4206C83.2122 84.534 85.4089 85.0987 87.636 85.0524V85.0524Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.44"
                        d="M87.636 85.0524L113 84.5399C113 84.5399 109.132 80.7369 108.889 71.0551L74 71.5676C74.598 74.0657 75.5174 76.4773 76.7355 78.7431C77.7892 80.6884 79.3649 82.3071 81.2886 83.4206C83.2122 84.534 85.4089 85.0987 87.636 85.0524V85.0524Z"
                        fill="white"
                    />
                    <path
                        d="M102 45.0551C102 45.0551 112.405 45.0551 118 45.0551"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M101 52.0551C101 52.0551 112.055 52.0551 118 52.0551"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M101 59.0551C101 59.0551 112.053 59.0551 118 59.0551"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M100 66.0551C100 66.0551 111.055 66.0551 117 66.0551"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M131 83.0551C135.418 83.0551 139 79.2495 139 74.5551C139 69.8606 135.418 66.0551 131 66.0551C126.582 66.0551 123 69.8606 123 74.5551C123 79.2495 126.582 83.0551 131 83.0551Z"
                        fill="#FFD200"
                    />
                    <path
                        opacity="0.09"
                        d="M131 83.0551C135.418 83.0551 139 79.2495 139 74.5551C139 69.8606 135.418 66.0551 131 66.0551C126.582 66.0551 123 69.8606 123 74.5551C123 79.2495 126.582 83.0551 131 83.0551Z"
                        fill="black"
                    />
                    <path
                        d="M131.5 81.0551C136.194 81.0551 140 77.2495 140 72.5551C140 67.8606 136.194 64.0551 131.5 64.0551C126.806 64.0551 123 67.8606 123 72.5551C123 77.2495 126.806 81.0551 131.5 81.0551Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M127 74.0551C130.866 74.0551 134 70.921 134 67.0551C134 63.1891 130.866 60.0551 127 60.0551C123.134 60.0551 120 63.1891 120 67.0551C120 70.921 123.134 74.0551 127 74.0551Z"
                        fill="#FFD200"
                    />
                    <path
                        opacity="0.09"
                        d="M127 74.0551C130.866 74.0551 134 70.921 134 67.0551C134 63.1891 130.866 60.0551 127 60.0551C123.134 60.0551 120 63.1891 120 67.0551C120 70.921 123.134 74.0551 127 74.0551Z"
                        fill="black"
                    />
                    <path
                        d="M127 72.0551C130.866 72.0551 134 68.921 134 65.0551C134 61.1891 130.866 58.0551 127 58.0551C123.134 58.0551 120 61.1891 120 65.0551C120 68.921 123.134 72.0551 127 72.0551Z"
                        fill="#FFD200"
                    />
                </svg>
            ),
        },
    ]
    let classes = ['grid grid-cols-1 gap-8 xl:gap-5 xl:gap-10']
    if (options.length === 2) {
        classes.push('xl:grid-cols-2')
    } else if (options.length > 2) {
        classes.push('xl:grid-cols-3')
    }

    return (
        <>
            <Header
                onBack={() => navigate('/programs/create/benefit', { state: programData })}
                onButtonClick={() => navigate('/programs')}
                buttonLabel={t('create_program.exit')}
            >
                <p className="text-xs">{t('create_program.step')} 2/5</p>
                <Heading>{t('create_program.purchase.header')}</Heading>
            </Header>

            <div className="flex flex-col h-full space-y-24 items-center md:mt-32">
                <div className={classes.join(' ')}>
                    {options.map((option) => {
                        return (
                            <div className="flex flex-col items-center bg-white space-y-4 py-8 px-8 md:w-72 xl:w-64 h-80">
                                {option.picto}

                                <label className="flex items-center text-center h-1/2 text-black text-sm">
                                    {option.content}
                                </label>

                                <Button appearance="outline" onClick={() => validate(option.value)}>
                                    {option.label}
                                </Button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

Purchase.propTypes = {
    onValidate: PropTypes.func,
}

Purchase.defaultProps = {
    onValidate: undefined,
}

export default Purchase
