import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { initLogRocket } from './helpers'
import Loading from './components/Loading'
import 'antd/dist/antd.variable.min.css'
import { createRoot } from 'react-dom/client'
import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { ProgramProvider } from './contexts/ProgramContext'
import { ErrorManagementProvider } from './contexts/ErrorManagementContext'

const Tailwind = require('../tailwind.config')

;(() => {
    initLogRocket()
})()

// Overriding AntD primary color
ConfigProvider.config({
    theme: {
        primaryColor: Tailwind.theme.extend.colors.primary,
    },
})

const rootContainer = document.getElementById('root')
const root = createRoot(rootContainer!)
root.render(
    <React.Suspense fallback={<Loading />}>
        <ErrorManagementProvider>
            <BrowserRouter>
                <AuthProvider>
                    <ProgramProvider>
                        <App />
                    </ProgramProvider>
                </AuthProvider>
            </BrowserRouter>
        </ErrorManagementProvider>
    </React.Suspense>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
