import PropTypes from 'prop-types'

function Heading({ children, className = '' }) {
    return <h1 className={['text-primary font-normal text-3xl text-center', className].join(' ')}>{children}</h1>
}

Heading.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
}

export default Heading
