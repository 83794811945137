import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Label from '../../../components/Label'
import React, { useEffect, useState, useContext } from 'react'
import DropdownInput from '../../../components/DropdownInput'
import BackButton from '../../../components/BackButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { User } from '../../../data/UserFetcher'
import { JobTitle } from '../../../data/JobTitleFetcher'
import { useTranslation } from 'react-i18next'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'

function Details() {
    const { t } = useTranslation('common')
    const navigate = useNavigate()
    const location = useLocation()
    let businessData = location.state || {}
    const { handleError } = useContext(ErrorManagementContext)
    const [isLoading, setIsLoading] = useState(false)

    const [firstName, setFirstName] = useState(businessData.firstName || '')
    const [lastName, setLastName] = useState(businessData.lastName || '')
    const [job, setJob] = useState(businessData.job || '')
    const [jobs, setJobs] = useState([])

    useEffect(() => {
        JobTitle.getAll()
            .then((e) =>
                setJobs(
                    e.map((job) => ({
                        label: t('Spaycial_onboarding_step3.' + job.translation_key),
                        value: job.id,
                    }))
                )
            )
            .catch(handleError('errors.business.onboarding.step3.getJobs'))
    }, [])

    const validate = () => {
        setIsLoading(true)
        businessData = Object.assign(businessData, { firstName, lastName, job })
        User.postAccountUpdate({
            firstName,
            lastName,
            jobTitleId: job.value,
        })
            .then((e) => navigate('/register/business/logs', { state: businessData }))
            .catch((e) => {
                setIsLoading(false)
                handleError('errors.business.onboarding.step3')(e)
            })
    }

    return (
        <>
            <p className="text-center text-xs mt-4">{t('Spaycial_onboarding_step3.step')} 3/4</p>

            <div className="flex items-center relative pb-8 md:pb-20 mx-6">
                <BackButton
                    className="absolute lg:left-0"
                    onClick={(e) => navigate('/register/business/company', { state: businessData })}
                />
                <h3 className={'text-xl md:text-3xl text-primary text-center flex-1 m-0'}>
                    {t('Spaycial_onboarding_step3.title')}
                </h3>
            </div>

            <div className="mx-auto max-w-sm">
                <div>
                    <Label>{t('Spaycial_onboarding_step3.firstNameField')}</Label>
                    <Input
                        className="mt-3.5"
                        onChange={(e) => setFirstName(e)}
                        value={firstName}
                        placeholder={t('Spaycial_onboarding_step3.firstNamePlaceholder')}
                        data-cy="back_user_first_name"
                    />
                </div>

                <div className={'mt-6'}>
                    <Label>{t('Spaycial_onboarding_step3.lastNameField')}</Label>
                    <Input
                        className="mt-3.5"
                        onChange={(e) => setLastName(e)}
                        value={lastName}
                        placeholder={t('Spaycial_onboarding_step3.lastNamePlaceholder')}
                        data-cy="back_user_last_name"
                    />
                </div>

                <div className={'mt-6'}>
                    <Label>{t('Spaycial_onboarding_step3.jobTitleField')}</Label>
                    <DropdownInput
                        placeholder={t('Spaycial_onboarding_step3.jobTitlePlaceholder')}
                        className="mt-3.5"
                        onChange={(e) => setJob(e)}
                        value={job}
                        options={jobs}
                        isClearable={false}
                        indicatorSeparator={null}
                        data-cy="back_user_job_title"
                    />
                </div>

                <Button
                    className={'mt-15 w-full py-4'}
                    disabled={isLoading || !firstName || !lastName || !job}
                    onClick={() => validate()}
                    data-cy="personal_info_next_button"
                >
                    {t('Spaycial_onboarding_step3.buttonLabel')}
                </Button>
            </div>
        </>
    )
}

export default Details
