function KpiPurchaseFrequency({className}) {
    return (
        <svg viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                opacity="0.45"
                d="M27.5312 43.8894C42.7362 43.8894 55.0623 42.2099 55.0623 40.138C55.0623 38.0661 42.7362 36.3865 27.5312 36.3865C12.3261 36.3865 0 38.0661 0 40.138C0 42.2099 12.3261 43.8894 27.5312 43.8894Z"
                fill="#E6E6E6"
            />
            <path d="M42.8211 5.70557H11.2247V40.1379H42.8211V5.70557Z" fill="#E6E6E6" />
            <path d="M39.1351 25.2206H34.7208V29.0855H39.1351V25.2206Z" fill="#24285B" />
            <path d="M39.1351 30.9379H34.7208V34.8027H39.1351V30.9379Z" fill="#A5A5A5" />
            <path
                opacity="0.08"
                d="M35.944 27.9489C35.944 27.9489 27.9037 29.2148 31.6709 40.1383H40.276L35.944 27.9489Z"
                fill="black"
            />
            <path d="M43.3814 3.20319H10.7064V11.8271H43.3814V3.20319Z" fill="#FF7700" />
            <path d="M19.6378 0H17.0178V7.51592H19.6378V0Z" fill="#F2F2F2" />
            <path opacity="0.08" d="M21.3675 3.14575H19.6378V7.51439H21.3675V3.14575Z" fill="black" />
            <path opacity="0.08" d="M38.6564 3.14575H36.9267V7.51439H38.6564V3.14575Z" fill="black" />
            <path d="M36.9262 0H34.3062V7.51592H36.9262V0Z" fill="#F2F2F2" />
            <path d="M22.7009 15.2626H15.5294V17.3186H22.7009V15.2626Z" fill="#24285B" />
            <path d="M20.1577 19.5403H15.7434V23.4052H20.1577V19.5403Z" fill="white" />
            <path opacity="0.32" d="M26.5642 19.5403H22.1499V23.4052H26.5642V19.5403Z" fill="#A5A5A5" />
            <path d="M32.9038 19.5403H28.4894V23.4052H32.9038V19.5403Z" fill="#FFD200" />
            <path d="M39.1351 19.5403H34.7208V23.4052H39.1351V19.5403Z" fill="#24285B" />
            <path d="M20.1577 25.2206H15.7434V29.0855H20.1577V25.2206Z" fill="white" />
            <path opacity="0.32" d="M26.5642 25.2206H22.1499V29.0855H26.5642V25.2206Z" fill="#A5A5A5" />
            <path opacity="0.32" d="M32.9038 25.2206H28.4894V29.0855H32.9038V25.2206Z" fill="#A5A5A5" />
            <path d="M20.1577 30.9379H15.7434V34.8027H20.1577V30.9379Z" fill="white" />
            <path opacity="0.32" d="M26.5642 30.9379H22.1499V34.8027H26.5642V30.9379Z" fill="#A5A5A5" />
            <path opacity="0.32" d="M32.9038 30.9379H28.4894V34.8027H32.9038V30.9379Z" fill="#A5A5A5" />
            <path opacity="0.32" d="M42.8211 11.8258H11.2247V13.6142H42.8211V11.8258Z" fill="#A5A5A5" />
        </svg>
    )
}

export default KpiPurchaseFrequency
