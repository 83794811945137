import React from 'react'

function CreativeProcess() {
    return (
        <svg width="265" height="202" viewBox="0 0 265 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.45"
                d="M127.432 182.676C176.249 182.676 215.823 177.669 215.823 171.493C215.823 165.317 176.249 160.311 127.432 160.311C78.6149 160.311 39.041 165.317 39.041 171.493C39.041 177.669 78.6149 182.676 127.432 182.676Z"
                fill="#E6E6E6"
            />
            <path
                d="M134.368 80.0352C141.584 86.5416 146.249 95.454 147.512 105.148C149.625 121.423 170.083 109.168 175.959 91.352L181.604 95.1578C181.604 95.1578 172.925 125.53 147.512 126.783C122.098 128.036 112.995 86.7891 134.368 80.0352Z"
                fill="#FF7700"
            />
            <path
                opacity="0.46"
                d="M134.368 80.0352C141.584 86.5416 146.249 95.454 147.512 105.148C149.625 121.423 170.083 109.168 175.959 91.352L181.604 95.1578C181.604 95.1578 172.925 125.53 147.512 126.783C122.098 128.036 112.995 86.7891 134.368 80.0352Z"
                fill="white"
            />
            <path
                opacity="0.3"
                d="M83.5415 51.993C70.2914 58.4388 60.8773 73.8562 59.5987 92.1012C58.5188 107.512 63.9712 129.677 87.5032 133.596C133.295 141.201 181.227 145.04 191.125 119.311C201.023 93.582 189.912 42.1034 166.751 40.1938C148.334 38.6862 109.405 39.3964 83.5415 51.993Z"
                fill="#E6E6E6"
            />
            <path
                d="M125.781 57.36C126.721 59.15 127.42 61.0585 127.862 63.0352C127.899 63.2738 127.89 63.5176 127.834 63.7524C127.778 63.9873 127.676 64.2086 127.535 64.4036C127.394 64.5986 127.216 64.7634 127.012 64.8885C126.808 65.0136 126.581 65.0965 126.344 65.1324C125.591 65.3017 124.805 65.2454 124.083 64.9706C123.361 64.6957 122.733 64.2142 122.277 63.5846L120.044 61.0854C119.649 60.5375 119.432 59.8792 119.422 59.2011C119.413 58.523 119.611 57.8587 119.991 57.2997C121.296 55.1288 124.841 55.3432 125.781 57.36Z"
                fill="#F4A28C"
            />
            <path d="M121.72 61.5547L118.885 73.1664L125.324 74.2853L124.814 65.6084L121.72 61.5547Z" fill="#F4A28C" />
            <path
                d="M126.75 59.457L128.227 60.3482C128.309 60.4016 128.377 60.475 128.424 60.5616C128.47 60.6482 128.495 60.7453 128.495 60.844C128.495 60.9427 128.47 61.0398 128.424 61.1264C128.377 61.213 128.309 61.2864 128.227 61.3398L127.088 62.1372L126.75 59.457Z"
                fill="#F4A28C"
            />
            <path
                opacity="0.31"
                d="M124.848 65.1531C124.848 65.1531 122.483 64.6372 121.701 63.8867C121.701 63.8867 121.92 69.3274 125.073 70.0376L124.848 65.1531Z"
                fill="#CE8172"
            />
            <path
                d="M120.661 60.4574C120.661 60.4574 118.727 59.8276 118.396 61.3485C118.064 62.8695 120.217 63.5127 121.324 62.3804C120.884 61.8396 120.649 61.1577 120.661 60.4574Z"
                fill="#F4A28C"
            />
            <path d="M94.3002 168.605H136.117V138.38H94.3002V168.605Z" fill="#E6E6E6" />
            <path
                opacity="0.08"
                d="M125.08 168.614L127.087 142L135.481 143.293L136.078 151.367L136.117 168.614H125.08Z"
                fill="black"
            />
            <path
                d="M126.127 165.115C126.127 165.115 127.028 167.635 129.274 167.829C131.52 168.023 132.116 170.248 130.095 170.824C128.075 171.4 122.848 168.774 122.848 168.774L122.993 165.49L126.127 165.115Z"
                fill="#FF7700"
            />
            <path
                d="M154.567 165.382C154.567 165.382 157.038 166.327 158.648 164.712C160.258 163.098 162.312 164.096 161.431 166.053C160.55 168.009 155.19 170.294 155.19 170.294L152.262 167.527L154.567 165.382Z"
                fill="#FF7700"
            />
            <path
                d="M125.875 74.1589L119.435 73.0332C119.435 73.0332 98.0164 73.0332 94.2601 80.484C90.5037 87.9347 91.2987 115.513 103.687 138.18L129.055 138.918C129.055 138.918 127.643 122.274 132.367 113.208C137.091 104.143 151.798 84.8794 125.875 74.1589Z"
                fill="#FF7700"
            />
            <path
                d="M103.693 138.181L116.102 136.285C117.894 136.011 119.723 136.156 121.451 136.708C123.179 137.261 124.759 138.205 126.07 139.47C127.38 140.735 128.388 142.287 129.014 144.007C129.64 145.726 129.869 147.568 129.683 149.391L128.067 165.324H121.978L122.502 150.403C122.533 149.568 122.38 148.736 122.055 147.968C121.73 147.2 121.241 146.515 120.623 145.961C120.004 145.407 119.272 144.999 118.479 144.766C117.686 144.533 116.851 144.481 116.036 144.613C110.756 145.484 104.217 145.109 103.693 138.181Z"
                fill="#24285B"
            />
            <path
                opacity="0.08"
                d="M105.868 83.1445C105.868 83.1445 112.903 89.3758 111.492 96.2638C111.26 97.376 111.022 98.7161 110.783 100.19C109.869 105.9 110.213 111.743 111.79 117.302C113.367 122.862 116.138 128.001 119.906 132.352L124.331 137.478L103.695 138.181L101.926 134.683L92.293 118.489L105.868 83.1445Z"
                fill="black"
            />
            <path
                d="M93.9086 81.1666C89.2115 87.867 85.0311 102.608 88.6616 123.432C89.2037 126.507 90.7496 129.308 93.052 131.389C95.3544 133.47 98.2803 134.71 101.362 134.91L120.746 136.15V129.751L106.019 127.6C105.213 127.483 104.441 127.191 103.757 126.744C103.073 126.297 102.493 125.706 102.056 125.011C101.62 124.316 101.337 123.533 101.227 122.717C101.117 121.901 101.184 121.071 101.421 120.283C104.018 111.64 108.046 95.9878 107 84.8317C106.39 78.0577 97.8041 75.6188 93.9086 81.1666Z"
                fill="#FF7700"
            />
            <path
                opacity="0.46"
                d="M93.9086 81.1666C89.2115 87.867 85.0311 102.608 88.6616 123.432C89.2037 126.507 90.7496 129.308 93.052 131.389C95.3544 133.47 98.2803 134.71 101.362 134.91L120.746 136.15V129.751L106.019 127.6C105.213 127.483 104.441 127.191 103.757 126.744C103.073 126.297 102.493 125.706 102.056 125.011C101.62 124.316 101.337 123.533 101.227 122.717C101.117 121.901 101.184 121.071 101.421 120.283C104.018 111.64 108.046 95.9878 107 84.8317C106.39 78.0577 97.8041 75.6188 93.9086 81.1666Z"
                fill="white"
            />
            <path
                d="M120.773 131.306C120.773 131.306 128.637 131.105 127.518 135.253C126.398 139.4 120.773 136.151 120.773 136.151V131.306Z"
                fill="#F4A28C"
            />
            <path
                d="M146.943 137.638L150.117 119.486H127.598L125.644 134.964L115.322 135.105V137.638H146.943Z"
                fill="#FFD200"
            />
            <path
                d="M137.973 130.468C139.217 130.468 140.226 129.448 140.226 128.19C140.226 126.932 139.217 125.912 137.973 125.912C136.729 125.912 135.721 126.932 135.721 128.19C135.721 129.448 136.729 130.468 137.973 130.468Z"
                fill="white"
            />
            <path
                opacity="0.58"
                d="M74.5178 161.471C74.5178 161.471 68.35 159.77 67.0117 153.96C67.0117 153.96 76.565 152.011 76.8366 162.001L74.5178 161.471Z"
                fill="#FF7700"
            />
            <path
                opacity="0.73"
                d="M75.274 160.846C75.274 160.846 70.9677 153.959 74.7572 147.52C74.7572 147.52 82.0447 152.21 78.7919 160.867L75.274 160.846Z"
                fill="#FF7700"
            />
            <path
                d="M76.3867 160.853C76.3867 160.853 78.6591 153.577 85.5424 152.203C85.5424 152.203 86.8343 156.927 81.0838 160.873L76.3867 160.853Z"
                fill="#FF7700"
            />
            <path
                d="M71.9141 160.693L73.1596 169.343L81.0367 169.377L82.1961 160.734L71.9141 160.693Z"
                fill="#24285B"
            />
            <path
                d="M121.119 65.2004C121.119 65.2004 114.997 63.5588 116.932 59.4381C116.932 59.4381 114.765 55.9473 118.363 54.165C120.483 53.113 123.623 52.5569 125.849 52.2755C126.151 52.2386 126.457 52.2884 126.733 52.4191C127.008 52.5497 127.242 52.7561 127.407 53.0145C127.572 53.273 127.662 53.5732 127.666 53.8809C127.671 54.1885 127.59 54.4913 127.433 54.7546C126.824 55.7899 125.993 56.6737 125.001 57.3405C124.009 58.0074 122.881 58.4403 121.702 58.6073C122.103 59.6795 122.259 60.8296 122.158 61.9715C122.057 63.1134 121.702 64.2173 121.119 65.2004Z"
                fill="#24285B"
            />
            <path
                d="M122.635 61.2145C122.635 61.2145 122.065 59.9348 121.144 60.4507C120.224 60.9666 121.045 62.8226 122.423 62.4608L122.635 61.2145Z"
                fill="#F4A28C"
            />
            <path
                d="M176.887 91.9552C176.887 91.9552 179.285 83.6267 181.047 83.5061C182.809 83.3855 181.047 88.2633 181.047 88.2633C181.047 88.2633 187.513 89.925 181.637 95.158L176.887 91.9552Z"
                fill="#F4A28C"
            />
            <path
                d="M184.42 57.4819C194.625 57.4819 202.898 49.1153 202.898 38.7946C202.898 28.474 194.625 20.1074 184.42 20.1074C174.216 20.1074 165.943 28.474 165.943 38.7946C165.943 49.1153 174.216 57.4819 184.42 57.4819Z"
                fill="#FFD200"
            />
            <path
                opacity="0.46"
                d="M188.581 45.5608C193.795 45.5608 198.022 41.286 198.022 36.0128C198.022 30.7396 193.795 26.4648 188.581 26.4648C183.367 26.4648 179.141 30.7396 179.141 36.0128C179.141 41.286 183.367 45.5608 188.581 45.5608Z"
                fill="white"
            />
            <path
                opacity="0.46"
                d="M191.755 39.8395C194.605 39.8395 196.916 37.5026 196.916 34.62C196.916 31.7373 194.605 29.4004 191.755 29.4004C188.904 29.4004 186.594 31.7373 186.594 34.62C186.594 37.5026 188.904 39.8395 191.755 39.8395Z"
                fill="white"
            />
            <path d="M195.193 53.8906H173.648V70.896H195.193V53.8906Z" fill="#24285B" />
            <path d="M189.469 70.8965H179.141V77.7174H189.469V70.8965Z" fill="#24285B" />
            <path opacity="0.75" d="M189.469 70.8965H179.141V77.7174H189.469V70.8965Z" fill="white" />
            <path
                d="M158.08 26.4649C158.08 26.4649 97.6334 6.65871 82.1971 33.9022C71.8224 52.2008 92.7507 62.4925 104.013 60.7974"
                stroke="#E6E6E6"
                stroke-width="2.03"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2.03 10.15"
            />
            <path
                d="M135.813 137.355L155.569 164.405L151.713 168.003C151.713 168.003 134.528 147.707 125.306 141.992L122.086 137.63L135.813 137.355Z"
                fill="#24285B"
            />
        </svg>
    )
}

export default CreativeProcess
