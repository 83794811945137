import ToolStatementsReport from './ToolStatementsReport'
import ToolEmailTemplate from './ToolEmailTemplate'
import Tabs from '../../../components/Tabs'
import Tab from '../../../components/Tab'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ProgramTools = () => {
    const { t } = useTranslation('common')
    const [currentTab, setCurrentTab] = useState('emailTemplates')

    return (
        <div className="p-1 pl-4">
            <div className="flex justify-between">
                <div className="text-3xl">{t('program.tools.title')}</div>
            </div>

            <Tabs className="my-4 md:my-10" current={currentTab} setCurrent={setCurrentTab}>
                {tabs.map((tab) => (
                    <Tab name={tab.key} key={tab.key} data-cy={tab.key}>
                        {t(tab.label)}
                    </Tab>
                ))}
            </Tabs>

            <div className="overflow-auto xl:mt-6">
                {currentTab === 'emailTemplates' && <ToolEmailTemplate />}
                {currentTab === 'statementReport' && <ToolStatementsReport />}
            </div>
        </div>
    )
}

const tabs = [
    {
        key: 'emailTemplates',
        label: 'tools.tab.emailTemplates',
    },
    {
        key: 'statementReport',
        label: 'tools.tab.statementReport',
    },
]

export default ProgramTools
