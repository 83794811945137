/**
 * This component is adapted from https://github.com/Tintef/react-google-places-autocomplete
 */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import debounceFn from 'lodash.debounce'
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'
import DropdownAsyncCreatableInput from './DropdownAsyncCreatableInput'

export type PlaceResult = google.maps.places.PlaceResult

type GooglePlacesAutocompleteHandle = {
    getSessionToken: () => google.maps.places.AutocompleteSessionToken | undefined
    refreshSessionToken: () => void
}

interface LatLng {
    lat: number
    lng: number
}

interface AutocompletionRequest {
    bounds?: [LatLng, LatLng]
    componentRestrictions?: { country: string | string[] }
    location?: LatLng
    offset?: number
    radius?: number
    types?: string[]
}

interface GooglePlacesAutocompleteProps {
    prefixSearchValue?: string
    onAddressSelected?: (place: PlaceResult | null) => void
    apiKey?: string
    apiOptions?: Partial<LoaderOptions>
    autocompletionRequest?: AutocompletionRequest
    debounce?: number
    minLengthAutocomplete?: number
    onLoadFailed?: (error: Error) => void
    withSessionToken?: boolean
    showCreateOption?: boolean
    placeholder?: string
}

const autocompletionRequestBuilder = (
    autocompletionRequest: AutocompletionRequest,
    input: string
): google.maps.places.AutocompletionRequest => {
    const { bounds, location, ...rest } = autocompletionRequest

    const res: google.maps.places.AutocompletionRequest = {
        input,
        ...rest,
    }

    if (bounds) {
        res.bounds = new google.maps.LatLngBounds(...bounds)
    }

    if (location) {
        res.location = new google.maps.LatLng(location)
    }

    return res
}

const GooglePlacesAutocomplete: React.ForwardRefRenderFunction<
    GooglePlacesAutocompleteHandle,
    GooglePlacesAutocompleteProps
> = (
    {
        apiKey = '',
        apiOptions = {},
        autocompletionRequest = {},
        debounce = 300,
        minLengthAutocomplete = 0,
        showCreateOption = true,
        placeholder = '',
        onLoadFailed = console.error,
        onAddressSelected,
        prefixSearchValue = '',
    }: GooglePlacesAutocompleteProps,
    ref
): React.ReactElement => {
    const [autoCompleteService, setAutoCompleteService] = useState<google.maps.places.AutocompleteService>()
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>()
    const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()
    const fetchSuggestions = debounceFn((value: string, cb: (options: any[]) => void): void => {
        let searchValue = prefixSearchValue + ' ' + value
        if (!autoCompleteService) return cb([])
        if (value.length < minLengthAutocomplete) return cb([])

        const autocompletionReq: AutocompletionRequest = { ...autocompletionRequest }

        autoCompleteService.getPlacePredictions(
            autocompletionRequestBuilder(autocompletionReq, searchValue),
            (suggestions) => {
                cb((suggestions || []).map((suggestion) => ({ label: suggestion.description, value: suggestion })))
            }
        )
    }, debounce)
    const [value, setValue] = useState(null)

    const getPlaceDetails = (placeId: string) => {
        placesService?.getDetails(
            {
                placeId,
            },
            (placeDetails) => {
                onAddressSelected && onAddressSelected(placeDetails)
            }
        )
    }

    const handleOnChange = (selectedOption: any) => {
        if (selectedOption && onAddressSelected) {
            getPlaceDetails(selectedOption.value.place_id)
        }
        setValue(selectedOption)
    }

    const initializeService = () => {
        if (!window.google) throw new Error('[react-google-places-autocomplete]: Google script not loaded')
        if (!window.google.maps) throw new Error('[react-google-places-autocomplete]: Google maps script not loaded')
        if (!window.google.maps.places)
            throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded')

        setAutoCompleteService(new window.google.maps.places.AutocompleteService())
        setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')))
        setSessionToken(new google.maps.places.AutocompleteSessionToken())
    }

    useImperativeHandle(
        ref,
        () => ({
            getSessionToken: () => {
                return sessionToken
            },
            refreshSessionToken: () => {
                setSessionToken(new google.maps.places.AutocompleteSessionToken())
            },
        }),
        [sessionToken]
    )

    useEffect(() => {
        const init = async () => {
            try {
                if (!window.google || !window.google.maps || !window.google.maps.places) {
                    await new Loader({ apiKey, ...{ libraries: ['places'], ...apiOptions } }).load()
                }
                initializeService()
            } catch (error) {
                // @ts-ignore
                onLoadFailed(error)
            }
        }

        if (apiKey) init()
        else initializeService()
    }, [])

    return (
        <DropdownAsyncCreatableInput
            loadOptions={fetchSuggestions}
            className="mt-3.5"
            value={value}
            // @ts-ignore
            onChange={handleOnChange}
            placeholder={placeholder}
            showCreateOption={showCreateOption}
        />
    )
}

export default forwardRef(GooglePlacesAutocomplete)
