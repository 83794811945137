module.exports = function getEnv() {
    switch (process.env.REACT_APP_API_URL) {
        case 'http://localhost:10010':
            return 'development'
        case 'http://localhost:3000':
            return 'development'
        case 'https://api-test.transactionconnect.com':
            return 'staging'
        case 'https://api-demo.transactionconnect.com':
            return 'demo'
        default:
            return 'production'
    }
}
