import { Request } from '../helpers'

class ShoppingHubFetcher {
    getById(id) {
        return Request.get('backoffice/shoppingHub/' + id)
    }

    patch(id, data) {
        return Request.patch('backoffice/shoppingHubs', { ...data, id })
    }

    getLanguages() {
        return Request.get('backoffice/shoppingHubs/languages')
    }
}

const ShoppingHub = new ShoppingHubFetcher()

export { ShoppingHub }
