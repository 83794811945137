import PropTypes from 'prop-types'

function ErrorAlert({ children, className = '' }) {
    return <div className={['border border-error text-error p-4 bg-error-light', className].join(' ')}>{children}</div>
}

ErrorAlert.propTypes = {
    className: PropTypes.string,
}

export default ErrorAlert
