import React from 'react'
import PropTypes from 'prop-types'
import './OldTable.css'
import EmptyList from './EmptyList'
import YLogoLoading from './Icons/YLogoLoading'
import * as Tailwind from './../../tailwind.config'

function OldTable({ children, loading, className = '' }) {
    // Children can be a react fragment
    if (!Array.isArray(children) && children.type === React.Fragment) {
        children = children.props.children
    }

    const thead = children[0]
    let tbody
    if (loading)
        tbody = (
            <tbody>
                <tr>
                    <td colSpan={100} className="p-12">
                        <YLogoLoading
                            width="30"
                            border={Tailwind.theme.extend.colors.primary}
                            color="light-grey"
                            percentage={50}
                            className="m-auto"
                        />
                    </td>
                </tr>
            </tbody>
        )
    else if (!children[1]?.props?.children || children[1].props.children.length === 0)
        tbody = (
            <tbody>
                <EmptyList />
            </tbody>
        )
    else tbody = children[1]

    return (
        <table className={['table-auto overflow-x-scroll w-full whitespace-nowrap', className].join(' ')}>
            {thead}
            {tbody}
        </table>
    )
}

OldTable.propTypes = {
    name: PropTypes.string,
    loading: PropTypes.bool,
}

export default OldTable
