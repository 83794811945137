import Button from '../../../components/Button'
import Tabs from '../../../components/Tabs'
import Tab from '../../../components/Tab'
import React, { useState, useRef, useCallback, useMemo } from 'react'
import moment from 'moment-timezone'
import BackButton from '../../../components/BackButton'
import { useNavigate, useLocation } from 'react-router-dom'
import Modal from '../../../components/Modal'
import Setup from './Setup'
import { Stores } from '../common/Stores'
import { numberWithSpaces, Request } from '../../../helpers'
import { useTranslation } from 'react-i18next'
import { withProgram } from '../../../contexts/ProgramContext'
import { useEffect, useContext } from 'react'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import ReactTooltip from 'react-tooltip'
import Datepicker from '../../../components/Datepicker'
import Label from '../../../components/Label'
import Timepicker from '../../../components/Timepicker'

const Form = ({ currentProgram }) => {
    const location = useLocation()
    const offerType = location?.state?.offerType
    const [current, setCurrent] = useState('setup')
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOfferConfirmOpen, setModalOfferConfirmOpen] = useState(false)
    const [modalEmailCampaignOpen, setModalEmailCampaignOpen] = useState(false)
    const [emailCampaignEdition, setEmailCampaignEdition] = useState(false)
    const [emailCampaignDate, setEmailCampaignDate] = useState(null)
    const [formData, setFormData] = useState(location?.state?.formData || {})
    const [nextDisabled, setNextDisabled] = useState(true)
    const [changedStores, setChangedStores] = useState()
    const { handleError } = useContext(ErrorManagementContext)
    const [hasFormError, setHasFormError] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation('common')
    const setup = useRef(null)
    const stores = useRef(null)
    const programTimezone = currentProgram?.timezone || 'Europe/Paris'

    function openModalConfirm() {
        let data = setup.current.validate()

        setFormData({
            ...formData,
            ...data,
        })
        setModalOfferConfirmOpen(true)
    }

    const createOffer = useCallback(
        (override = {}) => {
            const data = { ...formData, ...override }
            const storesIds = stores?.current?.validate()

            if (data?.rewardUnit?.label === '%') {
                data.rewardValue = data.rewardValue / 100
            }

            // Change from included to excluded end date (add one day)
            if (data.endDate) {
                data.endDate = moment(data.endDate).add(1, 'days').toDate()
            }

            // Shift dates' timezone to the local program one instead of the browser one without modifying hours as we want to keep the correct date
            // Because if the program's timezone is before the browser one, it will lead to -1 day
            if (programTimezone) {
                if (data.startDate) {
                    data.startDate = moment(data.startDate).tz(programTimezone, true).toDate()
                }

                if (data.endDate) {
                    data.endDate = moment(data.endDate).tz(programTimezone, true).toDate()
                }

                if (data.emailNotificationDate) {
                    data.emailNotificationDate = moment(data.emailNotificationDate).tz(programTimezone, true).toDate()
                }
            }

            Request.post(
                'backoffice/challenges',
                {
                    ...data,
                    ...storesIds,
                },
                { shoppingHubId: data.shoppingHubId }
            )
                .then(() => {
                    navigate(`/programs/${currentProgram.id}/offers`)
                })
                .catch((err) => {
                    if (offerType !== 'referral_offer') {
                        setCurrent('stores')
                    }
                    handleError('errors.offers.add')(err)
                })
        },
        [formData, navigate, currentProgram?.timezone]
    )

    function nextTab() {
        if (offerType === 'referral_offer') {
            setEmailCampaignDate(formData.emailNotificationDate)
            setModalEmailCampaignOpen(true)
        } else {
            setCurrent('stores') // Go to next step. Keep the formData
        }
        setModalOfferConfirmOpen(false) // Remove modal
    }

    function displayRewardCumulatedLimit() {
        if (formData.rewardCumulatedLimit > 0) {
            return (
                <p>
                    {t('offerCreation.reward_cumulated_limit_at')}: {numberWithSpaces(formData.rewardCumulatedLimit)}{' '}
                    {t('offerCreation.euro')}
                </p>
            )
        }
        return (
            <p>
                {t('offerCreation.reward_cumulated_limit_at')}:&nbsp;<strong>{t('offerCreation.no_limit')}</strong>
            </p>
        )
    }

    useEffect(() => {
        if (location.state?.currentTab === 'stores') {
            setCurrent('stores')
        }
    }, [location.state?.currentTab])

    const hasNoStore = useMemo(() => changedStores?.selected?.length === 0, [changedStores])

    return (
        <>
            <div className={'p-1 pl-4'}>
                <div className="md:flex md:justify-between">
                    <div className={'md:flex'}>
                        <BackButton onClick={() => navigate(-1)} />
                        <h1 className={'my-6 md:my-0 text-2xl md:ml-6'}>{t('offerCreation.name')}</h1>
                        <div className={'my-6 md:my-0 text-lg md:ml-4 pt-1 text-gray-400'}>
                            {t(`offerCreation.offerType.${location.state?.offerType}`)}
                        </div>
                    </div>
                    <div className={'flex items-center justify-end'}>
                        <Button
                            data-cy="offer_set_up_exit_button"
                            className={'mr-6'}
                            appearance={'inversed'}
                            onClick={() => setModalOpen(true)}
                        >
                            {t('offerCreation.exit')}
                        </Button>
                        {current === 'setup' ? (
                            <Button
                                data-cy="offer_set_up_next_button"
                                onClick={openModalConfirm}
                                disabled={nextDisabled || hasFormError}
                            >
                                {t('offerCreation.next')}
                            </Button>
                        ) : (
                            <div data-tip="create-offer-tip" data-for="create-offer-tip">
                                <Button
                                    data-cy="offer_set_up_create_offer_button"
                                    onClick={() => {
                                        setEmailCampaignDate(formData.emailNotificationDate)
                                        setModalEmailCampaignOpen(true)
                                    }}
                                    disabled={hasNoStore}
                                >
                                    {t('offerCreation.create')}
                                </Button>
                                {hasNoStore && (
                                    <ReactTooltip id="create-offer-tip" place="left" effect="solid">
                                        <span>{t('offerCreation.createBtn.noStoreTooltip')}</span>
                                    </ReactTooltip>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={'mt-10 xl:flex md:justify-between mb-6 lg:mb-0'}>
                    <Tabs current={current} setCurrent={setCurrent}>
                        <Tab name="setup" data-cy="offer_set_up_settings_tag">
                            {t('offerCreation.setup')}
                        </Tab>
                        {offerType !== 'referral_offer' ? (
                            <Tab name="stores" disabled={true} data-cy="offer_set_up_stores_tag">
                                {t('offerCreation.stores')}
                            </Tab>
                        ) : null}
                    </Tabs>
                </div>
                {current === 'setup' ? (
                    <Setup
                        formData={formData}
                        ref={setup}
                        allInputsFilled={(value) => setNextDisabled(value)}
                        offers={location?.state?.offers}
                        programTimezone={programTimezone}
                        hasError={setHasFormError}
                    />
                ) : (
                    <Stores
                        currentProgram={currentProgram}
                        ref={stores}
                        APIroute="backoffice/shoppingHubs/stores"
                        checkboxCheckedByDefault={true}
                        formData={formData}
                        offerType={offerType}
                        onChange={setChangedStores}
                    />
                )}
            </div>
            <Modal show={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="max-w-md text-center m-auto">
                    <p>{t('offerCreation.leaveModal.title')}</p>

                    <div className={'mt-12 flex justify-center'}>
                        <Button
                            data-cy="offer_set_up_exit_pop_up_cancel"
                            appearance={'inversed'}
                            className={'w-1/3'}
                            onClick={() => setModalOpen(false)}
                        >
                            {t('offerCreation.leaveModal.no')}
                        </Button>
                        <Button
                            data-cy="offer_set_up_exit_pop_up_confirm"
                            className={'w-1/3 ml-4'}
                            onClick={() => navigate(`/programs/${currentProgram.id}/offers`)}
                        >
                            {t('offerCreation.leaveModal.yes')}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalOfferConfirmOpen} onClose={() => setModalOfferConfirmOpen(false)}>
                <div className="max-w-md text-center m-auto">
                    <p>{t('offerCreation.createModal.title')}</p>
                    <div className={'mt-4'}>
                        <p className={'font-bold'}>{formData.teaser}</p>
                        <p>
                            {t('offerCreation.createModal.reward')} {numberWithSpaces(formData.rewardLabel)}
                        </p>
                        <p>
                            {t('offerCreation.createModal.conditionValue.minimum')}{' '}
                            {numberWithSpaces(formData.conditionValue)}{' '}
                            {t('offerCreation.createModal.conditionValue.perPurchase')}
                        </p>
                        <p>
                            {t('offerCreation.from')}{' '}
                            {formData.startDate ? moment(formData.startDate).format('DD/MM/YYYY') : 'N/A'}{' '}
                            {t('offerCreation.to')}{' '}
                            {formData.endDate ? moment(formData.endDate).format('DD/MM/YYYY') : 'N/A'}{' '}
                        </p>
                        <p>
                            {t('offerCreation.createModal.cappingLimit')} {numberWithSpaces(formData.cappingLimit)}{' '}
                            {t('offerCreation.euro')}
                        </p>
                        {offerType === 'average_basket' && displayRewardCumulatedLimit()}
                    </div>
                    <div className={'mt-12 flex justify-center'}>
                        <Button
                            data-cy="offer_set_up_confirmation_pop_up_cancel"
                            appearance={'inversed'}
                            className={'w-1/3'}
                            onClick={() => setModalOfferConfirmOpen(false)}
                        >
                            {t('offerCreation.createModal.cancel')}
                        </Button>
                        <Button
                            data-cy="offer_set_up_confirmation_pop_up_confirm"
                            className={'w-1/3 ml-4'}
                            onClick={nextTab}
                        >
                            {t('offerCreation.createModal.continue')}
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal show={modalEmailCampaignOpen} onClose={() => setModalEmailCampaignOpen(false)}>
                <div className="max-w-md text-center m-auto text-base">
                    {!emailCampaignEdition ? (
                        <>
                            <p>
                                {t('offerCreation.emailModal.title', {
                                    date: moment(formData.emailNotificationDate).format('DD/MM/YYYY'),
                                    time: moment(formData.emailNotificationDate).format('h:mm a'),
                                })}
                            </p>
                            <p>{t('offerCreation.emailModal.question')}</p>
                            <div className={'mt-12 flex justify-center'}>
                                <Button
                                    data-cy="offer_set_up_customize_email_date"
                                    appearance={'inversed'}
                                    className={'w-1/3'}
                                    onClick={() => setEmailCampaignEdition(true)}
                                >
                                    {t('offerCreation.emailModal.button.customize')}
                                </Button>
                                <Button
                                    data-cy="offer_set_up_skip_customize_email_date"
                                    className={'w-1/3 ml-4'}
                                    onClick={() => createOffer()}
                                >
                                    {t('offerCreation.emailModal.button.ok')}
                                </Button>
                            </div>
                            <div className={'mt-2 flex justify-center text-center'}>
                                <a
                                    href="#"
                                    className="underline"
                                    onClick={() => {
                                        createOffer({ emailNotificationDate: undefined })
                                    }}
                                >
                                    {t('offerCreation.emailModal.button.noEmail')}
                                </a>
                            </div>
                        </>
                    ) : (
                        <>
                            <h3>{t('offerCreation.editEmailModal.title')}</h3>

                            <div className="mt-12 flex justify-center">
                                <div className="mr-4">
                                    <Label>{t('offerCreation.editEmailModal.date.label')}</Label>
                                    <Datepicker
                                        minDate={formData.startDate}
                                        className="mt-2"
                                        onChange={(emailNotificationDate) =>
                                            setFormData({ ...formData, emailNotificationDate })
                                        }
                                        value={formData.emailNotificationDate}
                                        data-cy="offer_email_notification_date"
                                    />
                                </div>

                                <div>
                                    <Label className="inline-block mb-2">
                                        {t('offerCreation.editEmailModal.hour.label')}
                                    </Label>
                                    <Timepicker
                                        minDate={new Date()}
                                        onChange={(emailNotificationDate) =>
                                            setFormData({ ...formData, emailNotificationDate })
                                        }
                                        value={formData.emailNotificationDate}
                                        data-cy="offer_email_notification_date"
                                        minuteStep={5}
                                    />
                                </div>
                            </div>

                            <div className={'mt-12 flex justify-center'}>
                                <Button
                                    data-cy="offer_set_up_cancel_customization"
                                    appearance={'inversed'}
                                    className={'w-1/3'}
                                    onClick={() => {
                                        setFormData({ ...formData, emailNotificationDate: emailCampaignDate })
                                        setEmailCampaignEdition(false)
                                    }}
                                >
                                    {t('offerCreation.editEmailModal.button.cancel')}
                                </Button>
                                <Button
                                    data-cy="offer_set_up_done_customization"
                                    className={'w-1/3 ml-4'}
                                    onClick={() => createOffer()}
                                >
                                    {t('offerCreation.editEmailModal.button.ok')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default withProgram(Form)
