import { ReactElement, useContext } from 'react'
import ProgramContext from '../contexts/ProgramContext'

export enum EFeature {
    LOYALTY_POINTS = 'LOYALTY_POINTS',
}

export const useProgramFeature = (feature: EFeature) => {
    const { currentProgram } = useContext<any>(ProgramContext)
    return currentProgram.features.find((f: any) => f.name === feature)
}

type RequireFeatureProps = {
    children: ReactElement
    feature: EFeature
}

export default function RequireFeature({ children, feature }: RequireFeatureProps): ReactElement | null {
    const featureData = useProgramFeature(feature)
    return !!featureData ? children : null
}
