import React from 'react'

function MoneyPile() {
    return (
        <svg width="61" height="23" viewBox="0 0 61 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.45"
                d="M30.5 22.1341C47.3447 22.1341 61 20.1425 61 17.6857C61 15.2289 47.3447 13.2373 30.5 13.2373C13.6553 13.2373 0 15.2289 0 17.6857C0 20.1425 13.6553 22.1341 30.5 22.1341Z"
                fill="#E6E6E6"
            />
            <path
                d="M38.1519 10.7787V13.4356C38.1519 14.6394 34.8038 15.6045 30.6772 15.6045C26.5505 15.6045 23.2068 14.6155 23.209 13.4118V10.7549L38.1519 10.7787Z"
                fill="#FFD200"
            />
            <path
                d="M38.1477 13.9838V16.6407C38.1477 17.8445 34.7975 18.8096 30.6708 18.8096C26.5442 18.8096 23.2005 17.8206 23.2026 16.6169V13.96L38.1477 13.9838Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M38.1477 13.9838V16.6407C38.1477 17.8445 34.7975 18.8096 30.6708 18.8096C26.5442 18.8096 23.2005 17.8206 23.2026 16.6169V13.96L38.1477 13.9838Z"
                fill="black"
            />
            <path
                d="M38.0071 13.8999C38.0092 12.7068 34.6963 11.7339 30.6076 11.7267C26.5189 11.7195 23.2026 12.6809 23.2005 13.874C23.1985 15.067 26.5113 16.04 30.6001 16.0471C34.6888 16.0543 38.0051 15.0929 38.0071 13.8999Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M38.1519 10.7787V13.4356C38.1519 14.6394 34.8038 15.6045 30.6772 15.6045C26.5505 15.6045 23.2068 14.6155 23.209 13.4118V10.7549L38.1519 10.7787Z"
                fill="black"
            />
            <path
                d="M37.6155 7.60687V10.2638C37.6155 11.4675 34.2653 12.4327 30.1386 12.4327C26.012 12.4327 22.6682 11.4436 22.6704 10.2399V7.58301L37.6155 7.60687Z"
                fill="#FFD200"
            />
            <path
                d="M38.1626 4.40596V7.06287C38.1626 8.2666 34.8146 9.23176 30.6879 9.23176C26.5613 9.23176 23.2176 8.24275 23.2197 7.03901V4.38428L38.1626 4.40596Z"
                fill="#FFD200"
            />
            <path
                d="M38.0135 10.7256C38.0156 9.5325 34.7027 8.55954 30.6139 8.55238C26.5252 8.54522 23.209 9.50659 23.2069 10.6996C23.2048 11.8927 26.5177 12.8657 30.6064 12.8728C34.6952 12.88 38.0114 11.9186 38.0135 10.7256Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M37.6155 7.60687V10.2638C37.6155 11.4675 34.2653 12.4327 30.1386 12.4327C26.012 12.4327 22.6682 11.4436 22.6704 10.2399V7.58301L37.6155 7.60687Z"
                fill="black"
            />
            <path
                d="M37.4783 7.57957C37.4804 6.38652 34.1675 5.41355 30.0788 5.4064C25.9901 5.39924 22.6738 6.3606 22.6717 7.55366C22.6697 8.74671 25.9825 9.71967 30.0713 9.72682C34.16 9.73398 37.4762 8.77262 37.4783 7.57957Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M38.1626 4.40596V7.06287C38.1626 8.2666 34.8146 9.23176 30.6879 9.23176C26.5613 9.23176 23.2176 8.24275 23.2197 7.03901V4.38428L38.1626 4.40596Z"
                fill="black"
            />
            <path
                d="M38.165 4.4099C38.1671 3.21684 34.8223 2.24382 30.6941 2.2366C26.566 2.22937 23.2178 3.19068 23.2157 4.38373C23.2136 5.57679 26.5584 6.5498 30.6866 6.55703C34.8147 6.56425 38.1629 5.60295 38.165 4.4099Z"
                fill="#FFD200"
            />
            <path
                d="M55.5109 10.2426V13.2161C55.5109 14.563 51.7606 15.6496 47.143 15.6409C42.5254 15.6323 38.7815 14.5348 38.7837 13.1879V10.2144L55.5109 10.2426Z"
                fill="#FFD200"
            />
            <path
                d="M55.5045 13.826V16.7996C55.5045 18.1487 51.7564 19.2331 47.1366 19.2266C42.5169 19.2201 38.7752 18.1205 38.7773 16.7714V13.7979L55.5045 13.826Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M55.5045 13.826V16.7996C55.5045 18.1487 51.7564 19.2331 47.1366 19.2266C42.5169 19.2201 38.7752 18.1205 38.7773 16.7714V13.7979L55.5045 13.826Z"
                fill="black"
            />
            <path
                d="M55.3472 13.7298C55.3495 12.3942 51.6417 11.305 47.0656 11.297C42.4895 11.289 38.778 12.3652 38.7757 13.7008C38.7734 15.0364 42.4811 16.1256 47.0572 16.1336C51.6333 16.1417 55.3448 15.0654 55.3472 13.7298Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M55.5109 10.2426V13.2161C55.5109 14.563 51.7606 15.6496 47.143 15.6409C42.5254 15.6323 38.7815 14.5348 38.7837 13.1879V10.2144L55.5109 10.2426Z"
                fill="black"
            />
            <path
                d="M54.9069 6.68786V9.66142C54.9069 11.0105 51.1587 12.0949 46.539 12.0884C41.9192 12.0819 38.1775 10.9823 38.1797 9.63323V6.65967L54.9069 6.68786Z"
                fill="#FFD200"
            />
            <path
                d="M55.5216 3.10681V6.08037C55.5216 7.42725 51.7735 8.51387 47.1537 8.50519C42.5339 8.49652 38.7923 7.39906 38.7944 6.05217V3.07861L55.5216 3.10681Z"
                fill="#FFD200"
            />
            <path
                d="M55.3535 10.1795C55.3558 8.84393 51.6481 7.75472 47.072 7.74672C42.4959 7.73871 38.7844 8.81493 38.782 10.1505C38.7797 11.4861 42.4875 12.5753 47.0636 12.5833C51.6396 12.5914 55.3512 11.5151 55.3535 10.1795Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M54.9069 6.68786V9.66142C54.9069 11.0105 51.1587 12.0949 46.539 12.0884C41.9192 12.0819 38.1775 10.9823 38.1797 9.63323V6.65967L54.9069 6.68786Z"
                fill="black"
            />
            <path
                d="M54.7573 6.65805C54.7596 5.32245 51.0519 4.23324 46.4758 4.22523C41.8997 4.21722 38.1882 5.29345 38.1858 6.62905C38.1835 7.96465 41.8913 9.05386 46.4674 9.06187C51.0434 9.06987 54.755 7.99365 54.7573 6.65805Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M55.5216 3.10681V6.08037C55.5216 7.42725 51.7735 8.51387 47.1537 8.50519C42.5339 8.49652 38.7923 7.39906 38.7944 6.05217V3.07861L55.5216 3.10681Z"
                fill="black"
            />
            <path
                d="M55.5243 3.10803C55.5266 1.77244 51.783 0.683165 47.1627 0.675078C42.5425 0.666991 38.7951 1.74315 38.7928 3.07875C38.7905 4.41435 42.534 5.50363 47.1543 5.51171C51.7746 5.5198 55.522 4.44363 55.5243 3.10803Z"
                fill="#FFD200"
            />
            <path
                d="M21.8615 10.0209V12.9944C21.8615 14.3413 18.1112 15.4279 13.4936 15.4192C8.87595 15.4106 5.13212 14.3131 5.13428 12.9662V9.99268L21.8615 10.0209Z"
                fill="#FFD200"
            />
            <path
                d="M21.8488 13.598V16.5716C21.8488 17.9185 18.1006 19.0051 13.4809 18.9964C8.86109 18.9877 5.11942 17.8903 5.12158 16.5434V13.5698L21.8488 13.598Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M21.8488 13.598V16.5716C21.8488 17.9185 18.1006 19.0051 13.4809 18.9964C8.86109 18.9877 5.11942 17.8903 5.12158 16.5434V13.5698L21.8488 13.598Z"
                fill="black"
            />
            <path
                d="M21.6972 13.5091C21.6996 12.1735 17.9918 11.0843 13.4157 11.0763C8.83964 11.0683 5.12812 12.1445 5.12579 13.4801C5.12347 14.8157 8.83123 15.9049 13.4073 15.9129C17.9834 15.9209 21.6949 14.8447 21.6972 13.5091Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M21.8615 10.0209V12.9944C21.8615 14.3413 18.1112 15.4279 13.4936 15.4192C8.87595 15.4106 5.13212 14.3131 5.13428 12.9662V9.99268L21.8615 10.0209Z"
                fill="black"
            />
            <path
                d="M21.2585 6.46667V9.44023C21.2585 10.7871 17.5103 11.8737 12.8905 11.865C8.27076 11.8564 4.52909 10.7589 4.53125 9.41203V6.43848L21.2585 6.46667Z"
                fill="#FFD200"
            />
            <path
                d="M21.8722 2.88367V5.85722C21.8722 7.2041 18.1241 8.29072 13.5043 8.28421C8.88453 8.27771 5.14286 7.17591 5.14502 5.82902V2.85547L21.8722 2.88367Z"
                fill="#FFD200"
            />
            <path
                d="M21.7041 9.95834C21.7064 8.62274 17.9986 7.53353 13.4226 7.52552C8.84648 7.51751 5.13495 8.59374 5.13263 9.92934C5.1303 11.2649 8.83806 12.3541 13.4141 12.3622C17.9902 12.3702 21.7018 11.2939 21.7041 9.95834Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M21.2585 6.46667V9.44023C21.2585 10.7871 17.5103 11.8737 12.8905 11.865C8.27076 11.8564 4.52909 10.7589 4.53125 9.41203V6.43848L21.2585 6.46667Z"
                fill="black"
            />
            <path
                d="M21.1059 6.43637C21.1083 5.10078 17.4005 4.01157 12.8244 4.00356C8.24834 3.99555 4.53681 5.07177 4.53448 6.40737C4.53216 7.74297 8.23992 8.83218 12.816 8.84019C17.3921 8.84819 21.1036 7.77197 21.1059 6.43637Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M21.8722 2.88367V5.85722C21.8722 7.2041 18.1241 8.29072 13.5043 8.28421C8.88453 8.27771 5.14286 7.17591 5.14502 5.82902V2.85547L21.8722 2.88367Z"
                fill="black"
            />
            <path
                opacity="0.08"
                d="M21.5434 19.5598C24.9728 19.5598 27.7529 16.7719 27.7529 13.3329C27.7529 9.89383 24.9728 7.10596 21.5434 7.10596C18.114 7.10596 15.334 9.89383 15.334 13.3329C15.334 16.7719 18.114 19.5598 21.5434 19.5598Z"
                fill="black"
            />
            <path
                opacity="0.08"
                d="M17.4128 7.09131C17.4128 7.09131 10.9135 9.08018 11.956 18.9508C11.956 18.9508 16.1951 19.094 18.2065 18.5778C20.0038 18.1223 17.4128 7.09131 17.4128 7.09131Z"
                fill="black"
            />
            <path
                d="M21.8749 2.88587C21.8772 1.55027 18.1336 0.461001 13.5133 0.452914C8.89305 0.444827 5.14569 1.52099 5.14337 2.85659C5.14104 4.19219 8.88463 5.28146 13.5049 5.28954C18.1252 5.29763 21.8725 4.22147 21.8749 2.88587Z"
                fill="#FFD200"
            />
            <path
                d="M21.5434 19.5598C24.9728 19.5598 27.7529 16.7719 27.7529 13.3329C27.7529 9.89383 24.9728 7.10596 21.5434 7.10596C18.114 7.10596 15.334 9.89383 15.334 13.3329C15.334 16.7719 18.114 19.5598 21.5434 19.5598Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M21.5434 19.5598C24.9728 19.5598 27.7529 16.7719 27.7529 13.3329C27.7529 9.89383 24.9728 7.10596 21.5434 7.10596C18.114 7.10596 15.334 9.89383 15.334 13.3329C15.334 16.7719 18.114 19.5598 21.5434 19.5598Z"
                fill="black"
            />
            <g opacity="0.08">
                <path
                    opacity="0.08"
                    d="M21.8466 17.6867C25.276 17.6867 28.0561 14.8988 28.0561 11.4598C28.0561 8.02079 25.276 5.23291 21.8466 5.23291C18.4173 5.23291 15.6372 8.02079 15.6372 11.4598C15.6372 14.8988 18.4173 17.6867 21.8466 17.6867Z"
                    fill="black"
                />
            </g>
            <path
                d="M21.8466 17.6867C25.276 17.6867 28.0561 14.8988 28.0561 11.4598C28.0561 8.02079 25.276 5.23291 21.8466 5.23291C18.4173 5.23291 15.6372 8.02079 15.6372 11.4598C15.6372 14.8988 18.4173 17.6867 21.8466 17.6867Z"
                fill="#FFD200"
            />
            <path
                d="M22.0455 8.67383C22.2228 8.67383 22.2747 8.73456 22.2747 8.91024V9.28545C22.5835 9.31793 22.8776 9.43426 23.1253 9.62191C23.373 9.80956 23.565 10.0614 23.6806 10.3504C23.7714 10.5326 23.7346 10.6323 23.527 10.7256L23.1528 10.8926C22.9776 10.9772 22.8933 10.9382 22.7938 10.756C22.7161 10.5655 22.5793 10.4052 22.4036 10.2989C22.2278 10.1927 22.0226 10.146 21.8184 10.166C21.1695 10.166 20.8711 10.3569 20.8711 10.7928C20.886 10.9262 20.9466 11.0502 21.0424 11.1439C21.1381 11.2376 21.2633 11.2952 21.3966 11.3069C21.5785 11.3465 21.7627 11.3748 21.9481 11.3915C22.2941 11.4071 22.6359 11.4736 22.9625 11.5888C23.1262 11.642 23.2787 11.7251 23.4124 11.8339C23.5476 11.9769 23.653 12.1455 23.7225 12.3298C23.7919 12.5142 23.824 12.7106 23.8168 12.9075C23.8168 13.7404 23.261 14.3086 22.2747 14.4539V14.8508C22.2747 15.0265 22.2228 15.0894 22.0455 15.0894H21.6324C21.4572 15.0894 21.3966 15.0265 21.3966 14.8508V14.4648C21.0544 14.4423 20.7265 14.3184 20.4546 14.1087C20.1827 13.899 19.9791 13.613 19.8697 13.2871C19.8535 13.2527 19.8453 13.2151 19.8457 13.1772C19.8461 13.1392 19.8551 13.1018 19.8719 13.0678C19.8888 13.0337 19.9131 13.004 19.943 12.9808C19.973 12.9575 20.0078 12.9414 20.0449 12.9335L20.419 12.7969C20.6094 12.7275 20.6937 12.773 20.7781 12.9639C20.9468 13.3977 21.2907 13.6146 21.8097 13.6146C22.5105 13.6146 22.863 13.3977 22.863 12.94C22.8683 12.8356 22.839 12.7322 22.7795 12.6463C22.72 12.5603 22.6337 12.4966 22.5343 12.4651C22.2906 12.3791 22.0354 12.3301 21.7773 12.3197C21.4272 12.3009 21.0821 12.2277 20.7543 12.1028C20.5965 12.0441 20.4501 11.9582 20.3217 11.8491C20.1847 11.7137 20.0772 11.5513 20.0061 11.3721C19.935 11.1929 19.9018 11.0008 19.9086 10.808C19.9086 9.98167 20.4277 9.43077 21.4053 9.30064V8.92542C21.4053 8.74974 21.4658 8.68901 21.641 8.68901L22.0455 8.67383Z"
                fill="white"
            />
        </svg>
    )
}

export default MoneyPile
