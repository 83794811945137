import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import IPhoneMenuIcon from './Icons/IPhoneMenuIcon'
import IPhoneBookIcon from './Icons/IPhoneBookIcon'
import IPhoneTasksIcon from './Icons/IPhoneTasksIcon'
import IPhoneCellularIcon from './Icons/IPhoneCellularIcon'
import IPhoneWifiIcon from './Icons/IPhoneWifiIcon'
import IPhoneBatteryIcon from './Icons/IPhoneBatteryIcon'
import { useTranslation } from 'react-i18next'

function IPhone({ children, host }) {
    const { t } = useTranslation('common')
    const iphoneRef = useRef(null)
    const [ratio, setRatio] = useState(1)
    useEffect(() => {
        function handleResize() {
            setRatio(iphoneRef.current.clientWidth / iphoneRef.current.naturalWidth)
        }
        // Bind the event listener
        window.addEventListener('resize', handleResize)
        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener('resize', handleResize)
        }
    })
    return (
        <div className="relative">
            <div
                className="absolute top-4 w-iphone h-iphone overflow-hidden flex flex-col origin-top-left"
                style={{
                    transform: 'scale(' + ratio + ')',
                    left: ratio * 40,
                }}
            >
                <div className="px-3 flex flex-col gap-2 bg-gray-100 pb-3">
                    <div className="text-xxs flex justify-between">
                        <div>9:41</div>
                        <div className="flex items-center gap-0.5">
                            <IPhoneCellularIcon />
                            <IPhoneWifiIcon />
                            <IPhoneBatteryIcon />
                        </div>
                    </div>
                    <div className="rounded-lg bg-gray-200 text-xxs text-center py-0.5 truncate p-2">
                        {host || t('spaycialist.program.com')}
                    </div>
                </div>
                <div>{children}</div>
                <div className="absolute bottom-0 w-full px-3 flex flex-col border-t border-gray-300 items-center bg-gray-100 pb-3">
                    <div className="text-xxs flex justify-between w-full py-1">
                        <div className="flex flex-1 items-center gap-4">
                            <div>
                                <ChevronLeftIcon className="h-3.5 text-gray-500" />
                            </div>
                            <div>
                                <ChevronRightIcon className="h-3.5 text-gray-500" />
                            </div>
                        </div>
                        <div className="flex flex-1 items-center justify-between">
                            <IPhoneMenuIcon />
                            <IPhoneBookIcon />
                            <IPhoneTasksIcon />
                        </div>
                    </div>
                    <div className="border border-t-2 border-black rounded w-16 mt-1.5"></div>
                </div>
            </div>
            <img ref={iphoneRef} src="/iphone.png" className="relative" />
        </div>
    )
}

IPhone.propTypes = {
    name: PropTypes.string,
}

export default IPhone
