import 'rc-collapse/assets/index.css'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../../../components/Checkbox'
import { CommonFilterProps } from './audience-filters-types'

type CheckboxFilterProps = CommonFilterProps & {
    id: string
}

function CheckboxFilter({ getFilterValue, setFilterValue, isEnabled, id }: CheckboxFilterProps) {
    const { t } = useTranslation('common')

    return (
        <p className="mb-2">
            <label className={!isEnabled(id) ? 'text-disabled' : 'cursor-pointer'}>
                <Checkbox
                    checked={getFilterValue(id)?.value}
                    onChange={(e: boolean) => setFilterValue(id, e)}
                    className="mr-2"
                    disabled={!isEnabled(id)}
                    data-cy={id}
                />
                {t('audience.filters.' + id)}
            </label>
        </p>
    )
}

export default CheckboxFilter
