export class UrlBuilder {
    static fromBaseUrl(url: string) {
        return new UrlBuilder(url)
    }

    private readonly baseUrl: string
    private urlSearchParams: URLSearchParams
    private constructor(baseUrl: string) {
        this.baseUrl = baseUrl
        this.urlSearchParams = new URLSearchParams()
    }

    addParameter(name: string, value: string | number | Date | Array<string | number | Date>): UrlBuilder {
        if (Array.isArray(value)) {
            name = name.indexOf('[]') === name.length - 2 ? name : name + '[]'
            value.forEach((v) => this.addParameter(name, v))
        } else if (value instanceof Date) this.urlSearchParams.append(name, value.toISOString())
        else if (typeof value === 'number') this.urlSearchParams.append(name, value.toString())
        else this.urlSearchParams.append(name, value)

        return this
    }

    toString(): string {
        return this.baseUrl + (this.baseUrl.indexOf('?') >= 0 ? '' : '?') + this.urlSearchParams.toString()
    }
}
