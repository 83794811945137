import PropTypes from 'prop-types'
import YLogoLoading from '../../../components/Icons/YLogoLoading'
import Heading from '../../../components/Heading'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ProgramContext from '../../../contexts/ProgramContext'

function Success({ title }) {
    const navigate = useNavigate()
    const { currentProgram } = useContext(ProgramContext)

    useEffect(() => {
        setTimeout(() => {
            navigate(`/programs/${currentProgram.id}/stores/create`)
        }, 3000)
    }, [currentProgram.id, navigate])

    return (
        <div className="flex flex-col justify-center items-center h-full gap-16">
            <div>
                <YLogoLoading width="75" border="#FF7700" color="transparent" />
            </div>
            <Heading>{title}</Heading>
        </div>
    )
}

Success.propTypes = {
    title: PropTypes.string,
}

Success.defaultProps = {
    title: 'Congratulations. You will be redirected in a few second',
}

export default Success
