import YLogoLoading from '../../../components/Icons/YLogoLoading'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Heading from '../../../components/Heading'
import { Request } from '../../../helpers'
import { withAuth } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import ProgramContext from '../../../contexts/ProgramContext'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import { withShoppingHubOwner } from '../../../contexts/ShoppingHubOwnerContext'

function Loading({ user, currentShoppingHubOwner }) {
    const { t } = useTranslation('common')
    const { onProgramPageNavigated } = useContext(ProgramContext)
    const [onLoad, setOnLoad] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const navigate = useNavigate()
    const location = useLocation()
    const programData = location.state || {}
    const { handleError } = useContext(ErrorManagementContext)
    const {
        organizationName,
        programName,
        primaryColor,
        backgroundColor,
        benefit,
        dataRetrievalMethod,
        cashback,
        hasExternalFront,
        fontColor,
        fontColorOverPrimary,
        componentColor,
        logo,
        country,
        host,
    } = programData
    const title = t('create_program.loading.title')

    const runPercentAnimation = () => {
        setTimeout((e) => setPercentage(0), 1000)
        setTimeout((e) => setPercentage(25), 1000)
        setTimeout((e) => setPercentage(50), 2000)
        setTimeout((e) => setPercentage(75), 3000)
        setTimeout((e) => setPercentage(99), 4000)
        setTimeout((e) => {
            setPercentage(100)

            if (onLoad) runPercentAnimation()
        }, 5000)
    }

    useEffect(() => {
        if (!onLoad) {
            setOnLoad(true)
            runPercentAnimation()
            Request.post('backoffice/shoppingHubs', {
                name: organizationName,
                programName,
                colorPrimary: primaryColor,
                colorSecondary: backgroundColor,
                fontColor,
                fontColorOverPrimary,
                colorComponent: componentColor,
                colorBackground: backgroundColor,
                programType: benefit,
                dataRetrievalMethod,
                hasExternalFront,
                cashback,
                customerExternalIdRequired: hasExternalFront,
                emailRequired: !hasExternalFront,
                phoneRequired: !hasExternalFront,
                shoppingHubOwnerId: currentShoppingHubOwner?.id || user.shoppingHubOwnerId,
                country: country.value,
                status: 'Pending',
                logo_url: logo,
                host,
            })
                .then((res) => {
                    onProgramPageNavigated(res.id).then(() => navigate('/programs/create/success'))
                })
                .catch((e) => {
                    handleError('errors.program.creation')(e)
                    navigate('/programs/create/look-and-feel', { state: programData })
                })
        }
    }, [onLoad])

    return (
        <div className="flex flex-col justify-center items-center h-full gap-16">
            <div>
                <YLogoLoading width="75" border="#FF7700" color="transparent" percentage={percentage} />
            </div>
            <Heading>{title}</Heading>
        </div>
    )
}

export default withShoppingHubOwner(withAuth(Loading))
