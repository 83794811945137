import PropTypes from 'prop-types'
import React from 'react'

import { Modal as AntdModal } from 'antd'
import './Modal.css'

function Modal({ children, show = false, width = '', onClose }) {
    return (
        <AntdModal
            visible={show}
            onCancel={onClose}
            className={'modal'}
            footer={null}
            width={width.length === 0 ? undefined : width}
        >
            {children}
        </AntdModal>
    )
}

Modal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
}

export default Modal
