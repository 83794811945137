import Checkbox from 'src/components/Checkbox'
import InProgramIcon from 'src/components/Icons/InProgramIcon'
import OutOfProgramIcon from 'src/components/Icons/OutOfProgramIcon'
import { ShopperProfileSegment } from '../shopper-profile-behavior-types'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { Table } from 'src/components/Table'
import { useMemo } from 'react'

type ShoppingCategoriesTableProps = {
    segments: ShopperProfileSegment[]
    unselectedSegmentsId: string[]
    toggleSelectSegment: (segmentId: string) => void
}

function ShoppingCategoriesTable({
    segments,
    unselectedSegmentsId,
    toggleSelectSegment,
}: ShoppingCategoriesTableProps) {
    const { t } = useTranslation('common')
    const c = t('shopperProfile.currency')

    const columns: ColumnsType<ShopperProfileSegment> = useMemo(() => {
        const totalInMall = segments.reduce((acc, s) => acc + s.total_spend_in_mall, 0)
        const totalOutMall = segments.reduce((acc, s) => acc + s.total_spend_outside_mall, 0)

        return [
            {
                title: (
                    <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {t('shopperProfile.segments.perSegment')}
                    </div>
                ),
                render: (s: ShopperProfileSegment) => (
                    <div className="flex items-center cursor-pointer" onClick={() => toggleSelectSegment(s.id)}>
                        <Checkbox
                            onChange={() => {}}
                            checked={!unselectedSegmentsId.includes(s.id)}
                            className="mr-2"
                            disabled={s.total_spend_in_mall === 0}
                        />
                        <div className="w-auto truncate">{s.name}</div>
                        <div className="text-xs text-none ml-2">
                            {s.total_spend_in_mall + s.total_spend_outside_mall + c}
                        </div>
                    </div>
                ),
            },
            {
                title: (
                    <div className="flex">
                        <InProgramIcon className="min-w-1 h-4 mr-2" />
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {totalInMall + t('shopperProfile.currency')}
                        </div>
                    </div>
                ),
                className: 'w-1/5 truncate',
                render: (s: ShopperProfileSegment) => (
                    <div className="flex items-center">
                        {totalInMall > 0 ? ((s.total_spend_in_mall * 100) / totalInMall).toFixed(0) : 0}%
                        <div className="text-xs text-none ml-2">{s.total_spend_in_mall + c}</div>
                    </div>
                ),
            },
            {
                title: (
                    <div className="flex">
                        <OutOfProgramIcon className="min-w-1 h-4 mr-2" />
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {totalOutMall + t('shopperProfile.currency')}
                        </div>
                    </div>
                ),
                className: 'w-1/5 truncate',
                render: (s: ShopperProfileSegment) => (
                    <div className="flex items-center">
                        {totalOutMall > 0 ? ((s.total_spend_outside_mall * 100) / totalOutMall).toFixed(0) : 0}%
                        <span className="text-xs text-none ml-2">{s.total_spend_outside_mall + c}</span>
                    </div>
                ),
            },
        ]
    }, [segments, t, c, unselectedSegmentsId])

    return <Table className="mb-4 w-full" columns={columns} data={segments} tableLayout="fixed" />
}

export default ShoppingCategoriesTable
