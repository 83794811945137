export function PinpointIcon() {
    return (
        <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M16.272 10.272a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
                clipRule="evenodd"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.794 16.518a9 9 0 1112.724-.312l-6.206 6.518-6.518-6.206zm11.276-1.691l-4.827 5.07-5.07-4.827a7 7 0 119.897-.243z"
                clipRule="evenodd"
            />
        </svg>
    )
}
