import React from 'react'

type SetCurrent = (name: string) => void
type TabsContextType = {
    current: string
    setCurrent: SetCurrent
}

type TabsProps = {
    children: React.ReactNode
    setCurrent: SetCurrent
    current?: string
    className?: string
}

export const TabsContext = React.createContext<TabsContextType>({
    current: 'default',
    setCurrent: () => {},
})

function Tabs({ children, className = '', setCurrent, current = 'default' }: TabsProps) {
    return (
        <TabsContext.Provider value={{ current, setCurrent }}>
            <div className={['flex flex-row gap-x-6 md:gap-x-12', className].join(' ')}>{children}</div>
        </TabsContext.Provider>
    )
}

export default Tabs
