import Modal from './Modal'
import Button, { ButtonAppearance } from './Button'
import { useTranslation } from 'react-i18next'

type DeleteOfferModalProps = {
    show: boolean
    onClose: () => void
    onDelete: () => void
    name?: string
}

function DeleteOfferModal({ show, onClose, onDelete, name }: DeleteOfferModalProps) {
    const { t } = useTranslation('common')

    return (
        <Modal show={show} onClose={onClose}>
            <div className="flex flex-col gap-8 items-center max-w-lg p-4 text-center">
                <div>
                    {t('OfferView.confirmText')} <strong>{name}</strong> ?
                </div>
                <div className="flex gap-8 w-full">
                    <Button
                        appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                        className="flex-1 py-4"
                        onClick={() => onClose()}
                    >
                        {t('offerCreation.createModal.cancel')}
                    </Button>
                    <Button className="flex-1 py-4" onClick={onDelete}>
                        {t('OfferView.confirm')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteOfferModal
