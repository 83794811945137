import PropTypes from 'prop-types'

function InputValidation({ items = [], className = '' }) {
    const lastIndex = items.length - 1

    return (
        <ul className={['inline-flex text-none text-xs mb-0', className].join(' ')}>
            {items.map((item, index) => (
                <li key={index}>
                    <span className={item.completed ? 'text-success' : ''}>{item.label}</span>
                    {index !== lastIndex && <span className="inline-block mx-1">|</span>}
                </li>
            ))}
        </ul>
    )
}

InputValidation.propTypes = {
    className: PropTypes.string,
}

export default InputValidation
