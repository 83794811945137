import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Label from '../../../components/Label'
import Datepicker from '../../../components/Datepicker'
import moment from 'moment'
import Button, { ButtonAppearance } from '../../../components/Button'
import { Request } from '../../../helpers'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import { withProgram, WithProgramProps } from '../../../contexts/ProgramContext'
import Loading from '../../../components/Loading'

type DateRangeResponse = {
    from?: string
    to?: string
}

enum State {
    LOADING,
    NO_WALLET,
    OK,
}

const ToolStatementsReport = ({ currentProgram }: WithProgramProps) => {
    const { t } = useTranslation('common')
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month').toDate())
    const [endDate, setEndDate] = useState(moment().startOf('month').subtract(1, 'day').toDate())
    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const { handleError } = useContext(ErrorManagementContext)

    const [state, setState] = useState(State.LOADING)

    useEffect(() => {
        Request.get<DateRangeResponse>(`/backoffice/v3/wallet/date-range?shoppingHubId=${currentProgram.id}`)
            .then((res) => {
                const min = new Date(res.from || Date.now())
                const max = new Date(res.to || Date.now())

                setMinDate(min)
                setMaxDate(max)

                setStartDate(min > startDate ? min : startDate)
                setEndDate(max < endDate ? max : endDate)

                setState(State.OK)
            })
            .catch((err) => {
                if (err.code === 'noWalletData' || err.code === 'noWalletDataProvider') {
                    setState(State.NO_WALLET)
                } else {
                    handleError('errors.program.configuration.tools.get_statements_report_dates')(err)
                }
            })
    }, [])

    const downloadReport = async () => {
        setLoading(true)
        const from = moment(startDate).format('YYYY-MM-DD')
        const to = moment(endDate).format('YYYY-MM-DD')
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/backoffice/v3/wallet/export?shoppingHubId=${currentProgram.id}&from=${from}&to=${to}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('api_token')}`,
                    'Content-Type': `application/json`,
                },
            }
        )

        setLoading(false)

        if (res.status !== 200) {
            const body = await res.json()
            return handleError('errors.program.configuration.tools.get_statements_report')(body)
        }

        const blob = await res.blob()
        const url = URL.createObjectURL(blob)

        const contentDisposition = res.headers.get('Content-Disposition')
        const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'Statement report.xlsx'
        const a = document.createElement('a')
        a.href = url
        a.setAttribute('download', filename)
        a.click()
        URL.revokeObjectURL(url)
    }

    return (
        <div>
            <p className="font-bold mt-10">{t('program.configuration.tools.statements_report')}</p>
            {state === State.LOADING && <Loading fullScreen={false} />}
            {state === State.NO_WALLET && <p>{t('program.configuration.tools.no_wallet')}</p>}
            {state === State.OK && (
                <div className={'col-span-12 xl:col-span-11 2xl:col-span-10 grid grid-cols-8 gap-6'}>
                    <div className={'col-span-4 lg:col-span-2'}>
                        <Label>{t('offerCreation.from')}</Label>
                        <Datepicker
                            minDate={minDate}
                            maxDate={maxDate}
                            className="mt-3.5"
                            onChange={setStartDate}
                            value={startDate}
                            placeholder={t('offerCreation.fromDate')}
                        />
                    </div>

                    <div className={'col-span-4 lg:col-span-2'}>
                        <Label>{t('offerCreation.to')}</Label>
                        <Datepicker
                            minDate={minDate}
                            maxDate={maxDate}
                            className="mt-3.5"
                            onChange={setEndDate}
                            value={endDate}
                            placeholder={t('offerCreation.toDate')}
                        />
                    </div>

                    <div className={'col-span-4 lg:col-span-2 flex items-end'}>
                        <Button
                            appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                            className="h-16"
                            onClick={downloadReport}
                            disabled={startDate.getTime() > endDate.getTime() || loading}
                        >
                            {t('program.configuration.tools.create-report')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default withProgram(ToolStatementsReport)
