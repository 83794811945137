import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../../components/Button'
import Label from '../../../components/Label'
import Heading from '../../../components/Heading'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Request } from '../../../helpers'
import DropdownAsyncCreatableInput from '../../../components/DropdownAsyncCreatableInput'
import ProgramContext from '../../../contexts/ProgramContext'

function Brand() {
    const url = 'backoffice/retailers?limit=5'
    const { t } = useTranslation('common')
    const [brandName, setBrandName] = useState('')
    const [brandId, setBrandId] = useState('')
    const [inputValue, setInputValue] = useState(undefined)
    const navigate = useNavigate()
    const location = useLocation()
    const { currentProgram } = useContext(ProgramContext)
    let storeData = location.state || {}

    const validate = (brandName, brandId) => {
        storeData = Object.assign(storeData, { brandName, brandId })
        navigate(`/programs/${currentProgram.id}/stores/create/address`, { state: storeData })
    }

    const getBrands = async (url) => {
        try {
            const { retailers } = await Request.get(url)
            const options = retailers.map((retailer) => ({
                label: retailer.name,
                value: retailer.id,
            }))
            return options
        } catch (error) {
            console.error(error)
        }
    }

    const promiseOptions = async (inputValue) => {
        setInputValue(inputValue)
        if (inputValue) {
            return await getBrands(url + '&search=' + encodeURIComponent(inputValue))
        }
    }

    const handleChange = (newValue, actionMeta) => {
        // User selects an existing brand
        if (actionMeta.action !== 'clear') {
            setBrandId(newValue.value)
        }
        setBrandName(newValue?.label)
        setInputValue(newValue)
    }

    const handleCreate = (inputValue) => {
        setBrandId('')
        setBrandName(inputValue)
        setInputValue({ label: inputValue, value: inputValue })
    }

    return (
        <>
            <div className="flex flex-row justify-end items-center">
                <Button
                    as={'link'}
                    to={`/programs/${currentProgram.id}/stores`}
                    label={t('createBrand.setBrandName.exit')}
                    appearance="outline"
                />
            </div>
            <Heading className="mb-8 md:mb-20 mt-2">{t('createBrand.setBrandName.title')}</Heading>

            <div className="max-w-sm mx-auto flex flex-col gap-8 xl:py-12">
                <div>
                    <Label>{t('createBrand.setBrandName.header.title')}</Label>
                    <DropdownAsyncCreatableInput
                        placeholder="Spaycial"
                        loadOptions={promiseOptions}
                        className="mt-3.5"
                        onChange={handleChange}
                        onCreateOption={handleCreate}
                        value={inputValue}
                    />
                </div>

                <Button className="py-5" disabled={!brandName && !brandId} onClick={() => validate(brandName, brandId)}>
                    {t('createBrand.setBrandName.next')}
                </Button>
            </div>
        </>
    )
}

Brand.propTypes = {
    onValidate: PropTypes.func,
}

Brand.defaultProps = {
    onValidate: undefined,
}

export default Brand
