import { Link } from 'react-router-dom'
import React from 'react'

export enum ButtonAppearance {
    BUTTON_APPEARANCE_OUTLINE = 'outline',
    BUTTON_APPEARANCE_INVERSED = 'inversed',
    BUTTON_APPEARANCE_PRIMARY = 'primary',
}

type ButtonProps = {
    label?: string
    className?: string
    appearance?: ButtonAppearance
    disabled?: boolean
    children: React.ReactNode
    as?: 'link'
    to?: string
    onClick?: React.MouseEventHandler
    'data-cy'?: string
}

function Button({ children, label, className, appearance, disabled, as, to, ...props }: ButtonProps) {
    let classes = ['text-xs lg:text-base px-4 py-2 border transition-all']

    if (appearance === ButtonAppearance.BUTTON_APPEARANCE_OUTLINE) {
        classes.push('bg-transparent border-2 border-primary text-primary hover:text-white hover:bg-primary')
    } else if (appearance === ButtonAppearance.BUTTON_APPEARANCE_INVERSED) {
        classes.push('bg-transparent border-2 border-primary text-primary hover:text-white hover:bg-primary')
    } else {
        classes.push('bg-primary border-primary text-white border-1')
    }

    if (disabled) {
        classes.push('opacity-10')
    }

    if (className) {
        classes.push(className)
    }

    return (
        <>
            {as === 'link' ? (
                <Link
                    to={disabled ? '#' : (to as string)}
                    className={classes.join(' ')}
                    {...props}
                    data-cy={props['data-cy']}
                >
                    {children ?? label}
                </Link>
            ) : (
                <button
                    type="button"
                    className={classes.join(' ')}
                    disabled={disabled}
                    {...props}
                    data-cy={props['data-cy']}
                >
                    {children ?? label}
                </button>
            )}
        </>
    )
}

export default Button
