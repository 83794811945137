import { useTranslation } from 'react-i18next'
import Badge from '../../../components/Badge'
import RestTable, { RestTableRef } from '../../../components/Table'
import { ACCESS_LEVEL } from '../../../helpers'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import CreationModal from './CreationModal'

const Listing = forwardRef((_, ref) => {
    const { t } = useTranslation('common')
    const url = 'backoffice/admin/users'
    const [creationModalOpened, setCreationModalOpened] = useState(false)
    const [users, setUsers] = useState([])
    const restTableRef = React.createRef<RestTableRef>()

    useImperativeHandle(ref, () => ({
        openCreationModal: () => setCreationModalOpened(true),
    }))

    const appearances: Record<ACCESS_LEVEL, string | undefined> = {
        [ACCESS_LEVEL.READ]: 'text-dark opacity-70',
        [ACCESS_LEVEL.WRITE]: 'text-warning',
        [ACCESS_LEVEL.DELETE]: 'text-error',
    }

    const renderFunctionalities = (u: any) => {
        const grouped = u.functionalities.reduce(
            (acc: any, f: any) => {
                acc[f.level] = [...acc[f.level], f.code]
                return acc
            },
            {
                [ACCESS_LEVEL.READ]: [],
                [ACCESS_LEVEL.WRITE]: [],
                [ACCESS_LEVEL.DELETE]: [],
            }
        )

        return Object.keys(grouped)
            .filter((level) => grouped[level].length > 0)
            .map((level: string) => (
                <Badge className="mr-2 break-normal" key={u.id + level}>
                    <span className={'font-bold ' + appearances[level as ACCESS_LEVEL]}>
                        {t('listing_user.table.level.' + level.toLowerCase())}:&nbsp;
                    </span>
                    {grouped[level]
                        .map((c: string) => t('listing_user.table.functionality.' + c.toLowerCase()))
                        .join(', ')}
                </Badge>
            ))
    }

    const columns = [
        {
            title: t('listing_user.table_header.id'),
            render: (u: any) => u.backUserId,
        },
        { title: t('listing_user.table_header.email'), render: (u: any) => u.email },
        { title: t('listing_user.table_header.functionalities'), render: (u: any) => renderFunctionalities(u) },
    ]

    return (
        <>
            <div className="flex flex-col gap-4 p-1">
                <div className="overflow-auto">
                    <CreationModal
                        show={creationModalOpened}
                        onClose={() => setCreationModalOpened(false)}
                        users={users}
                        onCreated={() => restTableRef.current?.refresh()}
                    />

                    <RestTable
                        queryBaseUrl={url}
                        columns={columns}
                        errorMessageKey="errors.programs.search"
                        pagination={false}
                        pageResultsKey="backUsersDetails"
                        ref={restTableRef}
                        onDataReceived={(d: any) => {
                            d.backUsersDetails.forEach((b: any) => (b.id = b.backUserId))
                            setUsers(d.backUsersDetails)
                        }}
                    />
                </div>
            </div>
        </>
    )
})

export default Listing
