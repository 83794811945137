import React, { forwardRef, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import AuthContext from './AuthContext'
import { Setting } from '../interfaces/Setting'
import { Request } from '../helpers'

export type WithSettingsProps = {
    settings?: any
}

const SettingsContext = React.createContext<WithSettingsProps>({})

export function SettingsProvider({ children }: { children: ReactNode }) {
    const [settings, setSettings] = useState<Setting>()
    const ctx = useContext(AuthContext)

    const getSettings = useCallback(async () => {
        try {
            const res = await Request.get<Setting>('/backoffice/v3/settings')
            setSettings(res)
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        if (ctx.user) {
            getSettings()
        }
    }, [ctx.user])

    return <SettingsContext.Provider value={{ settings }}>{children}</SettingsContext.Provider>
}

export function withSettings<T extends WithSettingsProps = WithSettingsProps>(
    Component: React.ComponentType<T>
): (p: any) => any {
    return forwardRef((props: any, ref: any) => (
        <SettingsContext.Consumer>{(value) => <Component {...props} {...value} ref={ref} />}</SettingsContext.Consumer>
    ))
}

export default SettingsContext
