import Select from 'react-select'
import './DropdownInput.css'
import { useState, useEffect, SetStateAction } from 'react'

type DropdownInputProps = {
    value?: any
    placeholder: string
    className?: string
    onChange: (value: any) => void
    error?: any
    isClearable?: boolean
    indicatorSeparator?: boolean
    dropdownIndicator?: boolean
    asSearchInput?: boolean
    withoutMenu?: boolean
    disabled?: boolean
    isMulti?: boolean
    size?: string
    options: Array<any>
    'data-cy'?: string
}

function DropdownInput({
    value = null,
    placeholder = 'Choose',
    options = [],
    className = '',
    onChange,
    error = false,
    isClearable = true,
    indicatorSeparator = true,
    dropdownIndicator = true,
    asSearchInput = false,
    withoutMenu = false,
    disabled = false,
    isMulti = false,
    size,
    ...other
}: DropdownInputProps) {
    const [inputValue, setInputValue] = useState('')
    const [inputValueSave, setInputValueSave] = useState('')

    useEffect(() => {
        if (!value && asSearchInput) {
            setInputValue('')
            setInputValueSave('')
        }
    }, [value, asSearchInput])

    let components: any = {}
    let onInputChange
    let onMenuClose
    let onFocus

    // For placeholder
    if (value && value.value === '') value = null

    if (!indicatorSeparator) components.IndicatorSeparator = () => null

    if (!dropdownIndicator) components.DropdownIndicator = () => null

    // If the component is used as a search input we want to change the way it works
    if (asSearchInput) {
        isClearable = false
        components.IndicatorSeparator = () => null
        components.DropdownIndicator = () => (
            <svg
                className={'absolute right-0.5 top-2 cursor-pointer'}
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.7812 13.8438L12.6562 10.7188C12.5 10.5938 12.3125 10.5 12.125 10.5H11.625C12.4688 9.40625 13 8.03125 13 6.5C13 2.9375 10.0625 0 6.5 0C2.90625 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.5 13C8 13 9.375 12.5 10.5 11.625V12.1562C10.5 12.3438 10.5625 12.5312 10.7188 12.6875L13.8125 15.7812C14.125 16.0938 14.5938 16.0938 14.875 15.7812L15.75 14.9062C16.0625 14.625 16.0625 14.1562 15.7812 13.8438ZM6.5 10.5C4.28125 10.5 2.5 8.71875 2.5 6.5C2.5 4.3125 4.28125 2.5 6.5 2.5C8.6875 2.5 10.5 4.3125 10.5 6.5C10.5 8.71875 8.6875 10.5 6.5 10.5Z"
                    fill="#333333"
                />
            </svg>
        )
        onMenuClose = (): void => {
            if (!withoutMenu) {
                setInputValue('')
                setInputValueSave(inputValue)
            }
        }
        onFocus = (): void => {
            if (!withoutMenu) {
                setInputValue(inputValueSave)
                setInputValueSave('') // prevents undesired placeholder value
            }
        }
        onInputChange = (value: SetStateAction<string>, action: { action: string }): void => {
            if (action.action === 'input-change') {
                setInputValue(value)
                onChange({ value })
            }
        }

        if (size === 'big') {
            className += ' py-3.5'
        } else {
            className += ' lg:py-1'
        }
    } else {
        className += ' py-3.5'
    }

    if (withoutMenu) components.Menu = () => <></>

    return (
        <div className="container" data-cy={other['data-cy']}>
            <Select
                className={[
                    error
                        ? 'border-error focus:ring-error'
                        : disabled
                        ? 'border-color-none'
                        : 'border-black focus:ring-transparent',
                    'react-select-container cursor-text',
                    className,
                ].join(' ')}
                isMulti={isMulti}
                options={options}
                isClearable={isClearable}
                placeholder={placeholder}
                classNamePrefix="react-select"
                onChange={onChange}
                value={value}
                components={components}
                onInputChange={onInputChange}
                isDisabled={disabled}
                onMenuClose={onMenuClose} // before the input is cleared, save it
                onFocus={onFocus}
                inputValue={inputValue}
                styles={{
                    control: (provided: any) => {
                        return { ...provided, backgroundColor: '#FFFFFF', color: disabled ? '#C4C4C4' : '#000000' }
                    },
                    option: (provided: any) => {
                        return {
                            ...provided,
                            backgroundColor: '#FFFFFF',
                            color: disabled ? '#C4C4C4' : '#000000',
                            '&:hover': { backgroundColor: '#F2F2F2', color: '#FF7700' },
                        }
                    },
                    singleValue: (provided: any) => {
                        return { ...provided, color: disabled ? '#C4C4C4' : '#000000' }
                    },
                    dropdownIndicator: (provided: any) => {
                        return { ...provided, color: disabled ? '#C4C4C4' : '#000000' }
                    },
                }}
                {...other}
            />
            {error instanceof Error && <div className="text-error text-sm">{error.message}</div>}
        </div>
    )
}

export default DropdownInput
