import { useTranslation } from 'react-i18next'
import InfoTooltip from '../../../../components/InfoTooltip'
import { ArrowsExpandIcon } from '@heroicons/react/outline'
import IconKpi from './IconKpi'
import DonutKpi from './DonutKpi'
import { DonutData, EKpi, ETypeKpi, KpiData } from './kpi-types'
import { KpiDisplayData } from './hook/useKpiData'

type KpiProps = {
    type: EKpi
    data: KpiData
    displayData: KpiDisplayData
    isGlobalKpi: boolean
}

function Kpi({ type, data, displayData, isGlobalKpi }: KpiProps) {
    const { t } = useTranslation('common')

    const kpiDisplayContent =
        displayData.type === ETypeKpi.ICON ? (
            <IconKpi
                type={type}
                descriptionKey={displayData.descriptionKey}
                value={data.value}
                increasing={data.increasing as boolean}
            />
        ) : (
            <DonutKpi type={type} data={data as DonutData} displayData={displayData} />
        )

    const canClick = !isGlobalKpi && !!displayData.analyticsUrl

    return (
        <div className="block bg-white p-3 origin-center base-kpi">
            <div className="flex flex-row items-center">
                <div className="flex-1">
                    <h3 className="font-bold inline-block mr-2">{t(displayData.titleKey)}</h3>
                    {displayData.tooltipKey && <InfoTooltip text={t(displayData.tooltipKey)} />}
                </div>
                {canClick && (
                    <a
                        className="inline-block p-2"
                        href={displayData.analyticsUrl || '#'}
                        target="_blank"
                        rel="noreferrer"
                        data-cy={type + '_kpi_item'}
                    >
                        <ArrowsExpandIcon className="h-4 w-4" />
                    </a>
                )}
            </div>

            {kpiDisplayContent}
        </div>
    )
}

export default Kpi
