import './DropdownInput.css'
import AsyncCreatableSelect from 'react-select/async-creatable'

type DropdownAsyncCreatableInputProps = {
    placeholder?: string
    className: string
    loadOptions: any
    onChange: (selectedOption: any) => void
    value: any
    showCreateOption?: any
    onCreateOption?: () => void
}

function DropdownAsyncCreatableInput({
    placeholder,
    className,
    loadOptions,
    onChange,
    onCreateOption,
    value,
    showCreateOption = true,
}: DropdownAsyncCreatableInputProps) {
    let components: any = {}
    components.IndicatorSeparator = () => null
    components.DropdownIndicator = () => null
    className += ' lg:py-1'

    return (
        <AsyncCreatableSelect
            className={['border-black focus:ring-transparent', 'react-select-container', className].join(' ')}
            defaultOptions
            cacheOptions
            isClearable
            isValidNewOption={() => showCreateOption}
            placeholder={placeholder}
            classNamePrefix="react-select"
            components={components}
            loadOptions={loadOptions}
            onChange={onChange}
            onCreateOption={onCreateOption}
            value={value}
            styles={{
                control: (provided: any) => {
                    return { ...provided, backgroundColor: '#FFFFFF', color: '#000000', height: '54px' }
                },
                option: (provided: any) => {
                    return {
                        ...provided,
                        backgroundColor: '#FFFFFF',
                        color: '#000000',
                        '&:hover': { backgroundColor: '#F2F2F2', color: '#FF7700' },
                    }
                },
                singleValue: (provided: any) => {
                    return { ...provided, color: '#000000' }
                },
                dropdownIndicator: (provided: any) => {
                    return { ...provided, color: '#000000' }
                },
            }}
        />
    )
}

export default DropdownAsyncCreatableInput
