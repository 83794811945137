import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import { withProgram, WithProgramProps } from '../../../contexts/ProgramContext'
import { Table } from '../../../components/Table'
import { ColumnsType } from 'antd/es/table'
import { Request } from '../../../helpers'
import DropDown from '../../../components/DropDown'

const templateIds = [
    'subscriptionH1',
    'welcome',
    'relaunchD1',
    'missingCashbackMethod_iban',
    'missingCashbackMethod_creditCard',
    'refundCashback',
    'offerActivation_offers',
    'offerActivation_retailers',
    'cappingLimit',
    'customerAreaActivation',
    'desync',
    'monthlyOffers',
    'claimRejected',
    'claimValidated',
    'claimReceipt',
]

const ToolEmailTemplate = ({ currentProgram }: WithProgramProps) => {
    const uriSendEmail = '/backoffice/v3/email/template'
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)

    const sendEmail = async (templateId: string) => {
        Request.post(uriSendEmail, {
            templateId,
            shoppingHubId: currentProgram.id,
        })
            .then(() => {
                console.log('ok')
            })
            .catch((err) => {
                handleError('errors.program.configuration.tools.send_email')(err)
            })
    }

    const columns: ColumnsType<any> = [
        { title: t('templateEmail.header.name'), render: (template) => template.id },
        {
            title: t('templateEmail.header.description'),
            render: (template) => t('templateEmail.description.' + template.id),
        },
        {
            title: '',
            render: (template) => (
                <DropDown>
                    <span
                        className="px-4 py-3 hover:bg-gray-200 hover:text-primary w-full text-sm hover:bg-opacity-20"
                        onClick={() => sendEmail(template.id)}
                    >
                        {t('templateEmail.send')}
                    </span>
                </DropDown>
            ),
        },
    ]

    return (
        <div>
            <p className="font-bold mt-10">{t('program.configuration.tools.email_template')}</p>

            <Table columns={columns} data={templateIds.map((id) => ({ id }))} />
        </div>
    )
}

export default withProgram(ToolEmailTemplate)
