import { useTranslation } from 'react-i18next'
import { EKpi } from './kpi-types'
import { Cell, Pie, PieChart } from 'recharts'
import './DonutKpi.css'
import { KpiDisplayData } from './hook/useKpiData'
import { numberToShortcut } from '../../../../utils/number-utils'

type DonutKpiProps = {
    type: EKpi
    data: { [k: string]: number }
    displayData: KpiDisplayData
}

const RADIAN = Math.PI / 180

function DonutKpi({ type, data, displayData }: DonutKpiProps) {
    const { t } = useTranslation('common')

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)

        return (
            <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={13}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
    }

    const statsKeys = Object.keys(data).filter((k) => data[k] > 0)
    const numberOfStats = statsKeys.length
    const total = statsKeys.reduce((a, b) => a + data[b], 0)

    return (
        <div className="h-36 flex items-center justify-center relative">
            {data && statsKeys.length == 0 && <span>{t('audience.kpis.no-data')}</span>}
            {data && statsKeys.length > 0 && (
                <>
                    {numberOfStats === 3 && (
                        <div className="absolute bottom-0 left-0 leading-3">
                            {data[statsKeys[1]]}
                            <br />
                            <span className="text-none text-sm ">
                                {t(`audience.kpis.${type}.${statsKeys[1]}.legend`)}
                            </span>
                        </div>
                    )}

                    {numberOfStats >= 2 && (
                        <div className="absolute top-0 left-0 leading-3">
                            {numberOfStats === 3 ? data[statsKeys[2]] : data[statsKeys[1]]}
                            <br />
                            <span className="text-none text-sm">
                                {t(`audience.kpis.${type}.${statsKeys[numberOfStats === 3 ? 2 : 1]}.legend`)}
                            </span>
                        </div>
                    )}

                    <div className="absolute bottom-0 right-0 text-right leading-3">
                        {data[statsKeys[0]]}
                        <br />
                        <span className="text-none text-sm">{t(`audience.kpis.${type}.${statsKeys[0]}.legend`)}</span>
                    </div>

                    <PieChart width={140} height={140} className="cursor-pointer">
                        <text
                            x={75}
                            y={75}
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            fontSize={15}
                            fontWeight="bold"
                        >
                            {numberToShortcut(total)}
                        </text>
                        <Pie
                            data={statsKeys.map((k) => ({ value: data[k] }))}
                            cx={70}
                            cy={70}
                            innerRadius={25}
                            outerRadius={55}
                            paddingAngle={0}
                            dataKey="value"
                            startAngle={90}
                            endAngle={-270}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            isAnimationActive={false}
                        >
                            {statsKeys.map((_, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={displayData.colors ? displayData.colors[index] : 'black'}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </>
            )}
        </div>
    )
}

export default DonutKpi
