import { forwardRef, useImperativeHandle } from 'react'
import StoreFilter from '../../../components/StoreFilter'
import useStoresListData, {
    UseStoresListDataValidate,
    UseStoresListDataProps,
} from '../../../hooks/stores/useStoresListData'
import StoreTable from '../Creation/StoreTable'
import { WithProgramProps } from '../../../contexts/ProgramContext'

type OffersStoresProps = {
    challengeId: string | undefined
    APIroute: string
    onChange: UseStoresListDataProps['onChange']
    editButtonActive: boolean
    formData?: any
    offerType?: string
} & Partial<WithProgramProps>

/**
 * This component should :
 * Fetch the store API
 * DIstribute the data to the filter component and the store table component
 * @returns
 */
export const Stores = forwardRef<
    {
        validate: UseStoresListDataValidate
    },
    OffersStoresProps
>((props, ref) => {
    const { stores, loading, validate, filters, onFilterChange, ...rest } = useStoresListData(props)

    useImperativeHandle(ref, () => ({
        validate,
    }))

    return (
        <div data-testid="stores_component">
            <StoreFilter data={filters} onChange={onFilterChange} />
            <StoreTable
                ref={ref}
                methods={{ ...rest, loading }}
                stores={stores}
                editButtonActive={props.editButtonActive}
                formData={props.formData}
                offerType={props.offerType}
            />
        </div>
    )
})
