import Modal from '../../../components/Modal'
import Button, { ButtonAppearance } from '../../../components/Button'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type OffersModalConfirmationProps = {
    confirmationModal: any
    setConfirmationModalOpen: any
    onSave: any
    updatedData: any
    headerText: any
    unit: any
    storesAdded: number
    storesRemoved: number
    currentProgramTimeZone?: string
}

export const ModalConfirmation: React.FC<OffersModalConfirmationProps> = ({
    confirmationModal,
    setConfirmationModalOpen,
    onSave,
    updatedData,
    headerText,
    unit,
    storesAdded,
    storesRemoved,
    currentProgramTimeZone,
}: OffersModalConfirmationProps) => {
    const { t } = useTranslation('common')
    const programTimezone = currentProgramTimeZone || 'Europe/Paris'
    const displayRewardValue = (value: number) => {
        if (unit === '%') {
            return `${value * 100} ${unit}`
        }
        return `${value} ${unit}`
    }
    return (
        <Modal show={confirmationModal} onClose={() => setConfirmationModalOpen(false)}>
            <div className="max-w-md text-center m-auto">
                <p> {headerText}</p>
                {updatedData.rewardValue && (
                    <p>
                        {t('offerCreation.createModal.reward')} {displayRewardValue(updatedData.rewardValue)}
                    </p>
                )}

                {updatedData.endDate && (
                    <p>
                        {t('offerCreation.createModal.to', {
                            to: moment(updatedData.endDate)
                                .tz(programTimezone)
                                .subtract(1, 'days')
                                .format('DD/MM/YYYY'),
                        })}
                    </p>
                )}

                {(updatedData.displayed === true || updatedData.displayed === false) && (
                    <p>
                        {t('offerCreation.createModal.displayedInfo')}:&nbsp;
                        {t('offerCreation.createModal.displayed.' + updatedData.displayed)}
                    </p>
                )}

                {storesAdded > 0 && <p>{t('offerCreation.createModal.storesAdded', { storesAdded })}</p>}

                {storesRemoved > 0 && <p>{t('offerCreation.createModal.storesRemoved', { storesRemoved })}</p>}

                <div className={'mt-4'}></div>
                <div className={'mt-12 flex justify-center'}>
                    <Button
                        appearance={ButtonAppearance.BUTTON_APPEARANCE_INVERSED}
                        className={'w-1/3'}
                        onClick={() => setConfirmationModalOpen(false)}
                    >
                        {t('offerCreation.createModal.cancel')}
                    </Button>
                    <Button className={'w-1/3 ml-4'} onClick={() => onSave()}>
                        {t('offerCreation.createModal.continue')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
