import Pattern, { PatternSelection } from './Pattern'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import Fireworks from '@fireworks-js/react'
import RestTable from '../../../../../components/Table'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'

import Button, { ButtonAppearance } from '../../../../../components/Button'
import YLogoLoading from '../../../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../../../tailwind.config'
import { Request } from '../../../../../helpers'

const uriListTransactions = '/backoffice/patterns/transactions'
const uriCreatePattern = '/backoffice/v3/pinpoint/patterns'

type PatternCreationPageProps = {
    patternType: string
    getPicker?: (setObject: (obj: { id: string }) => void) => React.ReactNode
}

const PatternCreation = ({ patternType, getPicker }: PatternCreationPageProps) => {
    const location = useLocation()
    const locationData = location.state as any
    const navigate = useNavigate()
    const [pattern, setPattern] = useState<PatternSelection>()
    const [object, setObject] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (!locationData) navigate('/pinpoint/analyze')
    }, [locationData, navigate])

    const tableQueryUrl = useMemo(() => {
        return (
            uriListTransactions +
            `?patternStart=${pattern?.start}&patternEnd=${pattern?.end}&label=${encodeURIComponent(
                locationData?.transaction?.label
            )}`
        )
    }, [pattern?.start, pattern?.end, locationData?.transaction?.label])

    const columns: ColumnsType<any> = [
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Transaction date</div>,
            render: (t) => moment(t.transaction_date).format('MM/DD/YYYY'),
            defaultSortOrder: 'descend',
            key: 'transaction_date',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Label</div>,
            render: (t) => {
                const text = pattern?.text!
                const match = t.label.match(new RegExp(text, 'i'))

                return !match ? (
                    t.label
                ) : (
                    <>
                        <span className="text-dark/40">{t.label.substring(0, match.index)}</span>
                        <span className="text-primary font-bold">
                            {t.label.substring(match.index, match.index + text.length)}
                        </span>
                        <span className="text-dark/40">{t.label.substring(match.index + text.length)}</span>
                    </>
                )
            },
            key: 'label',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Amount</div>,
            render: (s) => s.amount,
            key: 'amount',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
    ]

    const savePattern = () => {
        setLoading(true)

        Request.post(
            uriCreatePattern,
            {
                selection: {
                    label: locationData?.transaction?.label,
                    start: pattern?.start,
                    end: pattern?.end,
                },
                pattern: pattern?.text,
                patternType,
                transactionId: locationData?.transaction?.id,
                entityId: object?.id,
            },
            {},
            null,
            true
        )
            .then(() => {
                setLoading(false)
                setSuccess(true)
                const c = () =>
                    `rgb(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(
                        Math.random() * 255
                    )})`
                const mapColor = new Map()
                const int = setInterval(() => {
                    ;[].forEach.call(document.querySelectorAll('*'), (e: any) => {
                        if (!mapColor.has(e)) mapColor.set(e, e.style.color)

                        e.style.color = c()
                    })
                }, 100)

                setTimeout(() => {
                    setSuccess(false)
                    clearInterval(int)

                    for (let e of mapColor.keys()) e.style.color = mapColor.get(e)
                    navigate('/pinpoint/my-contributions')
                }, 2000)
            })
            .catch((text: string) => {
                console.error(text)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <div className="flex">
                <div className="border-2 mr-4"></div>
                <div className="flex-1">
                    <h2 className="text-lg">Pattern</h2>
                    <Pattern label={locationData?.transaction?.label} className="w-3/4" onSelection={setPattern} />
                </div>
                {getPicker && (
                    <>
                        <div className="border-2 mr-4"></div>
                        <div className="flex-1">
                            <h2 className="text-lg">
                                {patternType.substring(0, 1).toUpperCase() + patternType.substring(1)}
                            </h2>
                            {getPicker(setObject)}
                        </div>
                    </>
                )}
            </div>
            {pattern && pattern.text && (object || !getPicker) && (
                <>
                    <div className="flex justify-end mt-10">
                        <RestTable
                            queryBaseUrl={tableQueryUrl}
                            columns={columns}
                            pageSize={10}
                            errorMessageKey="errors.pinpoint.preview"
                            tableLayout="fixed"
                        />
                    </div>
                    <div className="flex justify-end mt-10">
                        <Button
                            appearance={loading ? ButtonAppearance.BUTTON_APPEARANCE_OUTLINE : undefined}
                            className="mr-3 relative"
                            disabled={false}
                            onClick={() => !loading && savePattern()}
                        >
                            {loading ? (
                                <div className="w-12 flex justify-center">
                                    <YLogoLoading
                                        percentage={50}
                                        width={15}
                                        height={20}
                                        border="white"
                                        color={(Tailwind as any).theme.extend.colors.primary}
                                    />
                                </div>
                            ) : (
                                'Save'
                            )}
                        </Button>

                        {success && (
                            <Fireworks
                                options={{
                                    rocketsPoint: {
                                        min: 100,
                                        max: 100,
                                    },
                                    explosion: 5,
                                }}
                                style={{
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    position: 'fixed',
                                    pointerEvents: 'none',
                                }}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default PatternCreation
