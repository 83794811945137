function KpiOverallBudget({className}) {
    return (
        <svg viewBox="0 0 57 45" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M52.9779 -1.64e-05L45.9493 3.17192L48.0324 4.58973L40.6068 15.5857L31.001 6.54113L21.3484 19.7048L9.81063 16.9552L3.18719 27.0148L5.40569 28.4556L11.0059 19.9498L22.4476 22.6765L31.3658 10.5158L41.0587 19.6406L50.2184 6.08008L52.4481 7.59697L52.9779 -1.64e-05Z"
                fill="#FF7700"
            />
            <path
                opacity="0.45"
                d="M25.8592 44.4652C39.0698 44.4652 49.7791 43.3816 49.7791 42.0448C49.7791 40.7081 39.0698 39.6244 25.8592 39.6244C12.6486 39.6244 1.93928 40.7081 1.93928 42.0448C1.93928 43.3816 12.6486 44.4652 25.8592 44.4652Z"
                fill="#E6E6E6"
            />
            <path d="M43.2699 38.8934H33.5608V41.4326H43.2699V38.8934Z" fill="#E6E6E6" />
            <path opacity="0.43" d="M41.1478 36.4101H31.4387V38.9493H41.1478V36.4101Z" fill="#E6E6E6" />
            <path
                opacity="0.56"
                d="M26.7399 40.1469C27.7789 40.1469 28.6211 39.3104 28.6211 38.2785C28.6211 37.2466 27.7789 36.4101 26.7399 36.4101C25.701 36.4101 24.8587 37.2466 24.8587 38.2785C24.8587 39.3104 25.701 40.1469 26.7399 40.1469Z"
                fill="white"
            />
            <path d="M28.4427 31.439H21.9756V41.0956H28.4427V31.439Z" fill="#767676" />
            <path d="M19.2807 35.7312H12.8136V41.096H19.2807V35.7312Z" fill="#767676" />
            <path d="M10.658 38.4125H3.65199V41.0949H10.658V38.4125Z" fill="#767676" />
            <path d="M37.0656 25.0015H30.5985V41.0958H37.0656V25.0015Z" fill="#767676" />
            <path d="M45.6882 18.0266H39.2211V41.095H45.6882V18.0266Z" fill="#767676" />
            <rect x="3.11304" y="40.5593" width="43.1141" height="0.536484" fill="#767676" />
            <path
                d="M23.1038 9.87799C23.5884 9.87799 23.9813 9.48781 23.9813 9.0065C23.9813 8.52519 23.5884 8.13501 23.1038 8.13501C22.6192 8.13501 22.2264 8.52519 22.2264 9.0065C22.2264 9.48781 22.6192 9.87799 23.1038 9.87799Z"
                fill="white"
            />
            <path
                opacity="0.08"
                d="M20.8118 32.9412H23.6209L23.9072 41.4319L18.8043 41.4821L20.8118 32.9412Z"
                fill="black"
            />
            <path
                d="M26.7584 24.4382C29.2828 24.4382 31.3292 22.4056 31.3292 19.8984C31.3292 17.3912 29.2828 15.3586 26.7584 15.3586C24.234 15.3586 22.1876 17.3912 22.1876 19.8984C22.1876 22.4056 24.234 24.4382 26.7584 24.4382Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M26.7584 24.4382C29.2828 24.4382 31.3292 22.4056 31.3292 19.8984C31.3292 17.3912 29.2828 15.3586 26.7584 15.3586C24.234 15.3586 22.1876 17.3912 22.1876 19.8984C22.1876 22.4056 24.234 24.4382 26.7584 24.4382Z"
                fill="black"
            />
            <path
                d="M10.1864 36.646V38.6316C10.1864 39.5315 12.7127 40.2575 15.83 40.2524C18.9474 40.2474 21.4702 39.5131 21.4685 38.6132V36.626L10.1864 36.646Z"
                fill="#FFD200"
            />
            <path
                d="M26.7584 23.0163C29.2828 23.0163 31.3292 20.9838 31.3292 18.4765C31.3292 15.9693 29.2828 13.9368 26.7584 13.9368C24.234 13.9368 22.1876 15.9693 22.1876 18.4765C22.1876 20.9838 24.234 23.0163 26.7584 23.0163Z"
                fill="#FFD200"
            />
            <path
                d="M27.0009 16.2921C27.1188 16.2921 27.1559 16.3339 27.1559 16.4593V16.7102C27.3639 16.7317 27.5621 16.8094 27.7289 16.9347C27.8957 17.06 28.0249 17.2282 28.1024 17.4211C28.1647 17.5432 28.1394 17.6102 27.9997 17.672L27.747 17.7841C27.6291 17.841 27.5786 17.8142 27.5062 17.6921C27.4531 17.5648 27.3604 17.4578 27.2416 17.3868C27.1227 17.3157 26.9841 17.2844 26.846 17.2974C26.4081 17.2974 26.2077 17.4262 26.2077 17.7172C26.2194 17.805 26.2611 17.8861 26.3258 17.947C26.3904 18.0079 26.4742 18.0449 26.5631 18.0518C26.6854 18.0779 26.8091 18.0969 26.9336 18.1086C27.1673 18.1192 27.3983 18.1637 27.619 18.2408C27.7296 18.2776 27.8323 18.3343 27.9222 18.408C28.0134 18.5033 28.0846 18.6157 28.1315 18.7386C28.1783 18.8616 28.1999 18.9926 28.195 19.124C28.195 19.681 27.8194 20.0607 27.1559 20.1577V20.4237C27.1559 20.5408 27.1188 20.5826 27.0009 20.5826H26.718C26.6001 20.5826 26.558 20.5408 26.558 20.4237V20.1627C26.3274 20.1463 26.1067 20.0628 25.9234 19.9227C25.7401 19.7827 25.6024 19.5921 25.5273 19.3749C25.5163 19.3519 25.5108 19.3267 25.5111 19.3012C25.5114 19.2757 25.5175 19.2507 25.529 19.2279C25.5406 19.2052 25.5572 19.1853 25.5776 19.1699C25.598 19.1545 25.6217 19.1439 25.6469 19.139L25.8995 19.047C26.0275 19.0019 26.0848 19.032 26.1404 19.1591C26.1926 19.2967 26.289 19.4134 26.4148 19.4911C26.5405 19.5688 26.6885 19.6032 26.8359 19.589C27.3108 19.589 27.5466 19.4418 27.5466 19.139C27.5508 19.0694 27.5314 19.0004 27.4914 18.943C27.4514 18.8857 27.3931 18.8434 27.326 18.8229C27.1634 18.7656 26.9931 18.7329 26.8207 18.7259C26.5843 18.7113 26.3515 18.6611 26.1302 18.577C26.0218 18.539 25.9209 18.4824 25.8322 18.4097C25.7399 18.3191 25.6676 18.2105 25.6198 18.0907C25.5719 17.971 25.5496 17.8426 25.5543 17.7139C25.5543 17.1619 25.9046 16.7939 26.5648 16.7102V16.4593C26.5648 16.3422 26.6069 16.2921 26.7247 16.2921H27.0009Z"
                fill="white"
            />
            <path
                d="M10.1948 39.0398V41.0253C10.1948 41.9269 12.721 42.6512 15.8383 42.6462C18.9557 42.6412 21.4785 41.9069 21.4769 41.0069V39.0214L10.1948 39.0398Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M10.1948 39.0398V41.0253C10.1948 41.9269 12.721 42.6512 15.8383 42.6462C18.9557 42.6412 21.4785 41.9069 21.4769 41.0069V39.0214L10.1948 39.0398Z"
                fill="black"
            />
            <path
                d="M15.8983 40.5805C18.9854 40.5751 21.4867 39.8473 21.4851 38.9549C21.4836 38.0625 18.9797 37.3434 15.8926 37.3488C12.8055 37.3541 10.3042 38.0819 10.3058 38.9743C10.3074 39.8667 12.8112 40.5858 15.8983 40.5805Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M10.1864 36.646V38.6316C10.1864 39.5315 12.7127 40.2575 15.83 40.2524C18.9474 40.2474 21.4702 39.5131 21.4685 38.6132V36.626L10.1864 36.646Z"
                fill="black"
            />
            <path
                d="M10.5974 34.2713V36.2585C10.5974 37.1584 13.1236 37.8843 16.241 37.8793C19.3583 37.8743 21.8812 37.14 21.8795 36.2384V34.2529L10.5974 34.2713Z"
                fill="#FFD200"
            />
            <path
                d="M10.1864 31.8773V33.8661C10.1864 34.7661 12.7127 35.492 15.83 35.487C18.9474 35.482 21.4702 34.7477 21.4685 33.8477L21.4702 31.8589L10.1864 31.8773Z"
                fill="#FFD200"
            />
            <path
                d="M15.8923 38.2073C18.9794 38.2019 21.4807 37.4742 21.4791 36.5818C21.4775 35.6894 18.9737 34.9702 15.8866 34.9756C12.7995 34.981 10.2982 35.7087 10.2998 36.6011C10.3013 37.4935 12.8052 38.2126 15.8923 38.2073Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M10.5974 34.2713V36.2585C10.5974 37.1584 13.1236 37.8843 16.241 37.8793C19.3583 37.8743 21.8812 37.14 21.8795 36.2384V34.2529L10.5974 34.2713Z"
                fill="black"
            />
            <path
                d="M16.2941 35.8535C19.3811 35.8482 21.8825 35.1204 21.8809 34.228C21.8793 33.3356 19.3755 32.6165 16.2884 32.6218C13.2013 32.6272 10.7 33.355 10.7016 34.2474C10.7031 35.1398 13.207 35.8589 16.2941 35.8535Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M10.1864 31.8773V33.8661C10.1864 34.7661 12.7127 35.492 15.83 35.487C18.9474 35.482 21.4702 34.7477 21.4685 33.8477L21.4702 31.8589L10.1864 31.8773Z"
                fill="black"
            />
            <path
                opacity="0.08"
                d="M10.4054 43.0196C12.7186 43.0196 14.5938 41.1571 14.5938 38.8596C14.5938 36.562 12.7186 34.6995 10.4054 34.6995C8.09214 34.6995 6.2169 36.562 6.2169 38.8596C6.2169 41.1571 8.09214 43.0196 10.4054 43.0196Z"
                fill="black"
            />
            <path
                opacity="0.08"
                d="M13.1909 34.7025C13.1909 34.7025 17.5697 36.0406 16.8724 42.6261C16.8724 42.6261 14.0094 42.7215 12.6621 42.3752C11.4427 42.0624 13.1909 34.7025 13.1909 34.7025Z"
                fill="black"
            />
            <path
                d="M15.8317 33.4833C18.9485 33.4779 21.474 32.7501 21.4724 31.8577C21.4708 30.9653 18.9428 30.2462 15.826 30.2516C12.7091 30.257 10.1837 30.9849 10.1853 31.8773C10.1868 32.7697 12.7148 33.4887 15.8317 33.4833Z"
                fill="#FFD200"
            />
            <path
                d="M10.4054 43.0196C12.7186 43.0196 14.5938 41.1571 14.5938 38.8596C14.5938 36.562 12.7186 34.6995 10.4054 34.6995C8.09214 34.6995 6.2169 36.562 6.2169 38.8596C6.2169 41.1571 8.09214 43.0196 10.4054 43.0196Z"
                fill="#FFD200"
            />
            <path
                opacity="0.08"
                d="M10.4054 43.0196C12.7186 43.0196 14.5938 41.1571 14.5938 38.8596C14.5938 36.562 12.7186 34.6995 10.4054 34.6995C8.09214 34.6995 6.2169 36.562 6.2169 38.8596C6.2169 41.1571 8.09214 43.0196 10.4054 43.0196Z"
                fill="black"
            />
            <g opacity="0.08">
                <path
                    opacity="0.08"
                    d="M10.2016 41.766C12.5148 41.766 14.3901 39.9035 14.3901 37.606C14.3901 35.3084 12.5148 33.4459 10.2016 33.4459C7.88838 33.4459 6.01314 35.3084 6.01314 37.606C6.01314 39.9035 7.88838 41.766 10.2016 41.766Z"
                    fill="black"
                />
            </g>
            <path
                d="M10.2016 41.766C12.5148 41.766 14.3901 39.9035 14.3901 37.606C14.3901 35.3084 12.5148 33.4459 10.2016 33.4459C7.88838 33.4459 6.01314 35.3084 6.01314 37.606C6.01314 39.9035 7.88838 41.766 10.2016 41.766Z"
                fill="#FFD200"
            />
            <path
                d="M10.3751 35.423C10.493 35.423 10.5284 35.4631 10.5284 35.5802V35.8311C10.7365 35.8529 10.9348 35.9306 11.1019 36.0558C11.2689 36.1811 11.3985 36.3492 11.4766 36.542C11.5389 36.6658 11.5119 36.731 11.3738 36.7929L11.1212 36.905C11.0033 36.9619 10.9528 36.9368 10.8787 36.813C10.8261 36.6858 10.7336 36.5789 10.615 36.5081C10.4964 36.4373 10.3579 36.4064 10.2202 36.4199C9.7823 36.4199 9.58189 36.547 9.58189 36.8398C9.59358 36.9275 9.63525 37.0086 9.69993 37.0695C9.76462 37.1304 9.84839 37.1674 9.93724 37.1743C10.0592 37.2021 10.183 37.2211 10.3078 37.2312C10.5416 37.2424 10.7725 37.2875 10.9932 37.365C11.1037 37.4018 11.2065 37.4585 11.2964 37.5323C11.3877 37.6278 11.4588 37.7405 11.5057 37.8637C11.5526 37.987 11.5741 38.1182 11.5692 38.2499C11.5692 38.8069 11.1936 39.1849 10.5284 39.2819V39.5546C10.5284 39.6734 10.493 39.7135 10.3751 39.7135H10.0922C9.97261 39.7135 9.92377 39.6734 9.92377 39.5546V39.2936C9.69272 39.2785 9.4714 39.1955 9.2879 39.0553C9.1044 38.915 8.96698 38.7238 8.89307 38.5058C8.88239 38.483 8.87703 38.4581 8.87739 38.4329C8.87775 38.4077 8.88382 38.383 8.89515 38.3605C8.90648 38.338 8.92278 38.3183 8.94284 38.3029C8.96289 38.2875 8.98617 38.2768 9.01096 38.2716L9.26359 38.1796C9.39326 38.1328 9.44884 38.1646 9.5061 38.2917C9.55835 38.4293 9.6548 38.546 9.78053 38.6237C9.90626 38.7014 10.0543 38.7358 10.2017 38.7216C10.6749 38.7216 10.9124 38.5727 10.9124 38.2716C10.9163 38.2016 10.8965 38.1323 10.8563 38.0747C10.816 38.0171 10.7575 37.9746 10.6901 37.9538C10.5273 37.8971 10.3571 37.8645 10.1848 37.8568C9.94824 37.8433 9.71525 37.793 9.49431 37.7079C9.38522 37.6713 9.28407 37.6146 9.19622 37.5406C9.10372 37.4505 9.03109 37.3422 8.98297 37.2227C8.93484 37.1032 8.91226 36.9751 8.91665 36.8465C8.91665 36.2928 9.26695 35.9248 9.92714 35.8428V35.5919C9.92714 35.4748 9.96756 35.4347 10.0956 35.4347L10.3751 35.423Z"
                fill="white"
            />
        </svg>
    )
}

export default KpiOverallBudget
