import YLogoLoading from '../../../components/Icons/YLogoLoading'
import Heading from '../../../components/Heading'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withProgram } from '../../../contexts/ProgramContext'
import { useEffect, useState } from 'react'

function Confirm({ currentProgram }) {
    const { t } = useTranslation('common')
    const navigate = useNavigate()
    const [onLoad, setOnLoad] = useState(true)
    const [percentage, setPercentage] = useState(0)

    const runPercentAnimation = () => {
        setTimeout(() => onLoad && setPercentage(0), 1000)
        setTimeout(() => onLoad && setPercentage(25), 1000)
        setTimeout(() => onLoad && setPercentage(50), 2000)
        setTimeout(() => onLoad && setPercentage(75), 3000)
        setTimeout(() => onLoad && setPercentage(99), 4000)
        setTimeout(() => {
            onLoad && setPercentage(100)
            setOnLoad(false)
        }, 5000)
    }

    useEffect(() => {
        runPercentAnimation()
        if (currentProgram && !onLoad) {
            navigate(`/programs/${currentProgram?.id}/stores`)
        }
        return () => {
            setOnLoad(false)
        }
    }, [currentProgram, onLoad])

    return (
        <div className="flex flex-col justify-center items-center h-full gap-16">
            <div>
                <YLogoLoading width="75" border="#FF7700" color="transparent" percentage={percentage} />
            </div>
            <Heading>{t('stores.creation.congratulations_your_stores_repository_has_been_created')}</Heading>
        </div>
    )
}

export default withProgram(Confirm)
