import { useState } from 'react'
import { withProgram } from '../../contexts/ProgramContext'
import { useTranslation } from 'react-i18next'
import Tab from '../../components/Tab'
import Tabs from '../../components/Tabs'
import GlobalAudience from './GlobalAudience/GlobalAudience'

function Audience() {
    const { t } = useTranslation('common')
    const [currentTab, setCurrentTab] = useState('globalAudience')

    return (
        <div className="p-1 pl-4 h-full">
            <div className="flex flex-col">
                <div className="md:flex justify-between md:mb-16">
                    <h3 className="text-3xl text-black text-left md:spl-8">{t('audience.title')}</h3>
                </div>
                <div className="md:flex justify-between md:mb-8">
                    <Tabs current={currentTab} setCurrent={setCurrentTab}>
                        {tabs.map((tab) => (
                            <Tab name={tab.key} key={tab.key} data-cy={tab.key}>
                                {t(tab.label)}
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </div>
            {currentTab === 'globalAudience' && <GlobalAudience />}
            {/*{currentTab === 'segments' && <Segments />}*/}
        </div>
    )
}

const tabs = [
    {
        key: 'globalAudience',
        label: 'audience.tab.global-audience',
    },
    /*{
        key: 'segments',
        label: 'audience.tab.segments',
    },*/
]

export default withProgram(Audience)
