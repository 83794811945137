import React, { useCallback, useMemo, useState } from 'react'
import IPhone from '../../../components/IPhone'
import ColorPicker from '../../../components/ColorPicker'
import Input from '../../../components/Input'
import FileInput from '../../../components/FileInput'
import YLogo from '../../../components/Icons/YLogo'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { withSettings } from '../../../contexts/SettingsContext'
import Header from './Header'
import Heading from '../../../components/Heading'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ErrorText from '../../../components/ErrorText'
import DropdownInput from '../../../components/DropdownInput'
import countryList from 'country-list'
import { checkSpecialCharacters, checkCharactersForDomainName } from '../../../helpers'
import InfoTooltip from '../../../components/InfoTooltip'

function LookAndFeel({ settings }) {
    const navigate = useNavigate()
    const location = useLocation()
    let programData = location.state || {}
    const { t } = useTranslation('common')
    const [logo, setLogo] = useState(null)
    const [logoSrc, setLogoSrc] = useState(programData.logo || null)
    const [host, setHost] = useState(programData.host || '')
    const [organizationName, setOrganizationName] = useState(programData.organizationName || '')
    const [programName, setProgramName] = useState(programData.programName || '')
    const [componentColor, setComponentColor] = useState(programData.componentColor || '#F8F8F8')
    const [primaryColor, setPrimaryColor] = useState(programData.primaryColor || '#FF7700')
    const [backgroundColor, setBackgroundColor] = useState(programData.backgroundColor || '#FFFFFF')
    const [fontColor, setFontColor] = useState(programData.fontColor || '#000000')
    const [fontColorOverPrimary, setFontColorOverPrimary] = useState(programData.fontColorOverPrimary || '#000000')
    const [logoErrorMessage, setLogoErrorMessage] = useState('')
    const [country, setCountry] = useState(programData.country)
    const [checkInputValidation, setCheckInputValidation] = useState(false)

    const countryOptions = useMemo(
        () => countryList.getData().map(({ code, name }) => ({ value: code, label: name })),
        []
    )

    const errors = useMemo(() => {
        const errors = []

        if (!organizationName) {
            errors.push({
                key: 'organizationNameError',
                error: new Error('Mandatory'),
            })
        } else {
            try {
                checkSpecialCharacters(organizationName, false, t('invalid_character'))
            } catch (error) {
                errors.push({
                    key: 'organizationNameError',
                    error,
                })
            }
        }

        if (!programName) {
            errors.push({
                key: 'programNameError',
                error: new Error('Mandatory'),
            })
        } else {
            try {
                checkSpecialCharacters(programName, false, t('invalid_character'))
            } catch (error) {
                errors.push({
                    key: 'programNameError',
                    error,
                })
            }
        }

        if (!programData.hasExternalFront && !host) {
            errors.push({
                key: 'hostError',
                error: new Error('Mandatory'),
            })
        } else if (!programData.hasExternalFront && host) {
            try {
                checkCharactersForDomainName(host, t('invalid_character'))
            } catch (error) {
                errors.push({
                    key: 'hostError',
                    error,
                })
            }
        }

        if (!country?.value) {
            errors.push({
                key: 'countryError',
                error: new Error('Mandatory'),
            })
        }

        return errors
    }, [organizationName, programName, country, host, programData.hasExternalFront])

    const changeCountry = useCallback((country) => {
        setCountry(country)
    }, [])

    const validate = (
        logo,
        host,
        organizationName,
        programName,
        componentColor,
        primaryColor,
        backgroundColor,
        fontColor,
        fontColorOverPrimary,
        country
    ) => {
        let computedHost = undefined
        if (!programData.hasExternalFront) {
            computedHost = settings?.b2cFrontEnd?.domainSuffix ? `${host}.${settings?.b2cFrontEnd?.domainSuffix}` : host
        }

        programData = Object.assign(programData, {
            organizationName,
            programName,
            host: computedHost,
            componentColor,
            primaryColor,
            backgroundColor,
            fontColor,
            fontColorOverPrimary,
            country,
        })
        setCheckInputValidation(true)
        if (!errors.length > 0) {
            if (logo !== null) {
                const reader = new FileReader()
                reader.onload = (evt) => {
                    programData = Object.assign(programData, {
                        logo: evt.target.result,
                    })
                    navigate('/programs/create/loading', { state: programData })
                }
                reader.readAsDataURL(logo)
            } else {
                navigate('/programs/create/loading', { state: programData })
            }
        }
    }

    const onUploadFile = (type, file) => {
        setLogo(file)
        setLogoSrc(URL.createObjectURL(file))
        // 10 MB
        if (file.size > 10 * 1024 * 1024) {
            setLogoErrorMessage('exceedSizeLimit')
        } else if (file.type !== 'image/svg+xml') {
            setLogoErrorMessage('invalidLogoFormat')
        } else {
            setLogoErrorMessage('')
        }
    }

    const countryFilterOption = useCallback((candidate, input) => {
        return !input || candidate.label.toLowerCase().startsWith(input.toLowerCase())
    }, [])

    return (
        <>
            <Header
                onBack={() => navigate('/programs/create/reward', { state: programData })}
                onButtonClick={() =>
                    validate(
                        logo,
                        host,
                        organizationName,
                        programName,
                        componentColor,
                        primaryColor,
                        backgroundColor,
                        fontColor,
                        fontColorOverPrimary,
                        country
                    )
                }
                buttonAppearance="primary"
                disabled={!!logoErrorMessage}
                buttonLabel={t('create_program.look_and_feel.next_btn')}
            >
                <p className="text-xs">{t('create_program.step')} 5/5</p>
                <Heading>{t('create_program.look_and_feel.header')}</Heading>
            </Header>
            <div className="flex flex-col lg:flex-row gap-y-8">
                <div className="flex flex-col gap-2 xl:flex-1 lg:w-1/2">
                    <div className="flex flex-col gap-2">
                        <div>
                            {t('create_program.look_and_feel.form.organization_name.label')}{' '}
                            <InfoTooltip text={t('create_program.look_and_feel.form.organization_name.info')} />
                        </div>
                        <Input
                            type="text"
                            placeholder={t('create_program.look_and_feel.form.organization_name.placeholder')}
                            value={organizationName}
                            onChange={setOrganizationName}
                            error={checkInputValidation && errors.find((n) => n.key === 'organizationNameError')?.error}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div>{t('create_program.look_and_feel.form.program_name.label')}</div>
                        <Input
                            type="text"
                            placeholder={t('create_program.look_and_feel.form.program_name.placeholder')}
                            value={programName}
                            onChange={setProgramName}
                            error={checkInputValidation && errors.find((n) => n.key === 'programNameError')?.error}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div>{t('create_program.look_and_feel.form.country.label')}</div>
                        <DropdownInput
                            placeholder={t('create_program.look_and_feel.form.country.placeholder')}
                            onChange={changeCountry}
                            value={country}
                            options={countryOptions}
                            isClearable={false}
                            indicatorSeparator={null}
                            asSearchInput
                            filterOption={countryFilterOption}
                            size="big"
                            error={checkInputValidation && errors.find((n) => n.key === 'countryError')?.error}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between">
                            <div>{t('create_program.look_and_feel.form.logo.label')}</div>
                            <div className="text-gray-400 text-sm">
                                {t('create_program.look_and_feel.form.logo.file_size')}
                            </div>
                        </div>
                        <FileInput
                            file={logo}
                            accept=".svg"
                            onChange={(type, file) => onUploadFile(type, file)}
                            placeholder={t('create_program.look_and_feel.form.logo.placeholder')}
                        />
                    </div>
                    {!programData.hasExternalFront && (
                        <div className="flex flex-col gap-2">
                            <div>
                                {t('create_program.look_and_feel.form.domain_name.label')}
                                <InfoTooltip text={t('create_program.look_and_feel.form.domain_name.info')} />
                            </div>
                            <div className="flex">
                                <Input
                                    type="text"
                                    placeholder={t('create_program.look_and_feel.form.domain_name.placeholder')}
                                    value={host}
                                    onChange={setHost}
                                    error={checkInputValidation && errors.find((n) => n.key === 'hostError')?.error}
                                />
                                <div className="mt-5 ml-2">.{settings?.b2cFrontEnd?.domainSuffix}</div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 xl:gap-8">
                        <div className="flex flex-col gap-1 flex-1">
                            {t('create_program.look_and_feel.form.primary_color.label')}
                            <ColorPicker value={primaryColor} onChange={setPrimaryColor} />
                        </div>
                        <div className="flex flex-col gap-1 flex-1">
                            {t('create_program.look_and_feel.form.background_color.label')}
                            <ColorPicker value={backgroundColor} onChange={setBackgroundColor} />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 xl:gap-8">
                        <div className="flex flex-col gap-1 flex-1">
                            {t('create_program.look_and_feel.form.font_color.label')}
                            <ColorPicker value={fontColor} onChange={setFontColor} />
                        </div>
                        <div className="flex flex-col gap-1 flex-1">
                            {t('create_program.look_and_feel.form.component_color.label')}
                            <ColorPicker value={componentColor} onChange={setComponentColor} />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 xl:gap-8">
                        <div className="flex flex-col gap-1 flex-1">
                            {t('create_program.look_and_feel.form.font_color_over_primary.label')}
                            <ColorPicker value={fontColorOverPrimary} onChange={setFontColorOverPrimary} />
                        </div>
                        <div className="flex-1"></div>
                    </div>
                </div>
                <div className="flex-1 flex flex-col items-center gap-8">
                    <div className="text-center font-bold">{t('create_program.look_and_feel.preview.label')}</div>
                    <IPhone host={`${host}${host ? '.' : ''}${settings?.b2cFrontEnd?.domainSuffix}`}>
                        <div className="flex flex-col gap-2" style={{ backgroundColor: backgroundColor }}>
                            <div className="text-xxxs py-2 flex gap-0.5 ml-4">
                                <div className="flex gap-0.5 items-center">
                                    <div
                                        className="text-center rounded-full text-white w-2 h-2"
                                        style={{ backgroundColor: primaryColor }}
                                    >
                                        1
                                    </div>
                                    <div className="rounded-full font-bold" style={{ color: primaryColor }}>
                                        {t('create_program.look_and_feel.form.profile_creation')}
                                    </div>
                                </div>
                                <ChevronRightIcon className="h-3" style={{ color: primaryColor }} />
                                <div className="flex gap-0.5 items-center">
                                    <div
                                        className="text-center rounded-full text-white w-2 h-2"
                                        style={{ backgroundColor: primaryColor }}
                                    >
                                        2
                                    </div>
                                    <div className="rounded-full font-bold" style={{ color: primaryColor }}>
                                        {t('create_program.look_and_feel.form.bank_authentication')}
                                    </div>
                                </div>
                                <ChevronRightIcon className="h-3 opacity-20" style={{ color: primaryColor }} />
                                <div className="flex gap-0.5 items-center opacity-20">
                                    <div
                                        className="text-center rounded-full text-white w-2 h-2"
                                        style={{ backgroundColor: primaryColor }}
                                    >
                                        3
                                    </div>
                                    <div className="rounded-full" style={{ color: primaryColor }}>
                                        {t('create_program.look_and_feel.form.customer_area')}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex flex-col items-center rounded-3xl py-4"
                                style={{ backgroundColor: componentColor }}
                            >
                                <div className="px-8 text-xs font-bold text-center" style={{ color: fontColor }}>
                                    {t('create_program.look_and_feel.form.link_your_card_to_your_spaycial_program')}
                                </div>
                                <div className="flex my-6 gap-1 justify-center items-center w-40">
                                    <img src="/dodo.png" className="rounded-xl h-10 w-10" alt="" />
                                    <div className="h-0 flex-1 border-b border-dashed border-gray-500" />
                                    <div className="border border-black rounded-lg h-7 w-7 flex justify-center items-center">
                                        <YLogo />
                                    </div>
                                    <div className="h-0 flex-1 border-b border-dashed border-gray-500" />
                                    <div
                                        className={[
                                            'bg-guest-primary rounded-xl h-10 w-10 flex justify-center items-center overflow-hidden',
                                            logo === null ? 'bg-guest-primary' : 'bg-light',
                                        ].join(' ')}
                                    >
                                        {logo === null && <YLogo width="14" color="#FFFFFF" />}
                                        {logoSrc !== null && <img src={logoSrc} alt="" />}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="text-center text-xxxs" style={{ color: fontColor }}>
                                        <span className="underline">Spaycial</span>,{' '}
                                        {t('create_program.look_and_feel.would_like_to_access_your_bank_accounts')}:
                                    </div>
                                    <div className="flex items-center gap-2 py-3 mx-4 border-b border-gray-400">
                                        <img src="/cards.png" alt="" />
                                        <div className="text-xxxs" style={{ color: fontColor }}>
                                            {t(
                                                'create_program.look_and_feel.to_identify_purchases_made_at_myol_fidelity_so_that_you_can_benefit_from_advantages_of_the_programme'
                                            )}
                                        </div>
                                        <div className="">
                                            <ChevronDownIcon className="h-2" />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2 py-3 mx-4 border-b border-gray-400">
                                        <img src="/ticket.png" alt="" />
                                        <div className="text-xxxs" style={{ color: fontColor }}>
                                            {t(
                                                'create_program.look_and_feel.to_identify_purchases_made_at_myol_fidelity_in order_to_store_your_receipts'
                                            )}
                                        </div>
                                        <div className="">
                                            <ChevronDownIcon className="h-2" />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2 py-3 mx-4">
                                        <div className="border border-black rounded h-3 w-3"></div>
                                        <div className="text-xxxs" style={{ color: fontColor }}>
                                            {t(
                                                'create_program.look_and_feel.use_off_center_transactions_for_statistical_purposes_only_optionnal'
                                            )}
                                        </div>
                                        <div className="">
                                            <ChevronDownIcon className="h-2" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full px-2">
                                    <div
                                        className="rounded-lg text-center py-2 text-white font-bold text-xxs"
                                        style={{ backgroundColor: primaryColor, color: fontColorOverPrimary }}
                                    >
                                        {t('create_program.look_and_feel.allow')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IPhone>
                </div>
            </div>
            <div className="relative text-xs py-4">
                {logoErrorMessage && <ErrorText> {t(`create_program.look_and_feel.${logoErrorMessage}`)}</ErrorText>}
            </div>
        </>
    )
}

export default withSettings(LookAndFeel)
