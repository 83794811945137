function KpiAverageBasket({className}) {
    return (
        <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                opacity="0.3"
                d="M35.4408 61.862C52.9657 61.862 67.1724 60.3151 67.1724 58.4069C67.1724 56.4987 52.9657 54.9518 35.4408 54.9518C17.916 54.9518 3.70929 56.4987 3.70929 58.4069C3.70929 60.3151 17.916 61.862 35.4408 61.862Z"
                fill="#E6E6E6"
            />
            <path d="M46.2698 35.5798H31.7335V43.9759H46.2698V35.5798Z" fill="#C1C1C1" />
            <path d="M35.8391 28.4658H18.7618V43.9757H35.8391V28.4658Z" fill="#FF7700" />
            <path
                d="M9.64874 24.3847H13.412C13.9829 24.3843 14.5349 24.5909 14.9679 24.967C15.4008 25.3431 15.6858 25.8637 15.7712 26.4342L18.419 43.9755H45.9414C46.6152 43.9752 47.2693 43.7459 47.7987 43.3246C48.328 42.9032 48.7018 42.3143 48.8601 41.6523L52.9726 24.5216"
                stroke="#24285B"
                stroke-width="2.03"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.22638 23.6895C7.22638 23.6895 9.98468 22.065 10.676 23.6895C11.3674 25.314 7.93555 26.074 7.93555 26.074L7.22638 23.6895Z"
                fill="#F4A28C"
            />
            <path d="M35.839 20.9995H26.6268V28.4627H35.839V20.9995Z" fill="#E6E6E6" />
            <path opacity="0.43" d="M46.2709 25.0845H35.5086V35.5805H46.2709V25.0845Z" fill="#E6E6E6" />
            <path
                d="M17.92 40.6839L19.3455 49.6166C19.4893 50.5141 19.9444 51.3305 20.6294 51.9198C21.3143 52.5091 22.1843 52.8329 23.0838 52.8332H47.5522"
                stroke="#24285B"
                stroke-width="2.03"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M24.3424 58.0992C25.4643 58.0992 26.3737 57.18 26.3737 56.0461C26.3737 54.9122 25.4643 53.993 24.3424 53.993C23.2206 53.993 22.3111 54.9122 22.3111 56.0461C22.3111 57.18 23.2206 58.0992 24.3424 58.0992Z"
                fill="#FF7700"
            />
            <path
                d="M44.2387 58.0992C45.3605 58.0992 46.27 57.18 46.27 56.0461C46.27 54.9122 45.3605 53.993 44.2387 53.993C43.1168 53.993 42.2074 54.9122 42.2074 56.0461C42.2074 57.18 43.1168 58.0992 44.2387 58.0992Z"
                fill="#FF7700"
            />
            <path opacity="0.61" d="M28.7796 28.4658H25.4119V33.5013H28.7796V28.4658Z" fill="white" />
        </svg>
    )
}

export default KpiAverageBasket
