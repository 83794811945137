import RestTable from '../../../components/Table'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'
import Badge from '../../../components/Badge'

const url = '/backoffice/patterns'

const MyContributions = () => {
    const getBadgeAppearance = (type: string) => {
        switch (type) {
            case 'retailer':
            case 'store':
            case 'city':
            case 'service':
            case 'is_online':
                return 'success'
            default:
                return 'error'
        }
    }

    const handlePatternType = (type: string) => {
        const badgeAppearance = getBadgeAppearance(type)
        return (
            <Badge appearance={badgeAppearance} className="mr-2 inline-flex" key={type}>
                {type}
            </Badge>
        )
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Creation date',
            render: (p) => moment(p.createdAt).format('MM/DD/YYYY'),
            defaultSortOrder: 'descend',
            key: 'created_at',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Pattern',
            render: (p) => (
                <div className="text-xs monospaced border-1 rounded-lg font-mono p-1 text-center border-primary/75 bg-primary/10">
                    {p.pattern}
                </div>
            ),
            key: 'pattern',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        { title: 'Type', render: (p) => handlePatternType(p.patternType) },
        {
            title: 'Name',
            render: (p) => p.storeName || p.retailerName || p.cityName,
        },
    ]

    return (
        <div className="p-1 pl-4 h-full flex flex-col">
            <h3 className="text-3xl text-black text-left md:spl-8 mb-10">My Contributions</h3>

            <div className="flex flex-col h-full">
                <RestTable queryBaseUrl={url} columns={columns} errorMessageKey="errors.pinpoint.contributions" />
            </div>
        </div>
    )
}

export default MyContributions
