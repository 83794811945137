import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import General from './General'
import Front from './Front'
import Legal from './Legal'
import Technical from './Technical'
import Tab from '../../../components/Tab'
import Tabs from '../../../components/Tabs'
import Button, { ButtonAppearance } from '../../../components/Button'
import NotFound from '../../../pages/Errors/NotFound'
import { ShoppingHub } from '../../../data/ShoppingHubFetcher'
import { useTranslation } from 'react-i18next'
import { useErrorAlert } from '../../../hooks/useErrorAlert'
import { withProgram } from '../../../contexts/ProgramContext'
import { ACCESS_LEVEL, FUNCTIONALITIES, getUserAccessLevel } from '../../../helpers'
import { withAuth } from '../../../contexts/AuthContext'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import Modal from '../../../components/Modal'
import Label from '../../../components/Label'
import Datepicker from '../../../components/Datepicker'
import moment from 'moment-timezone'
import { canAccess } from '../../../components/RequireAuth'

function Configuration({ currentProgram, setCurrentProgram, user }) {
    const { t } = useTranslation('common')
    const [hasError, setHasError] = useState(false)
    const [showLaunchModal, setShowLaunchModal] = useState(false)
    const { handleError } = useContext(ErrorManagementContext)
    const [programLaunchDate, setProgramLaunchDate] = useState()

    const [editing, setEditing] = useState(false)

    const general = useRef(null)
    const technical = useRef(null)
    const front = useRef(null)
    const legal = useRef(null)

    const navigate = useNavigate()

    const canEdit = [ACCESS_LEVEL.WRITE, ACCESS_LEVEL.DELETE].includes(getUserAccessLevel(FUNCTIONALITIES.PROGRAM))
    const location = useLocation()

    useEffect(
        () => setProgramLaunchDate(Date.parse(currentProgram?.programLaunchDate)),
        [currentProgram?.programLaunchDate]
    )

    const { searchParams, current } = useMemo(() => {
        const searchParams = new URLSearchParams(location.search)
        const current = searchParams.get('tab') || (canAccess(user, undefined, undefined, true) && 'general') || 'front'

        return {
            searchParams,
            current,
        }
    }, [location.search])

    const setTab = useCallback(
        (tabName) => {
            if (tabName === 'general') {
                searchParams.delete('tab')
            } else {
                searchParams.set('tab', tabName)
            }
            navigate(`${location.pathname}?${searchParams.toString()}`)
        },
        [location.pathname, searchParams]
    )

    const { setError, content: alertContent } = useErrorAlert()

    const validate = useCallback(async () => {
        if (!currentProgram) {
            return
        }

        let data = undefined

        if (current === 'general') {
            data = general.current.validate()
        } else if (current === 'technical') {
            data = technical.current.validate()
        } else if (current === 'front') {
            data = await front.current.validate().catch(setError)
        } else if (current === 'legal') {
            data = await legal.current.validate().catch(setError)
        }

        if (data) {
            ShoppingHub.patch(currentProgram.id, data)
                .then((newProgram) => {
                    setCurrentProgram(newProgram)
                    setEditing(false)
                })
                .catch(handleError('errors.program.config.update'))
        }
    }, [current, currentProgram, setCurrentProgram, setError])

    const launchButton =
        canAccess(user, undefined, undefined, true) && currentProgram && !currentProgram.programLaunchDate ? (
            <Button
                onClick={() => setShowLaunchModal(true)}
                disabled={currentProgram.missingMandatoryFields?.length > 0}
                className="mr-4"
            >
                {t('program.config.button.launch')}
            </Button>
        ) : null

    const button = editing ? (
        <Button onClick={validate} disabled={hasError}>
            {t('program.config.button.save')}
        </Button>
    ) : (
        <Button appearance="outline" onClick={() => setEditing(true)}>
            {t('program.config.button.edit')}
        </Button>
    )
    if (!tabs.map((tab) => tab.value).includes(current)) {
        return <NotFound />
    }

    const updateLaunchDate = () => {
        setShowLaunchModal(false)
        ShoppingHub.patch(currentProgram.id, {
            id: currentProgram.id,
            programLaunchDate,
        })
            .then((newProgram) => {
                setCurrentProgram(newProgram)
                setEditing(false)
            })
            .catch(handleError('errors.program.config.update'))
    }

    return (
        <div className="p-1 pl-4">
            <div className="flex justify-between">
                <div className="text-3xl">{t('program.config.title')}</div>
                <div>
                    {launchButton}
                    {canEdit && button}
                </div>
            </div>

            <Tabs className="my-4 md:my-10" current={current} setCurrent={setTab}>
                {tabs.map((tab) => {
                    // Hide technical
                    if (tab.value === 'technical' && !canAccess(user, undefined, undefined, true)) return null

                    return (
                        <Tab name={tab.value} key={tab.key}>
                            {tab.label}
                        </Tab>
                    )
                })}
            </Tabs>

            {alertContent}

            {currentProgram ? (
                <>
                    {current === 'general' && (
                        <General ref={general} editing={editing} program={currentProgram} onChange={setHasError} />
                    )}
                    {current === 'technical' && (
                        <Technical ref={technical} editing={editing} program={currentProgram} />
                    )}
                    {current === 'front' && (
                        <Front ref={front} editing={editing} program={currentProgram} onChange={setHasError} />
                    )}
                    {current === 'legal' && (
                        <Legal ref={legal} editing={editing} program={currentProgram} onChange={setHasError} />
                    )}
                </>
            ) : null}

            <Modal show={showLaunchModal} onClose={() => setShowLaunchModal(false)}>
                <div
                    data-testid="launch_program_modal"
                    className="flex flex-col gap-8 items-center max-w-lg p-4 text-center mx-12"
                >
                    <div>
                        <Label>{t('program.config.launchDate.label')}</Label>
                        <Datepicker
                            className="mt-3.5"
                            value={programLaunchDate}
                            placeholder={'JJ/MM/AAAA'}
                            minDate={moment().add(1, 'days').toDate()}
                            onChange={setProgramLaunchDate}
                        />
                    </div>

                    <Button
                        appearance={ButtonAppearance.BUTTON_APPEARANCE_PRIMARY}
                        className="flex-1 py-4"
                        onClick={updateLaunchDate}
                    >
                        {t('program.config.button.launch')}
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

const tabs = [
    {
        key: 'config.general',
        label: 'General',
        value: 'general',
    },
    {
        key: 'config.technical',
        label: 'Technical',
        value: 'technical',
    },
    {
        key: 'config.front',
        label: 'Front',
        value: 'front',
    },
    {
        key: 'config.legal',
        label: 'Legal',
        value: 'legal',
    },
]

export default withProgram(withAuth(Configuration))
