type TextareaProps = {
    className?: string
    onChange?: (value: string) => void
    value?: number | string
    placeholder?: string
    error?: boolean
    maxLength?: number
    'data-cy'?: string
    disabled?: boolean
}

function Textarea({ className, onChange, value, disabled, placeholder, error, maxLength, ...rest }: TextareaProps) {
    return (
        <div className={'relative'}>
            <textarea
                value={value}
                data-cy={rest['data-cy']}
                disabled={disabled}
                onChange={(e) => onChange?.(e.target.value)}
                className={[
                    'border border-1 px-6 py-5 text-base w-full focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-black',
                    error ? 'border-error focus:ring-error' : 'border-black focus:ring-transparent',
                    disabled ? 'border-color-none text-none' : '',
                    className,
                ].join(' ')}
                placeholder={placeholder}
                maxLength={maxLength}
            />
        </div>
    )
}

export default Textarea
