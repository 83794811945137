import React from 'react'

function TFLogo() {
    return (
        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.34328 8.71654V17.6575C9.34328 19.8045 9.82616 20.5096 10.9541 20.5096C11.5663 20.5096 12.0815 20.3173 12.629 19.9328L13.1125 20.4775C11.5017 23.1694 9.76215 24.3231 7.2489 24.3231C4.12412 24.3231 1.86878 22.4323 1.86878 18.042V8.71654H0V7.62699C0 7.62699 2.53314 6.92944 4.63621 5.64619C6.46522 4.53035 7.84303 2.84936 8.53784 2.33937L9.34328 2.56369V7.43473H14.9844C15.307 3.36484 18.2062 0 23.0711 0C26.1959 0 28 1.92277 28 3.58917C28 4.96718 26.9367 5.67217 25.1648 5.7683L24.5527 5.57605C24.424 3.55713 23.8765 0.961386 23.0711 0.961386C22.5229 0.961386 22.4266 1.98686 22.4266 4.166V7.43473H25.2618V8.71654H22.4266V25.719C22.4266 30.1093 20.1713 32 17.0465 32C14.5332 32 12.7937 30.8464 11.1828 28.1545L11.6664 27.6097C12.2139 27.9942 12.7291 28.1865 13.3412 28.1865C14.4692 28.1865 14.9521 27.4815 14.9521 25.3344V8.71654H9.34328Z"
                fill="#ECBFD0"
            />
        </svg>
    )
}

export default TFLogo
