import Label from '../../../components/Label'
import React from 'react'
import MoneyPile from '../../../components/Icons/MoneyPile'
import Cart from '../../../components/Icons/Cart'
import Calendar from '../../../components/Icons/Calendar'
import OldTable from '../../../components/OldTable'
import Pagination from '../../../components/Pagination'

function Analytics() {
    return (
        <div className="flex flex-col xl:flex-row gap-8">
            <div className="flex-1">
                <Label>KPI's</Label>
                <div className="mt-3 flex flex-col gap-6">
                    <div className="flex gap-6">
                        <div className="flex-1 bg-white py-8 text-center">
                            <div className="text-2xl">35 696</div>
                            <div className="text-gray-400">members</div>
                        </div>
                        <div className="flex-1 bg-white py-8 text-center">
                            <div className="text-2xl">254 657</div>
                            <div className="text-gray-400">transactions</div>
                        </div>
                    </div>
                    <div className="flex bg-white py-8 items-center">
                        <div className="px-8">
                            <MoneyPile />
                        </div>
                        <div className="flex-1">
                            <div className="text-2xl">444,28 €</div>
                            <div className="text-gray-400">over the last month</div>
                        </div>
                    </div>
                    <div className="flex bg-white py-8 items-center">
                        <div className="px-8">
                            <Cart />
                        </div>
                        <div className="flex-1">
                            <div className="text-2xl">59,56 €</div>
                            <div className="text-gray-400">per purchase</div>
                        </div>
                    </div>
                    <div className="flex bg-white py-8 items-center">
                        <div className="px-8">
                            <Calendar />
                        </div>
                        <div className="flex-1">
                            <div className="text-2xl">1,7 purchases</div>
                            <div className="text-gray-400">over the last month</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <Label>Last transactions</Label>
                <div className="mt-3 bg-white p-1">
                    <OldTable>
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Member</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                            <tr>
                                <td>12,65 €</td>
                                <td>08/13/21</td>
                                <td>azae-12fz34-54fre...</td>
                            </tr>
                        </tbody>
                    </OldTable>
                    <div className="flex justify-end store-pagination">
                        <Pagination current={'1'} total={'3'} onChange={() => console.log('changing page')} />
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <Label>Running offers</Label>
                <div className="mt-3 bg-white p-1">
                    <OldTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Program</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                            <tr>
                                <td>Summer Maxi sales</td>
                                <td>Do&Co VIP</td>
                            </tr>
                        </tbody>
                    </OldTable>
                    <div className="flex justify-end store-pagination">
                        <Pagination current={'1'} total={'3'} onChange={() => console.log('changing page')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics
