import Modal from '../../../components/Modal'
import Button, { ButtonAppearance } from '../../../components/Button'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Request } from '../../../helpers'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import FiltersDropdown from '../../../components/FiltersDropdown'
import Input, { InputType } from '../../../components/Input'
import * as EmailValidator from 'email-validator'
import { message } from 'antd'
import YLogoLoading from '../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../tailwind.config'

type CreationModalProps = {
    show: boolean
    onClose: () => void
    users: { email: string }[]
    onCreated: () => void
}

type ShoppingHubOwner = {
    id: string
    name: string
}

function CreationModal({ show, onClose, users, onCreated }: CreationModalProps) {
    const uriGetShoppingHubOwnners = '/backoffice/shoppingHubOwners'
    const uriCreateBackUser = '/backoffice/admin/users'
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)
    const [shoppingHubOwners, setShoppingHubOwners] = useState<any[]>([])
    const spaycialSho = useMemo(() => ({ label: t('creation_user.shopping_hub_owner.spaycial'), value: null }), [])
    const [selectedShoppingHubOwner, setSelectedShoppingHubOwner] = useState<any>(spaycialSho)
    const [email, setEmail] = useState<string>('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (show) {
            Request.get<ShoppingHubOwner[]>(uriGetShoppingHubOwnners)
                .then((shos) =>
                    setShoppingHubOwners([spaycialSho, ...shos.map((sho) => ({ label: sho.name, value: sho.id }))])
                )
                .catch(handleError('errors.users.get_shopping_hub_owners'))
        }
    }, [show, handleError])

    const customOnClose = useCallback(() => {
        setEmail('')
        setSelectedShoppingHubOwner(spaycialSho)
        onClose()
    }, [onClose, spaycialSho])

    const onValidate = () => {
        setLoading(true)
        Request.post(
            uriCreateBackUser,
            {
                email,
                shoppingHubOwnerId: selectedShoppingHubOwner.value || undefined,
                functionalities: [
                    { code: 'SUPPORT_LVL_1', accessLevel: 'DELETE' },
                    { code: 'TRANSACTION_PINPOINT', accessLevel: 'DELETE' },
                    { code: 'HUB_SETUP_INIT', accessLevel: 'DELETE' },
                    { code: 'STORE_RETAILER_MANAGEMENT', accessLevel: 'DELETE' },
                    { code: 'STATISTIC_BOARD', accessLevel: 'DELETE' },
                    { code: 'ADMIN', accessLevel: 'DELETE' },
                    { code: 'IAM', accessLevel: 'DELETE' },
                    { code: 'STORE', accessLevel: 'DELETE' },
                    { code: 'PROGRAM', accessLevel: 'DELETE' },
                    { code: 'OFFER', accessLevel: 'DELETE' },
                    { code: 'AUDIENCE', accessLevel: 'DELETE' },
                    { code: 'INSIGHTS', accessLevel: 'DELETE' },
                    { code: 'PROFILE', accessLevel: 'DELETE' },
                ],
            },
            {},
            null,
            true
        )
            .then(() => {
                customOnClose()
                onCreated()
                message.success(t('creation_user.result.success'))
            })
            .catch((text: string) => {
                try {
                    const data = JSON.parse(text)
                    if (data.code === 'backUserAlreadyExists') {
                        message.error(t('creation_user.result.already_exists'))
                        return
                    }
                } catch (e) {
                    // Do nothing if we can't parse the response as we fall back in the default error handler: handleError
                } finally {
                    handleError('errors.users.create_back_user')
                }
            })
            .finally(() => setLoading(false))
    }

    const isEmailValid = useMemo(() => EmailValidator.validate(email), [email])
    const emailAlreadyExists = useMemo(() => users.some((u) => u.email === email), [email, users])

    return (
        <Modal show={show} onClose={customOnClose}>
            <h3 className="text-xl md:ml-6 text-center mb-8">{t('listing_user.create_btn')}</h3>
            <div className="px-4">
                <div>{t('listing_user.field.shopping_hub_owner')}</div>
                <div className="border border-1 px-6 py-5 text-base w-full focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-black placeholder-none border-black focus:ring-transparent">
                    <FiltersDropdown
                        placeholder="..."
                        options={shoppingHubOwners}
                        value={[selectedShoppingHubOwner]}
                        onChange={setSelectedShoppingHubOwner}
                    />
                </div>
            </div>

            <div className="px-4 mt-4">
                <div>{t('listing_user.field.email')}</div>
                <div>
                    <Input
                        type={InputType.EMAIL}
                        placeholder="Email"
                        value={email}
                        onChange={setEmail}
                        error={emailAlreadyExists ? new Error(t('creation_user.result.already_exists')) : undefined}
                    />
                </div>
            </div>

            <div className="flex justify-center mt-4">
                <Button
                    appearance={loading ? ButtonAppearance.BUTTON_APPEARANCE_OUTLINE : undefined}
                    className="mr-3 relative"
                    disabled={!isEmailValid || emailAlreadyExists}
                    onClick={() => !loading && onValidate()}
                >
                    {loading ? (
                        <div className="w-12 flex justify-center">
                            <YLogoLoading
                                percentage={50}
                                width={15}
                                height={20}
                                border="white"
                                color={(Tailwind as any).theme.extend.colors.primary}
                            />
                        </div>
                    ) : (
                        t('creation_user.button.create')
                    )}
                </Button>

                <Button appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE} onClick={customOnClose}>
                    {t('creation_user.button.cancel')}
                </Button>
            </div>
        </Modal>
    )
}

export default CreationModal
