import PropTypes from 'prop-types'
import Button from '../../../components/Button'
import Header from './Header'
import Heading from '../../../components/Heading'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Operate() {
    const navigate = useNavigate()
    const location = useLocation()
    let programData = location.state || {}
    const { t } = useTranslation('common')
    const validate = (hasExternalFront) => {
        programData = Object.assign(programData, { hasExternalFront })
        navigate('/programs/create/reward', { state: programData })
    }
    const options = [
        {
            id: 1,
            value: false,
            label: t('create_program.operate.options.plug_and_play.label'),
            content: t('create_program.operate.options.plug_and_play.content'),
            picto: (
                <svg width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="160" height="120" fill="white" />
                    <path
                        d="M101 46.7084C101 46.7084 99.6308 53.2391 92.3821 53.9775C85.1333 54.716 75 37 75 37C75 37 83.7463 37.9287 86.9339 42.4043C90.1215 46.8799 93.2342 48.9686 98.226 45.4888L101 46.7084Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.2"
                        d="M101 46.7084C101 46.7084 99.6308 53.2391 92.3821 53.9775C85.1333 54.716 75 37 75 37C75 37 83.7463 37.9287 86.9339 42.4043C90.1215 46.8799 93.2342 48.9686 98.226 45.4888L101 46.7084Z"
                        fill="white"
                    />
                    <path
                        opacity="0.45"
                        d="M80 103C99.8823 103 116 100.761 116 98C116 95.2386 99.8823 93 80 93C60.1177 93 44 95.2386 44 98C44 100.761 60.1177 103 80 103Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M74.8861 93.9174C74.8861 93.9174 74.43 95.3912 75.4495 96.2496C76.469 97.1081 75.9708 98.3423 74.7864 97.9113C73.6021 97.4802 72 94.4737 72 94.4737L73.238 93L74.8861 93.9174Z"
                        fill="#FF7700"
                    />
                    <path
                        d="M87.5643 92.7396C87.5643 92.7396 87.328 94.348 88.365 95.1228C89.4021 95.8976 89.0987 97.2555 87.9629 96.9581C86.8271 96.6607 85 93.7023 85 93.7023L85.9453 92L87.5643 92.7396Z"
                        fill="#FF7700"
                    />
                    <path
                        d="M66.988 26.5961C66.988 26.5961 66.8282 29.367 66.0127 31.1267C65.9337 31.2985 65.8165 31.4548 65.668 31.5864C65.5195 31.718 65.3426 31.8224 65.1477 31.8934C64.9528 31.9644 64.7437 32.0007 64.5327 32C64.3217 31.9993 64.1129 31.9618 63.9186 31.8896C62.9187 31.5297 61.6852 30.756 61.5663 29.2626L61.009 26.7437C60.9622 26.2289 61.0987 25.7142 61.3998 25.2706C61.7008 24.8271 62.1514 24.4766 62.6892 24.2679C64.6235 23.361 67.197 24.912 66.988 26.5961Z"
                        fill="#F4A28C"
                    />
                    <path d="M62 28L63.1892 35.7561L68 36L65.2027 29.94L62 28Z" fill="#F4A28C" />
                    <path
                        d="M66.1546 26.1727C65.287 26.085 64.4322 25.8894 63.6076 25.59C63.803 26.0286 63.8726 26.5184 63.8079 26.9986C63.7433 27.4788 63.5471 27.9284 63.2438 28.2914C62.9575 28.6648 62.5525 28.9146 62.1049 28.9938C61.952 29.0114 61.7973 28.9913 61.653 28.9351C61.5087 28.8788 61.3786 28.7879 61.2729 28.6694C61.1672 28.5509 61.0887 28.4082 61.0437 28.2522C60.9987 28.0962 60.9883 27.9314 61.0133 27.7704L61.4099 24.9725C61.4706 24.4258 61.6702 23.9068 61.9874 23.4711C62.3047 23.0354 62.7276 22.6993 63.211 22.4988C63.6257 22.3209 64.0512 22.1727 64.4845 22.055C65.5761 21.7694 66.9806 22.7188 67.9994 22.1167C68.1018 22.0575 68.217 22.0274 68.3336 22.0293C68.4503 22.0312 68.5645 22.065 68.6652 22.1276C68.7659 22.1901 68.8496 22.2792 68.9083 22.3861C68.967 22.4931 68.9985 22.6143 69 22.7381C69 23.8958 68.5816 25.7019 67.0279 26.1148C66.742 26.1863 66.4467 26.2059 66.1546 26.1727V26.1727Z"
                        fill="#24285B"
                    />
                    <path
                        d="M62.9947 28.2001C62.9947 28.2001 63.0876 27.0242 62.4932 27.0003C61.8987 26.9763 61.7581 28.668 62.5409 29L62.9947 28.2001Z"
                        fill="#F4A28C"
                    />
                    <path
                        d="M66.5498 28L66.9646 29.2462C66.9881 29.3158 67.0003 29.3938 67 29.4729C66.9997 29.5521 66.987 29.6298 66.963 29.6991C66.939 29.7684 66.9045 29.8269 66.8627 29.8694C66.8208 29.9119 66.7729 29.937 66.7234 29.9423L66 30L66.5498 28Z"
                        fill="#F4A28C"
                    />
                    <path
                        opacity="0.31"
                        d="M65.3488 31.3484C64.8827 31.39 64.4165 31.2695 64 31C64 31 64.1122 32.9233 66 33L65.3488 31.3484Z"
                        fill="#CE8172"
                    />
                    <path
                        d="M85.621 69L89.1935 74.8531C90.133 76.3917 90.7253 78.1363 90.9248 79.953C91.1243 81.7698 90.9257 83.6105 90.3443 85.334L87.7583 93L85.0764 92.3873L85.8299 84.7609C85.9434 83.6027 85.784 82.4329 85.3659 81.3548C84.9477 80.2768 84.2836 79.3237 83.4323 78.5798L78 73.8043L82.1102 69H85.621Z"
                        fill="#24285B"
                    />
                    <path
                        d="M53.0108 42.5498C53.0964 40.483 54.0015 38.5326 55.5303 37.1203C57.059 35.708 59.0886 34.9473 61.1802 35.0028C66.5008 35.1307 74.345 35.8138 79.2629 38.5133C87.266 42.8969 85.9137 68.0073 85.9137 68.0073L68.7436 72C68.7436 72 52.5231 56.1462 53.0108 42.5498Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.08"
                        d="M60.9041 40C60.9041 40 67.9745 43.0994 70.9635 55.4972C73.9167 67.7797 85 60.4708 85 60.4708L84.8276 68.2951L68.79 72L65.3123 68.1617C65.3123 68.1617 54.8038 56.5532 60.9041 40Z"
                        fill="black"
                    />
                    <path
                        d="M69 71.6512L72.8446 77.7121C73.5815 78.8737 74.0724 80.1829 74.2851 81.5539C74.4978 82.925 74.4276 84.327 74.079 85.668L72.0948 93.2698L75.1896 94L79.3667 86.1536C80.2247 84.5458 80.7214 82.7593 80.82 80.9264C80.9186 79.0935 80.6165 77.2612 79.9361 75.5652L79.0483 73.349L84 68L69 71.6512Z"
                        fill="#24285B"
                    />
                    <path
                        d="M96.744 60.3114L102.746 55.3848L96.628 47.8272C97.7023 48.0461 98.8176 47.9254 99.8212 47.4815C100.825 47.0377 101.668 46.2925 102.234 45.3478C102.801 44.4032 103.064 43.3053 102.987 42.2044C102.91 41.1036 102.497 40.0535 101.805 39.1981C101.112 38.3426 100.174 37.7235 99.1185 37.4255C98.0631 37.1275 96.942 37.1651 95.9087 37.5333C94.8754 37.9015 93.9803 38.5822 93.3461 39.4823C92.7118 40.3823 92.3693 41.4577 92.3654 42.5613L86.2435 35L66 51.6188L82.5029 72L88.5052 67.0735C88.0642 66.5288 87.7341 65.9021 87.5337 65.229C87.3332 64.5559 87.2665 63.8496 87.3371 63.1505C87.4799 61.7387 88.1739 60.4417 89.2664 59.545C90.3589 58.6483 91.7605 58.2253 93.1628 58.369C94.5651 58.5127 95.8533 59.2114 96.744 60.3114V60.3114Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M43.8015 59.2746L39.9485 53.4426L32.5602 58.2139C32.8582 57.2733 32.8546 56.2648 32.55 55.3263C32.2454 54.3877 31.6545 53.5648 30.8582 52.97C30.0618 52.3752 29.0988 52.0375 28.1006 52.0029C27.1024 51.9684 26.1177 52.2388 25.2811 52.7771C24.4445 53.3155 23.7967 54.0955 23.4262 55.0107C23.0558 55.9259 22.9807 56.9317 23.2112 57.8906C23.4417 58.8495 23.9667 59.7148 24.7143 60.3683C25.4619 61.0218 26.3958 61.4317 27.3883 61.5418L20 66.3168L33.0515 86L53 73.1221L49.1322 67.2901C48.5986 67.6342 48.0015 67.8713 47.375 67.9878C46.7485 68.1042 46.1048 68.0978 45.4809 67.9689C44.8569 67.8399 44.2648 67.5909 43.7383 67.2362C43.2118 66.8814 42.7613 66.4278 42.4125 65.9013C42.0637 65.3748 41.8234 64.7856 41.7054 64.1674C41.5873 63.5493 41.5939 62.9142 41.7246 62.2985C41.8553 61.6828 42.1076 61.0986 42.4671 60.5791C42.8266 60.0597 43.2864 59.6152 43.82 59.271L43.8015 59.2746Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M131.304 56.3976C132.276 56.2116 133.165 55.7336 133.851 55.029C134.536 54.3245 134.985 53.4278 135.135 52.4617C135.285 51.4955 135.129 50.5072 134.689 49.6319C134.249 48.7565 133.546 48.037 132.676 47.5717C131.806 47.1064 130.812 46.9182 129.829 47.0326C128.847 47.1471 127.924 47.5587 127.188 48.2112C126.451 48.8637 125.936 49.7251 125.714 50.6776C125.492 51.6301 125.574 52.6271 125.947 53.5322L118.259 49.4062L114.913 55.495C115.497 55.7705 116.019 56.1606 116.445 56.6415C116.872 57.1225 117.196 57.6843 117.396 58.2926C117.596 58.901 117.668 59.5432 117.609 60.1802C117.55 60.8172 117.36 61.4356 117.051 61.9977C116.742 62.5599 116.321 63.0541 115.812 63.4502C115.304 63.8462 114.719 64.1359 114.094 64.3016C113.469 64.4673 112.816 64.5055 112.175 64.4138C111.535 64.3222 110.92 64.1027 110.367 63.7686L107 69.8969L127.741 81L131.08 74.9112C130.508 74.6239 130 74.2263 129.586 73.7417C129.172 73.2572 128.86 72.6956 128.669 72.0902C128.479 71.4847 128.413 70.8476 128.476 70.2165C128.538 69.5854 128.728 68.9732 129.035 68.4159C129.341 67.8587 129.757 67.3677 130.259 66.972C130.76 66.5763 131.337 66.284 131.954 66.1122C132.572 65.9404 133.218 65.8927 133.855 65.9718C134.492 66.051 135.106 66.2554 135.662 66.5731L139 60.4843L131.304 56.3976Z"
                        fill="#C9C9C9"
                    />
                    <path
                        opacity="0.08"
                        d="M73 60L87.501 60.3608C87.501 60.3608 85.6807 63.3094 88 66.7624L82.0546 72L73 60Z"
                        fill="black"
                    />
                    <path
                        d="M53.5324 41.5688C52.7799 45.2641 52.1446 52.4558 56.6593 63.126C57.257 64.525 58.2413 65.7167 59.4923 66.5559C60.7432 67.395 62.2068 67.8452 63.7045 67.8518L84.8403 68L85 64.6988L65.5111 61.394C65.5111 61.394 66.2919 44.5663 60.8296 39.2077C58.3807 36.8105 54.2209 38.1808 53.5324 41.5688Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.2"
                        d="M53.5324 41.5688C52.7799 45.2641 52.1446 52.4558 56.6593 63.126C57.257 64.525 58.2413 65.7167 59.4923 66.5559C60.7432 67.395 62.2068 67.8452 63.7045 67.8518L84.8403 68L85 64.6988L65.5111 61.394C65.5111 61.394 66.2919 44.5663 60.8296 39.2077C58.3807 36.8105 54.2209 38.1808 53.5324 41.5688Z"
                        fill="white"
                    />
                    <path
                        d="M85 67.3921C85 67.3921 87.2981 68.7227 88.4679 67.4468C89.6376 66.1708 88.522 65.2594 88.4679 63.9215C88.4801 63.7305 88.4359 63.5404 88.3422 63.3817C88.2485 63.223 88.1109 63.105 87.9515 63.0466C87.7434 62.9748 87.5202 62.9863 87.3186 63.079C87.1169 63.1718 86.949 63.3404 86.8423 63.5569C86.5236 64.1475 85.9275 65.0626 85.1211 64.9094L85 67.3921Z"
                        fill="#F4A28C"
                    />
                    <path
                        d="M102 44.744C102 44.744 100.751 43.07 99.3691 44.744C97.9874 46.4181 100.847 48.8493 102 44.744Z"
                        fill="#F4A28C"
                    />
                    <path
                        opacity="0.08"
                        d="M65 62C64.0012 61.8623 62.9988 61.8623 62 62C62.9452 61.0415 63.9561 60.3675 65 60V62Z"
                        fill="black"
                    />
                    <path
                        d="M89.5711 18.0986L90.9905 19.5216L92.4099 18.0986L90.9905 16.6757L89.5711 18.0986Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M34.9346 39.9403L35.6621 43.135L38.8488 42.4057L38.1213 39.211L34.9346 39.9403Z"
                        fill="#E6E6E6"
                    />
                    <path d="M114 35V38H117V35H114Z" fill="#C9C9C9" />
                </svg>
            ),
        },
        {
            id: 2,
            value: true,
            label: t('create_program.operate.options.already_crm.label'),
            content: t('create_program.operate.options.already_crm.content'),
            picto: (
                <svg width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="160" height="120" fill="white" />
                    <path
                        opacity="0.3"
                        d="M36.7935 39.4507C27.866 45.5518 22.5659 56.7479 23.4156 68.4358C23.8423 74.4121 26.2428 79.579 31.059 83.3274C49.1846 97.4399 124.552 97.6555 133.556 82.8168C142.56 67.9781 126.816 40.714 97.7392 46.2969C79.4421 49.8147 59.8496 23.7004 36.7935 39.4507Z"
                        fill="#E6E6E6"
                    />
                    <path
                        opacity="0.45"
                        d="M80.225 101.801C115.999 101.801 145 100.199 145 98.2228C145 96.2466 115.999 94.6445 80.225 94.6445C44.4507 94.6445 15.45 96.2466 15.45 98.2228C15.45 100.199 44.4507 101.801 80.225 101.801Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M89.3781 36.504C92.9681 36.504 95.8784 33.6149 95.8784 30.0511C95.8784 26.4872 92.9681 23.5981 89.3781 23.5981C85.788 23.5981 82.8777 26.4872 82.8777 30.0511C82.8777 33.6149 85.788 36.504 89.3781 36.504Z"
                        fill="#FFD200"
                    />
                    <path
                        opacity="0.15"
                        d="M89.3781 42.3518C96.2215 42.3518 101.769 36.8446 101.769 30.0511C101.769 23.2577 96.2215 17.7505 89.3781 17.7505C82.5347 17.7505 76.9871 23.2577 76.9871 30.0511C76.9871 36.8446 82.5347 42.3518 89.3781 42.3518Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M50.4481 51.2027C50.5487 51.9559 50.5487 52.719 50.4481 53.4722C50.4326 53.5642 50.3986 53.6522 50.348 53.7308C50.2974 53.8095 50.2313 53.8771 50.1538 53.9298C50.0762 53.9824 49.9887 54.0189 49.8965 54.0372C49.8043 54.0554 49.7094 54.0549 49.6174 54.0358C49.324 53.9943 49.0492 53.8688 48.8266 53.6747C48.6039 53.4805 48.4431 53.2261 48.3638 52.9427L47.8876 51.7663C47.8189 51.5196 47.8294 51.2577 47.9175 51.0171C48.0055 50.7765 48.1669 50.569 48.3791 50.4235C49.1335 49.8108 50.3795 50.3668 50.4481 51.2027Z"
                        fill="#F4A28C"
                    />
                    <path
                        d="M47.9762 51.9629L47.458 56.4451L49.969 56.5473L49.3365 53.2452L47.9762 51.9629Z"
                        fill="#F4A28C"
                    />
                    <path
                        d="M50.5164 52.0688L50.9241 52.5833C50.9477 52.6122 50.9635 52.6466 50.97 52.6832C50.9765 52.7198 50.9735 52.7575 50.9613 52.7927C50.9491 52.8279 50.9281 52.8594 50.9003 52.8843C50.8725 52.9092 50.8387 52.9266 50.8022 52.935L50.2764 53.0561L50.5164 52.0688Z"
                        fill="#F4A28C"
                    />
                    <path
                        opacity="0.31"
                        d="M49.5035 53.9639C49.189 53.8978 48.9005 53.7428 48.6729 53.5176C48.7971 54.1589 49.1621 54.7295 49.694 55.1138L49.5035 53.9639Z"
                        fill="#CE8172"
                    />
                    <path
                        d="M50.1822 51.2595C50.0788 51.2944 49.9731 51.3222 49.8659 51.3427C49.7165 51.3757 49.571 51.4239 49.4315 51.4864L49.3172 51.5356C49.2374 51.5703 49.1696 51.6276 49.1222 51.7002C49.0748 51.7728 49.0499 51.8576 49.0505 51.9441C49.0675 52.1122 49.0489 52.2819 48.9961 52.4424C48.9432 52.6029 48.8572 52.7508 48.7435 52.8764C48.6298 53.0021 48.491 53.1028 48.3359 53.1722C48.1808 53.2416 48.0128 53.2781 47.8426 53.2793C47.8426 53.2793 46.7567 51.6642 47.6254 50.4198C47.8812 50.0763 48.211 49.7939 48.5906 49.593C48.9703 49.3921 49.3904 49.2779 49.8202 49.2585C50.0643 49.2584 50.3007 49.3435 50.488 49.4989C50.6752 49.6543 50.8015 49.8701 50.8447 50.1086C50.8878 50.3471 50.8451 50.5931 50.724 50.8035C50.6029 51.0139 50.4111 51.1753 50.1822 51.2595Z"
                        fill="#24285B"
                    />
                    <path
                        d="M49.0264 52.7082C49.0647 52.6611 49.0896 52.6046 49.0983 52.5447C49.107 52.4848 49.0993 52.4237 49.0759 52.3677C48.9997 52.1824 48.8473 51.9365 48.5958 51.9895C48.3444 52.0424 48.2872 52.4396 48.4663 52.7082C48.498 52.7527 48.5399 52.7889 48.5887 52.814C48.6374 52.839 48.6915 52.8521 48.7463 52.8521C48.8012 52.8521 48.8553 52.839 48.904 52.814C48.9528 52.7889 48.9947 52.7527 49.0264 52.7082V52.7082Z"
                        fill="#F4A28C"
                    />
                    <path d="M128.764 67.8875H90.6499V97.7464H128.764V67.8875Z" fill="#FFD200" />
                    <path
                        d="M58.3402 95.6735C58.3402 95.6735 58.8775 96.6077 59.7996 96.5245C60.7216 96.4413 61.1179 97.281 60.3406 97.6593C59.5633 98.0375 57.2924 97.3604 57.2924 97.3604L57.0752 95.6962L58.3402 95.6735Z"
                        fill="#FF7700"
                    />
                    <path
                        d="M41.4523 95.6735C41.4523 95.6735 41.9933 96.6077 42.9154 96.5245C43.8375 96.4413 44.23 97.281 43.4565 97.6593C42.683 98.0375 40.3854 97.3604 40.3854 97.3604L40.1682 95.6962L41.4523 95.6735Z"
                        fill="#FF7700"
                    />
                    <path opacity="0.08" d="M128.764 67.8875H90.6499V97.7464H128.764V67.8875Z" fill="black" />
                    <path d="M98.8703 67.8875H70.7922V97.7464H98.8703V67.8875Z" fill="#FFD200" />
                    <path d="M93.9246 81.6519H84.8333V97.7463H93.9246V81.6519Z" fill="#24285B" />
                    <path d="M124.067 76.4734H104.726V86.8298H124.067V76.4734Z" fill="white" />
                    <path opacity="0.08" d="M124.067 76.4734H104.726V86.8298H124.067V76.4734Z" fill="black" />
                    <path d="M80.2375 82.0073H76.0461V91.3803H80.2375V82.0073Z" fill="white" />
                    <path
                        d="M50.5896 56.0669L53.0968 56.1728C53.0968 56.1728 61.4985 57.2206 64.3905 53.9601C67.2825 50.6996 66.4138 44.9843 66.4138 44.9843H68.5819C68.5819 44.9843 73.2647 59.127 59.6429 63.004C58.4468 63.3516 57.3605 63.9981 56.4879 64.8815C55.6153 65.7649 54.9856 66.8558 54.659 68.0498L53.5426 72.0555H39.7951L39.5169 65.5723C39.4775 64.551 39.1962 63.5534 38.6957 62.6603C38.1952 61.7671 37.4897 61.0037 36.6363 60.4319C33.3519 58.2532 28.6766 53.457 32.1478 44.9843H34.6054C34.6054 44.9843 32.4717 57.3189 50.5896 56.0669Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.08"
                        d="M49.6938 57.9206C49.6938 57.9206 64.4016 62.921 65.8152 51.4904C65.8152 51.4904 64.5464 57.565 53.527 56.2071L50.5893 56.0558L49.6938 57.9206Z"
                        fill="black"
                    />
                    <path
                        d="M39.7955 72.0557H53.543L59.1975 95.9156H56.6484L49.1612 78.1568C49.1205 78.0644 49.0495 77.9885 48.9598 77.9413C48.8701 77.894 48.7669 77.8784 48.6671 77.8968C48.5672 77.9152 48.4766 77.9666 48.41 78.0427C48.3433 78.1188 48.3046 78.215 48.3 78.3157L48.2162 81.474C47.9719 85.5562 46.6198 89.4968 44.303 92.8782L42.2226 95.9042H39.5554L41.4606 92.3033C43.7086 87.9307 43.2933 83.0513 41.8149 78.3686L39.7955 72.0557Z"
                        fill="#24285B"
                    />
                    <path d="M57.8368 19.4526H50.8716V31.4166H57.8368V19.4526Z" fill="#FFD200" />
                    <path
                        d="M91.5991 90.9453C92.1294 90.9453 92.5593 90.5186 92.5593 89.9921C92.5593 89.4657 92.1294 89.0389 91.5991 89.0389C91.0688 89.0389 90.6389 89.4657 90.6389 89.9921C90.6389 90.5186 91.0688 90.9453 91.5991 90.9453Z"
                        fill="#FF7700"
                    />
                    <path d="M20.3628 43.479L33.5464 26.1061L48.4409 43.479H20.3628Z" fill="#24285B" />
                    <path d="M63.1095 26.1061H33.5454L48.4399 43.479H49.9297H78.0039L63.1095 26.1061Z" fill="#24285B" />
                    <path
                        opacity="0.19"
                        d="M63.1095 26.1061H33.5454L48.4399 43.479H49.9297H78.0039L63.1095 26.1061Z"
                        fill="black"
                    />
                    <path
                        opacity="0.08"
                        d="M33.0805 45.0145C33.0805 45.0145 30.0856 53.336 37.2146 58.5218C41.0021 61.2716 42.61 63.2196 43.2844 64.4413C44.2488 66.2199 45.3981 67.8933 46.7137 69.4342L48.9999 72.0555H39.7942L39.5351 66.0338C39.5908 64.9294 39.352 63.8299 38.8429 62.8464C38.3338 61.8628 37.5724 61.03 36.6355 60.4319C36.6355 60.4319 27.6698 55.5828 32.1469 44.9843L33.0805 45.0145Z"
                        fill="black"
                    />
                    <path
                        d="M46.1284 56.0898C46.1284 56.0898 48.929 53.3664 51.8134 56.0898C51.8134 56.0898 49.6187 60.3602 46.1284 56.0898Z"
                        fill="#FF7700"
                    />
                    <path
                        opacity="0.46"
                        d="M51.8134 56.0898C51.8134 56.0898 49.611 60.3602 46.1284 56.0898C46.1284 56.0898 48.929 53.3664 51.8134 56.0898Z"
                        fill="white"
                    />
                    <path
                        d="M33.5461 40.0028C35.217 40.0028 36.5715 38.6582 36.5715 36.9995C36.5715 35.3408 35.217 33.9962 33.5461 33.9962C31.8753 33.9962 30.5208 35.3408 30.5208 36.9995C30.5208 38.6582 31.8753 40.0028 33.5461 40.0028Z"
                        fill="white"
                    />
                    <path d="M129.786 66.7827H69.3582V70.2096H129.786V66.7827Z" fill="#FFD200" />
                    <path opacity="0.46" d="M129.786 66.7827H69.3582V70.2096H129.786V66.7827Z" fill="white" />
                    <path opacity="0.08" d="M129.786 66.7827H98.8696V70.2096H129.786V66.7827Z" fill="black" />
                    <path
                        d="M124.487 51.9101C124.487 51.2319 124.216 50.5816 123.733 50.1021C123.25 49.6225 122.595 49.3531 121.912 49.3531C121.773 49.353 121.634 49.3644 121.496 49.3872C121.201 48.8396 120.761 48.3817 120.224 48.0621C119.688 47.7424 119.074 47.573 118.448 47.5716H118.322C118.474 46.9684 118.484 46.339 118.354 45.7311C118.223 45.1232 117.955 44.5527 117.57 44.0631C117.184 43.5734 116.691 43.1773 116.128 42.9049C115.566 42.6325 114.948 42.491 114.322 42.491C113.695 42.491 113.078 42.6325 112.515 42.9049C111.952 43.1773 111.459 43.5734 111.074 44.0631C110.688 44.5527 110.42 45.1232 110.289 45.7311C110.159 46.339 110.17 46.9684 110.321 47.5716H110.195C109.729 47.5563 109.265 47.6341 108.83 47.8005C108.395 47.9669 107.999 48.2184 107.664 48.54C107.329 48.8617 107.063 49.2469 106.881 49.6729C106.699 50.0988 106.605 50.5567 106.605 51.0193C106.605 51.4819 106.699 51.9398 106.881 52.3658C107.063 52.7917 107.329 53.177 107.664 53.4986C107.999 53.8203 108.395 54.0718 108.83 54.2382C109.265 54.4045 109.729 54.4824 110.195 54.4671H122.247V54.4444C122.866 54.3628 123.435 54.061 123.847 53.5949C124.259 53.1289 124.486 52.5302 124.487 51.9101V51.9101Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M30.8937 74.2607C30.8937 73.7467 30.6883 73.2537 30.3225 72.89C29.9568 72.5262 29.4606 72.3213 28.9428 72.3203C28.8382 72.3213 28.7337 72.3301 28.6304 72.3468C28.4043 71.9314 28.0692 71.5845 27.6607 71.3428C27.2522 71.1011 26.7855 70.9736 26.3099 70.9737H26.2147C26.3325 70.5157 26.3433 70.0371 26.2463 69.5743C26.1493 69.1116 25.947 68.677 25.6549 68.3037C25.3629 67.9304 24.9887 67.6283 24.561 67.4205C24.1334 67.2126 23.6635 67.1046 23.1874 67.1046C22.7112 67.1046 22.2414 67.2126 21.8137 67.4205C21.3861 67.6283 21.0119 67.9304 20.7198 68.3037C20.4277 68.677 20.2255 69.1116 20.1285 69.5743C20.0315 70.0371 20.0423 70.5157 20.1601 70.9737H20.0648C19.7109 70.9604 19.3579 71.018 19.0269 71.1432C18.696 71.2685 18.3939 71.4587 18.1387 71.7025C17.8835 71.9464 17.6805 72.2388 17.5419 72.5624C17.4033 72.8859 17.3318 73.2339 17.3318 73.5855C17.3318 73.9371 17.4033 74.2851 17.5419 74.6087C17.6805 74.9322 17.8835 75.2247 18.1387 75.4685C18.3939 75.7124 18.696 75.9026 19.0269 76.0278C19.3579 76.153 19.7109 76.2107 20.0648 76.1973H29.2096V76.1784C29.6765 76.1152 30.1046 75.8861 30.4143 75.5334C30.724 75.1808 30.8943 74.7286 30.8937 74.2607V74.2607Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M35.589 97.8217C35.589 97.8217 37.7037 94.7957 34.5449 93.9636C31.3862 93.1314 35.307 89.0464 32.3655 88.5244C29.4239 88.0024 29.9154 92.3069 29.9154 92.3069C29.9154 92.3069 27.8845 90.5707 26.4862 92.0307C25.0878 93.4908 27.0463 95.0908 27.0463 95.0908C27.0463 95.0908 24.219 96.0591 25.9832 97.8331L35.589 97.8217Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M31.3291 98.2229C31.3291 98.2229 32.6741 95.9534 34.6555 96.2219C36.6368 96.4905 36.4273 98.2229 36.4273 98.2229H31.3291Z"
                        fill="#C9C9C9"
                    />
                    <path d="M125.062 85.9032H103.819V87.7944H125.062V85.9032Z" fill="#FFD200" />
                    <path opacity="0.46" d="M125.062 85.9032H103.819V87.7944H125.062V85.9032Z" fill="white" />
                    <path opacity="0.08" d="M125.062 85.9032H103.819V87.7944H125.062V85.9032Z" fill="black" />
                    <path d="M32.5212 44.9958L32.083 43.479H34.1863V44.9958H32.5212Z" fill="#F4A28C" />
                    <path d="M66.5285 44.9958L66.0903 43.479H68.1936V44.9958H66.5285Z" fill="#F4A28C" />
                </svg>
            ),
        },
    ]

    let classes = ['grid grid-cols-1 gap-5 xl:gap-10']
    if (options.length === 2) {
        classes.push('xl:grid-cols-2')
    } else if (options.length > 2) {
        classes.push('xl:grid-cols-3')
    }

    return (
        <>
            <Header
                onBack={(e) => navigate('/programs/create/purchase', { state: programData })}
                onButtonClick={(e) => navigate('/programs')}
                buttonLabel={t('create_program.exit')}
            >
                <p className="text-xs">{t('create_program.step')} 3/5</p>
                <Heading>{t('create_program.operate.header')}</Heading>
            </Header>

            <div className="flex flex-col h-full space-y-24 items-center md:mt-32">
                <div className={classes.join(' ')}>
                    {options.map((option) => {
                        return (
                            <div className="flex flex-col items-center bg-white space-y-4 py-8 px-8 md:w-72 xl:w-64 h-80">
                                {option.picto}

                                <label className="flex items-center text-center h-1/2 text-black text-sm">
                                    {option.content}
                                </label>

                                <div className="flex mx-auto">
                                    <Button
                                        className="whitespace-nowrap"
                                        appearance="outline"
                                        onClick={() => validate(option.value)}
                                    >
                                        {option.label}
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

Operate.propTypes = {
    onValidate: PropTypes.func,
}

Operate.defaultProps = {
    onValidate: undefined,
}

export default Operate
