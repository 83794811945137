import { useCallback, useState, useContext } from 'react'
import Button from '../../components/Button'
import { ACCESS_LEVEL, FUNCTIONALITIES, Request } from '../../helpers'
import { withProgram } from '../../contexts/ProgramContext'
import DropDown from '../../components/DropDown'
import Modal from '../../components/Modal'
import { useTranslation } from 'react-i18next'
import StoreFilter from '../../components/StoreFilter'
import { useNavigate, useParams } from 'react-router-dom'
import { StoreStatus } from '../../components/store/status'
import './Listing.css'
import RestTable from '../../components/Table'
import RequireAuth from '../../components/RequireAuth'
import ErrorManagementContext from '../../contexts/ErrorManagementContext'

function Listing({ currentProgram }) {
    const { t } = useTranslation('common')
    const url = `backoffice/shoppingHubs/stores?withExtra=false`
    const [tableQueryUrl, setTableQueryUrl] = useState(url)

    const [storeToDeactivate, setStoreToDeactivate] = useState({})
    const { handleError } = useContext(ErrorManagementContext)

    const [countryCitiesData, setCountryCitiesData] = useState(undefined)

    const navigate = useNavigate()
    const { programId } = useParams()

    const onPageReceived = (page) => {
        if (!countryCitiesData) {
            setCountryCitiesData(page.cities)
        }
    }

    const handleOnChange = async (countries, cities, input) => {
        let uri = url
        if (countries.length > 0) {
            uri += `&country=${countries}`
        }
        if (cities.length > 0) {
            uri += `&city=${cities}`
        }
        if (input?.value) {
            uri += `&search=${encodeURIComponent(input.value)}`
        }

        setTableQueryUrl(uri)
    }

    /**
     * Deactivate a store by remove the shoppingHubId associated
     */
    const patchStore = () => {
        Request.patch(`backoffice/stores/${storeToDeactivate.id}`, { disassociate: true, name: storeToDeactivate.name })
            .then(() => {
                setStoreToDeactivate({})
            })
            .catch((err) => {
                setStoreToDeactivate({})
                handleError('errors.stores.deactivate')(err)
            })
    }

    const goToStore = useCallback(
        (store) => {
            navigate({ pathname: `/programs/${currentProgram?.id}/stores/${store.id}/details`, state: store })
        },
        [navigate]
    )

    const columns = [
        { title: t('listingStore.tableHeader.store'), render: (s) => s.name },
        {
            title: t('listingStore.tableHeader.address'),
            className: 'store-address-cell',
            render: (s) => <div title={s.formattedAddress || 'N/A'}>{s.formattedAddress || 'N/A'}</div>,
        },
        { title: t('listingStore.tableHeader.city'), render: (s) => s.cityName || 'N/A' },
        { title: t('listingStore.tableHeader.status'), render: (s) => <StoreStatus store={s} /> },
        {
            title: '',
            render: (s) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <DropDown data-cy="kebab_menu">
                        <RequireAuth functionality={FUNCTIONALITIES.STORE} level={ACCESS_LEVEL.WRITE}>
                            <span
                                onClick={() => setStoreToDeactivate(s)}
                                className="px-4 py-3 hover:bg-gray-200 hover:text-primary w-full text-sm hover:bg-opacity-20"
                                data-cy="deactivate_store_button"
                            >
                                {t('listingStore.tableRowMenu.deactivate')}
                            </span>
                        </RequireAuth>
                    </DropDown>
                </div>
            ),
        },
    ]

    return (
        <div className="p-1 pl-4 h-full">
            <div className="flex flex-col h-full">
                <div className="md:flex justify-between md:mb-20">
                    <h3 className="text-3xl text-black text-left md:spl-8">{t('stores.listing.stores_referential')}</h3>
                    <div className="flex flex-row gap-3 my-4 md:my-0">
                        <RequireAuth functionality={FUNCTIONALITIES.STORE} level={ACCESS_LEVEL.WRITE}>
                            <Button
                                appearance="outline"
                                as={'link'}
                                to={`/programs/${currentProgram?.id}/stores/create`}
                                data-cy="add_store_button"
                            >
                                {t('stores.listing.add_a_store')}
                            </Button>
                        </RequireAuth>
                    </div>
                </div>

                <div className="flex flex-col gap-4 flex-1">
                    <div className="grid grid-cols-1 gap-4">
                        <StoreFilter onChange={handleOnChange} data={countryCitiesData} />
                    </div>
                    <div className="mt-4 overflow-auto">
                        <RestTable
                            queryBaseUrl={tableQueryUrl}
                            headers={{ shoppingHubId: currentProgram?.id || programId }}
                            onDataReceived={onPageReceived}
                            columns={columns}
                            errorMessageKey="errors.stores.search"
                            pageResultsKey="stores"
                            totalItemsKey="total"
                            onRowClicked={(s) => goToStore(s)}
                        />
                    </div>

                    <Modal show={!!Object.keys(storeToDeactivate).length} onClose={() => setStoreToDeactivate({})}>
                        <div className="max-w-md text-center m-auto">
                            <p>
                                {storeToDeactivate.name} {t('store.deactivate.confirmation')}
                            </p>
                            <div className={'mt-12 flex justify-center'}>
                                <Button
                                    appearance="inversed"
                                    className="w-1/3"
                                    onClick={() => setStoreToDeactivate(false)}
                                    data-cy="confirmation_popup_button_no"
                                >
                                    {t('offerCreation.leaveModal.no')}
                                </Button>
                                <Button
                                    className={'w-1/3 ml-4'}
                                    onClick={() => patchStore()}
                                    data-cy="confirmation_popup_button_yes"
                                >
                                    {t('offerCreation.leaveModal.yes')}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default withProgram(Listing)
