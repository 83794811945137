export const getPlaceInfo = async (address, { center, limit } = {}) => {
    const searchParams = new URLSearchParams()

    if (center) {
        searchParams.append('proximity', center.join(','))
    }

    if (limit) {
        searchParams.append('limit', limit)
    }

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${
        process.env.REACT_APP_MAPBOX_API
    }&${searchParams.toString()}`

    return await fetch(url).then((response) => response.json())
}
