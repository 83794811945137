import Button from '../../../components/Button'
import PropTypes from 'prop-types'
import Label from '../../../components/Label'
import React, { useMemo, useState } from 'react'
import AddressesInput from '../../../components/AddressesInput'
import Heading from '../../../components/Heading'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import { useTranslation } from 'react-i18next'
import { withProgram } from '../../../contexts/ProgramContext'
import Input from '../../../components/Input'
import InfoTooltip from '../../../components/InfoTooltip'

function Address({ currentProgram }) {
    const { t } = useTranslation('common')
    const navigate = useNavigate()
    const location = useLocation()
    let storeData = location.state || {}

    const [streetName, setStreetName] = useState(storeData.streetName || '')
    const [streetNumber, setStreetNumber] = useState(storeData.streetNumber || '')
    const [city, setCity] = useState(storeData.city || '')
    const [postCode, setPostCode] = useState(storeData.postCode || '')
    const [country, setCountry] = useState(storeData.country || '')
    const [placeId, setPlaceId] = useState(null)
    const [externalId, setExternalId] = useState()
    const [formattedAddress, setFormattedAddress] = useState(storeData.formattedAddress)

    const validate = (placeId, streetName, streetNumber, city, postCode, country, formattedAddress) => {
        storeData = Object.assign(storeData, {
            googlePlaceId: placeId,
            streetName,
            streetNumber,
            city,
            postCode,
            country,
            formattedAddress,
            externalId,
        })
        navigate(`/programs/${currentProgram.id}/stores/create/loading`, { state: storeData })
    }

    const isNextButtonDisabled = useMemo(() => {
        // If address is not entered, and we do not have a place id, disable the button
        if (!((streetName && city && postCode && country) || placeId)) {
            return true
        }
        // If the program is an API program, and externalId was not given, disable the button
        if (currentProgram.hasExternalFront && !externalId) {
            return true
        }
    }, [streetName, city, postCode, country, placeId, currentProgram, externalId])

    const nextButton = (
        <Button
            className={'w-full py-4'}
            disabled={isNextButtonDisabled}
            onClick={() => validate(placeId, streetName, streetNumber, city, postCode, country, formattedAddress)}
        >
            {t('createBrand.setBrandName.next')}
        </Button>
    )

    return (
        <>
            <div className="md:flex md:justify-between">
                <div className={'flex'}>
                    <BackButton onClick={() => navigate(-1)} />
                </div>
                <Heading className="mb-8 md:mb-20 mt-2">{t('createBrand.setAddress.title')}</Heading>
                <div className={'flex items-start'}>
                    <Button
                        as={'link'}
                        to={`/programs/${currentProgram.id}/stores`}
                        label={t('createBrand.setBrandName.exit')}
                        appearance="outline"
                    />
                </div>
            </div>
            <div className="max-w-xl mx-auto flex flex-col gap-8 xl:py-12">
                <div>
                    <Label> {t('createBrand.setAddress.inputLabel')}</Label>
                    <AddressesInput
                        prefixSearch={storeData.brandName}
                        className="mt-3.5"
                        onChange={(e) => {
                            setStreetName(e.street_name)
                            setStreetNumber(e.street_number)
                            setCity(e.city)
                            setPostCode(e.postal_code)
                            setCountry(e.country)
                            setPlaceId(e.placeId)
                            setFormattedAddress(e.formatted_address)
                        }}
                        value={
                            city
                                ? `${
                                      streetNumber ? streetNumber + ' ' : ''
                                  }${streetName}, ${postCode} ${city}, ${country}`
                                : ''
                        }
                    />
                </div>
                {currentProgram.hasExternalFront && (
                    <div>
                        <Label>
                            {t('createBrand.externalId.inputLabel')}{' '}
                            <InfoTooltip text={t('createBrand.externalId.info')} />
                        </Label>
                        <Input
                            value={externalId}
                            placeholder={t('createBrand.externalId.placeholder')}
                            onChange={setExternalId}
                        />
                    </div>
                )}
                {nextButton}
            </div>
        </>
    )
}

Address.propTypes = {
    onValidate: PropTypes.func,
}

Address.defaultProps = {
    onValidate: undefined,
}

export default withProgram(Address)
