import React from 'react'

function SlideshowArrowLeft({ color = '#000000' }) {
    return (
        <svg width="27" height="69" viewBox="0 0 27 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 68.5L1 35.5L26 1" stroke={color} />
        </svg>
    )
}

export default SlideshowArrowLeft
