import { withProgram, WithProgramProps } from '../../../../../contexts/ProgramContext'
import ShoppingCategoriesChart from './Segments/ShoppingCategoriesChart'
import ShoppingHabitsChart from './ShoppingHabits/ShoppingHabitsChart'
import FavoriteStores from './FavoriteStores/FavoriteStores'
import ShopperProfileKpis from './Kpi/ShopperProfileKpis'
import PurchaseHistoryTable from './PurchaseHistory/PurchaseHistoryTable'
import ShoppingCategoriesTable from './Segments/ShoppingCategoriesTable'
import { useParams } from 'react-router-dom'
import Loading from '../../../../../components/Loading'
import { useBehaviorData } from './hooks/useBehaviorData'
import { ShopperProfileKpis as KpisType } from './shopper-profile-behavior-types'
import { useTranslation } from 'react-i18next'

type BehaviorProps = WithProgramProps

function Behavior({ currentProgram }: BehaviorProps) {
    const { t } = useTranslation('common')
    const { customerId } = useParams<{ customerId: string }>()
    const { unselectedSegmentsId, toggleSelectSegment, segments, kpis, segmentsLoading, kpisLoading } = useBehaviorData(
        customerId!
    )

    return (
        <>
            <div className="md:flex flex-row mt-6 gap-6">
                <div className="h-full 2xl:w-1/4 xl:w-2/5 lg:w-1/2 md:w-1/2 w-full">
                    <div className="pt-8 pb-8 pl-6 pr-6 bg-white mb-6">
                        <div className="w-full text-xl font-black text-center whitespace-nowrap overflow-hidden text-ellipsis">
                            {t('shopperProfile.behavior.shoppingCategories')}&nbsp;
                            {currentProgram ? t('shopperProfile.behavior.at') : ''}&nbsp;
                            {currentProgram?.name}
                        </div>

                        {segmentsLoading ? (
                            <div className="h-96 relative">
                                <Loading fullScreen={false} />
                            </div>
                        ) : (
                            <>
                                <ShoppingCategoriesChart
                                    segments={segments}
                                    unselectedSegmentsId={unselectedSegmentsId}
                                />
                                <ShoppingCategoriesTable
                                    segments={segments}
                                    toggleSelectSegment={toggleSelectSegment}
                                    unselectedSegmentsId={unselectedSegmentsId}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="h-full 2xl:w-3/4 xl:w-3/5 lg:w-1/2 md:w-1/2 w-full">
                    {kpisLoading || segmentsLoading ? (
                        <div className="h-72 bg-white relative">
                            <Loading fullScreen={false} />
                        </div>
                    ) : (
                        <ShopperProfileKpis kpis={kpis || ({} as KpisType)} />
                    )}
                    <div className="mb-6 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6 mt-6">
                        <div className="grid-cols-1 gap-6 relative">
                            {kpisLoading || segmentsLoading ? (
                                <div className="bg-white h-96">
                                    <Loading fullScreen={false} />
                                </div>
                            ) : (
                                <FavoriteStores favoriteStores={kpis?.favorite_stores || []} />
                            )}
                        </div>

                        <div className="grid-cols-1 col-auto xl:col-span-2 2xl:col-span-2 gap-6 relative">
                            {segmentsLoading ? (
                                <div className="bg-white h-96">
                                    <Loading fullScreen={false} />
                                </div>
                            ) : (
                                <ShoppingHabitsChart segments={segments} unselectedSegmentsId={unselectedSegmentsId} />
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 gap-6">
                        <PurchaseHistoryTable />
                    </div>
                </div>
            </div>
        </>
    )
}

export default withProgram(Behavior)
