export const NotAuthorizedIcon = () => (<svg width="630" height="306" viewBox="0 0 630 306" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M324.709 57.2753H93.5424V216.691H324.709V57.2753Z" fill="#E6E6E6"/>
    <path d="M324.709 90.4658H93.5424V216.691H324.709V90.4658Z" fill="white"/>
    <path d="M237.799 145.011H180V183.539H237.799V145.011Z" fill="#E5E5E5"/>
    <path d="M192.668 145.011V133.051C192.668 130.943 193.083 128.856 193.889 126.908C194.695 124.961 195.876 123.192 197.366 121.701C198.855 120.211 200.623 119.028 202.57 118.222C204.516 117.415 206.601 117 208.708 117V117C212.963 117 217.043 118.691 220.053 121.701C223.063 124.71 224.755 128.793 224.758 133.051V145.001" stroke="#E5E5E5" stroke-width="4.06" stroke-miterlimit="10"/>
    <path d="M208.905 167.25C213.168 167.25 216.624 163.792 216.624 159.525C216.624 155.259 213.168 151.801 208.905 151.801C204.642 151.801 201.186 155.259 201.186 159.525C201.186 163.792 204.642 167.25 208.905 167.25Z" fill="white"/>
    <path d="M211.913 163.087H205.896V176.978H211.913V163.087Z" fill="white"/>
    <path opacity="0.08" d="M96.425 103.996C96.425 103.996 101.216 100.768 103.459 112.623C105.702 124.478 99.1249 134.547 114.878 136.983C130.631 139.419 140.943 132.761 144.577 151.68C148.21 170.6 168.673 179.157 161.679 196.259C154.686 213.362 148.85 216.691 148.85 216.691H93.5424C93.5424 216.691 93.1263 110.735 96.425 103.996Z" fill="black"/>
    <path opacity="0.45" d="M102.018 282.301C143.399 282.301 176.945 280.129 176.945 277.449C176.945 274.77 143.399 272.597 102.018 272.597C60.6364 272.597 27.0903 274.77 27.0903 277.449C27.0903 280.129 60.6364 282.301 102.018 282.301Z" fill="#E6E6E6"/>
    <path d="M112.188 76.3472C114.643 76.3472 116.634 74.3568 116.634 71.9015C116.634 69.4462 114.643 67.4558 112.188 67.4558C109.733 67.4558 107.742 69.4462 107.742 71.9015C107.742 74.3568 109.733 76.3472 112.188 76.3472Z" fill="#24285B"/>
    <path d="M125.413 76.3472C127.869 76.3472 129.859 74.3568 129.859 71.9015C129.859 69.4462 127.869 67.4558 125.413 67.4558C122.958 67.4558 120.968 69.4462 120.968 71.9015C120.968 74.3568 122.958 76.3472 125.413 76.3472Z" fill="#FF7700"/>
    <path d="M137.878 76.3472C140.333 76.3472 142.323 74.3568 142.323 71.9015C142.323 69.4462 140.333 67.4558 137.878 67.4558C135.422 67.4558 133.432 69.4462 133.432 71.9015C133.432 74.3568 135.422 76.3472 137.878 76.3472Z" fill="#FFD200"/>
    <path d="M68.0659 269.146C68.0659 269.146 66.8174 272.618 63.6811 272.882C60.5447 273.146 59.7327 276.211 62.5443 277.003C65.3558 277.794 72.6334 274.181 72.6334 274.181L72.4202 269.664L68.0659 269.146Z" fill="#FF7700"/>
    <path d="M111.102 269.146C111.102 269.146 112.34 272.618 115.477 272.882C118.613 273.146 119.425 276.211 116.613 277.003C113.802 277.794 106.534 274.181 106.534 274.181L106.737 269.664L111.102 269.146Z" fill="#FF7700"/>
    <path d="M115.111 130.457C115.111 130.457 137.441 134.974 135.604 155.832C133.767 176.69 154.067 188.089 141.623 200.126C129.179 212.164 112.594 205.394 112.594 205.394L115.111 130.457Z" fill="#FF7700"/>
    <path opacity="0.39" d="M115.111 130.457C115.111 130.457 137.441 134.974 135.604 155.832C133.767 176.69 154.067 188.089 141.623 200.126C129.179 212.164 112.594 205.394 112.594 205.394L115.111 130.457Z" fill="white"/>
    <path d="M96.628 109.812C97.572 112.243 98.1864 114.79 98.455 117.384C98.4659 117.694 98.4153 118.003 98.3063 118.293C98.1972 118.584 98.0318 118.85 97.8196 119.076C97.6074 119.303 97.3525 119.485 97.0696 119.613C96.7867 119.74 96.4814 119.81 96.1713 119.82C95.1753 119.917 94.1726 119.723 93.2845 119.262C92.3964 118.8 91.661 118.092 91.1673 117.221L88.6603 113.699C88.2334 112.939 88.0529 112.065 88.1438 111.198C88.2347 110.331 88.5925 109.513 89.1678 108.858C91.1775 106.31 95.7145 107.102 96.628 109.812Z" fill="#F4A28C"/>
    <path d="M89.8174 114.582L90.0102 129.939L98.4652 129.229L94.9228 118.338L89.8174 114.582Z" fill="#F4A28C"/>
    <path d="M89.1373 117.353C89.1628 117.448 89.2201 117.531 89.2996 117.588C89.3791 117.645 89.4759 117.674 89.5738 117.668C90.2537 117.658 90.9119 117.427 91.4486 117.009C91.9853 116.592 92.3711 116.01 92.5477 115.354C92.7581 114.632 92.755 113.864 92.5389 113.144C92.3227 112.423 91.9028 111.781 91.3297 111.294C92.7746 111.155 94.1984 110.848 95.5724 110.38C96.0363 110.225 96.4717 109.996 96.8615 109.7L97.0137 105.985C97.0137 105.985 97.8663 102.067 95.6637 101.925C93.4612 101.783 93.1872 104.321 91.1064 103.143C89.0257 101.966 88.4674 101.468 87.0464 102.849C85.6254 104.229 87.9091 104.991 85.1788 105.772C82.4484 106.554 82.7022 107.69 83.0371 108.939C83.2121 109.356 83.5094 109.71 83.8898 109.954C86.3908 111.79 88.2314 114.386 89.1373 117.353Z" fill="#24285B"/>
    <path d="M92.9943 115.08C92.9943 115.08 92.4462 113.354 91.1876 113.872C89.929 114.389 90.7004 116.917 92.5376 116.592L92.9943 115.08Z" fill="#F4A28C"/>
    <path d="M97.572 112.623L99.3279 113.983C99.4273 114.06 99.5057 114.161 99.5559 114.277C99.6062 114.392 99.6267 114.518 99.6156 114.643C99.6045 114.768 99.5621 114.889 99.4923 114.994C99.4226 115.098 99.3276 115.184 99.2163 115.242L97.5923 116.074L97.572 112.623Z" fill="#F4A28C"/>
    <path opacity="0.31" d="M95.41 119.799C94.1991 119.727 93.026 119.35 91.9996 118.703C91.9996 118.703 92.5274 121.748 96.4758 122.997L95.41 119.799Z" fill="#CE8172"/>
    <path d="M105.976 128.457C92.0305 128.705 78.2646 131.649 65.437 137.125C63.4704 137.957 61.6951 139.183 60.2209 140.728C58.7467 142.272 57.6047 144.103 56.8657 146.106C56.1266 148.109 55.806 150.243 55.9238 152.375C56.0416 154.506 56.5952 156.592 57.5505 158.501L79.6064 202.502L120.491 200.076C120.491 200.076 127.941 175.35 126.449 147.387C126.148 142.196 123.847 137.324 120.029 133.794C116.212 130.264 111.175 128.351 105.976 128.457Z" fill="#FF7700"/>
    <path d="M79.6065 202.502L67.4265 269.776H73.5165L88.8328 233.236C94.2568 220.3 103.821 209.529 116.025 202.613L120.511 200.066L79.6065 202.502Z" fill="#24285B"/>
    <path opacity="0.08" d="M76.2265 142.972C79.1234 145.333 81.362 148.402 82.7258 151.882C84.0896 155.362 84.5325 159.134 84.0116 162.835C82.144 175.574 80.185 185.48 91.959 182.506C103.733 179.532 110.056 152.229 110.056 152.229L123.759 152.564L121.394 183.014L113.447 183.562C111.566 183.69 109.78 184.433 108.364 185.677C106.948 186.92 105.981 188.595 105.611 190.443L103.469 201.07L79.6065 202.481L76.2265 197.406L61.3365 181.329L59.479 155.609L76.2265 142.972Z" fill="black"/>
    <path d="M92.4607 178.194L119.013 180.053L121.559 143.686L95.0064 141.827L92.4607 178.194Z" fill="#FFD200"/>
    <path d="M120.491 200.076L114.188 269.776H106.068C106.068 269.776 107.966 217.331 93.309 206.166L120.491 200.076Z" fill="#24285B"/>
    <path d="M77.14 146.91C77.0836 144.858 76.3942 142.873 75.1662 141.228C73.9382 139.583 72.2317 138.357 70.2803 137.719C68.3289 137.081 66.2281 137.062 64.2654 137.665C62.3028 138.267 60.5743 139.461 59.3166 141.084C54.6273 147.072 50.3034 157.06 50.6587 173.462C51.4199 208.48 71.9737 217.869 103.692 179.552L97.44 174.731C97.44 174.731 84.9555 185.328 73.5165 185.48C64.96 185.602 77.7186 163.617 77.14 146.91Z" fill="#FF7700"/>
    <path opacity="0.39" d="M77.14 146.91C77.0836 144.858 76.3942 142.873 75.1662 141.228C73.9382 139.583 72.2317 138.357 70.2803 137.719C68.3289 137.081 66.2281 137.062 64.2654 137.665C62.3028 138.267 60.5743 139.461 59.3166 141.084C54.6273 147.072 50.3034 157.06 50.6587 173.462C51.4199 208.48 71.9737 217.869 103.692 179.552L97.44 174.731C97.44 174.731 84.9555 185.328 73.5165 185.48C64.96 185.602 77.7186 163.617 77.14 146.91Z" fill="white"/>
    <path opacity="0.08" d="M120.257 160.866C120.257 160.866 111.122 177.644 92.8522 172.904L92.4564 178.781L118.948 180.578L120.257 160.866Z" fill="black"/>
    <path d="M98.5971 175.614C98.5971 175.614 102.332 169.017 106.9 170.763C111.467 172.508 103.692 179.552 103.692 179.552L98.5971 175.614Z" fill="#F4A28C"/>
    <path opacity="0.58" d="M139.745 266.183C139.745 266.183 131.097 263.818 129.22 255.779C129.22 255.779 142.608 253.069 142.993 266.893L139.745 266.183Z" fill="#FF7700"/>
    <path opacity="0.73" d="M140.801 265.32C140.801 265.32 134.762 255.779 140.08 246.847C140.08 246.847 150.23 253.323 145.734 265.34L140.801 265.32Z" fill="#FF7700"/>
    <path d="M142.354 265.33C142.354 265.33 145.551 255.251 155.194 253.343C155.194 253.343 157 259.89 148.951 265.361L142.354 265.33Z" fill="#FF7700"/>
    <path d="M136.091 265.107L137.837 277.094L148.88 277.134L150.504 265.168L136.091 265.107Z" fill="#24285B"/>
    <path d="M381.777 200.092H406.411V232.41H381.777V281H346.521V232.41H279.625V182.916L330.475 122.8H381.777V200.092ZM306.067 195.572V200.092H346.521V151.728H342.001L306.067 195.572Z" fill="#E3E3E3"/>
    <path d="M460.977 284.39C446.815 284.39 434.912 281.075 425.269 274.446C415.627 267.666 408.47 257.797 403.799 244.84C399.581 232.636 397.471 218.323 397.471 201.9C397.471 184.875 399.581 170.561 403.799 158.96C408.621 145.852 415.777 135.983 425.269 129.354C434.912 122.725 446.815 119.41 460.977 119.41C475.14 119.41 486.967 122.8 496.459 129.58C506.102 136.209 513.259 146.003 517.929 158.96C522.148 170.561 524.257 184.875 524.257 201.9C524.257 218.323 522.148 232.636 517.929 244.84C513.259 257.797 506.102 267.666 496.459 274.446C486.967 281.075 475.14 284.39 460.977 284.39ZM460.977 252.298C471.524 252.298 478.907 246.497 483.125 234.896C485.837 227.363 487.193 216.364 487.193 201.9C487.193 187.436 485.837 176.437 483.125 168.904C479.057 157.303 471.675 151.502 460.977 151.502C450.129 151.502 442.671 157.303 438.603 168.904C435.891 177.04 434.535 188.039 434.535 201.9C434.535 216.364 435.891 227.363 438.603 234.896C442.822 246.497 450.28 252.298 460.977 252.298Z" fill="#E3E3E3"/>
    <path d="M574.323 284.39C556.695 284.39 542.759 279.87 532.513 270.83C522.419 261.639 517.371 249.059 517.371 233.088V231.506H553.079V232.636C553.079 239.567 554.887 244.765 558.503 248.23C562.27 251.695 567.543 253.428 574.323 253.428C580.501 253.428 585.397 251.62 589.013 248.004C592.177 244.84 593.759 240.847 593.759 236.026C593.759 230.903 592.328 226.911 589.465 224.048C585.548 220.131 579.898 218.172 572.515 218.172H562.345V185.402H572.515C579.898 185.402 585.548 183.443 589.465 179.526C592.328 176.663 593.759 172.746 593.759 167.774C593.759 162.953 592.177 158.96 589.013 155.796C585.397 152.18 580.501 150.372 574.323 150.372C567.694 150.372 562.496 152.18 558.729 155.796C554.963 159.412 553.079 164.459 553.079 170.938V172.294H517.371V170.486C517.371 154.666 522.419 142.236 532.513 133.196C542.759 124.005 556.695 119.41 574.323 119.41C583.665 119.41 591.951 120.691 599.183 123.252C606.566 125.813 612.743 129.58 617.715 134.552C625.55 142.085 629.467 151.879 629.467 163.932C629.467 173.575 626.303 181.711 619.975 188.34C613.949 194.367 605.813 198.058 595.567 199.414V204.16C605.511 205.215 613.647 208.906 619.975 215.234C626.303 221.863 629.467 230.075 629.467 239.868C629.467 251.921 625.55 261.715 617.715 269.248C612.743 274.22 606.566 277.987 599.183 280.548C591.951 283.109 583.665 284.39 574.323 284.39Z" fill="#E3E3E3"/>
</svg>)

export const NotFoundIcon = () => (<svg width="641" height="306" viewBox="0 0 641 306" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M324.709 57.2753H93.5424V216.691H324.709V57.2753Z" fill="#E6E6E6"/>
    <path d="M324.709 90.4658H93.5424V216.691H324.709V90.4658Z" fill="white"/>
    <path opacity="0.08" d="M96.425 103.996C96.425 103.996 101.216 100.768 103.459 112.623C105.702 124.478 99.1249 134.547 114.878 136.983C130.631 139.419 140.943 132.761 144.577 151.68C148.21 170.6 168.673 179.157 161.679 196.259C154.686 213.362 148.85 216.691 148.85 216.691H93.5424C93.5424 216.691 93.1263 110.735 96.425 103.996Z" fill="black"/>
    <path opacity="0.45" d="M102.018 282.301C143.399 282.301 176.945 280.129 176.945 277.449C176.945 274.77 143.399 272.597 102.018 272.597C60.6364 272.597 27.0903 274.77 27.0903 277.449C27.0903 280.129 60.6364 282.301 102.018 282.301Z" fill="#E6E6E6"/>
    <path d="M112.188 76.3472C114.643 76.3472 116.634 74.3568 116.634 71.9015C116.634 69.4462 114.643 67.4558 112.188 67.4558C109.733 67.4558 107.742 69.4462 107.742 71.9015C107.742 74.3568 109.733 76.3472 112.188 76.3472Z" fill="#24285B"/>
    <path d="M125.413 76.3472C127.869 76.3472 129.859 74.3568 129.859 71.9015C129.859 69.4462 127.869 67.4558 125.413 67.4558C122.958 67.4558 120.968 69.4462 120.968 71.9015C120.968 74.3568 122.958 76.3472 125.413 76.3472Z" fill="#FF7700"/>
    <path d="M137.878 76.3472C140.333 76.3472 142.323 74.3568 142.323 71.9015C142.323 69.4462 140.333 67.4558 137.878 67.4558C135.422 67.4558 133.432 69.4462 133.432 71.9015C133.432 74.3568 135.422 76.3472 137.878 76.3472Z" fill="#FFD200"/>
    <path d="M68.0659 269.146C68.0659 269.146 66.8175 272.618 63.6811 272.882C60.5448 273.146 59.7328 276.211 62.5443 277.003C65.3559 277.794 72.6334 274.181 72.6334 274.181L72.4203 269.664L68.0659 269.146Z" fill="#FF7700"/>
    <path d="M111.102 269.146C111.102 269.146 112.34 272.618 115.477 272.882C118.613 273.146 119.425 276.211 116.613 277.003C113.802 277.794 106.534 274.181 106.534 274.181L106.737 269.664L111.102 269.146Z" fill="#FF7700"/>
    <path d="M156.452 139.805C156.216 139.569 156.028 139.289 155.9 138.981C155.772 138.673 155.707 138.342 155.707 138.008C155.707 137.675 155.772 137.344 155.9 137.036C156.028 136.728 156.216 136.448 156.452 136.212L171.586 121.088C172.069 120.656 172.7 120.426 173.348 120.444C173.996 120.462 174.613 120.727 175.071 121.186C175.53 121.644 175.795 122.261 175.813 122.909C175.832 123.557 175.601 124.188 175.169 124.671L160.035 139.805C159.559 140.279 158.915 140.545 158.244 140.545C157.572 140.545 156.928 140.279 156.452 139.805Z" fill="#E5E5E5"/>
    <path d="M171.829 140.049L156.696 124.915C156.454 124.681 156.261 124.4 156.128 124.091C155.996 123.781 155.926 123.448 155.924 123.111C155.921 122.774 155.986 122.44 156.114 122.128C156.242 121.816 156.431 121.533 156.669 121.296C156.908 121.058 157.192 120.87 157.504 120.742C157.816 120.615 158.15 120.552 158.487 120.555C158.824 120.558 159.157 120.629 159.466 120.762C159.775 120.896 160.055 121.089 160.289 121.332L175.412 136.456C175.655 136.689 175.849 136.969 175.982 137.278C176.115 137.588 176.186 137.921 176.189 138.258C176.193 138.594 176.129 138.929 176.002 139.241C175.875 139.553 175.687 139.836 175.449 140.075C175.211 140.313 174.928 140.502 174.616 140.63C174.304 140.758 173.97 140.823 173.634 140.821C173.297 140.818 172.964 140.749 172.654 140.616C172.344 140.484 172.064 140.291 171.829 140.049Z" fill="#E5E5E5"/>
    <path d="M240.372 139.805C240.136 139.569 239.949 139.289 239.821 138.981C239.693 138.673 239.627 138.342 239.627 138.008C239.627 137.675 239.693 137.344 239.821 137.036C239.949 136.728 240.136 136.448 240.372 136.212L255.506 121.088C255.736 120.831 256.016 120.623 256.329 120.478C256.642 120.333 256.982 120.253 257.327 120.243C257.672 120.233 258.015 120.294 258.336 120.422C258.657 120.549 258.948 120.741 259.192 120.985C259.436 121.229 259.628 121.521 259.755 121.841C259.883 122.162 259.944 122.505 259.934 122.85C259.925 123.196 259.845 123.535 259.699 123.848C259.554 124.161 259.346 124.441 259.089 124.671L243.965 139.805C243.487 140.277 242.841 140.542 242.169 140.542C241.496 140.542 240.851 140.277 240.372 139.805Z" fill="#E5E5E5"/>
    <path d="M255.78 140.049L240.646 124.915C240.404 124.681 240.211 124.4 240.079 124.091C239.946 123.781 239.877 123.448 239.874 123.111C239.872 122.774 239.937 122.44 240.065 122.128C240.193 121.816 240.382 121.533 240.62 121.296C240.859 121.058 241.142 120.87 241.454 120.742C241.766 120.615 242.101 120.552 242.437 120.555C242.774 120.558 243.107 120.629 243.417 120.762C243.726 120.896 244.006 121.089 244.239 121.332L259.363 136.456C259.606 136.689 259.799 136.969 259.933 137.278C260.066 137.588 260.137 137.921 260.14 138.258C260.143 138.594 260.08 138.929 259.953 139.241C259.825 139.553 259.637 139.836 259.399 140.075C259.162 140.313 258.879 140.502 258.567 140.63C258.255 140.758 257.921 140.823 257.584 140.821C257.247 140.818 256.914 140.749 256.604 140.616C256.295 140.484 256.014 140.291 255.78 140.049Z" fill="#E5E5E5"/>
    <path d="M115.111 130.457C115.111 130.457 137.441 134.974 135.604 155.832C133.767 176.69 154.067 188.089 141.623 200.126C129.179 212.164 112.594 205.394 112.594 205.394L115.111 130.457Z" fill="#FF7700"/>
    <path opacity="0.39" d="M115.111 130.457C115.111 130.457 137.441 134.974 135.604 155.832C133.767 176.69 154.067 188.089 141.623 200.126C129.179 212.164 112.594 205.394 112.594 205.394L115.111 130.457Z" fill="white"/>
    <path d="M96.628 109.812C97.572 112.243 98.1864 114.79 98.455 117.384C98.4659 117.694 98.4153 118.003 98.3063 118.293C98.1972 118.584 98.0318 118.85 97.8196 119.076C97.6074 119.303 97.3525 119.485 97.0696 119.613C96.7867 119.74 96.4814 119.81 96.1713 119.82C95.1753 119.917 94.1726 119.723 93.2845 119.262C92.3964 118.8 91.661 118.092 91.1673 117.221L88.6603 113.699C88.2334 112.939 88.0529 112.065 88.1438 111.198C88.2347 110.331 88.5925 109.513 89.1678 108.858C91.1775 106.31 95.7145 107.102 96.628 109.812Z" fill="#F4A28C"/>
    <path d="M89.8174 114.582L90.0102 129.939L98.4652 129.229L94.9228 118.338L89.8174 114.582Z" fill="#F4A28C"/>
    <path d="M89.1374 117.353C89.1629 117.448 89.2202 117.531 89.2997 117.588C89.3792 117.645 89.476 117.674 89.5738 117.668C90.2538 117.658 90.912 117.427 91.4487 117.009C91.9853 116.592 92.3712 116.01 92.5478 115.354C92.7581 114.632 92.755 113.864 92.5389 113.144C92.3228 112.423 91.9029 111.781 91.3298 111.294C92.7747 111.155 94.1985 110.848 95.5725 110.38C96.0363 110.225 96.4718 109.996 96.8615 109.7L97.0138 105.985C97.0138 105.985 97.8664 102.067 95.6638 101.925C93.4613 101.783 93.1872 104.321 91.1065 103.143C89.0257 101.966 88.4675 101.468 87.0465 102.849C85.6255 104.229 87.9092 104.991 85.1789 105.772C82.4485 106.554 82.7023 107.69 83.0372 108.939C83.2121 109.356 83.5094 109.71 83.8898 109.954C86.3908 111.79 88.2315 114.386 89.1374 117.353Z" fill="#24285B"/>
    <path d="M92.9942 115.08C92.9942 115.08 92.4461 113.354 91.1875 113.872C89.9289 114.389 90.7003 116.917 92.5375 116.592L92.9942 115.08Z" fill="#F4A28C"/>
    <path d="M97.572 112.623L99.3279 113.983C99.4273 114.06 99.5057 114.161 99.5559 114.277C99.6062 114.392 99.6267 114.518 99.6156 114.643C99.6045 114.768 99.5621 114.889 99.4923 114.994C99.4226 115.098 99.3276 115.184 99.2163 115.242L97.5923 116.074L97.572 112.623Z" fill="#F4A28C"/>
    <path opacity="0.31" d="M95.41 119.799C94.1991 119.727 93.026 119.35 91.9996 118.703C91.9996 118.703 92.5274 121.748 96.4758 122.997L95.41 119.799Z" fill="#CE8172"/>
    <path d="M105.976 128.457C92.0305 128.705 78.2646 131.649 65.437 137.125C63.4704 137.957 61.6951 139.183 60.2209 140.728C58.7467 142.272 57.6047 144.103 56.8657 146.106C56.1266 148.109 55.806 150.243 55.9238 152.375C56.0416 154.506 56.5952 156.592 57.5505 158.501L79.6064 202.502L120.491 200.076C120.491 200.076 127.941 175.35 126.449 147.387C126.148 142.196 123.847 137.324 120.029 133.794C116.212 130.264 111.175 128.351 105.976 128.457Z" fill="#FF7700"/>
    <path d="M79.6065 202.502L67.4265 269.776H73.5165L88.8329 233.236C94.2569 220.3 103.821 209.529 116.025 202.613L120.511 200.066L79.6065 202.502Z" fill="#24285B"/>
    <path opacity="0.08" d="M76.2265 142.972C79.1234 145.333 81.362 148.402 82.7258 151.882C84.0896 155.362 84.5325 159.134 84.0116 162.835C82.144 175.574 80.185 185.48 91.959 182.506C103.733 179.532 110.056 152.229 110.056 152.229L123.759 152.564L121.394 183.014L113.447 183.562C111.566 183.69 109.78 184.433 108.364 185.677C106.948 186.92 105.981 188.595 105.611 190.443L103.469 201.07L79.6065 202.481L76.2265 197.406L61.3365 181.329L59.479 155.609L76.2265 142.972Z" fill="black"/>
    <path d="M92.4607 178.194L119.013 180.053L121.559 143.686L95.0064 141.827L92.4607 178.194Z" fill="#FFD200"/>
    <path d="M120.491 200.076L114.188 269.776H106.068C106.068 269.776 107.966 217.331 93.309 206.166L120.491 200.076Z" fill="#24285B"/>
    <path d="M77.14 146.91C77.0836 144.858 76.3942 142.873 75.1662 141.228C73.9382 139.583 72.2317 138.357 70.2803 137.719C68.3289 137.081 66.2281 137.062 64.2654 137.665C62.3028 138.267 60.5743 139.461 59.3166 141.084C54.6273 147.072 50.3034 157.06 50.6587 173.462C51.4199 208.48 71.9737 217.869 103.692 179.552L97.44 174.731C97.44 174.731 84.9555 185.328 73.5165 185.48C64.96 185.602 77.7186 163.617 77.14 146.91Z" fill="#FF7700"/>
    <path opacity="0.39" d="M77.14 146.91C77.0836 144.858 76.3942 142.873 75.1662 141.228C73.9382 139.583 72.2317 138.357 70.2803 137.719C68.3289 137.081 66.2281 137.062 64.2654 137.665C62.3028 138.267 60.5743 139.461 59.3166 141.084C54.6273 147.072 50.3034 157.06 50.6587 173.462C51.4199 208.48 71.9737 217.869 103.692 179.552L97.44 174.731C97.44 174.731 84.9555 185.328 73.5165 185.48C64.96 185.602 77.7186 163.617 77.14 146.91Z" fill="white"/>
    <path opacity="0.08" d="M120.257 160.866C120.257 160.866 111.122 177.644 92.8522 172.904L92.4563 178.781L118.948 180.578L120.257 160.866Z" fill="black"/>
    <path d="M98.5971 175.614C98.5971 175.614 102.332 169.017 106.9 170.763C111.467 172.508 103.692 179.552 103.692 179.552L98.5971 175.614Z" fill="#F4A28C"/>
    <path opacity="0.58" d="M139.745 266.183C139.745 266.183 131.097 263.818 129.22 255.779C129.22 255.779 142.608 253.069 142.993 266.893L139.745 266.183Z" fill="#FF7700"/>
    <path opacity="0.73" d="M140.801 265.32C140.801 265.32 134.762 255.779 140.08 246.847C140.08 246.847 150.23 253.323 145.734 265.34L140.801 265.32Z" fill="#FF7700"/>
    <path d="M142.354 265.33C142.354 265.33 145.551 255.251 155.194 253.343C155.194 253.343 157 259.89 148.951 265.361L142.354 265.33Z" fill="#FF7700"/>
    <path d="M136.091 265.107L137.837 277.094L148.88 277.134L150.504 265.168L136.091 265.107Z" fill="#24285B"/>
    <rect x="186" y="165" width="47" height="8" rx="4" fill="#E5E5E5"/>
    <path d="M376.149 200.092H400.783V232.41H376.149V281H340.893V232.41H273.997V182.916L324.847 122.8H376.149V200.092ZM300.439 195.572V200.092H340.893V151.728H336.373L300.439 195.572Z" fill="#E3E3E3"/>
    <path d="M455.349 284.39C441.187 284.39 429.284 281.075 419.641 274.446C409.999 267.666 402.842 257.797 398.171 244.84C393.953 232.636 391.843 218.323 391.843 201.9C391.843 184.875 393.953 170.561 398.171 158.96C402.993 145.852 410.149 135.983 419.641 129.354C429.284 122.725 441.187 119.41 455.349 119.41C469.512 119.41 481.339 122.8 490.831 129.58C500.474 136.209 507.631 146.003 512.301 158.96C516.52 170.561 518.629 184.875 518.629 201.9C518.629 218.323 516.52 232.636 512.301 244.84C507.631 257.797 500.474 267.666 490.831 274.446C481.339 281.075 469.512 284.39 455.349 284.39ZM455.349 252.298C465.896 252.298 473.279 246.497 477.497 234.896C480.209 227.363 481.565 216.364 481.565 201.9C481.565 187.436 480.209 176.437 477.497 168.904C473.429 157.303 466.047 151.502 455.349 151.502C444.501 151.502 437.043 157.303 432.975 168.904C430.263 177.04 428.907 188.039 428.907 201.9C428.907 216.364 430.263 227.363 432.975 234.896C437.194 246.497 444.652 252.298 455.349 252.298Z" fill="#E3E3E3"/>
    <path d="M615.93 200.092H640.564V232.41H615.93V281H580.674V232.41H513.778V182.916L564.628 122.8H615.93V200.092ZM540.22 195.572V200.092H580.674V151.728H576.154L540.22 195.572Z" fill="#E3E3E3"/>
</svg>)