import { useCallback, useMemo, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Tab from '../../components/Tab'
import Tabs from '../../components/Tabs'
import TableauRenderer from '../../components/TableauRenderer'
import ProgramContext from '../../contexts/ProgramContext'
import SettingsContext from '../../contexts/SettingsContext'
import { AnalyticsMenuItem } from '../../interfaces/Setting'

function Analytics() {
    const { currentProgram } = useContext(ProgramContext)
    const { settings } = useContext(SettingsContext)
    const { boardId } = useParams()
    let analyticsMenu: AnalyticsMenuItem[] | undefined = settings?.analyticsMenu
    const hubName = currentProgram ? currentProgram!['name'] : ''
    const { t } = useTranslation('common')

    const navigate = useNavigate()
    const location = useLocation()

    const { searchParams, currentTab } = useMemo(() => {
        const searchParams = new URLSearchParams(location.search)
        const currentTab = parseInt(searchParams.get('tab') || '') || 0
        return {
            searchParams,
            currentTab,
        }
    }, [location.search])

    const currentBoard = useMemo(() => {
        const board = analyticsMenu?.find((item: AnalyticsMenuItem) => item.menuTranslationKey === boardId)
        if (board && board.tabs) board.tabs = board.tabs.sort((a, b) => parseInt(a.order) - parseInt(b.order))

        return board
    }, [analyticsMenu, boardId])

    const setTab = useCallback(
        (tabName: string) => {
            if (tabName === 'overview') {
                searchParams.delete('tab')
            } else {
                searchParams.set('tab', tabName)
            }
            navigate(`${location.pathname}?${searchParams.toString()}`)
        },
        [location.pathname, searchParams, navigate]
    )

    return currentBoard ? (
        <div className="p-1 pl-4 h-full flex flex-col">
            <h3 className="text-3xl text-black text-left md:spl-8">{t(currentBoard?.menuTranslationKey)}</h3>
            {currentBoard.tabs.length > 1 && (
                <Tabs className="my-4 md:my-10" current={`${currentTab}`} setCurrent={setTab}>
                    {currentBoard.tabs.map((tab: any, index: Number) => {
                        return (
                            <Tab name={`${index}`} key={tab.translationKey} data-cy={tab.translationKey}>
                                {t(`${tab.translationKey}`)}
                            </Tab>
                        )
                    })}
                </Tabs>
            )}
            <TableauRenderer
                url={currentBoard.tabs[currentTab].url}
                field={currentBoard.tabs[currentTab].filterFieldName}
                value={hubName}
            />
        </div>
    ) : (
        <></>
    )
}

export default Analytics
