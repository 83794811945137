import { useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import RestTable from '../../../../../../components/Table'
import { useParams } from 'react-router-dom'

const url = '/backoffice/v3/shopper-profile/purchase-history'

function PurchaseHistoryTable() {
    const { customerId } = useParams<{ customerId: string }>()
    const { t } = useTranslation('common')

    const tableQueryUrl = useMemo(() => `${url}?customerId=${customerId}`, [customerId])

    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.purchaseHistory.date')}
                </div>
            ),
            render: (s) => moment(s.transaction_date).format('MM/DD/YYYY'),
            defaultSortOrder: 'descend',
            key: 'transaction_date',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.purchaseHistory.amount')}
                </div>
            ),
            render: (s) => -s.amount + ' ' + t('shopperProfile.currency'),
            key: 'amount',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: (
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('shopperProfile.purchaseHistory.store')}
                </div>
            ),
            render: (s) => s.store,
            key: 'store',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
    ]

    return (
        <div className="col-span-3 2xl:pl-10 xl:pl-8 pl-6 2xl:pr-10 xl:pr-8 pr-6 pt-8 bg-white">
            <div className="w-full text-xl font-black mb-6">{t('shopperProfile.purchaseHistory.title')}</div>

            <RestTable
                queryBaseUrl={tableQueryUrl}
                columns={columns}
                pageSize={7}
                errorMessageKey="errors.shopperProfile.purchaseHistory"
                tableLayout="fixed"
            />
        </div>
    )
}

export default PurchaseHistoryTable
