import PropTypes from 'prop-types'

function Link({ children, url, className, onClick, target }) {
    return (
        <a
            onClick={(e) => onClick()}
            href={url}
            target={target}
            className={['text-primary cursor-pointer', className].join(' ')}
        >
            {children}
        </a>
    )
}

Link.propTypes = {
    children: PropTypes.any,
    url: PropTypes.string,
    className: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func,
}

Link.defaultProps = {
    children: null,
    url: '#',
    className: '',
    target: '',
    onClick: () => {},
}

export default Link
