import React from 'react'

function IPhoneBatteryIcon() {
    return (
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.36"
                d="M2.36253 0.118332H8.90288C9.45366 0.118332 9.65339 0.17568 9.85474 0.283367C10.0561 0.391053 10.2141 0.54908 10.3218 0.750437C10.4295 0.951794 10.4869 1.15152 10.4869 1.7023V3.60915C10.4869 4.15993 10.4295 4.35966 10.3218 4.56101C10.2141 4.76237 10.0561 4.9204 9.85474 5.02808C9.65339 5.13577 9.45366 5.19312 8.90288 5.19312H2.36253C1.81175 5.19312 1.61203 5.13577 1.41067 5.02808C1.20931 4.9204 1.05129 4.76237 0.943599 4.56101C0.835912 4.35966 0.778564 4.15993 0.778564 3.60915V1.7023C0.778564 1.15152 0.835912 0.951794 0.943599 0.750437C1.05129 0.54908 1.20931 0.391053 1.41067 0.283367C1.61203 0.17568 1.81175 0.118332 2.36253 0.118332ZM2.36221 0.558625C1.92667 0.558625 1.77462 0.587987 1.61845 0.671506C1.494 0.738065 1.39897 0.833099 1.33241 0.957554C1.24889 1.11372 1.21953 1.26577 1.21953 1.70131V3.60816C1.21953 4.0437 1.24889 4.19574 1.33241 4.35191C1.39897 4.47637 1.494 4.5714 1.61845 4.63796C1.77462 4.72148 1.92667 4.75084 2.36221 4.75084H8.90256C9.3381 4.75084 9.49015 4.72148 9.64631 4.63796C9.77077 4.5714 9.8658 4.47637 9.93236 4.35191C10.0159 4.19574 10.0452 4.0437 10.0452 3.60816V1.70131C10.0452 1.26577 10.0159 1.11372 9.93236 0.957554C9.8658 0.833099 9.77077 0.738065 9.64631 0.671506C9.49015 0.587987 9.3381 0.558625 8.90256 0.558625H2.36221ZM11.5898 2.62924C11.5898 3.17493 10.9279 3.51182 10.9279 3.51182V1.74667C10.9279 1.74667 11.5898 2.08356 11.5898 2.62924Z"
                fill="black"
            />
            <rect x="1.66089" y="0.964127" width="7.94314" height="3.38319" rx="1.6" fill="black" />
        </svg>
    )
}

export default IPhoneBatteryIcon
