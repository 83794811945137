import PropTypes from 'prop-types'

function Label({ children, inputId = '', className = '' }) {
    return (
        <label htmlFor={inputId} className={['text-black text-base', className].join(' ')}>
            {children}
        </label>
    )
}

Label.propTypes = {
    className: PropTypes.string,
    inputId: PropTypes.string,
}

export default Label
