import Input from '../../../components/Input'
import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import Label from '../../../components/Label'
import Exclamation from '../../../components/Exclamation'
import ColorPicker from '../../../components/ColorPicker'
import Switch from '../../../components/Switch'
import FileInput, { FileInputProps } from '../../../components/FileInput'
import InputValidation from '../../../components/InputValidation'
import Link from '../../../components/Link'
import {
    checkCharactersForDomainName,
    checkSpecialCharacters,
    fileReaderReadAsDataURLAsync,
    getFileNameFromPath,
} from '../../../helpers'
import { ClipboardCopyIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import InfoTooltip from '../../../components/InfoTooltip'
import { ShoppingHubStatusEnum } from '../../../utils/ShoppingHubUtils'
import { withSettings } from '../../../contexts/SettingsContext'
import { Setting } from '../../../interfaces/Setting'
import { withAuth } from 'src/contexts/AuthContext'

type ProgramShoppingHubStyle = {
    colorPrimary?: string
    colorSecondary?: string
    colorBackground?: string
    colorComponent?: string
    fontColor?: string
    fontColorOverPrimary?: string
}

type Program = {
    status: ShoppingHubStatusEnum
    name?: string
    programName?: string
    baseline?: string
    contactEmail?: string
    supportContactEmail?: string
    host?: string
    bankTransferLabel?: string
    smsSenderName?: string
    emailSenderName?: string
    logoProgramUrl?: string
    logoUrl?: string
    faviconUrl?: string
    hasExternalFront?: boolean
    hasLoyaltyCard?: boolean
    ShoppingHubStyle?: ProgramShoppingHubStyle
}

type ProgramData = Partial<
    Omit<Program, 'logoProgramUrl' | 'logoUrl' | 'faviconUrl' | 'ShoppingHubStyle'> & {
        logo_program_url?: string
        logoProgramUrlFileName?: string
        logo_client_url?: string
        logoClientUrlFileName?: string
        favicon_url?: string
        faviconUrlFileName?: string
        shoppingHubStyle?: ProgramShoppingHubStyle
        smsSenderName?: string
    }
>

type FrontProps = {
    editing: boolean
    program: Program
    settings: Setting
    user?: { shoppingHubOwnerId: string }
    inputRef: ForwardedRef<unknown>
    onChange: (hasError: boolean) => void
}

type ValidationError = {
    key: string
    error?: Error
}

const Front = withAuth(
    withSettings(({ editing, program, settings, user, inputRef, onChange }: FrontProps) => {
        const { t } = useTranslation('common')
        const [organisationName, setOrganisationName] = useState(program.name)
        const [programName, setProgramName] = useState(program.programName)
        const [programLogo, setProgramLogo] = useState<File | null | undefined>(null)
        const [organisationLogo, setOrganisationLogo] = useState<File | null | undefined>(null)
        const [favicon, setFavicon] = useState<File | null | undefined>(null)
        const [contactEmail, setContactEmail] = useState(program.contactEmail)
        const [supportContactEmail, setSupportContactEmail] = useState(program.supportContactEmail)
        const [host, setHost] = useState(program?.host || '')
        const [labelVirement, setLabelVirement] = useState(program.bankTransferLabel)
        const [componentColor, setComponentColor] = useState(program.ShoppingHubStyle?.colorComponent || '#F8F8F8')
        const [primaryColor, setPrimaryColor] = useState(program.ShoppingHubStyle?.colorPrimary || '#FF7700')
        const [backgroundColor, setBackgroundColor] = useState(program.ShoppingHubStyle?.colorBackground || '#FFFFFF')
        const [fontColor, setFontColor] = useState(program.ShoppingHubStyle?.fontColor || '#000000')
        const [fontColorOverPrimary, setFontColorOverPrimary] = useState(
            program.ShoppingHubStyle?.fontColorOverPrimary || '#000000'
        )
        const [hasLoyaltyCard, setHasLoyaltyCard] = useState(program.hasLoyaltyCard || false)
        const [smsSenderName, setSmsSenderName] = useState(program.smsSenderName || '')
        const [smsSenderNameError, setSmsSenderNameError] = useState<Error>()
        const isInternal = useMemo(() => user && !user.shoppingHubOwnerId, [user])
        const [itemsSmsSenderName] = useState([
            { label: t('program.config.front.smsSenderName.hint'), completed: false },
        ])

        const disableEditingProgramUrl =
            program.hasExternalFront === true ||
            program.status === ShoppingHubStatusEnum.LIVE ||
            program.status === ShoppingHubStatusEnum.TERMINATED ||
            !editing

        const programIsNotLiveYet = useMemo(
            () => [ShoppingHubStatusEnum.PENDING, ShoppingHubStatusEnum.VALIDATED].includes(program.status),
            [program.status]
        )

        const isLegacyProgram = !program?.host?.endsWith(settings?.b2cFrontEnd?.domainSuffix)
        const hostWithoutDomainSuffix = useMemo(
            () => (isLegacyProgram ? host : host.replace('.' + settings?.b2cFrontEnd?.domainSuffix, '')),
            [isLegacyProgram, settings?.b2cFrontEnd?.domainSuffix, host]
        )

        useEffect(() => {
            const error = !/^[0-9a-zA-Z]*$/.test(smsSenderName) ? new Error(t('invalid_character')) : undefined
            setSmsSenderNameError(error)
            onChange(!!error)
        }, [onChange, smsSenderName, t])

        const errors = useMemo(() => {
            const errors: ValidationError[] = []

            if (!program.hasExternalFront && !host && editing) {
                errors.push({
                    key: 'hostError',
                    error: new Error('Mandatory'),
                })
            } else if (!program.hasExternalFront && !isLegacyProgram) {
                try {
                    checkCharactersForDomainName(hostWithoutDomainSuffix, t('invalid_character'))
                } catch (error) {
                    if (error instanceof Error) {
                        errors.push({
                            key: 'hostError',
                            error,
                        })
                    }
                }
            }

            return errors
        }, [host, program.hasExternalFront, editing])

        const hostError = errors.find((n) => n.key === 'hostError')?.error

        useImperativeHandle(inputRef, () => ({
            validate,
        }))

        const validate = useCallback(async () => {
            const data: ProgramData = {
                bankTransferLabel: checkSpecialCharacters(labelVirement as string),
                shoppingHubStyle: {
                    colorPrimary: primaryColor,
                    colorSecondary: backgroundColor,
                    colorBackground: backgroundColor,
                    colorComponent: componentColor,
                    fontColor,
                    fontColorOverPrimary,
                },
                hasLoyaltyCard,
                smsSenderName,
            }

            if (isInternal) {
                data.contactEmail = contactEmail
                data.supportContactEmail = supportContactEmail
            }

            if (!disableEditingProgramUrl) {
                data.host = host
            }

            if (programIsNotLiveYet) {
                data.name = organisationName
                data.programName = programName
            }

            if (programLogo) {
                data.logo_program_url = (await fileReaderReadAsDataURLAsync(programLogo)) as string
                data.logoProgramUrlFileName = programLogo.name
            }

            if (organisationLogo) {
                data.logo_client_url = (await fileReaderReadAsDataURLAsync(organisationLogo)) as string
                data.logoClientUrlFileName = organisationLogo.name
            }

            if (favicon) {
                data.favicon_url = (await fileReaderReadAsDataURLAsync(favicon)) as string
                data.faviconUrlFileName = favicon.name
            }

            return data
        }, [
            backgroundColor,
            componentColor,
            favicon,
            fontColor,
            fontColorOverPrimary,
            labelVirement,
            organisationLogo,
            primaryColor,
            programLogo,
            hasLoyaltyCard,
            host,
            disableEditingProgramUrl,
            programName,
            organisationName,
            programIsNotLiveYet,
            contactEmail,
            supportContactEmail,
            isInternal,
            smsSenderName,
        ])

        const onChangeProgramLogo: FileInputProps['onChange'] = useCallback((_type, file) => {
            setProgramLogo(file)
        }, [])

        const onChangeOrgLogo: FileInputProps['onChange'] = useCallback((_type, file) => {
            setOrganisationLogo(file)
        }, [])

        const onChangeFavicon: FileInputProps['onChange'] = useCallback((_type, file) => {
            setFavicon(file)
        }, [])

        const displayDownloadButton = useCallback(
            (url: string | null | undefined) => {
                return url ? (
                    <Link url={url} target="_blank" className="underline text-sm">
                        {' '}
                        {t('program.config.front.download')}
                    </Link>
                ) : null
            },
            [t]
        )

        return (
            <>
                {
                    <div className="flex flex-col gap-4 p-1 mb-12">
                        <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6 md:gap-y-10">
                            <div className="col-span-1">
                                <Label>
                                    {t('program.config.front.organisation.label')}{' '}
                                    <InfoTooltip text={t('program.config.front.organisation.info')} />
                                </Label>
                                <Input
                                    disabled={!editing || !programIsNotLiveYet}
                                    className="mt-3.5"
                                    value={organisationName || ''}
                                    onChange={setOrganisationName}
                                    placeholder={t('program.config.front.organisation.placeHolder')}
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('program.config.front.name.label')}</Label>
                                <Input
                                    disabled={!editing || !programIsNotLiveYet}
                                    onChange={setProgramName}
                                    className="mt-3.5"
                                    value={programName || ''}
                                    placeholder={t('program.config.front.name.placeHolder')}
                                />
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-between">
                                    <Label>{t('program.config.front.logo.label')}</Label>
                                    <InputValidation items={items} />
                                </div>
                                <div>
                                    <FileInput
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={onChangeProgramLogo}
                                        file={
                                            programLogo ||
                                            (program.logoProgramUrl
                                                ? new File([], getFileNameFromPath(program.logoProgramUrl))
                                                : undefined)
                                        }
                                        accept=".svg"
                                    />
                                    {displayDownloadButton(program.logoProgramUrl)}
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-between">
                                    <Label className="block lg:hidden xl:block">
                                        {t('program.configuration.front.organisation_logo')}
                                        <InfoTooltip text={t('program.config.front.organisationLogo.info')} />
                                    </Label>
                                    <Label className="hidden lg:block xl:hidden">
                                        {t('program.configuration.front.org_logo')}
                                        <InfoTooltip text={t('program.config.front.organisationLogo.info')} />
                                    </Label>
                                    <InputValidation items={items} />
                                </div>
                                <div>
                                    <FileInput
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={onChangeOrgLogo}
                                        file={
                                            organisationLogo ||
                                            (program.logoUrl
                                                ? new File([], getFileNameFromPath(program.logoUrl))
                                                : undefined)
                                        }
                                        accept=".svg"
                                    />
                                    {displayDownloadButton(program.logoUrl)}
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-between">
                                    <Label>
                                        {t('program.config.front.domain.label')}{' '}
                                        <InfoTooltip text={t('program.config.front.domain.info')} />
                                    </Label>
                                    {program.hasExternalFront === false && (
                                        <Label>
                                            <a target="_blank" href={'https://' + host} rel="noreferrer">
                                                <ExternalLinkIcon className={'h-5 text-black'} />
                                            </a>
                                        </Label>
                                    )}
                                    {program.hasExternalFront === true && (
                                        <Label>
                                            <button
                                                onClick={() => {
                                                    navigator.clipboard.writeText('https://' + host)
                                                }}
                                            >
                                                <ClipboardCopyIcon className={'h-5 text-black'} />
                                            </button>
                                        </Label>
                                    )}
                                </div>
                                <Input
                                    disabled={disableEditingProgramUrl}
                                    className="mt-3.5"
                                    value={
                                        disableEditingProgramUrl || !editing || isLegacyProgram
                                            ? host
                                            : hostWithoutDomainSuffix
                                    }
                                    onChange={(h: string) =>
                                        setHost(h + (isLegacyProgram ? '' : '.' + settings?.b2cFrontEnd?.domainSuffix))
                                    }
                                    error={errors.length && editing ? hostError : undefined}
                                />
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-between">
                                    <Label>
                                        {t('program.config.front.favicon.label')}{' '}
                                        <InfoTooltip text={t('program.config.front.favicon.info')} />
                                    </Label>
                                </div>
                                <div>
                                    <FileInput
                                        disabled={!editing}
                                        className="mt-3.5"
                                        onChange={onChangeFavicon}
                                        file={
                                            favicon ||
                                            (program.faviconUrl
                                                ? new File([], getFileNameFromPath(program.faviconUrl))
                                                : undefined)
                                        }
                                        accept=".svg"
                                    />
                                    {displayDownloadButton(program.faviconUrl)}
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-between">
                                    <Label>
                                        {t('program.config.front.bankingTransfer.label')}
                                        <InfoTooltip text={t('program.config.front.bankingTransfer.info')} />
                                    </Label>
                                    {!labelVirement && <Exclamation />}
                                </div>
                                <Input
                                    disabled={!editing}
                                    className="mt-3.5"
                                    onChange={setLabelVirement}
                                    value={labelVirement || ''}
                                    placeholder={t('program.config.front.bankingTransfer.placeHolder')}
                                    maxLength={12}
                                />
                            </div>

                            {program.hasExternalFront === false && (
                                <>
                                    <div className="col-span-1">
                                        <Label>{t('program.config.front.contactEmail.label')}</Label>
                                        <Input
                                            disabled={!editing || !isInternal}
                                            className="mt-3.5"
                                            value={contactEmail || ''}
                                            onChange={setContactEmail}
                                            placeholder={t('program.config.front.contactEmail.placeHolder')}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <Label>{t('program.config.front.supportUrl.label')}</Label>
                                        <Input
                                            disabled={!editing || !isInternal}
                                            className="mt-3.5"
                                            onChange={setSupportContactEmail}
                                            value={supportContactEmail || ''}
                                            placeholder={t('program.config.front.supportUrl.placeHolder')}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex justify-between">
                                            <Label>{t('program.config.front.smsSenderName.label')}</Label>
                                            <InputValidation items={itemsSmsSenderName} />
                                        </div>
                                        <div>
                                            <Input
                                                disabled={!editing}
                                                className="mt-3.5"
                                                value={smsSenderName}
                                                onChange={setSmsSenderName}
                                                error={smsSenderNameError}
                                                maxLength={11}
                                                placeholder={t('program.config.front.smsSenderName.placeHolder')}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="col-span-1">
                                <Label>{t('program.config.front.loyaltyCard.label')}</Label>
                                <Switch
                                    data-cy="program.config.front.loyaltyCard"
                                    value={hasLoyaltyCard}
                                    onChange={setHasLoyaltyCard}
                                    disabled={!editing}
                                />
                            </div>

                            <div className="grid grid-cols-5 lg:col-span-3 xl:col-span-2 gap-x-6 gap-y-6 md:gap-y-10">
                                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                                    <Label className="truncate block">
                                        {t('program.config.front.primaryColor.label')}
                                    </Label>
                                    <ColorPicker
                                        className="mt-3.5"
                                        value={primaryColor || ''}
                                        onChange={setPrimaryColor}
                                        disabled={!editing}
                                    />
                                </div>
                                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                                    <Label className="truncate block">
                                        {t('program.config.front.backgroundColor.label')}
                                    </Label>
                                    <ColorPicker
                                        className="mt-3.5"
                                        value={backgroundColor || ''}
                                        onChange={setBackgroundColor}
                                        disabled={!editing}
                                    />
                                </div>
                                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                                    <Label className="truncate block">
                                        {t('program.config.front.fontColor.label')}
                                    </Label>
                                    <ColorPicker
                                        className="mt-3.5"
                                        value={fontColor || ''}
                                        onChange={setFontColor}
                                        disabled={!editing}
                                    />
                                </div>
                                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                                    <Label className="truncate block">
                                        {t('program.config.front.fontColorOverPrimary.label')}
                                    </Label>
                                    <ColorPicker
                                        className="mt-3.5"
                                        value={fontColorOverPrimary || ''}
                                        onChange={setFontColorOverPrimary}
                                        disabled={!editing}
                                    />
                                </div>
                                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                                    <Label className="truncate block">
                                        {t('program.config.front.componentColor.label')}
                                    </Label>
                                    <ColorPicker
                                        className="mt-3.5"
                                        value={componentColor || ''}
                                        onChange={setComponentColor}
                                        disabled={!editing}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
)

const items = [{ label: '.jpg, .png or .svg', completed: false }]

export default forwardRef((props: FrontProps, ref) => {
    return <Front {...props} inputRef={ref} />
})
