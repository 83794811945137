import './Badge.css'
import React from 'react'

type BadgeProps = {
    children?: React.ReactNode
    appearance?: string
    className?: string
    'data-cy'?: string
}

function Badge({ children, appearance, className = '', ...rest }: BadgeProps) {
    let classes = ['bg-opacity-10 px-2 py-1 rounded-lg cursor-default hover:bg-opacity-20']

    if (appearance === 'error') {
        classes.push('bg-error text-error')
    } else if (appearance === 'warning') {
        classes.push('bg-warning text-warning')
    } else if (appearance === 'success') {
        classes.push('bg-success text-success')
    } else if (appearance === 'info') {
        classes.push('bg-blue-500 text-blue-400')
    } else {
        classes.push('bg-gray-500 text-gray-400')
    }

    classes.push(className)

    return (
        <span className={classes.join(' ')} data-cy={rest['data-cy']}>
            {children}
        </span>
    )
}

export default Badge
