import Tabs from '../../components/Tabs'
import React, { useContext, useEffect, useState } from 'react'
import Tab from '../../components/Tab'
import Button from '../../components/Button'
import DropdownInput from '../../components/DropdownInput'
import DropDown from '../../components/DropDown'
import { withProgram, WithProgramProps } from '../../contexts/ProgramContext'
import { useTranslation } from 'react-i18next'
import OfferContext from '../../contexts/OfferContext'
import { User } from '../../data/UserFetcher'
import { OffersFetcher } from '../../data/Offers'
import DeleteOfferModal from '../../components/DeleteOfferModal'
import { ACCESS_LEVEL, FUNCTIONALITIES } from '../../helpers'
import { Offer } from '../../interfaces/Offer'
import { DateTime } from 'luxon'
import { Table } from '../../components/Table'
import { ColumnsType } from 'antd/es/table'
import { useNavigate } from 'react-router-dom'
import RequireAuth from '../../components/RequireAuth'
import ErrorManagementContext from '../../contexts/ErrorManagementContext'

type OffersProps = WithProgramProps

const Offers = ({ currentProgram }: OffersProps) => {
    const [current, setCurrent] = useState<string>('running')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [search, setSearch] = useState<string>('')
    const [deleteOffer, setDeleteOffer] = useState<Offer>()
    const functionalities = User.getFunctionalities()
    const offerFunctionaly = functionalities.find((e: { OFFER: string }) => e?.OFFER)
    // Splitting offers according to their status helps us having more control over the search bar, which will concern only running offers
    const [runningOffers, setRunningOffers] = useState<Offer[]>([])
    const [doneOffers, setDoneOffers] = useState<Offer[]>([])
    const [deletedOffers, setDeletedOffers] = useState<Offer[]>([])
    const { handleError } = useContext(ErrorManagementContext)

    const navigate = useNavigate()
    const { setOffers, offers } = useContext<any>(OfferContext)

    const { t } = useTranslation('common')

    useEffect(() => {
        getOffers()
    }, [search, currentProgram?.id])

    function getOffers() {
        if (currentProgram) {
            return OffersFetcher.get(currentProgram.id, search)
                .then((res: any) => {
                    const offersData: Offer[] = res.data || []
                    setRunningOffers(offersData.filter((offer: Offer) => offer?.active))
                    if (!search) {
                        setDoneOffers(
                            offersData.filter((offer: Offer) => !offer?.active && !offer?.manuallyDeactivated)
                        )
                        setDeletedOffers(
                            offersData.filter((offer: Offer) => !offer?.active && offer?.manuallyDeactivated)
                        )
                    }
                    setOffers(offersData)
                    setIsLoading(false)
                })
                .catch((err) => {
                    setRunningOffers([])
                    setDoneOffers([])
                    setOffers()
                    setIsLoading(false)
                    handleError('errors.offers.getByProgramId')(err)
                })
        }
    }

    const redirectToUpdate = (offer: { id: string }) => {
        navigate(`/programs/${currentProgram?.id}/offers/${offer?.id}/detail`, { state: offer })
    }

    const deactivateOffer = (offer: Offer) => {
        const data = { challengeId: offer?.id, active: false }
        OffersFetcher.patch(data)
            .then(() => {
                getOffers() // Fetch again the new active offer
                setDeleteOffer(undefined)
            })
            .catch((err) => {
                setDeleteOffer(undefined)
                handleError('errors.offers.deactivate')(err)
            })
    }

    const columns: ColumnsType<any> = [
        { title: t('offerView.name'), render: (o) => o.teaser },
        {
            title: t('offerView.period'),
            render: (o) =>
                (o.startDate
                    ? DateTime.fromISO(o.startDate)
                          .setZone(currentProgram?.timezone)
                          .toLocaleString(DateTime.DATE_SHORT)
                    : 'N/A') +
                ' ' +
                (o.endDate
                    ? DateTime.fromISO(o.endDate)
                          .setZone(currentProgram?.timezone)
                          .minus({ days: 1 })
                          .toLocaleString(DateTime.DATE_SHORT)
                    : 'N/A'),
        },
        { title: t('offerView.reward'), render: (o) => (o.rewardLabel ? o.rewardLabel : 'N/A') },
        { title: t('offerView.rewarded_members'), render: (o) => (o.nbRewardedMember ? o.nbRewardedMember : 'N/A') },
        {
            title: t('offerView.nb_of_rewards'),
            render: (o) => (o.totalRewardTriggered ? o.totalRewardTriggered : 'N/A'),
        },
        {
            title: t('offerView.budget'),
            render: (o) => (o.cappingLimit ? o.cappingLimit + ' ' + t('offerView.euro') : 'N/A'),
        },
        {
            title: '',
            render: (o) =>
                !o?.manuallyDeactivated && (
                    <DropDown>
                        <span
                            onClick={() => redirectToUpdate(o)}
                            className="px-4 py-3 hover:bg-gray-200 hover:text-primary w-full text-sm hover:bg-opacity-20"
                        >
                            {t('offerView.details')}
                        </span>
                        <RequireAuth functionality={FUNCTIONALITIES.OFFER} level={ACCESS_LEVEL.WRITE}>
                            <span
                                onClick={() => setDeleteOffer(o)}
                                className="px-4 py-3 hover:bg-gray-200 hover:text-primary w-full text-sm hover:bg-opacity-20"
                            >
                                {t('offerView.delete')}
                            </span>
                        </RequireAuth>
                    </DropDown>
                ),
        },
    ]

    let currentOffersDisplayed = undefined
    if (offers?.length > 0) {
        switch (current) {
            case 'running':
                currentOffersDisplayed = runningOffers
                break
            case 'done':
                currentOffersDisplayed = doneOffers
                break
            case 'deleted':
                currentOffersDisplayed = deletedOffers
                break
        }
    }

    return (
        <div className={'p-1 pl-4'}>
            <div className="flex justify-between mb-8">
                <h1 className={'text-3xl'}>{t('offerView.offers')}</h1>
                {offerFunctionaly?.OFFER === 'READ' ? null : (
                    <Button
                        as={'link'}
                        to={`/programs/${currentProgram?.id}/offers/create`}
                        data-cy="create_new_offer_button"
                    >
                        {t('offerView.new')}
                    </Button>
                )}
            </div>

            <div className={'lg:flex lg:justify-between'}>
                <Tabs current={current} setCurrent={setCurrent}>
                    <Tab data-cy="offers_live_tab" name="running">
                        {t('offerView.running')}
                    </Tab>
                    <Tab data-cy="offers_expired_tab" name="done">
                        {t('offerView.done')}
                    </Tab>
                    <Tab data-cy="offers_deleted_tab" name="deleted">
                        {t('offerView.deleted')}
                    </Tab>
                </Tabs>

                <div className={['flex items-center', current === 'running' ? 'opacity-100' : 'opacity-0'].join(' ')}>
                    <div className="w-60">
                        <DropdownInput
                            data-cy="search_offers_button"
                            value={search}
                            onChange={(e: { value: React.SetStateAction<string> }) => {
                                setSearch(e.value)
                            }}
                            options={searchOptions}
                            placeholder={t('offerView.search')}
                            asSearchInput
                            withoutMenu
                        />
                    </div>
                </div>
            </div>
            <div className={'mt-4 overflow-x-scroll'}>
                <Table data={currentOffersDisplayed} columns={columns} loading={isLoading} />

                <DeleteOfferModal
                    show={!!deleteOffer?.id}
                    onClose={() => setDeleteOffer(undefined)}
                    onDelete={() => deleteOffer && deactivateOffer(deleteOffer)}
                    name={deleteOffer?.teaser}
                />
            </div>
        </div>
    )
}

let searchOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Initialized',
        value: 'initialized',
    },
]

export default withProgram(Offers)
