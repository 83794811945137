import Modal from '../../../../../components/Modal'
import Button, { ButtonAppearance } from '../../../../../components/Button'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useState } from 'react'
import { Request } from '../../../../../helpers'
import ErrorManagementContext from '../../../../../contexts/ErrorManagementContext'
import { message } from 'antd'
import YLogoLoading from '../../../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../../../tailwind.config'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'

type RedeemRewardModalProps = {
    reward: any
    show: boolean
    onClose: () => void
    onCreated: () => void
}

function RedeemRewardModal({ show, onClose, onCreated, reward }: RedeemRewardModalProps) {
    const { customerId } = useParams<{ customerId: string }>()
    const uriRedeemReward = '/backoffice/v3/customers/reward/redeem'
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)
    const [loading, setLoading] = useState(false)

    const customOnClose = useCallback(() => {
        onClose()
    }, [onClose])

    const onValidate = () => {
        setLoading(true)
        Request.post(
            uriRedeemReward,
            {
                customerId,
                rewardId: reward.id,
                redeemed: true,
            },
            {},
            null,
            true
        )
            .then(() => {
                customOnClose()
                onCreated()
                message.success(t('shopperProfile.rewardsTable.redeemReward.success'))
            })
            .catch(handleError('errors.rewardsTable.redeemReward'))
            .finally(() => setLoading(false))
    }

    return (
        <Modal show={show} onClose={customOnClose}>
            <h3 className="text-xl md:ml-6 text-center mb-8">
                {reward?.Challenge.teaser} - {reward ? moment(reward.end_date).format('DD/MM/YYYY') : ''}
            </h3>
            <p>Reward id : {reward?.id}</p>
            <p>Customer id : {customerId}</p>
            <p>
                {' '}
                {t('shopperProfile.rewardsTable.header.amount')} : {reward?.reward_value}
            </p>
            <div className="flex justify-center mt-4">
                <Button
                    appearance={loading ? ButtonAppearance.BUTTON_APPEARANCE_OUTLINE : undefined}
                    className="mr-3 relative"
                    onClick={() => !loading && onValidate()}
                >
                    {loading ? (
                        <div className="w-12 flex justify-center">
                            <YLogoLoading
                                percentage={50}
                                width={15}
                                height={20}
                                border="white"
                                color={(Tailwind as any).theme.extend.colors.primary}
                            />
                        </div>
                    ) : (
                        t('shopperProfile.rewardsTable.redeemReward.redeemButton')
                    )}
                </Button>

                <Button appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE} onClick={customOnClose}>
                    {t('creation_user.button.cancel')}
                </Button>
            </div>
        </Modal>
    )
}

export default RedeemRewardModal
