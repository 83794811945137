import PropTypes from 'prop-types'

function ErrorText({ children, className = '' }) {
    return <div className={['text-error italic text-xs h-6', className].join(' ')}>{children}</div>
}

ErrorText.propTypes = {
    className: PropTypes.string,
}

export default ErrorText
