import DropdownInput from '../../../components/DropdownInput'
import Button, { ButtonAppearance } from '../../../components/Button'
import RestTable from '../../../components/Table'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'

const url = '/backoffice/patterns/transactions'

const AnalyseByText = () => {
    const location = useLocation()
    const locationData = location.state as any
    const [search, setSearch] = useState<string>(locationData?.customerId || '')
    const navigate = useNavigate()

    const onSearchChanged = useMemo(() => debounce((search) => setSearch(search), 750), [])

    const tableQueryUrl = useMemo(
        () => search && `${url}?label=${encodeURIComponent(search)}&patternStart=0&patternEnd=${search.length}`,
        [search]
    )

    const columns: ColumnsType<any> = [
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Transaction date</div>,
            render: (t) => moment(t.transaction_date).format('MM/DD/YYYY'),
            defaultSortOrder: 'descend',
            key: 'transaction_date',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Label</div>,
            render: (t) => {
                const match = t.label.match(new RegExp(search, 'i'))

                return !match ? (
                    t.label
                ) : (
                    <>
                        <span className="text-dark/40">{t.label.substring(0, match.index)}</span>
                        <span className="text-primary font-bold">
                            {t.label.substring(match.index, match.index + search.length)}
                        </span>
                        <span className="text-dark/40">{t.label.substring(match.index + search.length)}</span>
                    </>
                )
            },
            key: 'label',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Amount</div>,
            render: (s) => s.amount,
            key: 'amount',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
    ]

    return (
        <>
            <div className="grid grid-cols-1 gap-4">
                <div className="w-full flex justify-between gap-2">
                    <div className="2xl:w-1/3 lg:w-3/4 w-full flex gap-2">
                        <DropdownInput
                            onChange={(e) => onSearchChanged(e.value)}
                            options={[]}
                            placeholder={'Text'}
                            value={search}
                            asSearchInput
                            withoutMenu
                            className="h-full"
                            data-cy="search_input"
                        />
                        <Button
                            disabled={false}
                            appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                            onClick={() => setSearch('')}
                            data-cy="search_clear_button"
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            </div>
            {search && (
                <div className="mt-8 overflow-x-scroll">
                    <RestTable
                        queryBaseUrl={tableQueryUrl}
                        columns={columns}
                        pageSize={10}
                        errorMessageKey="errors.pinpoint.list"
                        tableLayout="fixed"
                        onRowClicked={(t) => navigate(`/pinpoint/analyze/${t.id}`, { state: { transaction: t } })}
                    />
                </div>
            )}
        </>
    )
}

export default AnalyseByText
