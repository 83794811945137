import React from 'react'

function SocialBox({ children, url, className = '' }) {
    return (
        <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className={[
                'cursor-pointer w-16 h-16 border-2 border-guest-secondary flex items-center justify-center',
                className,
            ].join(' ')}
        >
            {children}
        </a>
    )
}

export default SocialBox
