import { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FileInput from '../../../components/FileInput'
import Link from '../../../components/Link'
import { getFileNameFromPath } from '../../../helpers'
import InputValidation from '../../../components/InputValidation'
import { withAuth } from '../../../contexts/AuthContext'

const Legal = withAuth(({ program, onChange, editing, user, inputRef }) => {
    const { t } = useTranslation('common')
    const [termsOfUse, setTermsOfUse] = useState(null)
    const [cookiePolicy, setCookiePolicy] = useState(null)
    const [privacyPolicy, setPrivacyPolicy] = useState(null)
    const [isInternal, setIsInternal] = useState(!user?.shoppingHubOwnerId)

    useImperativeHandle(inputRef, () => ({
        validate,
    }))

    useEffect(() => {
        setIsInternal(!user?.shoppingHubOwnerId)
    }, [setIsInternal, user])

    useEffect(() => {
        if (program.privacyPolicyUrl) {
            setPrivacyPolicy(
                new File([], getFileNameFromPath(program.privacyPolicyUrl), {
                    type: 'application/pdf',
                })
            )
        }
        if (program.termsOfUseUrl) {
            setTermsOfUse(
                new File([], getFileNameFromPath(program.termsOfUseUrl), {
                    type: 'application/pdf',
                })
            )
        }
        if (program.cookiePolicyUrl) {
            setCookiePolicy(
                new File([], getFileNameFromPath(program.cookiePolicyUrl), {
                    type: 'application/pdf',
                })
            )
        }
        /**
         * NOTE: Each time a new `program` object is received, we want to useEffect to be triggered.
         * If we use `useDeepCompareWithRef` it will prevent that happens as, the URLs doesn't change
         **/
    }, [program])

    useEffect(() => {
        if (program.hasExternalFront) {
            const isDirty = editing && (cookiePolicy || termsOfUse || privacyPolicy)
            onChange(!isDirty)
        }
    }, [cookiePolicy, termsOfUse, privacyPolicy])

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const validate = async () => {
        return {
            termsOfUse: termsOfUse && termsOfUse.size > 0 ? await convertBase64(termsOfUse) : null,
            termsOfUseFileName: termsOfUse && termsOfUse.size > 0 ? termsOfUse.name : undefined,
            cookiePolicy: cookiePolicy && cookiePolicy.size > 0 ? await convertBase64(cookiePolicy) : null,
            cookiePolicyFileName: cookiePolicy && cookiePolicy.size > 0 ? cookiePolicy.name : undefined,
            privacyPolicy: privacyPolicy && privacyPolicy.size > 0 ? await convertBase64(privacyPolicy) : null,
            privacyPolicyFileName: privacyPolicy && privacyPolicy.size > 0 ? privacyPolicy.name : undefined,
        }
    }

    const displayDownloadButton = (url) =>
        url && (
            <Link url={url} target="_blank" className="underline text-sm">
                {' '}
                {t('program.config.legal.download')}
            </Link>
        )

    return (
        <>
            <div className="flex flex-col gap-4 p-1 mb-12">
                <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6 md:gap-y-10">
                    <div className={'col-span-1'}>
                        <div className="flex justify-between items-center">
                            <div>{t('program.config.legal.privacyPolicy.label')}</div>
                            <InputValidation items={items} />
                        </div>
                        <FileInput
                            accept=".pdf"
                            disabled={!editing || !isInternal}
                            className="mt-3.5 mb-2"
                            onChange={(type, file) => setPrivacyPolicy(file)}
                            file={privacyPolicy}
                        />
                        {displayDownloadButton(program.privacyPolicyUrl)}
                    </div>
                    <div className={'col-span-1'}>
                        <div className="flex justify-between items-center">
                            <div>{t('program.config.legal.termsOfUse.label')}</div>
                            <InputValidation items={items} />
                        </div>
                        <FileInput
                            accept=".pdf"
                            disabled={!editing || !isInternal}
                            className="mt-3.5 mb-2"
                            onChange={(type, file) => setTermsOfUse(file)}
                            file={termsOfUse}
                        />
                        {displayDownloadButton(program.termsOfUseUrl)}
                    </div>
                    {!program.hasExternalFront && (
                        <div className={'col-span-1'}>
                            <div className="flex justify-between items-center">
                                <div>{t('program.config.legal.cookiePolicy.label')}</div>
                                <InputValidation items={items} />
                            </div>
                            <FileInput
                                accept=".pdf"
                                disabled={!editing || !isInternal}
                                className="mt-3.5 mb-2"
                                onChange={(type, file) => setCookiePolicy(file)}
                                file={cookiePolicy}
                            />
                            {displayDownloadButton(program.cookiePolicyUrl)}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
})

const items = [{ label: '.pdf', completed: false }]

export default forwardRef((props, ref) => {
    return <Legal {...props} inputRef={ref} />
})
