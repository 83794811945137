import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import Select, { components } from 'react-select'
import './FiltersDropdown.css'
import Checkbox from './Checkbox'

const Menu = (props) => {
    return (
        <Fragment>
            <components.Menu {...props}>
                <div className="flex flex-col">
                    <div>{props.children}</div>
                </div>
            </components.Menu>
        </Fragment>
    )
}

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <div className="flex inline-flex items-center gap-2">
                    <Checkbox checked={props.isSelected} onChange={() => null} className="h-3 w-3" />
                    <label htmlFor="no-cashback" className="cursor-pointer">
                        {props.label}
                    </label>
                </div>
            </components.Option>
        </div>
    )
}

function FiltersDropdown({ value, placeholder, options, className, onChange, error, disabled, isMulti, filterName }) {
    const ValueContainer = ({ children, ...props }) => {
        /**
         * children is an array with two elements: a list of all selected items, an input field for filtering purpose
         */
        const inputElement = children[1]
        const autoCompleteDOM = document.getElementById(inputElement.props.id)
        if (autoCompleteDOM) {
            // Fix a UI issue when user clicks on an item, the width of the input field becomes to small
            autoCompleteDOM.style.width = 'auto'
        }
        return <components.ValueContainer {...props}>{inputElement}</components.ValueContainer>
    }
    const newComponents = { Menu, Option }
    if (isMulti) {
        newComponents.ValueContainer = ValueContainer
    }
    const [opened, setOpened] = useState(false)
    const wrapperRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpened(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    newComponents.IndicatorSeparator = () => null
    newComponents.DropdownIndicator = () => (
        <svg
            className={'absolute right-0 top-auto cursor-pointer'}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.7812 13.8438L12.6562 10.7188C12.5 10.5938 12.3125 10.5 12.125 10.5H11.625C12.4688 9.40625 13 8.03125 13 6.5C13 2.9375 10.0625 0 6.5 0C2.90625 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.5 13C8 13 9.375 12.5 10.5 11.625V12.1562C10.5 12.3438 10.5625 12.5312 10.7188 12.6875L13.8125 15.7812C14.125 16.0938 14.5938 16.0938 14.875 15.7812L15.75 14.9062C16.0625 14.625 16.0625 14.1562 15.7812 13.8438ZM6.5 10.5C4.28125 10.5 2.5 8.71875 2.5 6.5C2.5 4.3125 4.28125 2.5 6.5 2.5C8.6875 2.5 10.5 4.3125 10.5 6.5C10.5 8.71875 8.6875 10.5 6.5 10.5Z"
                fill="#333333"
            />
        </svg>
    )

    const displayHeader = () => {
        if (isMulti) {
            const allItemIdx = value.findIndex((e) => e.value === 'all')
            if (allItemIdx > -1) {
                return <span>{value[allItemIdx].label}</span>
            }
            return (
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {value.length > 1 ? `${value.length} ${filterName}` : value.map((v) => v.label).join(', ')}
                </span>
            )
        }
        return <span>{value.length > 0 ? value[0]?.label : ''}</span>
    }

    return (
        <div ref={wrapperRef} className="relative inline-block">
            {disabled ? (
                <div className="flex cursor-pointer" style={{ color: '#C4C4C4' }}>
                    <span>{value.label}</span>
                    <ChevronDownIcon className="h-6" />
                </div>
            ) : (
                <div className="flex cursor-pointer" onClick={(e) => setOpened((prev) => !prev)}>
                    {displayHeader()}
                    {opened ? <ChevronUpIcon className="h-6" /> : <ChevronDownIcon className="h-6" />}
                </div>
            )}

            {opened && (
                <div className="absolute top-full md:right-0 w-64 md:w-56 z-10">
                    <Select
                        className={[
                            error ? 'border-error focus:ring-error' : 'border-black focus:ring-transparent',
                            'filter-dropdown-container',
                            className,
                        ].join(' ')}
                        isMulti={isMulti}
                        options={options}
                        classNamePrefix="filter-dropdown"
                        onChange={onChange}
                        value={value}
                        components={newComponents}
                        isClearable={false}
                        placeholder={placeholder}
                        menuIsOpen={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={false}
                        styles={{
                            control: (provided) => {
                                return { ...provided, backgroundColor: '#FFFFFF' }
                            },
                            option: (provided, state) => {
                                return {
                                    ...provided,
                                    backgroundColor: state.isDisabled ? '#2684FF' : '#FFFFFF',
                                    color: '#000000',
                                    '&:hover': { backgroundColor: '#F2F2F2', color: '#FF7700' },
                                }
                            },
                        }}
                    />
                </div>
            )}
        </div>
    )
}

FiltersDropdown.propTypes = {
    value: PropTypes.array,
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    options: PropTypes.array,
    error: PropTypes.bool,
    filterName: PropTypes.string,
}

FiltersDropdown.default = {
    value: [],
    placeholder: 'Choose',
    options: [],
    className: '',
    error: false,
    disabled: false,
    isMulti: false,
    filterName: '',
}

export default FiltersDropdown
