import Button from '../../../components/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import OfferContext from '../../../contexts/OfferContext'
import ProgramContext from '../../../contexts/ProgramContext'
import { OffersFetcher } from '../../../data/Offers'
import ReactTooltip from 'react-tooltip'
import '../../../components/InfoTooltip.css'
import Loading from '../../../components/Loading'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'

const Menu = () => {
    const location = useLocation()
    const navigate = useNavigate()
    let programData = location.state || {}
    const { t } = useTranslation('common')
    const { programId } = useParams()
    const { offers, setOffers } = useContext(OfferContext)
    const { currentProgram } = useContext(ProgramContext)
    const [hasWelcomeOffer, setHasWelcomeOffer] = useState(false)
    const [hasReferralOffer, setHasReferralOffer] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [id] = useState(`tooltip-refferal-offer`)
    const { handleError } = useContext(ErrorManagementContext)

    const getOffers = useCallback(async () => {
        OffersFetcher.get(currentProgram?.id || programId, '')
            .then((res) => setOffers(res.data))
            .catch(handleError('errors.offers.getByProgramId'))
            .finally(() => setIsLoading(false))
    }, [currentProgram?.id, programId, setOffers])

    useEffect(() => {
        if (!offers) {
            getOffers()
        } else {
            setIsLoading(false)
            setHasWelcomeOffer(!!offers.filter((offer) => offer?.offerType === 'welcome' && offer?.active)?.length)
            setHasReferralOffer(!!offers.filter((offer) => offer?.offerType === 'referral' && offer?.active)?.length)
        }
    }, [getOffers, offers])

    const validate = (offer) => {
        const offerType = offer.type
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('offerType', offerType)
        navigate(`/programs/${currentProgram.id}/offers/create/form?${searchParams.toString()}`, {
            state: {
                ...programData,
                offerType,
                offers,
            },
        })
    }

    return (
        <div className={'p-1 pl-4 relative'}>
            {isLoading && <Loading fullScreen={false} />}

            <div className="md:flex md:start">
                <div className={'md:flex'}>
                    <BackButton data-cy="previous_page_button" onClick={() => navigate(-1)} />
                </div>
            </div>
            <div className={'sm:max-w-2xl m-auto'}>
                <h1 className={'text-3xl text-primary text-center py-4'}>{t('offerCreation.createOffer')}</h1>
                <div className={'mt-14 grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-8'}>
                    {options.map((option) => {
                        // Welcome offers should be greyed out if the offer has already been setup
                        // Referral offers should be greyed out if there is no welcome offer set up or if a referral offer already exists
                        if (
                            (option.type === 'referral_offer' && !hasWelcomeOffer) ||
                            (option.type === 'referral_offer' && hasReferralOffer)
                        ) {
                            return (
                                <React.Fragment key={option.id}>
                                    <div
                                        className={
                                            'bg-white grey-card flex items-center justify-between flex-col px-4 py-8 text-center h-64'
                                        }
                                        data-for={option.type === 'referral_offer' && id}
                                        data-tip={
                                            option.type === 'referral_offer' && !hasWelcomeOffer
                                                ? t('offerCreation.referral_offer.tooltip')
                                                : t('offerCreation.referral_offer.exists.tooltip')
                                        }
                                        data-event="mouseenter"
                                        data-event-off="click"
                                        data-cy={option.type + '_item'}
                                    >
                                        <h3 className={'text-xl font-bold'}>
                                            {t('offerCreation.' + option.type + '.label')}
                                        </h3>
                                        <p className={'text-sm'}>
                                            {t('offerCreation.' + option.type + '.description')}
                                        </p>
                                        <Button
                                            disabled
                                            onClick={(e) => validate(option)}
                                            appearance={'inversed'}
                                            className={'font-bold text-sm'}
                                        >
                                            {t('offerCreation.' + option.type + '.buttonLabel')}
                                        </Button>
                                    </div>
                                    <ReactTooltip
                                        globalEventOff="click"
                                        type="light"
                                        isCapture={true}
                                        effect="solid"
                                        multiline={true}
                                        id={id}
                                        className="info-tooltip"
                                    />
                                </React.Fragment>
                            )
                        }
                        return (
                            <div
                                key={option.id}
                                className={
                                    'bg-white flex items-center justify-between flex-col px-4 py-8 text-center h-64'
                                }
                                data-cy={option.type + '_item'}
                            >
                                <h3 className={'text-xl font-bold'}>{t('offerCreation.' + option.type + '.label')}</h3>
                                <p className={'text-sm'}>{t('offerCreation.' + option.type + '.description')}</p>
                                <Button
                                    onClick={(e) => validate(option)}
                                    appearance={'inversed'}
                                    className={'font-bold text-sm'}
                                >
                                    {t('offerCreation.' + option.type + '.buttonLabel')}
                                </Button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const options = [
    {
        id: 1,
        type: 'purchase_frequency',
    },
    {
        id: 2,
        type: 'purchase_frequency_non_cumulative',
    },
    {
        id: 3,
        type: 'average_basket',
    },
    {
        id: 4,
        type: 'welcome_offer',
    },
    {
        id: 5,
        type: 'referral_offer',
    },
]

export default Menu
