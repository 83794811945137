import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button'
import React from 'react'

type ErrorPageTemplateProps = {
    title: string
    description: string
    icon: React.ReactNode
    pathname?: string
}

const ErrorPageTemplate = ({ title, description, icon, pathname = '/programs' }: ErrorPageTemplateProps) => {
    const { t } = useTranslation('common')

    return (
        <div className="flex flex-col justify-center items-center h-full max-w-xl m-auto">
            <div className="flex justify-center items-center">{icon}</div>
            <div className="text-center text-xl mt-8">{title}</div>
            <div className="text-center">{description}</div>
            <div className="text-center mt-8">
                <Button as="link" to={pathname}>
                    {t('errorpage.go_to_homepage')}
                </Button>
            </div>
        </div>
    )
}

export default ErrorPageTemplate
