type CopyToClipboardIconProps = {
    className?: string
}

function CopyToClipboardIcon({ className }: CopyToClipboardIconProps) {
    return (
        <svg
            width="10"
            height="12"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M7.73214 1.28906L6.82143 0.292969C6.67857 0.136719 6.41071 0 6.21429 0H3.14286C2.66071 0 2.28571 0.429688 2.28571 0.9375V1.875H0.857143C0.375 1.875 0 2.30469 0 2.8125V9.0625C0 9.58984 0.375 10 0.857143 10H4.85714C5.32143 10 5.71429 9.58984 5.71429 9.0625V8.125H7.14286C7.60714 8.125 8 7.71484 8 7.1875V1.95312C8 1.73828 7.875 1.44531 7.73214 1.28906ZM4.75 9.0625H0.964286C0.892857 9.0625 0.857143 9.02344 0.857143 8.94531V2.92969C0.857143 2.87109 0.892857 2.8125 0.964286 2.8125H2.28571V7.1875C2.28571 7.71484 2.66071 8.125 3.14286 8.125H4.85714V8.94531C4.85714 9.02344 4.80357 9.0625 4.75 9.0625ZM7.03571 7.1875H3.25C3.17857 7.1875 3.14286 7.14844 3.14286 7.07031V1.05469C3.14286 0.996094 3.17857 0.9375 3.25 0.9375H5.14286V2.65625C5.14286 2.92969 5.32143 3.125 5.57143 3.125H7.14286V7.07031C7.14286 7.14844 7.08929 7.1875 7.03571 7.1875ZM7.14286 2.1875H6V0.9375H6.16071C6.19643 0.9375 6.21429 0.957031 6.23214 0.976562L7.10714 1.93359C7.125 1.95312 7.14286 1.97266 7.14286 2.01172V2.1875Z"
                fill="#333333"
            />
        </svg>
    )
}

export default CopyToClipboardIcon
