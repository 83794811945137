import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Tabs from '../../components/Tabs'
import Tab from '../../components/Tab'
import ShoppingHubOwnerListing from './ShoppingHubOwner/Listing'
import BackendUserListing from './BackendUser/Listing'
import { ACCESS_LEVEL, FUNCTIONALITIES } from '../../helpers'
import Button from '../../components/Button'
import RequireAuth from '../../components/RequireAuth'

function Admin() {
    const { t } = useTranslation('common')
    const [currentTab, setCurrentTab] = useState('shoppingHubOwners')
    const tagElementRef = React.createRef<any>()

    return (
        <>
            <div className="flex flex-col gap-4 p-1">
                <div className="flex justify-between">
                    <div className="text-2xl">{t('admin.title')}</div>
                </div>
                <div className="md:flex justify-between">
                    <Tabs current={currentTab} setCurrent={setCurrentTab}>
                        {tabs.map((tab) => (
                            <Tab name={tab.key} key={tab.key} data-cy={tab.key}>
                                {t(tab.label)}
                            </Tab>
                        ))}
                    </Tabs>

                    <RequireAuth functionality={FUNCTIONALITIES.IAM} level={ACCESS_LEVEL.WRITE}>
                        <Button onClick={() => tagElementRef.current.openCreationModal()}>
                            {t('listing_shoppinghubowner.create_btn')}
                        </Button>
                    </RequireAuth>
                </div>
                <div className="overflow-auto xl:mt-6">
                    {currentTab === 'shoppingHubOwners' && <ShoppingHubOwnerListing ref={tagElementRef} />}
                    {currentTab === 'backendUsers' && <BackendUserListing ref={tagElementRef} />}
                </div>
            </div>
        </>
    )
}

const tabs = [
    {
        key: 'shoppingHubOwners',
        label: 'admin.tab.shopping-hub-owner',
    },
    {
        key: 'backendUsers',
        label: 'admin.tab.backend-user',
    },
]

export default Admin
