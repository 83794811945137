import Input from '../../components/Input'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import ErrorText from '../../components/ErrorText'
import { useRef, useState } from 'react'
import BackButton from '../../components/BackButton'
import Label from '../../components/Label'
import { useNavigate } from 'react-router-dom'
import * as EmailValidator from 'email-validator'
import { User } from '../../data/UserFetcher'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'

function PasswordForgotten() {
    const { t } = useTranslation('common')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [serverErrorMessage, setServerErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isEmailSent, setIsEmailSent] = useState(false)
    const recaptchaRef = useRef(null)

    const [email, setEmail] = useState('')
    const navigate = useNavigate()

    const refreshPassword = (captchaValue) => {
        User.sendResetPasswordEmail(email, process.env.NODE_ENV !== 'production' ? 'api-test' : captchaValue)
            .then((e) => {
                setIsEmailSent(true)
            })
            .catch((e) => {
                const msg =
                    e.code === 'incompleteRegistration'
                        ? t('Password_forgotten.passwordRequired')
                        : e.code === 'invalidCaptcha'
                        ? t('internalServerError')
                        : t('internalServerError')
                setServerErrorMessage(msg)
            })
            .finally(() => {
                setIsLoading(false)
                recaptchaRef.current.reset()
            })
    }

    const onCaptchaChange = async (value) => {
        if (!!value) {
            setTimeout(() => refreshPassword(value), 100)
        } else {
            setIsLoading(false)
        }
    }

    const checkEmail = () => {
        if (email !== '' && !EmailValidator.validate(email)) {
            setEmailErrorMessage(t('Password_forgotten.wrongEmailFormat'))
        } else {
            setEmailErrorMessage('')
        }
    }

    const isButtonDisabled = () => {
        return isLoading || isEmailSent || !!emailErrorMessage || !email || !!serverErrorMessage
    }

    return (
        <div className="flex flex-col gap-8">
            <div className="flex justify-center items-start gap-1 md:gap-0 mb-6">
                <BackButton className="flex-shrink-0" onClick={() => navigate('/login')} />
                <Heading className="flex-1">{t('Password_forgotten.title')}</Heading>
            </div>

            <div className={'xl:py-12'}>
                <div className="mx-auto max-w-sm">
                    <div>
                        <Label>{t('Password_forgotten.emailField')}</Label>
                        <Input
                            className="mt-3.5"
                            value={email}
                            onChange={setEmail}
                            onBlur={checkEmail}
                            type="text"
                            placeholder={t('Password_forgotten.emailPlaceholder')}
                            error={emailErrorMessage !== ''}
                        />
                        <ErrorText>{emailErrorMessage}</ErrorText>
                    </div>

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        onChange={(v) => onCaptchaChange(v)}
                        size="invisible"
                        badge="bottomleft"
                    />

                    <Button
                        disabled={isButtonDisabled()}
                        className="mt-2 py-4 w-full"
                        onClick={() => {
                            setIsLoading(true)
                            recaptchaRef.current.execute()
                        }}
                    >
                        {isLoading ? t('Password_forgotten.buttonLoadingLabel') : t('Password_forgotten.buttonLabel')}
                    </Button>
                    <div className="relative text-xs py-4">
                        {isEmailSent && (
                            <span className="text-success">{t('Password_forgotten.emailSentMessage')}</span>
                        )}
                        {serverErrorMessage && <ErrorText> {serverErrorMessage} </ErrorText>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordForgotten
