import React from 'react'

function Calendar() {
    return (
        <svg width="56" height="47" viewBox="0 0 56 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.45"
                d="M27.9882 46.933C43.4456 46.933 55.9763 45.1369 55.9763 42.9213C55.9763 40.7057 43.4456 38.9097 27.9882 38.9097C12.5307 38.9097 0 40.7057 0 42.9213C0 45.1369 12.5307 46.933 27.9882 46.933Z"
                fill="#E6E6E6"
            />
            <path d="M43.532 6.10156H11.4111V42.9221H43.532V6.10156Z" fill="#E6E6E6" />
            <path d="M39.7845 26.9702H35.2969V31.1031H39.7845V26.9702Z" fill="#24285B" />
            <path d="M39.7845 33.0835H35.2969V37.2164H39.7845V33.0835Z" fill="#A5A5A5" />
            <path
                opacity="0.08"
                d="M36.5407 29.8867C36.5407 29.8867 28.3669 31.2405 32.1966 42.9216H40.9446L36.5407 29.8867Z"
                fill="black"
            />
            <path d="M44.1007 3.42529H10.8833V12.6473H44.1007V3.42529Z" fill="#FF7700" />
            <path d="M19.9642 0H17.3008V8.03722H19.9642V0Z" fill="#F2F2F2" />
            <path opacity="0.08" d="M21.7222 3.36328H19.9639V8.03493H21.7222V3.36328Z" fill="black" />
            <path opacity="0.08" d="M39.2979 3.36328H37.5396V8.03493H39.2979V3.36328Z" fill="black" />
            <path d="M37.5394 0H34.876V8.03722H37.5394V0Z" fill="#F2F2F2" />
            <path d="M23.0782 16.3213H15.7876V18.5199H23.0782V16.3213Z" fill="#24285B" />
            <path d="M20.4925 20.8955H16.0049V25.0284H20.4925V20.8955Z" fill="white" />
            <path opacity="0.32" d="M27.0052 20.8955H22.5176V25.0284H27.0052V20.8955Z" fill="#A5A5A5" />
            <path d="M33.4505 20.8955H28.9629V25.0284H33.4505V20.8955Z" fill="#FFD200" />
            <path d="M39.7845 20.8955H35.2969V25.0284H39.7845V20.8955Z" fill="#24285B" />
            <path d="M20.4925 26.9702H16.0049V31.1031H20.4925V26.9702Z" fill="white" />
            <path opacity="0.32" d="M27.0052 26.9702H22.5176V31.1031H27.0052V26.9702Z" fill="#A5A5A5" />
            <path opacity="0.32" d="M33.4505 26.9702H28.9629V31.1031H33.4505V26.9702Z" fill="#A5A5A5" />
            <path d="M20.4925 33.0835H16.0049V37.2164H20.4925V33.0835Z" fill="white" />
            <path opacity="0.32" d="M27.0052 33.0835H22.5176V37.2164H27.0052V33.0835Z" fill="#A5A5A5" />
            <path opacity="0.32" d="M33.4505 33.0835H28.9629V37.2164H33.4505V33.0835Z" fill="#A5A5A5" />
            <path opacity="0.32" d="M43.532 12.647H11.4111V14.5594H43.532V12.647Z" fill="#A5A5A5" />
        </svg>
    )
}

export default Calendar
