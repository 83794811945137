import { User } from './data/UserFetcher'
import jwtDecode from 'jwt-decode'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import _ from 'lodash'
import React from 'react'

export const useDeepCompareWithRef = (value: any, useRef: typeof React.useRef) => {
    const ref = useRef()
    if (!_.isEqual(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}

export enum FUNCTIONALITIES {
    SUPPORT_LVL_1 = 'SUPPORT_LVL_1',
    PROGRAM = 'PROGRAM',
    ADMIN = 'ADMIN',
    OFFER = 'OFFER',
    STORE = 'STORE',
    AUDIENCE = 'AUDIENCE',
    PROFILE = 'PROFILE',
    IAM = 'IAM',
    TOOLS = 'TOOLS',
    PINPOINT = 'PINPOINT',
    HUB_SETUP_INIT = 'HUB_SETUP_INIT',
}

/**
 * Access levels, order matters
 */
export enum ACCESS_LEVEL {
    READ = 'READ',
    WRITE = 'WRITE',
    DELETE = 'DELETE',
}

export const ACCESS_LEVEL_ORDER: ACCESS_LEVEL[] = [ACCESS_LEVEL.READ, ACCESS_LEVEL.WRITE, ACCESS_LEVEL.DELETE]

export { default as routes } from './routes'

export const logRocketIdentifyUser = (user: any) => {
    LogRocket.identify(user.backUserId, {
        email: user.backUserEmail,
        jobTitleId: user.jobTitleId,
    })
}

export const initLogRocket = () => {
    const { NODE_ENV, REACT_APP_VERSION, REACT_APP_LOG_ROCKET_APP_ID } = process.env
    if (NODE_ENV === 'development') {
        // Disable LOG_ROCKET for development purpose
        return false
    }
    if (!REACT_APP_LOG_ROCKET_APP_ID) {
        return false
    }
    LogRocket.init(REACT_APP_LOG_ROCKET_APP_ID, {
        release: REACT_APP_VERSION,
        network: {
            requestSanitizer(request) {
                const requestModified = { ...request }
                if (request.headers.Authorization) {
                    requestModified.headers.Authorization = 'hidden'
                }
                if (request.url.toLowerCase().includes('login')) {
                    requestModified.body = 'hidden'
                }
                return requestModified
            },
        },
    })
    setupLogRocketReact(LogRocket)
}

export const getUserDecodedToken = () => {
    const api_token = localStorage.getItem('api_token')
    if (api_token) {
        return jwtDecode<Record<string, any>>(api_token)
    }
}

export const isSessionValid = () => {
    const decodedToken = getUserDecodedToken()
    if (!decodedToken) {
        return false
    }
    const { exp } = decodedToken
    const nowInSeconds = new Date().getTime() / 1000
    return exp - nowInSeconds > 0 // session is still valid
}

/**
 * Request
 */
class RequestProvider {
    prepareHeaders(headers: Record<string, string>, data: string | Record<string, any> | null) {
        const api_token = localStorage.getItem('api_token')

        if (api_token) headers['Authorization'] = `Bearer ${api_token}`

        if (
            data !== null &&
            typeof data === 'object' &&
            Object.keys(data).filter((key) => data[key] instanceof File).length
        ) {
            delete headers['Content-Type']

            return {
                accept: 'application/json',
                ...headers,
            }
        }

        return {
            'content-type': 'application/json',
            'Content-Language': 'en',
            accept: 'application/json',
            ...headers,
        }
    }

    createFormData(form: FormData, key: string, data: string | Blob | Record<string, any> | any[]) {
        if (Array.isArray(data) || typeof data === 'object') {
            for (let i in data) {
                this.createFormData(form, key + '[' + i + ']', data[i as keyof typeof data])
            }
        } else {
            form.append(key, data)
        }
    }

    prepareData(data: Record<string, any> | null, method: string) {
        if (data === null && method === 'GET') return null

        if (data !== null) {
            if (Object.keys(data).filter((key) => data[key] instanceof File).length) {
                let form = new FormData()

                Object.keys(data).map((key) => {
                    if (data[key] instanceof File) {
                        form.append(key, data[key])
                    } else if (data[key] !== null) this.createFormData(form, key, data[key])

                    return null
                })

                return form
            }
        }

        return JSON.stringify({
            ...data,
        })
    }

    request<T>(
        method: string,
        uri: string,
        headers: any,
        body: any = null,
        raw = false,
        signal: AbortSignal | null = null
    ) {
        headers = this.prepareHeaders(headers, body)
        body = this.prepareData(body, method)
        uri = uri?.replace(/^\/+|\/+$/g, '')

        return new Promise<T>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/${uri}`, { method, body, headers, signal })
                .then((response) =>
                    response.text().then((text) => {
                        if (text.length === 0) resolve({} as T)

                        if (
                            response.status === 401 &&
                            window.location.pathname !== '/register/user/token-expired' &&
                            window.location.pathname !== '/login'
                        ) {
                            console.error(response.statusText)
                            localStorage.removeItem('currentProgram')
                            window.location.href = '/'
                        }

                        if (raw) {
                            return response.ok ? resolve(text as unknown as T) : reject(text as unknown as T)
                        }

                        const data = JSON.parse(text)
                        response.ok ? resolve(data) : reject(data)
                    })
                )
                .catch((error) => {
                    if (!(signal && error instanceof DOMException)) reject(error)
                })
        })
    }

    get<T>(uri: string, headers = {}, signal: AbortSignal | null = null, raw = false) {
        return this.request<T>('GET', uri, headers, undefined, raw, signal)
    }

    post<T>(uri: string, data: any = null, headers = {}, signal: AbortSignal | null = null, raw = false) {
        return this.request<T>('POST', uri, headers, data, raw, signal)
    }

    patch<T>(uri: string, data: any = null, headers = {}, signal: AbortSignal | null = null, raw = false) {
        return this.request<T>('PATCH', uri, headers, data, raw, signal)
    }

    put<T>(uri: string, data: any = null, headers = {}, signal: AbortSignal | null = null, raw = false) {
        return this.request<T>('PUT', uri, headers, data, raw, signal)
    }

    delete<T>(uri: string, data: any = null, headers = {}, signal: AbortSignal | null = null, raw = false) {
        return this.request<T>('DELETE', uri, headers, data, raw, signal)
    }
}

export const Request = new RequestProvider()

export function handleRequestError(e: any) {
    let found = false

    if (e.errors) {
        Object.keys(e.errors).forEach((field) => {
            if (Array.isArray(e.errors[field])) {
                e.errors[field].forEach((error: Error) => {
                    console.error(error)
                    found = true
                })
            } else if (e.errors[field]) {
                console.error(e.errors[field])
                found = true
            }
        })
    }

    if (!found && e.message) {
        console.log(e.message)
    }
}

const accessLevelValue = (level: 'READ' | 'WRITE' | 'DELETE') => {
    switch (level) {
        case 'WRITE':
            return 2
        case 'DELETE':
            return 3
    }

    return 1
}

export const getUserAccessLevel = (functionality: any) => {
    const res = User.getFunctionalities().find((e: any) => e[functionality])
    if (!res) {
        return null
    }
    return res[functionality]
}

export const canUserAccess = (functionality?: FUNCTIONALITIES, level: ACCESS_LEVEL = ACCESS_LEVEL.READ) => {
    if (!functionality) {
        return true
    }
    let functionalities = User.getFunctionalities()

    return (
        functionalities.filter(
            (e: any) => e[functionality] && accessLevelValue(e[functionality]) >= accessLevelValue(level)
        ).length > 0
    )
}

export function numberWithSpaces(number: number | string | null | undefined) {
    return number === null || number === undefined ? null : number.toLocaleString()
}

export function truncate(str: string, n: number) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str
}

export const fileReaderReadAsDataURLAsync = (file: File) => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader()
            reader.onload = (evt) => {
                resolve(evt?.target?.result)
            }
            reader.readAsDataURL(file)
        } catch (error) {
            reject(error)
        }
    })
}

export const checkSpecialCharacters = (str: string, allowStartWithSpaces = true, errorMessage?: string) => {
    str = typeof str === 'string' ? str : ''
    if (!/^[0-9A-Za-zÀ-ÖØ-öø-ÿŁ-ňŊ-ſ ']*$/.test(str) || (!allowStartWithSpaces && str.startsWith(' '))) {
        throw new Error(errorMessage || 'invalid_character')
    }
    return str
}

export const checkCharactersForDomainName = (str: string, errorMessage?: string) => {
    str = typeof str === 'string' ? str : ''
    if (!/^[0-9a-z-]*$/.test(str) || str.startsWith('-') || str.endsWith('-')) {
        throw new Error(errorMessage || 'invalid_character')
    }
    return str
}

export const ENVIRONMENT = {
    staging: 'https://api-test.transactionconnect.com',
    production: 'https://api.transactionconnect.com',
}
export const getFileNameFromPath = (path: string): string => path.split(/[\\/]/).pop() as string
