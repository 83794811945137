import { useTranslation } from 'react-i18next'

function EmptyList() {
    const { t } = useTranslation('common')

    return (
        <tr>
            <td colSpan={100} className="text-sm text-gray-300 uppercase text-center py-24">
                {t('table.no_data')}
            </td>
        </tr>
    )
}

export default EmptyList
