import { useState, useContext } from 'react'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import ErrorText from '../../components/ErrorText'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { User } from '../../data/UserFetcher'
import * as EmailValidator from 'email-validator'

import { useTranslation } from 'react-i18next'
import InputPassword from '../../components/InputPassword'
import ErrorAlert from '../../components/ErrorAlert'
import { withAuth } from '../../contexts/AuthContext'
import { Password } from '../../validators/PasswordValidator'
import { withProgram } from '../../contexts/ProgramContext'
import ErrorManagementContext from '../../contexts/ErrorManagementContext'

function Login({ setUser, setCurrentProgram }) {
    const navigate = useNavigate()
    const location = useLocation()
    let state = location.state || {}
    const { t } = useTranslation('common')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [serverErrorMessage, setServerErrorMessage] = useState(state.error || '')
    //const [keepLogin, setKeepLogin] = useState(false)
    const [hasEmailError, setHasEmailError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [hasPasswordError, setHasPasswordError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
    const { handleError } = useContext(ErrorManagementContext)

    const emailErrors = ['emailRequired', 'invalidEmail']

    const validatePassword = (password) =>
        Password.isLongEnough(password) &&
        Password.hasOneUppercase(password) &&
        Password.hasOneDigit(password) &&
        Password.hasOneOWASPSpecialCharacter(password)

    const updatePassword = (value) => {
        setPassword(value)
        setPasswordErrorMessage('')
        if (hasPasswordError) {
            const hasError = !validatePassword(value)
            setHasPasswordError(hasError)
        }
    }

    const updateEmail = (value) => {
        setEmail(value)
        if (hasEmailError) {
            checkEmail(email)
        }
    }

    const checkEmail = (email) => {
        if (!EmailValidator.validate(email)) {
            setHasEmailError(true)
            setEmailErrorMessage(t('Spaycial_login.wrongEmailFormat'))
        } else {
            setHasEmailError(false)
            setEmailErrorMessage('')
        }
    }

    const loginUser = () => {
        setIsLoading(true)
        User.login(email, password)
            .then((e) => {
                localStorage.setItem('api_token', e.accessToken)
                localStorage.setItem('tableau_token', e.tableauTicket || '')
                User.get()
                    .then((user) => {
                        // localStorage.removeItem('currentProgram')
                        setCurrentProgram(null)
                        const u = {
                            ...user,
                            isSpaycialUser: !user.shoppingHubOwnerId && !user.backUserHubAssociations?.length,
                        }
                        setUser(u)
                        setIsLoading(false)
                        if (state?.from?.pathname === '/') {
                            navigate('/')
                        } else {
                            navigate(state?.from?.pathname + state?.from?.search || '/')
                        }
                    })
                    .catch((e) => {
                        setUser(null)
                        setIsLoading(false)
                        handleError('errors.login.getUser')(e)
                    })
            })
            .catch((e) => {
                setIsLoading(false)
                setServerErrorMessage('')
                setHasEmailError(false)
                setEmailErrorMessage('')
                setHasPasswordError(false)
                setPasswordErrorMessage('')
                if (emailErrors.includes(e.code)) {
                    setHasEmailError(true)
                    setEmailErrorMessage(t('Spaycial_login.' + e.code))
                } else if (e.code) {
                    setHasPasswordError(true)
                    setPasswordErrorMessage(t('Spaycial_login.' + e.code))
                } else {
                    setHasPasswordError(true)
                    setServerErrorMessage(t('Spaycial_login.internalServerError'))
                }
            })
    }

    return (
        <div className="w-96 flex flex-col gap-8 mx-auto">
            <Heading className="mb-6">{t('Spaycial_login.title')}</Heading>
            {serverErrorMessage !== '' && <ErrorAlert>{t(serverErrorMessage)}</ErrorAlert>}

            <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                    <div>{t('Spaycial_login.emailField')}</div>
                    <Input
                        value={email}
                        onChange={updateEmail}
                        onBlur={(e) => {
                            if (email) checkEmail(email)
                        }}
                        type="email"
                        placeholder={t('Spaycial_login.emailPlaceholder')}
                        error={hasEmailError}
                        data-cy="back_user_email"
                    />
                    <ErrorText>{emailErrorMessage}</ErrorText>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex justify-between">
                        <div>{t('Spaycial_login.passwordField')}</div>
                        <Link to={'/password-forgotten'} className="text-primary" tabIndex="-1">
                            {t('Spaycial_login.forgottenLink')}
                        </Link>
                    </div>
                    <InputPassword
                        value={password}
                        onBlur={(e) => {
                            if (password) {
                                const hasError = !validatePassword(password)
                                setHasPasswordError(hasError)
                            }
                        }}
                        onChange={updatePassword}
                        placeholder="*************************"
                        error={hasPasswordError}
                        data-cy="back_user_password"
                    />
                    <ErrorText>{passwordErrorMessage}</ErrorText>
                </div>
                <Button
                    className="mt-8 py-5 font-bold"
                    disabled={isLoading || email === '' || password === ''}
                    onClick={(e) => loginUser()}
                    data-cy="login_button"
                >
                    {isLoading ? t('Spaycial_login.buttonLoadingLabel') : t('Spaycial_login.buttonLabel')}
                </Button>
            </div>
        </div>
    )
}

export default withProgram(withAuth(Login))
