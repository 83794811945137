import { Request } from '../helpers'

import { isSessionValid, getUserDecodedToken } from '../helpers'

class UserFetcher {
    login(email, password) {
        return Request.post('backoffice/auth/login', {
            email: email,
            password: password,
        })
    }

    sendResetPasswordEmail(email, captcha) {
        return Request.post('backoffice/auth/passwordReset', { email }, { captcha })
    }

    get() {
        if (!isSessionValid()) {
            return Promise.reject({ code: 'TokenNotFound' })
        }
        return Request.get('backoffice/auth/account')
    }

    getFunctionalities() {
        if (!isSessionValid()) {
            return []
        }

        const decodedToken = getUserDecodedToken()

        return decodedToken ? decodedToken.functionalities : []
    }

    patch(userId, data) {
        return Request.patch('backoffice/admin/users/' + userId, data)
    }

    patchCurrent(data) {
        const userId = this.getCurrentId()
        if (userId === null) {
            return Promise.reject({ code: 'TokenNotFound' })
        }

        return this.patch(userId, data)
    }

    getCurrentId() {
        if (!isSessionValid()) {
            return Promise.reject({ code: 'TokenNotFound' })
        }

        return getUserDecodedToken().backUserId
    }

    postAccountUpdate(data) {
        const userId = this.getCurrentId()
        if (userId === null) {
            return Promise.reject({ code: 'TokenNotFound' })
        }

        const { firstName, lastName, jobTitleId, password } = data

        return Request.post('backoffice/auth/accountUpdate', {
            firstName,
            lastName,
            password,
            jobTitleId,
        })
    }

    postAccountResendInvitation(token, captcha) {
        return Request.post('backoffice/admin/users/resend', { token }, { captcha })
    }
}

const User = new UserFetcher()

export { User }
