import { createRef, useContext, useEffect, useState } from 'react'
import { TableauFetcher } from '../data/TableauFetcher'
import YLogoLoading from './Icons/YLogoLoading'
import ErrorText from './ErrorText'
import { useTranslation } from 'react-i18next'
import * as Tailwind from '../../tailwind.config'
import ErrorManagementContext from '../contexts/ErrorManagementContext'

type TableauRendererProps = {
    url: string
    field?: string
    value?: string
}

const TableauRenderer = ({ url, field, value }: TableauRendererProps) => {
    const [onLoad, setOnLoad] = useState(true)
    const [tableauToken, setTableauToken] = useState<string | null>(null)
    const divContainer = createRef<HTMLDivElement>()
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)

    // Fetch a ticket for every view (one-time usage)
    useEffect(() => {
        setTableauToken(null)
        setOnLoad(true)
        TableauFetcher.getToken()
            .then(({ tableau_token }: any) => setTableauToken(tableau_token))
            .catch(handleError('analytics.tableau.get-token'))
    }, [url, handleError])

    // Build the view using Tableau Javascript API
    useEffect(() => {
        if (divContainer.current !== null && tableauToken) {
            // Fit the container's size
            const containerSize = divContainer.current.getBoundingClientRect()
            let viz: any
            let timeoutHandler: any = null
            let options = {
                width: containerSize.width + 'px',
                height: containerSize.height + 'px',
                device: 'desktop',
                hideTabs: true,
                hideToolbar: true,
                onFirstInteractive: () => {
                    viz.getWorkbook()
                        .changeParameterValueAsync(field, value)
                        .then(() => setOnLoad(false))

                    viz.getWorkbook().changeParameterValueAsync('device', 'desktop')

                    // If the param doesn't exist or there is an error, changeParameterValueAsync is not resolved so add a time-based fallback
                    timeoutHandler = setTimeout(() => setOnLoad(false), 1500)
                },
            }

            try {
                // Build the URL using the previously fetched token
                const viewIndex = url.indexOf('/views')
                const urlWithToken = `${url.substring(0, viewIndex)}/trusted/${tableauToken}/${url.substring(
                    viewIndex + 1
                )}`
                viz = new window.tableau.Viz(divContainer.current, urlWithToken, options)
            } catch (e) {
                console.error('Error while rendering Tableau : ', e)
                handleError('analytics.tableau.create-view')(e)
            }

            return () => {
                clearTimeout(timeoutHandler)
                viz.dispose()
            }
        }
    }, [divContainer.current, tableauToken])

    if (onLoad || tableauToken) {
        return (
            <div className="relative h-full">
                <div
                    className={
                        'flex flex-col justify-center items-center absolute inset-0 bg-background ' +
                        (!onLoad ? 'hidden' : '')
                    }
                >
                    <YLogoLoading
                        width={75}
                        border={(Tailwind as any).theme.extend.colors.primary}
                        color="transparent"
                        percentage={50}
                    />
                </div>
                <div id="tableau-container" ref={divContainer} className={'h-full ' + (onLoad ? 'opacity-0' : '')} />
            </div>
        )
    } else return <ErrorText>{t('analytics.tableau.error')}</ErrorText>
}

export default TableauRenderer
