import React, { useEffect, useState } from 'react'
import CreativeProcess from '../../../components/Icons/CreativeProcess'
import Button from '../../../components/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import YLogoLoading from '../../../components/Icons/YLogoLoading'
import Heading from '../../../components/Heading'
import { Request } from '../../../helpers'
import { withProgram } from '../../../contexts/ProgramContext'

function Loading({ currentProgram }) {
    const { t } = useTranslation('common')
    const [percentage, setPercentage] = useState(0)
    const [onLoad, setOnLoad] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const storeData = location.state || {}
    const validate = () => {
        navigate(`/programs/${currentProgram?.id}/stores/create/confirm`)
    }

    const runPercentAnimation = () => {
        setTimeout(() => onLoad && setPercentage(0), 1000)
        setTimeout(() => onLoad && setPercentage(25), 1000)
        setTimeout(() => onLoad && setPercentage(50), 2000)
        setTimeout(() => onLoad && setPercentage(75), 3000)
        setTimeout(() => onLoad && setPercentage(99), 4000)
        setTimeout(() => {
            onLoad && setPercentage(100)
            onLoad && runPercentAnimation()
        }, 5000)
    }

    useEffect(() => {
        runPercentAnimation()
        const {
            brandName,
            brandId,
            googlePlaceId,
            streetName,
            streetNumber,
            city,
            postCode,
            country,
            formattedAddress,
            externalId,
        } = storeData

        Request.post('backoffice/v3/stores', {
            shoppingHubId: currentProgram?.id,
            brandName,
            brandId,
            googlePlaceId,
            streetName,
            streetNumber,
            city,
            postCode,
            country,
            formattedAddress,
            externalId,
        })
            .then((res) => {
                setOnLoad(false)
            })
            .catch((e) => {
                console.error(e)
                navigate(
                    `/programs/${currentProgram?.id}/stores/create/brand`,
                    Object.assign(storeData, { serverError: { key: 'errors.stores.createStore', code: e?.code } })
                )
            })

        return () => {
            setOnLoad(false)
        }
    }, [])

    return (
        <>
            {onLoad ? (
                <div className="flex flex-col justify-center items-center h-full gap-16">
                    <div>
                        <YLogoLoading width="75" border="#FF7700" color="transparent" percentage={percentage} />
                    </div>
                    <Heading>{t('createBrand.loading.creating.title')}</Heading>
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center h-full gap-16 max-w-xl m-auto">
                    <CreativeProcess />
                    <div className="text-center text-xl">{t('createBrand.loading.success.title')}</div>
                    <Button className="py-5 w-full" onClick={() => validate()}>
                        {t('createBrand.loading.success.continue')}
                    </Button>
                </div>
            )}
        </>
    )
}

export default withProgram(Loading)
