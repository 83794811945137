import Modal from '../../../components/Modal'
import Button, { ButtonAppearance } from '../../../components/Button'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Request } from '../../../helpers'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import Input, { InputType } from '../../../components/Input'
import { message } from 'antd'
import YLogoLoading from '../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../tailwind.config'

type CreationModalProps = {
    show: boolean
    onClose: () => void
    shoppingHubOwners: { name: string }[]
    onCreated: () => void
}

function CreationModal({ show, onClose, shoppingHubOwners, onCreated }: CreationModalProps) {
    const uriCreateShoppingHubOwner = '/backoffice/shoppingHubOwners'
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)
    const [name, setName] = useState<string>('')
    const [loading, setLoading] = useState(false)

    const customOnClose = useCallback(() => {
        setName('')
        onClose()
    }, [onClose])

    const onValidate = () => {
        setLoading(true)
        Request.post(uriCreateShoppingHubOwner, { name })
            .then(() => {
                customOnClose()
                onCreated()
                message.success(t('creation_shoppinghubowner.result.success'))
            })
            .catch((data: any) => {
                if (data.code === 'nameAlreadyUsed') {
                    message.error(t('creation_shoppinghubowner.result.already_exists'))
                    return
                }

                handleError('errors.users.create_back_user')
            })
            .finally(() => setLoading(false))
    }

    const isNameValid = useMemo(() => name?.length > 0, [name])
    const nameAlreadyExists = useMemo(
        () => shoppingHubOwners.some((sho) => sho.name === name),
        [shoppingHubOwners, name]
    )

    return (
        <Modal show={show} onClose={customOnClose}>
            <h3 className="text-xl md:ml-6 text-center mb-8">{t('listing_user.create_btn')}</h3>

            <div className="px-4 mt-4">
                <div>{t('creation_shoppinghubowner.field.name')}</div>
                <div>
                    <Input
                        type={InputType.EMAIL}
                        placeholder="Name"
                        value={name}
                        onChange={setName}
                        error={
                            nameAlreadyExists
                                ? new Error(t('creation_shoppinghubowner.result.already_exists'))
                                : undefined
                        }
                    />
                </div>
            </div>

            <div className="flex justify-center mt-4">
                <Button
                    appearance={loading ? ButtonAppearance.BUTTON_APPEARANCE_OUTLINE : undefined}
                    className="mr-3 relative"
                    disabled={!isNameValid || nameAlreadyExists}
                    onClick={() => !loading && onValidate()}
                >
                    {loading ? (
                        <div className="w-12 flex justify-center">
                            <YLogoLoading
                                percentage={50}
                                width={15}
                                height={20}
                                border="white"
                                color={(Tailwind as any).theme.extend.colors.primary}
                            />
                        </div>
                    ) : (
                        t('creation_user.button.create')
                    )}
                </Button>

                <Button appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE} onClick={customOnClose}>
                    {t('creation_user.button.cancel')}
                </Button>
            </div>
        </Modal>
    )
}

export default CreationModal
