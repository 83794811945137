import React from 'react'

function YLogo({ width = 10, height = width * 1.6, color = '#454545' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.8594 0.451447L5.08179 6.99331V6.99196L5.20848 11.2919H5.31685H5.42665L5.5674 6.98655L5.78979 0.451447H9.70271L8.47535 11.6676H6.61601C7.03389 11.9339 7.35135 12.3223 7.52139 12.7754C7.69144 13.2284 7.70503 13.7219 7.56016 14.183C7.41529 14.644 7.11966 15.048 6.71703 15.3351C6.31439 15.6223 5.82616 15.7773 5.3246 15.7773C4.82303 15.7773 4.33479 15.6223 3.93216 15.3351C3.52952 15.048 3.2339 14.644 3.08904 14.183C2.94417 13.7219 2.95776 13.2284 3.1278 12.7754C3.29785 12.3223 3.61531 11.9339 4.03319 11.6676H2.17244L0.945068 0.451447H4.8594Z"
                fill={color}
            />
        </svg>
    )
}

export default YLogo
