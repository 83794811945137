import { Request } from '../helpers'

class Offers {
    get(id, search) {
        let route = 'backoffice/challenges?hubId=' + id
        if (search) {
            route += '&current=true&search=' + search
        }
        return Request.get(route)
    }

    patch(data, headers) {
        return Request.patch('backoffice/challenges', data, headers)
    }
}

const OffersFetcher = new Offers()

export { OffersFetcher }
