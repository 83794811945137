import { useMemo, useState } from 'react'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'
import RestTable from '../../../../../components/Table'
import ReactTooltip from 'react-tooltip'
import { ColumnsType } from 'antd/es/table'
import Badge from '../../../../../components/Badge'
import Input from '../../../../../components/Input'

type RetailerPickerProps = {
    onRetailerSelected: (store: any) => void
}

const mapPatternStatusToBadgeAppearance: Record<string, string> = {
    active: 'success',
    candidate: 'warning',
    rejected: 'error',
}

const RetailerPicker = ({ onRetailerSelected }: RetailerPickerProps) => {
    const [show, setShow] = useState(false)
    const [retailer, setRetailer] = useState<any>()
    const [search, setSearch] = useState<string>()

    const tableQueryUrl = useMemo(() => {
        let uri = 'backoffice/retailers?patterns=true'
        if (search) {
            uri += `${uri.indexOf('?') >= 0 ? '&' : '?'}search=${encodeURIComponent(search)}`
        }
        return uri
    }, [search])

    const columns: ColumnsType<any> = [
        {
            title: 'Retailers',
            className: 'w-1/5 truncate',
            render: (r) => (
                <div data-tip={r.id} data-for={r.name ? `showMoreNameText--${r.id}` : ''}>
                    {r.name || 'N/A'}
                    <ReactTooltip id={`showMoreNameText--${r.id}`}>
                        <span>{r.name}</span>
                    </ReactTooltip>
                </div>
            ),
        },
        {
            title: 'Logo',
            className: 'w-1/5 truncate',
            render: (r) =>
                r.logo_url ? (
                    <div
                        className="flex justify-center items-center"
                        data-tip={r.id + 'logo'}
                        data-for={r.logo_url ? `logo--${r.id}` : ''}
                    >
                        <img src={r.logo_url} alt="" className="max-h-6" />

                        <ReactTooltip id={`logo--${r.id}`} className="text-center">
                            <img src={r.logo_url} alt="" className="max-h-20 max-w-20" />
                        </ReactTooltip>
                    </div>
                ) : (
                    <div className="text-center">
                        <i className="opacity-50">{'No logo'}</i>
                    </div>
                ),
        },
        {
            title: 'Patterns',
            className: 'w-1/4 truncate',
            render: (r) =>
                r.Patterns?.length > 0 ? (
                    r.Patterns.map((p: any, i: number) => (
                        <div data-tip={p.pattern} data-for={r.formattedAddress ? `pattern--${r.id}${i}` : ''}>
                            <Badge
                                className="my-1 block truncate"
                                appearance={mapPatternStatusToBadgeAppearance[p.status]}
                            >
                                {p.pattern}
                                <ReactTooltip id={`pattern--${r.id}${i}`} className="text-center">
                                    <span>
                                        {p.status}
                                        <br />
                                        {p.pattern}
                                    </span>
                                </ReactTooltip>
                            </Badge>
                        </div>
                    ))
                ) : (
                    <i className="opacity-50">{'No pattern associated'}</i>
                ),
        },
    ]

    const debouncedOnChange = (input: string) => {
        setSearch(input)
    }

    return (
        <div>
            <Button onClick={() => setShow(true)}>{retailer ? retailer.name : 'Select a retailer'}</Button>

            <Modal show={show} onClose={() => setShow(false)} width={'800'}>
                <div className="flex-1 mr-6">
                    <Input onChange={debouncedOnChange} placeholder="Search a retailer" value={search} />
                </div>

                <div className="flex w-full mt-4">
                    <RestTable
                        className="mt-16 md:mt-4 mb-4 table-fixed"
                        queryBaseUrl={tableQueryUrl!}
                        columns={columns}
                        errorMessageKey="errors.programs.search"
                        pageSize={10}
                        tableLayout="fixed"
                        pageResultsKey="retailers"
                        onRowClicked={(retailer) => {
                            setShow(false)
                            setRetailer(retailer)
                            onRetailerSelected(retailer)
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default RetailerPicker
