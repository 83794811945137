import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useEffect } from 'react'
import './Datepicker.css'
import moment from 'moment'
import enGB from 'date-fns/locale/en-GB'

type DatepickerProps = {
    className?: string
    onChange?: (e: any) => void
    value: Date
    minDate?: Date
    maxDate?: Date
    placeholder?: string
    error?: boolean
    disabled?: boolean
    dateFormat?: string
    'data-cy'?: string
}

function Input({
    minDate,
    maxDate,
    className,
    onChange,
    value,
    placeholder,
    error,
    disabled,
    dateFormat,
    ...rest
}: DatepickerProps) {
    useEffect(() => {
        registerLocale('en-GB', enGB)
    }, [])

    return (
        <div className={'relative'} data-cy={rest['data-cy']}>
            <DatePicker
                dateFormat={dateFormat}
                selected={moment(value).isValid() ? value : null}
                locale="en-GB"
                onChange={(e) => onChange?.(e)}
                minDate={minDate}
                maxDate={maxDate}
                className={[
                    'border border-1 px-6 py-5 text-base w-full focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-black',
                    error
                        ? 'border-error focus:ring-error'
                        : disabled
                        ? 'border-color-none text-none placeholder-none'
                        : 'border-black focus:ring-transparent',
                    className,
                ].join(' ')}
                placeholderText={placeholder}
                disabled={disabled}
            />
        </div>
    )
}

Input.defaultProps = {
    minDate: null,
    maxDate: null,
    className: '',
    value: '',
    placeholder: '',
    error: false,
    disabled: false,
    dateFormat: 'dd/MM/yyyy',
}

export default Input
