import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Input, { InputType } from '../../../components/Input'
import DropdownInput from '../../../components/DropdownInput'
import Exclamation from '../../../components/Exclamation'
import Label from '../../../components/Label'
import Datepicker from '../../../components/Datepicker'
import { ShoppingHub } from '../../../data/ShoppingHubFetcher'
import { useTranslation } from 'react-i18next'
import { useDeepCompareWithRef } from '../../../helpers'
import { ShoppingHubStatusEnum } from '../../../utils/ShoppingHubUtils'
import InfoTooltip from '../../../components/InfoTooltip'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'
import { withAuth } from '../../../contexts/AuthContext'
import moment from 'moment-timezone'

const getLanguageTranslation = (t, lng) => {
    const supportedLanguages = {
        ca: t('language.catalan'),
        'en-GB': t('language.english'),
        'es-ES': t('language.spanish'),
        'fr-FR': t('language.french'),
        'de-AT': t('language.german'),
        'pl-PL': t('language.polish'),
        'cs-CZ': t('language.czech'),
        'sv-SE': t('language.swedish'),
        'nl-NL': t('language.dutch'),
    }
    return supportedLanguages[lng]
}

const General = forwardRef(({ program, editing, onChange, user }, ref) => {
    const { t } = useTranslation('common')
    const [languageOptions, setLanguageOptions] = useState([])
    const integrationOptions = [
        { label: t('program.config.integrationOptions.api.label'), hasExternalFront: true },
        { label: t('program.config.integrationOptions.plugAndPlay.label'), hasExternalFront: false },
    ]

    const dataRetrievalMethodOptions = useMemo(
        () => [
            { label: t('program.config.dataRetrievalMethodOptions.both'), value: ['scan', 'paymentCard'] },
            { label: t('program.config.dataRetrievalMethodOptions.accountLinking'), value: ['paymentCard'] },
            { label: t('program.config.dataRetrievalMethodOptions.scanEnable'), value: ['scan'] },
        ],
        [t]
    )

    const isInternal = useMemo(() => user && !user.shoppingHubOwnerId, [user])

    const [dataRetrievalMethod, setDataRetrievalMethod] = useState()
    useEffect(() => {
        const currentValue = dataRetrievalMethodOptions.find((d) =>
            d.value.every((val) => program.dataRetrievalMethod?.includes(val))
        )
        setDataRetrievalMethod(currentValue)
    }, [program.dataRetrievalMethod, dataRetrievalMethodOptions])

    const filterLanguageOptions = (lng) => languageOptions.filter((e) => e.value !== lng)

    const [mainLanguage, setMainLanguage] = useState({})
    const [additionalLanguagesOptions, setAdditionalLanguagesOptions] = useState([])
    const [additionalLanguages, setAdditionalLanguages] = useState([])
    const [externalId, setExternalId] = useState('')
    const [walletNotificationThreshold, setWalletNotificationThreshold] = useState()
    const [claimMinimumAmount, setClaimMinimumAmount] = useState()
    const [programLaunchDate, setProgramLaunchDate] = useState()

    const { handleError } = useContext(ErrorManagementContext)

    const programIsLive = program.status === ShoppingHubStatusEnum.LIVE
    // This is used to identify the program's integration type
    // if true, the program integration type is 'api' else `plug&play`
    const hasExternalFront = program.hasExternalFront

    useEffect(() => {
        ShoppingHub.getLanguages()
            .then((res) => {
                const languages = res.map((lng) => ({
                    label: getLanguageTranslation(t, lng),
                    value: lng,
                }))
                setLanguageOptions(languages)
            })
            .catch(handleError('errors.program.config.getLang'))
    }, [])

    useEffect(() => {
        if (program.mainLanguage) {
            setMainLanguage({
                label: getLanguageTranslation(t, program.mainLanguage),
                value: program.mainLanguage,
            })
        }
        setAdditionalLanguagesOptions(
            program.mainLanguage ? filterLanguageOptions(program.mainLanguage) : languageOptions
        )

        if (program.additionalLanguages) {
            setAdditionalLanguages(
                program?.additionalLanguages.map((lng) => ({
                    label: getLanguageTranslation(t, lng),
                    value: lng,
                }))
            )
        }

        setExternalId(program.externalId || '')
        setWalletNotificationThreshold(Math.floor(program.walletNotificationThreshold / 100) || 500)
        setClaimMinimumAmount(program.claimMinimumAmount)
        setProgramLaunchDate(Date.parse(program.programLaunchDate))
    }, [useDeepCompareWithRef(program, useRef)])

    useEffect(() => {
        // this effect is used to provide instant feedback to the input values
        // for an `api` integration type, it should have an externalId attached
        const hasError = hasExternalFront && !programIsLive && !externalId
        onChange(hasError)
    }, [externalId, onChange, programIsLive])

    useImperativeHandle(ref, () => {
        // for an `api` integration type, it should have an externalId attached
        const hasError = hasExternalFront && !programIsLive && !externalId
        return {
            validate,
            hasError,
        }
    })

    const validate = () => {
        const data = {
            mainLanguage: mainLanguage.value || null,
            additionalLanguages: additionalLanguages.map((lng) => lng.value),
            externalId: externalId || null,
            walletNotificationThreshold: walletNotificationThreshold * 100, // send amount in cents
            dataRetrievalMethod: dataRetrievalMethod.value,
            programLaunchDate: programLaunchDate || undefined,
            claimMinimumAmount: claimMinimumAmount,
        }
        return data
    }

    const programIsNotLiveYet = useMemo(
        () => [ShoppingHubStatusEnum.PENDING, ShoppingHubStatusEnum.VALIDATED].includes(program.status),
        [program.status]
    )

    return (
        <div className="flex flex-col gap-4 p-1 mb-12">
            <div className="md:mt-10 grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6 md:gap-y-10">
                <div className="col-span-1">
                    <Label>{t('program.config.createdDate.label')}</Label>
                    <Datepicker
                        className="mt-3.5"
                        value={Date.parse(program.createdAt)}
                        placeholder={'JJ/MM/AAAA'}
                        disabled={true}
                    />
                </div>
                <div className="col-span-1">
                    <Label>{t('program.config.launchDate.label')}</Label>
                    <Datepicker
                        className="mt-3.5"
                        value={programLaunchDate}
                        placeholder={'JJ/MM/AAAA'}
                        minDate={moment().add(1, 'days').toDate()}
                        onChange={setProgramLaunchDate}
                        disabled={!editing || !isInternal || !programLaunchDate}
                    />
                </div>
                <div className="col-span-1">
                    <Label>{t('program.config.updatedDate.label')}</Label>
                    <Datepicker
                        className="mt-3.5"
                        value={Date.parse(program.updatedAt)}
                        placeholder={'JJ/MM/AAAA'}
                        disabled={true}
                    />
                </div>
                <div className="col-span-1">
                    <Label>
                        {t('program.config.hubShortcut.label')}{' '}
                        <InfoTooltip text={t('program.config.hubShortcut.info')} />
                    </Label>
                    <Input className="mt-3.5" value={program.shortcut} type="text" placeholder="" disabled={true} />
                </div>
                <div className="col-span-1">
                    <div className="flex justify-between">
                        <Label>
                            {t('program.config.currency.label')}{' '}
                            <InfoTooltip text={t('program.config.currency.info')} />
                        </Label>
                    </div>
                    <Input className="mt-3.5" value={program.currency} type="text" placeholder="" disabled={true} />
                </div>
                <div className="col-span-1">
                    <div className="flex justify-between">
                        <Label>{t('program.config.language.label')}</Label>
                        {editing && !mainLanguage && <Exclamation />}
                    </div>
                    <DropdownInput
                        className="mt-3.5"
                        isClearable={false}
                        indicatorSeparator={null}
                        options={languageOptions}
                        value={mainLanguage}
                        placeholder={t('program.config.language.placeholder.label')}
                        onChange={(e) => {
                            setMainLanguage(e)
                            setAdditionalLanguages(additionalLanguages.filter((lng) => lng.value !== e.value))
                            setAdditionalLanguagesOptions(filterLanguageOptions(e.value))
                        }}
                        disabled={!editing}
                    />
                </div>
                <div className="col-span-1">
                    <Label>
                        {t('program.config.integration.label')}{' '}
                        <InfoTooltip text={t('program.config.integration.info')} />
                    </Label>
                    <Input
                        className="mt-3.5"
                        value={integrationOptions.filter((e) => e.hasExternalFront === hasExternalFront)[0].label}
                        type="text"
                        placeholder=""
                        disabled={true}
                    />
                </div>
                <div className="col-span-1">
                    <Label>
                        {t('program.config.walletNotificationThreshold.label')}{' '}
                        <InfoTooltip
                            text={t('program.config.walletNotificationThreshold.info', { currency: program.currency })}
                        />
                    </Label>
                    <Input
                        className="mt-3.5"
                        value={walletNotificationThreshold}
                        onChange={setWalletNotificationThreshold}
                        type={InputType.NUMBER}
                        placeholder="500"
                        disabled={!editing}
                        currency={true}
                    />
                </div>
                <div className="col-span-1">
                    <div className="flex justify-between">
                        <Label>{t('program.config.dataRetrievalMethod.label')}</Label>
                    </div>
                    <DropdownInput
                        className="mt-3.5"
                        isClearable={false}
                        options={dataRetrievalMethodOptions}
                        value={dataRetrievalMethod}
                        onChange={setDataRetrievalMethod}
                        disabled={!editing || !programIsNotLiveYet}
                    />
                </div>
                <div className="col-span-1">
                    <div className="flex justify-between">
                        <Label>
                            {t('program.config.additionalLanguage.label')}{' '}
                            <InfoTooltip text={t('program.config.additionalLanguage.info')} />
                        </Label>
                    </div>
                    <DropdownInput
                        className="mt-3.5"
                        isMulti
                        isClearable={false}
                        indicatorSeparator={null}
                        options={additionalLanguagesOptions}
                        value={additionalLanguages}
                        placeholder={t('program.config.language.placeholder.label')}
                        onChange={setAdditionalLanguages}
                        disabled={!editing || !mainLanguage}
                    />
                </div>
                {program.hasExternalFront && (
                    <div
                        className="col-start-2 col-span-1"
                        data-tip={program.id}
                        data-for={programIsLive ? `showMessage--${program.id}` : ''}
                    >
                        <div className="flex justify-between">
                            <Label>
                                {t('program.config.externalProgramID.label')}
                                <InfoTooltip text={t('program.config.externalProgramID.info')} />
                            </Label>
                            {!externalId && editing && !programIsLive && <Exclamation />}
                        </div>
                        <Input
                            className="mt-3.5"
                            value={externalId}
                            onChange={setExternalId}
                            type="text"
                            disabled={!editing || programIsLive}
                        />
                        <ReactTooltip id={`showMessage--${program.id}`}>
                            <span>{t('program.config.externalProgramID.tooltip.label')}</span>
                        </ReactTooltip>
                    </div>
                )}
                <div className="col-span-1">
                    <Label>{t('program.config.claimMinimumAmount.label')} </Label>
                    <Input
                        className="mt-3.5"
                        value={claimMinimumAmount}
                        onChange={setClaimMinimumAmount}
                        type={InputType.NUMBER}
                        placeholder="0"
                        disabled={!editing}
                        currency={true}
                    />
                </div>
            </div>
        </div>
    )
})

General.propTypes = {
    editing: PropTypes.bool,
}

export default withAuth(General)
