import PropTypes from 'prop-types'

function Exclamation({ className = '' }) {
    return (
        <div
            className={[
                'h-5 w-5 bg-primary text-white flex justify-center items-center text-lg leading-none',
                className,
            ].join(' ')}
        >
            !
        </div>
    )
}

Exclamation.propTypes = {
    className: PropTypes.string,
}

export default Exclamation
