import SpaycialLogo from '../components/Icons/SpaycialLogo'
import TwitterLogo from '../components/Icons/TwitterLogo'
import SocialBox from '../components/SocialBox'
import LinkedInLogo from '../components/Icons/LinkedInLogo'
import TFLogo from '../components/Icons/TFLogo'
import { useTranslation } from 'react-i18next'

export const Guest = ({ children }) => {
    const { t } = useTranslation('common')
    return (
        <div className="min-h-screen bg-white md:flex md:flex-row">
            <div className="py-8 md:pl-14 flex flex-col gap-2 justify-between items-center md:items-start bg-guest-primary text-white md:w-full md:max-w-xs lg:max-w-lg">
                <div className="md:flex flex-row md:flex-col lg:flex-row">
                    <SpaycialLogo />
                </div>

                {window.location.pathname === '/login' && (
                    <div className="hidden md:flex flex-row mt-2 md:flex-col lg:flex-row gap-6 mb-6">
                        <SocialBox url="https://twitter.com/spaycial_com">
                            <TwitterLogo />
                        </SocialBox>
                        <SocialBox url="https://www.linkedin.com/company/spaycial/">
                            <LinkedInLogo />
                        </SocialBox>
                        <div className="ml-6 md:ml-0 lg:ml-6 flex flex-row md:flex-row-reverse lg:flex-row gap-4 items-center">
                            <div className="uppercase">{t('login.leaveAComment')}</div>
                            <SocialBox
                                url="https://trustfolio.co/profil/transactioncon-rsoDpZ4B"
                                className="bg-guest-secondary"
                            >
                                <TFLogo />
                            </SocialBox>
                        </div>
                    </div>
                )}
            </div>
            <div className="hidden md:flex flex-col">
                <div className="w-16 h-1/2 bg-guest-primary transform -skew-x-6 origin-top-left shadow-guest-firefox-fix" />
                <div className="w-16 h-1/2 bg-guest-primary transform -skew-x-6 origin-top-left shadow-guest-firefox-fix" />
            </div>
            <div className="md:flex-1 py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
                {' '}
                {/*flex flex-col md:justify-center*/}
                <div className="mx-auto lg:max-w-3xl relative">{children}</div>
            </div>
        </div>
    )
}

export default Guest
