import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Information from './Icons/Information'
import './InfoTooltip.css'
import * as Tailwind from '../../tailwind.config'

function InfoTooltip({ text = '', element, className = '' }) {
    const [id] = useState(`tooltip-${Math.random().toString()}`)
    const [show, setShow] = useState(false)

    return (
        <span>
            <a data-for={id} data-tip={text} data-event="click focus" className={'cursor-pointer ' + className}>
                {element || <Information color={show ? Tailwind.theme?.extend?.colors.primary : '#00000055'} />}
            </a>
            <ReactTooltip
                globalEventOff="click"
                type="light"
                isCapture={true}
                effect="solid"
                multiline={true}
                id={id}
                className="info-tooltip"
                afterShow={() => setShow(true)}
                afterHide={() => setShow(false)}
            />
        </span>
    )
}

InfoTooltip.propTypes = {
    text: PropTypes.string,
    element: PropTypes.element,
}

export default InfoTooltip
