import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { TabsContext } from './Tabs'

type TabProps = {
    name: string
    children: React.ReactNode
    'data-cy'?: string
    className?: string
    disabled?: boolean
}

function Tab({ children, name, className = '', disabled = false, ...rest }: TabProps) {
    const context = useContext(TabsContext)
    const stateClass =
        context.current === name
            ? 'text-primary border-primary font-bold'
            : disabled
            ? 'text-none cursor-not-allowed border-transparent'
            : 'text-black border-transparent hover:text-dark'
    return (
        <button
            data-cy={rest['data-cy']}
            className={['border-b-3 pb-0 text-lg', stateClass, className].join(' ')}
            disabled={disabled}
            onClick={() => context.setCurrent(name)}
        >
            {children}
        </button>
    )
}

Tab.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
}

export default Tab
