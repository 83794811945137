import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tab'
import { useState } from 'react'
import PatternCreation from './components/PatternCreation'
import StorePicker from './components/StorePicker'
import RetailerPicker from './components/RetailerPicker'

const Create = () => {
    const [currentTab, setCurrentTab] = useState('store')

    return (
        <div className="p-1 pl-4 h-full flex flex-col">
            <h3 className="text-3xl text-black text-left md:spl-8">Highlight your data! ✨</h3>
            <Tabs className="my-4 md:my-10" current={`${currentTab}`} setCurrent={setCurrentTab}>
                {tabs.map((t) => (
                    <Tab key={t.key} name={t.key}>
                        {t.label}
                    </Tab>
                ))}
            </Tabs>

            {currentTab === 'store' && (
                <PatternCreation patternType="store" getPicker={(set) => <StorePicker onStoreSelected={set} />} />
            )}
            {currentTab === 'retailer' && (
                <PatternCreation
                    patternType="retailer"
                    getPicker={(set) => <RetailerPicker onRetailerSelected={set} />}
                />
            )}
            {currentTab === 'online' && <PatternCreation patternType="is_online" />}
        </div>
    )
}

const tabs = [
    {
        key: 'store',
        label: 'Store',
    },
    {
        key: 'retailer',
        label: 'Retailer',
    },
    {
        key: 'online',
        label: 'Is online / EShop',
    },
]

export default Create
