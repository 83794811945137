import { useEffect, useState } from 'react'
import moment from 'moment'
import { Offer } from '../../interfaces/Offer'

export const useOverlapOtherWelcomeOffer = (
    offerType: string | undefined,
    offers: Offer[],
    startDate: Date | string | undefined,
    endDate: Date | string | undefined,
    programTimezone: string
) => {
    const [overlappedOffer, setOverlappedOffer] = useState<Offer>()

    useEffect(() => {
        if (offerType?.startsWith('welcome') && startDate && endDate) {
            const existingWelcomeOffers = offers?.filter((o) => o.offerType === 'welcome')

            for (const existingOffer of existingWelcomeOffers) {
                if (existingOffer.manuallyDeactivated || !existingOffer.active) continue

                const existingStartDate = moment(existingOffer.startDate).tz(programTimezone)
                const existingEndDate = moment(existingOffer.endDate || '5000-01-01').tz(programTimezone) //Simplify algorithm
                const currentStartDate = moment(startDate).tz(programTimezone, true)
                const currentEndDate = moment(endDate).tz(programTimezone, true).add(1, 'days')

                const included =
                    currentStartDate.isBetween(existingStartDate, existingEndDate, undefined, '[]') &&
                    currentEndDate.isBetween(existingStartDate, existingEndDate, undefined, '[]')

                const includes =
                    existingStartDate.isBetween(currentStartDate, currentEndDate, undefined, '[]') &&
                    existingEndDate.isBetween(currentStartDate, currentEndDate, undefined, '[]')

                const overlapsBefore =
                    currentStartDate.isBefore(existingStartDate) &&
                    currentEndDate.isBetween(existingStartDate, existingEndDate, undefined, '(]')

                const overlapsAfter =
                    currentStartDate.isBetween(existingStartDate, existingEndDate, undefined, '[)') &&
                    currentEndDate.isAfter(existingEndDate)

                if (included || includes || overlapsBefore || overlapsAfter) {
                    return setOverlappedOffer(existingOffer)
                }
            }
        }

        setOverlappedOffer(undefined)
    }, [offers, startDate, endDate, programTimezone, offerType])

    return {
        overlappedOffer,
    }
}
