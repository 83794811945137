import React, { forwardRef, ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { logRocketIdentifyUser } from '../helpers'
import { User } from '../data/UserFetcher'
import Loading from '../components/Loading'

export type WithAuthProps = {
    user?: any
    setUser: (u: any) => void
}

const AuthContext = React.createContext<WithAuthProps>({
    setUser: () => {},
})

const TOKEN_STORAGE_KEY = 'api_token'

export function AuthProvider({ children }: { children: ReactNode }) {
    const search = useLocation().search
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const token = new URLSearchParams(search).get('t')

    if (token !== null) {
        localStorage.setItem(TOKEN_STORAGE_KEY, token)
    }

    useEffect(() => {
        User.get()
            .then((e) => {
                const u = {
                    ...e,
                    isSpaycialUser: !e.shoppingHubOwnerId && !e.backUserHubAssociations?.length,
                }

                setUser(u)
                logRocketIdentifyUser(u)
            })
            .catch(() => {
                setUser(null)
            })
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) return <Loading />

    return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
}

export function withAuth<T extends WithAuthProps = WithAuthProps>(Component: React.ComponentType<T>): (p: any) => any {
    return forwardRef((props: any, ref: any) => (
        <AuthContext.Consumer>{(value) => <Component {...props} {...value} ref={ref} />}</AuthContext.Consumer>
    ))
}

export default AuthContext
