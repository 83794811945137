const getEnv = require('./src/utils/GetEnv')

const env = getEnv()
const mapEnvToPrimaryColor = {
    development: '#27ae60',
    staging: '#3F65BF',
    demo: '#FF7700',
    production: '#FF7700',
}

/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
    theme: {
        extend: {
            maxWidth: {
                60: '15rem',
            },
            minWidth: {
                1: '1rem',
            },
            borderWidth: {
                1: '1px',
                3: '3px',
            },
            spacing: {
                3.25: '0.80rem',
                15: '3.75rem',
            },
            width: {
                '1/6': '15%',
                iphone: '209px',
            },
            height: {
                'standard-input': '66px',
                iphone: '460px',
            },
            fontSize: {
                //Those are used for the Iphone container
                xxs: '10px',
                xxxs: '5px',
            },
            fontFamily: {
                spaycial: 'GT-Flexa, sans-serif',
            },
            colors: {
                orange: '#FF7700',
                primary: mapEnvToPrimaryColor[env],
                success: '#0CD05A',
                error: '#FF0000',
                warning: '#FF7700',
                info: '#17185B',
                none: '#C4C4C4',
                'guest-primary': '#ECBFD0',
                'guest-secondary': '#F9EDAB',
                background: '#F9F9F9',
                'error-light': '#FCEAEC',
                disabled: '#D1D3D4',
                dark: '#333333',
                light: '#FBFBFB',
            },
            borderColor: (theme) => ({
                ...theme('colors'),
                'color-none': '#C4C4C4',
            }),
            boxShadow: {
                // This is to fix Firefox "half pixel bug", where two 50vh blocks are one pixel short from the whole screen if its height is an odd number.
                'guest-firefox-fix': '0 -1px #ECBFD0, 0 1px #ECBFD0',
                'user-firefox-fix': '0 -1px #FFFFFF, 0 1px #FFFFFF',
            },
            keyframes: {
                wave: {
                    '0%': { transform: 'translate3d(0, 0, 0)' },
                    '100%': { transform: 'translate3d(-1430px, 0, 0)' },
                },
            },
            animation: {
                wave: 'wave 2s linear infinite',
            },
        },
        zIndex: {
            1: 1,
            999: 999,
        },
    },
    variants: {
        extend: {
            backgroundColor: ['checked'],
            ringColor: ['focus'],
            ringWidth: ['focus'],
            opacity: ['disabled'],
        },
    },
    plugins: [require('@tailwindcss/forms')],
}
