type ChevronDownProps = {
    className?: string
}

function ChevronDown({ className }: ChevronDownProps) {
    return (
        <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4.48438 6.24219C4.76563 6.52344 5.23438 6.52344 5.51563 6.24219L9.76563 1.99219C10.0781 1.67969 10.0781 1.21094 9.76563 0.929686L9.07813 0.210936C8.76562 -0.070314 8.29687 -0.070314 8.01563 0.210936L4.98438 3.24219L1.98438 0.210937C1.70312 -0.0703128 1.23437 -0.0703127 0.921875 0.210937L0.234375 0.929687C-0.0781248 1.21094 -0.0781247 1.67969 0.234375 1.99219L4.48438 6.24219Z"
                fill="black"
            />
        </svg>
    )
}

export default ChevronDown
