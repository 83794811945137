import { useState } from 'react'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'
import RestTable from '../../../../../components/Table'
import ReactTooltip from 'react-tooltip'
import getCountryName from '../../../../../utils/GetCountryName'
import { ColumnsType } from 'antd/es/table'
import useSearchStoresFilters from '../../../../../hooks/stores/useSearchStoresFilters'
import StoreFilter from '../../../../../components/StoreFilter'
import Badge from '../../../../../components/Badge'

type StorePickerProps = {
    onStoreSelected: (store: any) => void
}

const mapPatternStatusToBadgeAppearance: Record<string, string> = {
    active: 'success',
    candidate: 'warning',
    rejected: 'error',
}

const StorePicker = ({ onStoreSelected }: StorePickerProps) => {
    const { tableQueryUrl, onPageReceived, onFilterChanges, countryCitiesData } = useSearchStoresFilters({
        patterns: true,
    })

    const [show, setShow] = useState(false)
    const [store, setStore] = useState<any>()

    const columns: ColumnsType<any> = [
        {
            title: 'Stores',
            className: 'w-1/5 truncate',
            render: (s) => (
                <div data-tip={s.id} data-for={s.name ? `showMoreNameText--${s.id}` : ''}>
                    {s.name || 'N/A'}
                    <ReactTooltip id={`showMoreNameText--${s.id}`}>
                        <span>{s.name}</span>
                    </ReactTooltip>
                </div>
            ),
        },
        {
            title: 'Country',
            className: 'w-1/5 truncate',
            render: (s) => getCountryName(s.country) || 'N/A',
        },
        { title: 'City', className: 'w-1/5 truncate', render: (s) => s.locality || 'N/A' },
        {
            title: 'Address',
            className: 'w-1/4 truncate',
            render: (s) => (
                <div data-tip={s.id} data-for={s.formattedAddress ? `showMoreAddressText--${s.id}` : ''}>
                    {s.formattedAddress || 'N/A'}
                    <ReactTooltip id={`showMoreAddressText--${s.id}`}>
                        <span>{s.formattedAddress}</span>
                    </ReactTooltip>
                </div>
            ),
        },
        {
            title: 'Shopping Hub',
            className: 'w-1/4 truncate',
            render: (s) => s.ShoppingHub?.name,
        },
        {
            title: 'Patterns',
            className: 'w-1/4 truncate',
            render: (s) =>
                s.Patterns?.length > 0 ? (
                    s.Patterns.map((p: any, i: number) => (
                        <div data-tip={p.pattern} data-for={s.formattedAddress ? `pattern--${s.id}${i}` : ''}>
                            <Badge
                                className="my-1 block truncate"
                                appearance={mapPatternStatusToBadgeAppearance[p.status]}
                            >
                                {p.pattern}
                                <ReactTooltip id={`pattern--${s.id}${i}`} className="text-center">
                                    <span>
                                        {p.status}
                                        <br />
                                        {p.pattern}
                                    </span>
                                </ReactTooltip>
                            </Badge>
                        </div>
                    ))
                ) : (
                    <i className="opacity-50">{'No pattern associated'}</i>
                ),
        },
    ]

    return (
        <div>
            <Button onClick={() => setShow(true)}>{store ? store.name : 'Select a store'}</Button>

            <Modal show={show} onClose={() => setShow(false)} width={'800'}>
                <div className="flex-1 mr-6">
                    <StoreFilter onChange={onFilterChanges} data={countryCitiesData} />
                </div>

                <div className="flex w-full mt-4">
                    <RestTable
                        className="mt-16 md:mt-4 mb-4 table-fixed"
                        queryBaseUrl={tableQueryUrl}
                        columns={columns}
                        errorMessageKey="errors.programs.search"
                        pageSize={10}
                        tableLayout="fixed"
                        onDataReceived={onPageReceived}
                        onRowClicked={(store) => {
                            setShow(false)
                            setStore(store)
                            onStoreSelected(store)
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default StorePicker
