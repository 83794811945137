import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { ShopperProfileSegment } from '../shopper-profile-behavior-types'
import { useMemo } from 'react'

type ShoppingCategoriesChartProps = {
    segments: ShopperProfileSegment[]
    unselectedSegmentsId: string[]
}

const colors = ['#FFD200', '#ECBFD0', '#17185B', '#3F65BF']
const RADIAN = Math.PI / 180

function ShoppingCategoriesChart({ segments, unselectedSegmentsId }: ShoppingCategoriesChartProps) {
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.4
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)
        const fillColor = unselectedSegmentsId.includes(pieData[index].id) ? '#D1D3D4' : colors[index % colors.length]

        return (
            <>
                <text x={x} y={y - 10} fill={fillColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="start">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
                <text x={x} y={y + 10} fill={fillColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="start">
                    {segments[index].name}
                </text>
            </>
        )
    }

    const pieData = useMemo(
        () => segments.map((s) => ({ name: s.name, value: s.total_spend_in_mall, id: s.id })),
        [segments]
    )

    if (!segments || segments.length === 0) return <div className="mb-8"></div>

    return (
        <div className="flex items-center justify-center h-64 my-4">
            <ResponsiveContainer debounce={100}>
                <PieChart className="cursor-pointer">
                    <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        outerRadius={65}
                        paddingAngle={0}
                        dataKey="value"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        isAnimationActive={true}
                        startAngle={360 + 90}
                        endAngle={90}
                    >
                        {pieData.map((_: any, index: number) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    unselectedSegmentsId.includes(pieData[index].id)
                                        ? '#D1D3D4'
                                        : colors[index % colors.length]
                                }
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ShoppingCategoriesChart
