import PropTypes from 'prop-types'
import { PencilIcon } from '@heroicons/react/solid'
import { ChromePicker } from 'react-color'
import { useState } from 'react'
import './ColorPicker.css'
import { Popover } from 'antd'

function ColorPicker({ className, onChange, value = '#000000', disabled }) {
    const [open, setOpen] = useState(false)
    let classes = ['relative border-2 border-gray-500 relative flex flex-row']
    classes.push(className)

    const button = !disabled ? (
        <button
            onClick={() => setOpen(!open)}
            className="bg-gray-700 bg-opacity-80 border-l-2 border-gray-500 border-opacity-50 px-3 h-14"
        >
            <PencilIcon className="h-5 text-white" />
        </button>
    ) : (
        <div className="h-14" />
    )

    return (
        <div>
            {open && <div className="fixed left-0 top-0 right-0 bottom-0" onClick={(e) => setOpen(false)} />}
            <div className={classes.join(' ')} style={{ backgroundColor: value }}>
                <div className="w-full" />

                <Popover
                    content={
                        <div className="relative" style={{ margin: '-12px -16px' }}>
                            <ChromePicker
                                color={value}
                                onChangeComplete={(color) => onChange(color.hex)}
                                disableAlpha
                                style={{ borderRadius: 0 }}
                            />
                        </div>
                    }
                    trigger="click"
                    open={open}
                    onOpenChange={(o) => setOpen(o)}
                >
                    {button}
                </Popover>
            </div>
        </div>
    )
}

ColorPicker.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default ColorPicker
