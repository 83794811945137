import React, { useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'

function DropDown({ children, ...rest }) {
    const [open, setOpen] = useState(false)

    const ref = useRef()

    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    element: popperElement,
                    offset: [-60, -40],
                },
            },
        ],
    })

    function useOnClickOutside(ref, handler) {
        useEffect(() => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return
                }
                handler(event)
            }
            document.addEventListener('mousedown', listener)
            document.addEventListener('touchstart', listener)
            return () => {
                document.removeEventListener('mousedown', listener)
                document.removeEventListener('touchstart', listener)
            }
        }, [ref, handler])
    }

    useOnClickOutside(ref, () => setOpen(false))

    return (
        <div className={'relative flex flex-col gap-4 mr-3 cursor-pointer dropdown'} ref={ref}>
            <div
                className={[
                    'flex gap-4 items-center justify-center text-sm px-3 py-4',
                    open ? 'font-bold text-primary' : '',
                ].join(' ')}
                ref={setReferenceElement}
                onClick={() => setOpen((prev) => !prev)}
                data-cy={rest['data-cy']}
            >
                <svg
                    className={'fill-current'}
                    width="4"
                    height="14"
                    viewBox="0 0 4 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 0.25C1.175 0.25 0.5 0.925 0.5 1.75C0.5 2.575 1.175 3.25 2 3.25C2.825 3.25 3.5 2.575 3.5 1.75C3.5 0.925 2.825 0.25 2 0.25ZM2 10.75C1.175 10.75 0.5 11.425 0.5 12.25C0.5 13.075 1.175 13.75 2 13.75C2.825 13.75 3.5 13.075 3.5 12.25C3.5 11.425 2.825 10.75 2 10.75ZM2 5.5C1.175 5.5 0.5 6.175 0.5 7C0.5 7.825 1.175 8.5 2 8.5C2.825 8.5 3.5 7.825 3.5 7C3.5 6.175 2.825 5.5 2 5.5Z"
                        fill="#333333"
                    />
                </svg>
            </div>
            {open && (
                <div
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="bg-white flex flex-col z-10"
                >
                    {children}
                </div>
            )}
        </div>
    )
}

export default DropDown
