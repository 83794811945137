import YLogoLoading from '../../../components/Icons/YLogoLoading'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Created() {
    const { t } = useTranslation('common')
    const navigate = useNavigate()

    useEffect(() => {
        window.setTimeout(() => navigate('/login'), 2000)
    }, [])

    return (
        <div className={'fixed w-screen h-screen inset-0 bg-guest-primary flex items-center justify-center flex-col'}>
            <YLogoLoading width="75" border="white" color="transparent" percentage={5} />
            <h2 className={'text-white text-3xl mt-12 p-5 text-center'}>{t('Loading_screen.title')}</h2>
        </div>
    )
}

export default Created
