import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'
import getEnv from './utils/GetEnv'

const env = getEnv() === 'staging' ? 'staging' : process.env.NODE_ENV
const version = env === 'development' ? 'latest' : env
export const I18N_STORAGE_KEY = 'spaycial_lng'

const locizeOptions = {
    development: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_API,
        referenceLng: 'en',
        version: version,
        allowedAddOrUpdateHosts: () => true,
        saveMissingTo: 'all',
        saveMissing: true,
        updateMissing: true,
        crossDomain: true,
        private: false,
    },
    default: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_API,
        referenceLng: 'en',
        version: version,
    },
}

i18n
    // i18next-locize-backend
    // loads translations from your project, saves new keys to it (saveMissing: true)
    // https://github.com/locize/i18next-locize-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        saveMissing: true,
        // keySeparator: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: locizeOptions[env] || locizeOptions.default,
        react: {
            useSuspense: true,
        },
        ns: ['common'],
        defaultNS: 'common',
    })

i18n.changeLanguage(localStorage.getItem(I18N_STORAGE_KEY) || 'en')

export default i18n
