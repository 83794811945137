import React, { forwardRef, useEffect, useState } from 'react'
import Input from '../../../components/Input'
import DropdownInput from '../../../components/DropdownInput'
import Label from '../../../components/Label'
import moment from 'moment'
import Textarea from '../../../components/Textarea'
import Datepicker from '../../../components/Datepicker'
import { withProgram } from '../../../contexts/ProgramContext'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Switch from '../../../components/Switch'
import { useFormData } from '../../../hooks/OfferCreationHook/useFormData'
import InfoTooltip from '../../../components/InfoTooltip'
import FormError from '../../../components/FormError'
import { useOverlapOtherWelcomeOffer } from '../../../hooks/OfferCreationHook/useOverlapOtherWelcomeOffer'

const Setup = forwardRef((props, ref) => {
    const { t } = useTranslation('common')
    const location = useLocation()
    const [offerType, setOfferType] = useState(location?.state?.offerType)
    const searchParams = new URLSearchParams(location.search)
    const { data, updateFormValue, updateFormValues, displayMultipleOccurrencePerDayToggle } = useFormData(
        props,
        offerType,
        ref
    )
    const { overlappedOffer } = useOverlapOtherWelcomeOffer(
        offerType,
        props.offers,
        data.startDate,
        data.endDate,
        props.programTimezone
    )

    useEffect(() => {
        props.hasError(!!overlappedOffer)
    }, [overlappedOffer])

    useEffect(() => {
        if (!location?.state) {
            setOfferType(searchParams.get('offerType'))
        }
    }, [location, searchParams])

    const onStartDateChange = (start) => {
        const toUpdate = {
            startDate: start,
            // By default, email notification will be sent at 9:30am
            emailNotificationDate: moment(start).set('hour', 9).set('minutes', 30).toDate(),
        }

        if (!!data.endDate && data.endDate.getTime() <= start?.getTime()) toUpdate.endDate = moment(start).toDate()

        updateFormValues(toUpdate)
    }

    const checkInputInteger = (value, property) => {
        if (Math.abs(value) === 0) return updateFormValue(value, property)
        return Math.abs(value) ? updateFormValue(Math.abs(value), property) : updateFormValue(undefined, property)
    }

    return (
        <div className={'lg:mt-20 grid grid-cols-12 mx-auto gap-3'}>
            <div className={'col-span-12 xl:col-span-11 2xl:col-span-10 grid grid-cols-8 gap-6'}>
                <div className={'col-span-8 lg:col-span-4'}>
                    <Label>
                        {t('offerCreation.name')} <InfoTooltip text={t('offerCreation.name-info')} />
                    </Label>
                    <Input
                        className="mt-3.5"
                        onChange={(e) => updateFormValue(e, 'teaser')}
                        value={data.teaser}
                        placeholder={t('offerCreation.myOffer')}
                        maxLength={25}
                        data-cy="offer_teaser"
                    />
                </div>

                <div className={'col-span-8 lg:col-span-2'}>
                    <Label>
                        {t('offerCreation.from')} <InfoTooltip text={t('offerCreation.from-info')} />
                    </Label>
                    <Datepicker
                        minDate={moment().toDate()}
                        className="mt-3.5"
                        onChange={onStartDateChange}
                        value={data.startDate}
                        error={!!overlappedOffer}
                        placeholder={t('offerCreation.fromDate')}
                        data-cy="offer_start_date"
                    />
                </div>

                <div className={'col-span-8 lg:col-span-2'}>
                    <Label>
                        {t('offerCreation.to')} <InfoTooltip text={t('offerCreation.to-info')} />
                    </Label>
                    <Datepicker
                        minDate={data.startDate}
                        className="mt-3.5"
                        onChange={(e) => updateFormValue(e, 'endDate')}
                        value={data.endDate}
                        error={!!overlappedOffer}
                        placeholder={t('offerCreation.toDate')}
                        data-cy="offer_end_date"
                    />
                    {overlappedOffer && (
                        <FormError>
                            {t('offerCreation.error.overlap-existing-welcome-offer', {
                                offerTeaser: overlappedOffer.teaser,
                            })}
                        </FormError>
                    )}
                </div>

                <div className={'col-span-8 lg:col-span-2 grid gap-x-2 gap-y-4'}>
                    <div className="flex flex-col">
                        <Label className="flex justify-between">
                            <div>
                                {t('offerCreation.reward')} <InfoTooltip text={t('offerCreation.reward-info')} />
                            </div>
                        </Label>
                        <div className="flex gap-2">
                            <Input
                                type={'number'}
                                className="mt-3.5"
                                onChange={(e) => checkInputInteger(e, 'rewardValue')}
                                value={data.rewardValue}
                                placeholder={'10'}
                                disabled={data.activeCashback}
                                validateIntegerNumbers={true}
                                data-cy="offer_reward_value"
                            />
                            <DropdownInput
                                className="mt-3.5 h-standard-input"
                                onChange={(e) => updateFormValue(e, 'rewardUnit')}
                                value={data.rewardUnit}
                                options={data.rewardUnits}
                                placeholder={t('offerCreation.euro')}
                                isClearable={false}
                                dropdownIndicator={data.rewardUnits.length > 1}
                                indicatorSeparator={null}
                                disabled={
                                    data.activeCashback ||
                                    data.rewardUnits.length <= 1 ||
                                    offerType === 'referral_offer'
                                }
                                data-cy="offer_reward_currency"
                            />
                        </div>
                    </div>
                </div>

                {offerType !== 'referral_offer' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>
                            {t('offerCreation.minPurchase')} <InfoTooltip text={t('offerCreation.minPurchase-info')} />
                        </Label>
                        <Input
                            type={'number'}
                            className="mt-3.5"
                            onChange={(e) => checkInputInteger(e, 'conditionValue')}
                            value={data.conditionValue}
                            placeholder={'20'}
                            disabled={offerType === 'referral_offer'}
                            data-cy="offer_min_purchase"
                        />
                    </div>
                )}

                {offerType === 'average_basket' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>{t('offerCreation.reward_limit')}</Label>
                        <Input
                            type={'number'}
                            className="mt-3.5"
                            onChange={(e) => checkInputInteger(e, 'rewardCumulatedLimit')}
                            value={data.rewardCumulatedLimit}
                            placeholder={'200'}
                            data-cy="offer_reward_limit"
                        />
                    </div>
                )}

                {offerType !== 'average_basket' && offerType !== 'referral_offer' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>
                            {t('offerCreation.redeem')} <InfoTooltip text={t('offerCreation.redeem-info')} />
                        </Label>
                        <Input
                            type={'number'}
                            className="mt-3.5"
                            onChange={(e) => checkInputInteger(e, 'occurrenceLimit')}
                            value={data.occurrenceLimit}
                            placeholder={'2'}
                            min={1}
                            disabled={offerType === 'welcome_offer' || data.occurrenceLimitDisabled}
                            data-cy="offer_redeem"
                        />
                    </div>
                )}

                {offerType !== 'referral_offer' && (
                    <div className={'col-span-8 lg:col-span-2'}>
                        <Label>
                            {t('offerCreation.campaignBudget')}{' '}
                            <InfoTooltip text={t('offerCreation.campaignBudget-info')} />
                        </Label>
                        <Input
                            type={'number'}
                            className="mt-3.5"
                            onChange={(e) => checkInputInteger(e, 'cappingLimit')}
                            value={data.cappingLimit}
                            placeholder={'1000'}
                            currency={true}
                            data-cy="offer_campaign_budget"
                        />
                    </div>
                )}

                <div className={'col-span-8 lg:col-span-4'}>
                    <Label>
                        {t('offerCreation.description')} <InfoTooltip text={t('offerCreation.description-info')} />
                    </Label>
                    <Textarea
                        className="mt-3.5 h-48"
                        onChange={(e) => updateFormValue(e, 'conditionLabel')}
                        value={data.conditionLabel}
                        placeholder={t('offerCreation.offerDescription')}
                        maxLength={100}
                        data-cy="offer_description"
                    />
                </div>

                {displayMultipleOccurrencePerDayToggle(data.occurrenceLimit, data.uncapped) && (
                    <div className={'col-span-8 lg:col-span-4 content-center'}>
                        <Label>
                            {t('offerCreation.multipleOccurrenceByDay')}{' '}
                            <InfoTooltip text={t('offerCreation.multipleOccurrenceByDay-info')} />
                        </Label>
                        <Switch
                            data-cy="multiple_occurrence_toggle"
                            className="col-span-8 lg:col-span-4 content-center"
                            onChange={(val) => updateFormValue(val, 'multipleOccurrenceByDay')}
                            value={data.multipleOccurrenceByDay}
                        />
                    </div>
                )}
            </div>
        </div>
    )
})

export default withProgram(Setup)
