import React from 'react'

function Cart() {
    return (
        <svg width="64" height="41" viewBox="0 0 64 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.3"
                d="M31.9376 40.6772C49.5762 40.6772 63.8751 39.1373 63.8751 37.2378C63.8751 35.3382 49.5762 33.7983 31.9376 33.7983C14.2989 33.7983 0 35.3382 0 37.2378C0 39.1373 14.2989 40.6772 31.9376 40.6772Z"
                fill="#E6E6E6"
            />
            <path d="M42.8367 14.5146H28.2061V22.8726H42.8367V14.5146Z" fill="#C1C1C1" />
            <path d="M32.339 7.43262H15.1509V22.872H32.339V7.43262Z" fill="#FFD200" />
            <path
                d="M5.97852 3.37061H9.76621C10.3408 3.37016 10.8964 3.57583 11.3322 3.95022C11.7679 4.32461 12.0548 4.84287 12.1407 5.41079L14.8057 22.8724H42.5068C43.185 22.8721 43.8433 22.6439 44.3761 22.2244C44.9089 21.805 45.2851 21.2187 45.4444 20.5597L49.5836 3.50686"
                stroke="#24285B"
                stroke-width="2.03"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.54004 2.67818C3.54004 2.67818 6.31625 1.06109 7.0121 2.67818C7.70794 4.29526 4.25382 5.05182 4.25382 5.05182L3.54004 2.67818Z"
                fill="#F4A28C"
            />
            <path d="M32.3384 0H23.0664V7.42926H32.3384V0Z" fill="#E6E6E6" />
            <path opacity="0.43" d="M42.8376 4.06641H32.0054V14.5147H42.8376V4.06641Z" fill="#E6E6E6" />
            <path
                d="M14.3037 19.5952L15.7384 28.4874C15.8832 29.3807 16.3413 30.1934 17.0306 30.7801C17.72 31.3667 18.5957 31.689 19.501 31.6893H44.1282"
                stroke="#24285B"
                stroke-width="2.03"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.7672 36.9313C21.8963 36.9313 22.8116 36.0163 22.8116 34.8875C22.8116 33.7588 21.8963 32.8438 20.7672 32.8438C19.638 32.8438 18.7227 33.7588 18.7227 34.8875C18.7227 36.0163 19.638 36.9313 20.7672 36.9313Z"
                fill="#FF7700"
            />
            <path
                d="M40.793 36.9313C41.9222 36.9313 42.8375 36.0163 42.8375 34.8875C42.8375 33.7588 41.9222 32.8438 40.793 32.8438C39.6639 32.8438 38.7485 33.7588 38.7485 34.8875C38.7485 36.0163 39.6639 36.9313 40.793 36.9313Z"
                fill="#FF7700"
            />
            <path opacity="0.61" d="M25.2333 7.43262H21.8438V12.4452H25.2333V7.43262Z" fill="white" />
        </svg>
    )
}

export default Cart
