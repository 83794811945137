import Button from '../../../components/Button'
import { useNavigate, useLocation } from 'react-router-dom'
import Link from '../../../components/Link'
import React, { useEffect, useState } from 'react'
import { CompanyIndustry } from '../../../data/CompanyIndustryFetcher'
import Slideshow from '../../../components/Slideshow'
import SlideshowSlide from '../../../components/SlideshowSlide'
import { ShoppingHubOwner } from '../../../data/ShoppingHubOwnerFetcher'
import ErrorAlert from '../../../components/ErrorAlert'
import { useTranslation } from 'react-i18next'
import { withAuth } from '../../../contexts/AuthContext'
import { isSessionValid } from '../../../helpers'

function Business(props) {
    const { t } = useTranslation('common')
    const [isLoading, setIsLoading] = useState(false)
    const [serverErrorMessage, setServerErrorMessage] = useState('')
    const navigate = useNavigate()
    const search = useLocation().search
    const [options, setOptions] = useState([])
    const [otherOption, setOtherOption] = useState(null)
    const validate = (companyIndustryId) => {
        setIsLoading(true)
        ShoppingHubOwner.patch(props.user.shoppingHubOwnerId, { companyIndustryId })
            .then((e) => {
                navigate('/register/business/company')
            })
            .catch((e) => {
                setIsLoading(false)
                setServerErrorMessage(t('Spaycial_onboarding_step1.' + e.code))
            })
    }

    useEffect(() => {
        const token = new URLSearchParams(search).get('t')
        if (token) {
            if (isSessionValid(token)) {
                localStorage.setItem('api_token', token)
            } else {
                navigate('/register/user/token-expired?t=' + token)
            }
        } else if (!localStorage.getItem('api_token')) {
            navigate('/login')
        }

        CompanyIndustry.getAll()
            .then((e) => {
                const chunkSize = 6
                const res = []
                let currentChunk = []
                for (let i = 0; i < e.length; i++) {
                    if (e[i].code === 'other') {
                        setOtherOption(e[i])
                    } else {
                        currentChunk.push(e[i])
                        if (currentChunk.length === chunkSize) {
                            res.push(currentChunk)
                            currentChunk = []
                        }
                    }
                }

                if (currentChunk.length > 0) {
                    res.push(currentChunk)
                }

                setOptions(res)
            })
            .catch((e) => {
                setServerErrorMessage(t('Spaycial_onboarding_step1.' + e.code))
            })
    }, [])

    return (
        <>
            <p className="text-center text-xs mt-4">{t('Spaycial_onboarding_step1.step')} 1/4</p>
            <h3 className={'text-xl md:text-3xl text-primary text-center mb-8 md:mb-20'}>
                {t('Spaycial_onboarding_step1.title')}
            </h3>
            {serverErrorMessage !== '' ? (
                <ErrorAlert>{serverErrorMessage}</ErrorAlert>
            ) : (
                <div>
                    <Slideshow disabled={options.length <= 1}>
                        {options.map((chunk) => {
                            return (
                                <SlideshowSlide>
                                    <div
                                        className={
                                            'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-11 text-center'
                                        }
                                    >
                                        {chunk.map((option) => {
                                            return (
                                                <div
                                                    className={
                                                        'bg-gray-50 flex items-center justify-center flex-col px-8 py-6'
                                                    }
                                                >
                                                    <img src={option.icon} />
                                                    <Button
                                                        appearance={'inversed'}
                                                        className={'mt-8'}
                                                        disabled={isLoading}
                                                        onClick={() => validate(option.id)}
                                                    >
                                                        {t('Spaycial_onboarding_step1.' + option.code)}
                                                    </Button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </SlideshowSlide>
                            )
                        })}
                    </Slideshow>

                    {otherOption !== null && (
                        <div className="text-center mt-12">
                            <Link onClick={() => validate(otherOption.id)} url="#" className="underline">
                                {t('Spaycial_onboarding_step1.other')}
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default withAuth(Business)
