import Button from '../../../components/Button'
import PropTypes from 'prop-types'
import Input from '../../../components/Input'
import Label from '../../../components/Label'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import { ShoppingHubOwner } from '../../../data/ShoppingHubOwnerFetcher'
import { useTranslation } from 'react-i18next'
import ErrorAlert from '../../../components/ErrorAlert'
import { withAuth } from '../../../contexts/AuthContext'

function Company(props) {
    const { t } = useTranslation('common')
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [serverErrorMessage, setServerErrorMessage] = useState('')
    const location = useLocation()

    let businessData = location.state || {}
    const [companyName, setName] = useState(businessData.companyName || '')

    const validate = (companyName) => {
        setIsLoading(true)
        businessData.companyName = companyName
        ShoppingHubOwner.patch(props.user.shoppingHubOwnerId, { name: companyName })
            .then((e) => {
                navigate('/register/business/details', businessData)
            })
            .catch((e) => {
                setIsLoading(false)
                setServerErrorMessage(t('Spaycial_onboarding_step2.' + e.code))
            })
    }

    return (
        <>
            <p className="text-center text-xs mt-4">{t('Spaycial_onboarding_step2.step')} 2/4</p>

            <div className="flex items-center relative pb-8 md:pb-20 mx-6">
                <BackButton
                    className="absolute lg:left-0"
                    onClick={(e) => navigate('/register/business/business', { state: businessData })}
                />
                <h3 className={'text-xl md:text-3xl text-primary text-center flex-1 m-0'}>
                    {t('Spaycial_onboarding_step2.title')}
                </h3>
            </div>

            <div className="mx-auto max-w-sm xl:py-16">
                {serverErrorMessage !== '' && <ErrorAlert className="mb-4">{serverErrorMessage}</ErrorAlert>}
                <div>
                    <Label>{t('Spaycial_onboarding_step2.companyNameField')}*</Label>
                    <Input
                        className="mt-3.5"
                        onChange={setName}
                        value={companyName}
                        placeholder={t('Spaycial_onboarding_step2.companyNamePlaceholder')}
                    />
                </div>

                <Button
                    className={'mt-15 w-full py-4'}
                    disabled={isLoading || !companyName}
                    onClick={() => validate(companyName)}
                >
                    {t('Spaycial_onboarding_step2.buttonLabel')}
                </Button>
            </div>
        </>
    )
}

Company.propTypes = {
    onValidate: PropTypes.func,
}

Company.defaultProps = {
    onValidate: undefined,
}

export default withAuth(Company)
