import React from 'react'
import KpiAverageBasket from '../../../../../../components/Icons/KpiAverageBasket'
import KpiOverallBudget from '../../../../../../components/Icons/KpiOverallBudget'
import KpiPurchaseFrequency from '../../../../../../components/Icons/KpiPurchaseFrequency'
import KpiStoreWithPurchase from '../../../../../../components/Icons/KpiStoreWithPurchase'
import KpiDistinctVisitedStore from '../../../../../../components/Icons/KpiDistinctVisitedStore'
import KpiPurchaseIntent from '../../../../../../components/Icons/KpiPurchaseIntent'
import { EShopperProfileKpi } from './kpi-types'

type ShopperProfileIconKpiProps = {
    type: EShopperProfileKpi
    description?: string
    value: string | number
}

function ShopperProfileIconKpi({ type, description, value }: ShopperProfileIconKpiProps) {
    const typeToIcon: Partial<Record<EShopperProfileKpi, React.ReactNode>> = {
        [EShopperProfileKpi.AVERAGE_BASKET]: <KpiAverageBasket className="h-24" />,
        [EShopperProfileKpi.PURCHASE_FREQUENCY]: <KpiPurchaseFrequency className="h-16 mt-4" />,
        [EShopperProfileKpi.OVERALL_BUDGET]: <KpiOverallBudget className="h-16 mt-4" />,
        [EShopperProfileKpi.STORE_WITH_PURCHASE]: <KpiStoreWithPurchase className="h-16 mt-2" />,
        [EShopperProfileKpi.DISTINCT_VISITED_STORE]: <KpiDistinctVisitedStore className="h-12 mt-6" />,
        [EShopperProfileKpi.PURCHASE_INTENT]: <KpiPurchaseIntent className="h-20 mt-2" />,
    }

    return (
        <div className="block bg-white pt-3 pl-3 pr-3 pb-6 origin-center min-w-min">
            <div className="h-full grid grid-cols-2 grid-rows-2 gap-1">
                <div className="row-span-2 flex items-center justify-center">{typeToIcon[type]}</div>
                <div className="row-span-2 items-center">
                    <div className="font-bold text-3xl mt-8 whitespace-nowrap overflow-hidden text-ellipsis">
                        {value}
                    </div>
                    <div className="text-none">{description}</div>
                </div>
            </div>
        </div>
    )
}

export default ShopperProfileIconKpi
