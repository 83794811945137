type SuccessIconProps = {
    className?: string
}

function SuccessIcon({ className }: SuccessIconProps) {
    return (
        <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4.74414 10.2402C5.01758 10.5137 5.48242 10.5137 5.75586 10.2402L13.7949 2.20117C14.0684 1.92773 14.0684 1.46289 13.7949 1.18945L12.8105 0.205078C12.5371 -0.0683594 12.0996 -0.0683594 11.8262 0.205078L5.26367 6.76758L2.17383 3.70508C1.90039 3.43164 1.46289 3.43164 1.18945 3.70508L0.205078 4.68945C-0.0683594 4.96289 -0.0683594 5.42773 0.205078 5.70117L4.74414 10.2402Z"
                fill="#0CD05A"
            />
        </svg>
    )
}

export default SuccessIcon
