import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function Switch({ className = '', onChange, value = false, error = false, disabled = false, ...rest }) {
    const { t } = useTranslation('common')
    return (
        <>
            <div
                className={[
                    ' mt-3.5 grid grid-cols-2 justify-around border bg-white text-center cursor-pointer',
                    error
                        ? 'border-error focus:ring-error'
                        : disabled
                        ? 'border-gray-300 text-gray-300'
                        : 'border-black focus:ring-primary',
                    className,
                ].join(' ')}
                onClick={() => !disabled && onChange(!value)}
                data-cy={rest['data-cy']}
            >
                <div
                    className={[
                        'col-span-1 py-5',
                        value
                            ? disabled
                                ? 'bg-none text-white transform scale-105'
                                : 'bg-primary text-white transform scale-105'
                            : '',
                    ].join(' ')}
                    onClick={() => !disabled && onChange(true)}
                >
                    <span>{t('switch.yes')}</span>
                </div>
                <div
                    className={[
                        'col-span-1 py-5',
                        !value
                            ? disabled
                                ? 'bg-none text-white transform scale-105'
                                : 'bg-primary text-white transform scale-105'
                            : '',
                    ].join(' ')}
                    onClick={() => !disabled && onChange(false)}
                >
                    <span>{t('switch.no')}</span>
                </div>
            </div>
        </>
    )
}

Switch.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    error: PropTypes.bool,
}

export default Switch
