import { useState, useImperativeHandle, useEffect } from 'react'
import { allInputsFilled } from './formCreationUtils'

/**
 * Set the initial value of the offer forms according to its offerType
 * @param {*} offerType {String} - Type of the offer
 * @param {*} formData {Object} - In case of editing, the forms got data already
 * @returns
 */
const setDefaultFormValue = (offerType, formData, shoppingHubId) => {
    const data = {
        teaser: formData.teaser,
        startDate: formData.startDate,
        endDate: formData.endDate,
        name: null,
        rewardValue: formData.rewardValue,
        rewardLabel: formData.rewardLabel,
        cappingLimit: formData.cappingLimit,
        conditionValue: formData.conditionValue,
        uncapped: formData.uncapped,
        conditionLabel: formData.conditionLabel,
        activeCashback: false,
        shoppingHubId,
        emailNotificationDate: null,
    }

    switch (offerType) {
        case 'referral_offer': {
            return {
                ...data,
                offerType: 'referral',
                conditionType: 'RFRL',
                rewardUnits: [
                    { label: '€', value: 'euro' },
                    { label: '%', value: 'percent' },
                ],
                rewardUnit: { label: '€', value: 'euro' },
                multipleOccurrenceByDay: true,
                rewardType: 'RFND',
                conditionValue: 0,
            }
        }
        case 'purchase_frequency': {
            return {
                ...data,
                offerType: 'frequency',
                multipleOccurrenceByDay: false,
                nbTransactionPerReward: 0,
                occurrenceLimit: 1,
                conditionType: 'SPND',
                rewardType: 'RFND',
                conditionValueType: 'AMNT',
                occurrenceLimitDisabled: true,
                rewardUnits: [{ label: '€', value: 'euro' }],
                rewardUnit: { label: '€', value: 'euro' },
            }
        }
        case 'purchase_frequency_non_cumulative': {
            return {
                ...data,
                offerType: 'frequency',
                multipleOccurrenceByDay: false,
                nbTransactionPerReward: 1,
                occurrenceLimit: 1,
                conditionType: 'SPND',
                rewardType: 'RFND',
                conditionValueType: 'AMNT',
                occurrenceLimitDisabled: false,
                rewardUnit: { label: '€', value: 'euro' },
                rewardUnits: [{ label: '€', value: 'euro' }],
            }
        }
        case 'welcome_offer': {
            return {
                ...data,
                offerType: 'welcome',
                multipleOccurrenceByDay: false,
                nbTransactionPerReward: 1,
                conditionType: 'SPND',
                rewardType: 'RFND',
                conditionValueType: 'AMNT',
                occurrenceLimitDisabled: true,
                rewardUnit: { label: '€', value: 'euro' },
                occurrenceLimit: 1,
                rewardUnits: [{ label: '€', value: 'euro' }],
            }
        }
        case 'average_basket': {
            return {
                ...data,
                offerType: 'average',
                multipleOccurrenceByDay: true,
                occurrenceLimitDisabled: true,
                nbTransactionPerReward: 1,
                conditionType: 'SPND',
                rewardType: 'RFND',
                conditionValueType: 'AMNT',
                occurrenceLimit: 1,
                rewardCumulatedLimit: 0,
                rewardUnit: { label: '%', value: 'percent' },
                rewardUnits: [{ label: '%', value: 'percent' }],
            }
        }
        default:
            return data
    }
}

export const useFormData = (props, offerType, ref) => {
    const { formData, currentProgram } = props
    const [data, setData] = useState(setDefaultFormValue(offerType, formData, currentProgram?.id))
    const displayMultipleOccurrencePerDayToggle = () => data.occurrenceLimit > 1 || data.uncapped

    useEffect(() => {
        setData(setDefaultFormValue(offerType, formData, currentProgram?.id))
    }, [offerType, currentProgram?.id, formData])

    useEffect(() => {
        props.allInputsFilled(!allInputsFilled(offerType, data))
    }, [data, offerType, props])

    useEffect(() => {
        updateFormValue(`${data.rewardValue} ${data.rewardUnit?.label}`, 'rewardLabel')
    }, [data.rewardUnit, data.rewardValue])

    useImperativeHandle(ref, () => ({
        validate,
    }))

    const validate = () => {
        if (!currentProgram) return null
        return data
    }

    const updateFormValue = (value, propertyToUpdate) => {
        setData({ ...data, [propertyToUpdate]: value })
    }

    const updateFormValues = (values) => {
        setData({ ...data, ...values })
    }

    const handleRewardValue = (value) => {
        if (data.rewardUnit.label === '%' && value > 100) {
            updateFormValue(100, 'rewardValue')
        } else {
            updateFormValue(value, 'rewardValue')
        }
    }

    return {
        data,
        handleRewardValue,
        updateFormValue,
        updateFormValues,
        displayMultipleOccurrencePerDayToggle,
    }
}
