import { useTranslation } from 'react-i18next'
import ErrorPageTemplate from './ErrorPageTemplate'
import { NotFoundIcon } from './ErrorsAssets'

const NotFound = () => {
    const { t } = useTranslation('common')
    
    return <ErrorPageTemplate
        title={t('errorpage.404.title')}
        description={t('errorpage.404.description')}
        icon={<NotFoundIcon />}
    />
}

export default NotFound