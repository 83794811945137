type ErrorIconProps = {
    className?: string
}

function ErrorIcon({ className }: ErrorIconProps) {
    return (
        <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M7.61719 5.75L10.3516 3.01562C10.707 2.6875 10.707 2.14062 10.3516 1.8125L9.75 1.21094C9.42188 0.855469 8.875 0.855469 8.54688 1.21094L5.8125 3.94531L3.05078 1.21094C2.72266 0.855469 2.17578 0.855469 1.84766 1.21094L1.24609 1.8125C0.890625 2.14062 0.890625 2.6875 1.24609 3.01562L3.98047 5.75L1.24609 8.51172C0.890625 8.83984 0.890625 9.38672 1.24609 9.71484L1.84766 10.3164C2.17578 10.6719 2.72266 10.6719 3.05078 10.3164L5.8125 7.58203L8.54688 10.3164C8.875 10.6719 9.42188 10.6719 9.75 10.3164L10.3516 9.71484C10.707 9.38672 10.707 8.83984 10.3516 8.51172L7.61719 5.75Z"
                fill="#E20000"
            />
        </svg>
    )
}

export default ErrorIcon
