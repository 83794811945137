import React from 'react'

function IPhoneBookIcon() {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 1.01171C5.66759 0.559105 4.52632 0 3.18006 0C1.56787 0 0.216066 0.793397 0 1.44302V9.54739C0 9.86688 0.216066 10 0.465374 10C0.66482 10 0.781163 9.94143 0.897507 9.85091C1.2687 9.51012 1.96676 9.09478 3.18006 9.09478C4.41551 9.09478 5.1856 9.51544 5.4903 9.82428C5.60665 9.92013 5.74515 10 6 10C6.24931 10 6.38781 9.90948 6.5097 9.82428C6.83102 9.52609 7.58449 9.09478 8.81994 9.09478C10.0332 9.09478 10.7479 9.49947 11.1025 9.85091C11.2188 9.94143 11.3352 10 11.5346 10C11.7839 10 12 9.86688 12 9.54739V1.44302C11.7839 0.793397 10.4321 0 8.81994 0C7.47368 0 6.33241 0.559105 6 1.01171ZM0.731302 1.65602C0.814404 1.32055 1.79501 0.665602 3.18006 0.665602C4.57064 0.665602 5.55679 1.32588 5.63435 1.65602V9.15335C5.07479 8.69542 4.17174 8.43983 3.18006 8.43983C2.18283 8.43983 1.27978 8.70075 0.731302 9.164V1.65602ZM11.2687 1.65602V9.164C10.7147 8.70075 9.81717 8.43983 8.81994 8.43983C7.82825 8.43983 6.92521 8.69542 6.36565 9.15335V1.65602C6.44321 1.32588 7.42936 0.665602 8.81994 0.665602C10.205 0.665602 11.1801 1.32055 11.2687 1.65602Z"
                fill="#454545"
            />
        </svg>
    )
}

export default IPhoneBookIcon
