import { useCallback, useContext, useEffect, useState } from 'react'
import { Request } from '../../helpers'
import { Store } from 'src/interfaces/store'
import { ECheckboxState } from '../../components/Table'
import ErrorManagementContext from '../../contexts/ErrorManagementContext'

type UseStoresListDataRoute = {
    shoppingHubId: string
    challengeId?: string
}

type GetHeaders = (route: string, shoppingHubId: string, challengeId?: string) => UseStoresListDataRoute

export type UseStoresListDataProps = {
    currentProgram?: { id: string } // TODO typescript of current program
    APIroute: string
    checkboxCheckedByDefault?: boolean
    challengeId?: string
    onChange?: (changes: { added: string[]; removed: string[]; selected: string[] }) => void
}

export type UseStoresListDataValidate = () => { stores: { include: boolean; storeIds: string[] } }

type UseStoresListData = (props: UseStoresListDataProps) => {
    stores: Store[]
    selectedStores: string[]
    allStoresState: ECheckboxState
    loading: boolean
    toggleStore: (id: string) => void
    validate: UseStoresListDataValidate
    toggleAllStores: () => void
    onFilterChange: OnFilterChange
    filters?: string[]
}

type OnFilterChange = (countriesFilter: string[], citiesFilter: string[], input: HTMLInputElement) => void

const getHeaders: GetHeaders = (route, shoppingHubId, challengeId) => {
    const headers: Record<string, UseStoresListDataRoute> = {
        'backoffice/shoppingHubs/stores': {
            shoppingHubId,
        },
        'backoffice/shoppingHubs/challenges/stores': {
            shoppingHubId,
            challengeId,
        },
    }
    return headers[route]
}

const useStoresListData: UseStoresListData = ({
    currentProgram,
    challengeId,
    APIroute,
    checkboxCheckedByDefault,
    onChange,
}) => {
    const limit = 1000

    const [search, setSearch] = useState('')
    const [stores, setStores] = useState<Store[]>([])
    const [loading, setLoading] = useState(true)
    const { handleError } = useContext(ErrorManagementContext)
    const [filters, setFilters] = useState<string[]>()
    const [cities, setCities] = useState<string[]>()
    const [countries, setCountries] = useState<string[]>()
    const [selectedStoresId, setSelectedStoresId] = useState<string[]>([])
    const [allStoresState, setAllStoresState] = useState(ECheckboxState.UNCHECKED)
    const [initialSelectedStoreIds, setInitialSelectedStoreIds] = useState<string[]>([])

    const getStores = useCallback(async () => {
        const route = `${APIroute}?offset=0&limit=${limit}${search ? `&search=${encodeURIComponent(search)}` : ''}${
            countries?.length ? `&country=${countries}` : ''
        }${cities?.length ? `&city=${cities}` : ''}`
        const headers = getHeaders(APIroute, currentProgram!.id, challengeId)

        try {
            setLoading(true)
            const res: any = await Request.get(route, headers)
            const stores: Store[] = res.stores
            setStores(stores)
            if (!filters) {
                setFilters(res.countriesAndCities || res.cities)
            }
            setLoading(false)

            const selectedStores = checkboxCheckedByDefault
                ? stores.map((s: any) => s.id)
                : stores.filter((s) => s.associatedToChallenge).map((s) => s.id)

            setSelectedStoresId(selectedStores)
            setInitialSelectedStoreIds(selectedStores)
        } catch (err) {
            setLoading(false)
            handleError('errors.offers.create.stores')(err)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, APIroute, currentProgram, challengeId, countries, cities])

    const onFilterChange: OnFilterChange = (countriesFilter, citiesFilter, input) => {
        if (!input && !countriesFilter?.length && !citiesFilter?.length) {
            setSearch('')
            setCountries(undefined)
            setCities(undefined)
        } else {
            setCountries(countriesFilter)
            setCities(citiesFilter)
            setSearch(input?.value)
        }
    }

    useEffect(() => {
        if (currentProgram) {
            getStores()
        }
    }, [currentProgram, getStores, search])

    // The onchange event is triggered after
    // changes in the selection
    useEffect(() => {
        onChange?.({
            added: selectedStoresId.filter((newId) => !initialSelectedStoreIds.includes(newId)),
            removed: initialSelectedStoreIds.filter((oldId) => !selectedStoresId.includes(oldId)),
            selected: selectedStoresId,
        })
    }, [selectedStoresId])

    // updates the allStoresState top checkbox
    useEffect(() => {
        if (selectedStoresId.length === 0) {
            setAllStoresState(ECheckboxState.UNCHECKED)
        } else if (stores.every((s) => selectedStoresId.includes(s.id))) {
            setAllStoresState(ECheckboxState.CHECKED)
        } else {
            setAllStoresState(ECheckboxState.INDETERMINATE)
        }
    }, [stores, selectedStoresId])

    const validate: UseStoresListDataValidate = () => {
        return { stores: { include: true, storeIds: selectedStoresId } }
    }

    function toggleStore(id: string) {
        if (selectedStoresId.includes(id)) {
            setSelectedStoresId(selectedStoresId.filter((sId) => sId !== id))
        } else {
            setSelectedStoresId([...selectedStoresId, id])
        }
    }

    function toggleAllStores() {
        setSelectedStoresId(
            stores.every((s) => selectedStoresId.some((id) => id === s.id)) ? [] : stores.map((s) => s.id)
        )
    }

    return {
        stores,
        allStoresState,
        selectedStores: selectedStoresId,
        loading,
        filters,
        toggleStore,
        validate,
        toggleAllStores,
        onFilterChange,
    }
}

export default useStoresListData
