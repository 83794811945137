import { useContext, useEffect, useState } from 'react'
import { ShopperProfileKpis, ShopperProfileSegment } from '../shopper-profile-behavior-types'
import { Request } from '../../../../../../helpers'
import { UrlBuilder } from '../../../../../../utils/url-builder'
import ErrorManagementContext from '../../../../../../contexts/ErrorManagementContext'

const segmentsUrl = '/backoffice/v3/shopper-profile/segments'
const kpisUrl = '/backoffice/v3/shopper-profile/kpis'

const defaultSegmentsData: ShopperProfileSegment[] = []
const defaultKpisData: ShopperProfileKpis = {
    average_basket_per_shopping_session: 0,
    monthly_shopping_session: 0,
    spent_for_rewards: 0,
    total_reward_value: 0,
    store_with_purchase_per_shopping_session: 0,
    distinct_visited_store: 0,
    purchase_intent: 0,
    favorite_stores: [],
}

/**
 * Retrieve all data for the Behavior tab in the shopper profile
 * @param customerId
 */
export const useBehaviorData = (customerId: string) => {
    // Error management
    const { handleError } = useContext(ErrorManagementContext)

    // Loading
    const [segmentsLoading, setSegmentsLoading] = useState(true)
    const [kpisLoading, setKpisLoading] = useState(true)

    // Unselected segment id
    const [unselectedSegmentsId, setUnselectedSegmentsId] = useState<string[]>([])

    // Data
    const [segments, setSegments] = useState<ShopperProfileSegment[]>(defaultSegmentsData)
    const [kpis, setKpis] = useState<ShopperProfileKpis>(defaultKpisData)

    // Fetch segments
    useEffect(() => {
        setSegmentsLoading(true)
        setKpisLoading(true)
        Request.get<ShopperProfileSegment[]>(`${segmentsUrl}?customerId=${customerId}`)
            .then((segments) => {
                setSegments(
                    segments.sort(
                        (a, b) =>
                            b.total_spend_in_mall +
                            b.total_spend_outside_mall -
                            (a.total_spend_in_mall + a.total_spend_outside_mall)
                    )
                )
            })
            .catch(handleError('errors.shopperProfile.segments'))
            .finally(() => setSegmentsLoading(false))
    }, [customerId])

    // Fetch KPIs
    useEffect(() => {
        if (segments && segments.length > 0) {
            const segmentsIds = segments.map((s) => s.id).filter((id) => !unselectedSegmentsId.includes(id))
            const url = UrlBuilder.fromBaseUrl(kpisUrl)
                .addParameter('customerId', customerId)
                .addParameter('segmentsId', segmentsIds)
                .toString()

            setKpisLoading(true)
            Request.get<ShopperProfileKpis>(url)
                .then(setKpis)
                .catch(handleError('errors.shopperProfile.kpis'))
                .finally(() => setKpisLoading(false))
        } else setKpisLoading(false)
    }, [customerId, segments, unselectedSegmentsId])

    const toggleSelectSegment = (id: string) => {
        const nextUnselected = unselectedSegmentsId.includes(id)
            ? unselectedSegmentsId.filter((sId) => sId !== id)
            : [...unselectedSegmentsId, id]
        setUnselectedSegmentsId(nextUnselected.length !== segments.length ? nextUnselected : [])
    }

    return {
        unselectedSegmentsId,
        toggleSelectSegment,
        segments,
        kpis,
        segmentsLoading,
        kpisLoading,
    }
}
