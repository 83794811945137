import React from 'react'

type BackButtonProps = {
    label?: string
    className?: string
    'data-cy'?: string
} & React.HTMLProps<HTMLButtonElement>

function BackButton({ label, className, ...props }: BackButtonProps) {
    let classes = ['flex items-center justify-center space-x-2']
    if (label) {
        classes.push('text-black text-sm')
    } else {
        classes.push('border border-1 border-primary text-primary h-9 w-9 hover:bg-primary hover:text-white')
    }

    if (className) {
        classes.push(className)
    }

    return (
        <button
            {...props}
            type={'button'}
            className={classes.join(' ')}
            data-cy={props['data-cy'] || 'previous_page_button'}
        >
            {label && (
                <>
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.4335 1L1 5.5L5.4335 10" stroke="#333333" strokeLinejoin="round" />
                    </svg>
                    <span>{label}</span>
                </>
            )}

            {!label && (
                <svg
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.320313 7.50384C0.320313 7.66384 0.38644 7.79984 0.528142 7.91184L8.08559 13.9998C8.2084 14.1038 8.369 14.1598 8.54848 14.1598C8.91691 14.1598 9.20031 13.9278 9.20031 13.6078C9.20031 13.4558 9.12474 13.3198 9.02083 13.2238L1.91682 7.50384L9.02083 1.78384C9.12474 1.67984 9.20031 1.54384 9.20031 1.39184C9.20031 1.07984 8.91691 0.839844 8.54848 0.839844C8.369 0.839844 8.2084 0.895844 8.08559 0.999844L0.528142 7.09584C0.38644 7.20784 0.320313 7.34384 0.320313 7.50384Z" />
                </svg>
            )}
        </button>
    )
}

export default BackButton
