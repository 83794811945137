import debounce from 'lodash.debounce'
import CampaignLaunch from '../../components/Icons/CampaignLaunch'
import Button, { ButtonAppearance } from '../../components/Button'
import DropdownInput from '../../components/DropdownInput'
import React, { useContext, useMemo, useState } from 'react'
import { ACCESS_LEVEL, canUserAccess, FUNCTIONALITIES, getUserAccessLevel } from '../../helpers'
import { useTranslation } from 'react-i18next'
import Badge from '../../components/Badge'
import DropDown from '../../components/DropDown'
import ProgramContext from '../../contexts/ProgramContext'
import { useNavigate } from 'react-router-dom'
import RestTable from '../../components/Table'
import AuthContext from '../../contexts/AuthContext'
import Modal from '../../components/Modal'
import { withShoppingHubOwner } from '../../contexts/ShoppingHubOwnerContext'
import { getStatusFromShoppingHub } from '../../utils/ShoppingHubUtils'
import getSymbolFromCurrency from 'currency-symbol-map'
import { canAccess } from '../../components/RequireAuth'

const isReadOnly = getUserAccessLevel(FUNCTIONALITIES.PROGRAM) === ACCESS_LEVEL.READ

function Listing({ currentShoppingHubOwner }) {
    const url = 'backoffice/shoppingHubs?withExtra=true'
    const { t } = useTranslation('common')
    const { onProgramPageNavigated } = useContext(ProgramContext)
    const navigate = useNavigate()
    const [textSearch, setTextSearch] = useState('')
    const [programs, setPrograms] = useState([])
    const [noData, setNoData] = useState(false)
    const [showModalMissingMandatoryFields, setShowModalMissingMandatoryFields] = useState(null)
    const [showShoppingHubOwnerErrorModal, setShowShoppingHubOwnerErrorModal] = useState(false)
    const authCtx = useContext(AuthContext)

    const isButtonHiddenFlag = useMemo(() => {
        return programs.length > 0 && isReadOnly
    }, [programs])

    const appearances = {
        Pending: 'warning',
        Validated: 'info',
        Live: 'success',
        Terminated: 'error',
    }
    const statusLabel = {
        Pending: t('listing_program.dropdown_label_pending'),
        Validated: t('listing_program.dropdown_label_validated'),
        Live: t('listing_program.dropdown_label_live'),
        Terminated: t('listing_program.dropdown_label_terminated'),
    }

    const renderDataRetrievalMethod = (p) =>
        p.dataRetrievalMethod.map((method) => (
            <Badge className="mr-2 whitespace-nowrap" key={p.id + method}>
                {t('program.listing.experience.' + (method === 'paymentCard' ? 'accountLinking' : 'scan'))}
            </Badge>
        ))

    const handleClickProgram = (url, program) => {
        onProgramPageNavigated(program.id).then(() => navigate(url))
    }

    const columns = [
        {
            title: t('listing_program.table_header_name'),
            render: (p) => (
                <>
                    {p.programName || 'N/A'}
                    {canAccess(authCtx.user, undefined, undefined, true) && p.missingMandatoryFields.length > 0 && (
                        <div
                            className="inline-block px-2 py-1/2 ml-1 bold bg-primary text-white"
                            onClick={(e) => {
                                setShowModalMissingMandatoryFields(p)
                                e.stopPropagation()
                            }}
                        >
                            !
                        </div>
                    )}
                </>
            ),
        },
        { title: t('listing_program.table_header_organization'), render: (p) => p.name || 'N/A' },
        { title: t('listing_program.table_header_member'), render: (p) => p.members },
        { title: t('listing_program.table_header_offer'), render: (p) => p.offers },
        {
            title: t('listing_program.table_header_wallet_balance'),
            render: (p) =>
                p.walletBalance !== undefined
                    ? `${p.walletBalance} ${getSymbolFromCurrency(p.walletCurrency)}`
                    : t('listing_program.table_no_wallet_balance'),
        },
        {
            title: t('listing_program.table_header_experience'),
            render: renderDataRetrievalMethod,
        },
        {
            title: t('listing_program.table_header_status'),
            render: (p) => <Badge appearance={appearances[p.status]}>{statusLabel[p.status]}</Badge>,
        },
        {
            render: (p) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <DropDown>
                        <span
                            onClick={() => handleClickProgram(`/programs/${p.id}/configuration`, p)}
                            className="px-4 py-3 hover:bg-gray-200 hover:text-primary w-full text-sm hover:bg-opacity-20"
                        >
                            {t('listing_program.edit')}
                        </span>
                    </DropDown>
                </div>
            ),
        },
    ]

    const onPageReceived = (page) => {
        page.rows = page.rows.map((program) => ({
            status: getStatusFromShoppingHub(program),
            ...program,
        }))

        setPrograms(page.rows)
    }

    const noDataContent = (
        <div className="h-full flex flex-col gap-4 justify-center items-center">
            <CampaignLaunch />
            <Button appearance="outline" as="link" to="/programs/create/benefit" disabled={isReadOnly}>
                <div className="px-16 py-4 font-bold">{t('listing_program.create_first_btn')}</div>
            </Button>
        </div>
    )

    const debounceSetSearch = debounce((e) => setTextSearch(e), 500)

    const tableQueryUrl = useMemo(() => {
        return (
            url +
            (!!currentShoppingHubOwner ? `&shoppingHubOwnerId=${currentShoppingHubOwner.id}` : '') +
            (!!textSearch.value ? `&shoppingHubName=${textSearch.value}` : '')
        )
    }, [currentShoppingHubOwner, textSearch.value])

    return (
        <>
            <div className="flex flex-col gap-4 p-1">
                {noData ? (
                    noDataContent
                ) : (
                    <>
                        <div className="flex justify-between">
                            <div className="text-3xl">{t('listing_program.title')}</div>
                            {!isButtonHiddenFlag && (
                                <Button
                                    as="link"
                                    to="/programs/create/benefit"
                                    onClick={(e) => {
                                        if (!authCtx.user.shoppingHubOwnerId && !currentShoppingHubOwner) {
                                            e.preventDefault()
                                            setShowShoppingHubOwnerErrorModal(true)
                                        }
                                    }}
                                    data-cy="create_new_program_button"
                                >
                                    {t('listing_program.create_btn')}
                                </Button>
                            )}
                        </div>
                        <div className="flex justify-end gap-6 items-center xl:mt-10">
                            <div className="w-96">
                                <DropdownInput
                                    value={textSearch}
                                    onChange={debounceSetSearch}
                                    options={searchOptions}
                                    placeholder={t('listing_program.input_search_placeholder')}
                                    asSearchInput
                                    withoutMenu
                                />
                            </div>
                        </div>
                        <div className="overflow-auto xl:mt-6">
                            <RestTable
                                queryBaseUrl={tableQueryUrl}
                                columns={columns}
                                onDataReceived={onPageReceived}
                                errorMessageKey="errors.programs.search"
                                onNoData={() => setNoData(true)}
                                onRowClicked={(p) => {
                                    // Check rights
                                    for (let functionality of ['AUDIENCE', 'STORE']) {
                                        if (canUserAccess(functionality))
                                            return handleClickProgram(
                                                `/programs/${p.id}/${functionality.toLowerCase()}`,
                                                p
                                            )
                                    }

                                    handleClickProgram(`/programs/${p.id}/configuration`, p)
                                }}
                            />
                        </div>
                    </>
                )}
            </div>

            <Modal show={showShoppingHubOwnerErrorModal} onClose={() => setShowShoppingHubOwnerErrorModal(false)}>
                <div className="flex flex-col gap-8 items-center max-w-lg p-4 text-center mx-12">
                    <p className="text-xl">{t('listing_program.must_select_shopping_hub_owner')}</p>

                    <Button
                        appearance={ButtonAppearance.BUTTON_APPEARANCE_PRIMARY}
                        className="flex-1 py-4"
                        onClick={() => setShowShoppingHubOwnerErrorModal(false)}
                    >
                        {t('listing_program.must_select_shopping_hub_owner_button')}
                    </Button>
                </div>
            </Modal>

            <Modal show={!!showModalMissingMandatoryFields} onClose={() => setShowModalMissingMandatoryFields(null)}>
                <div className="flex flex-col gap-8 p-4 mx-12">
                    <p className="text-xl text-center">{t('listing_program.mandatory_fields_missing')}</p>

                    <ul>
                        {showModalMissingMandatoryFields &&
                            showModalMissingMandatoryFields.missingMandatoryFields.sort().map((f) => (
                                <li key={f} className="mb-4 truncate w-full">
                                    <span className="inline-block px-2 py-1/2 ml-1 mr-3 bold bg-primary text-white">
                                        !
                                    </span>
                                    {t(`listing_program.mandatory_field.${f}`)}
                                </li>
                            ))}
                    </ul>

                    <Button
                        appearance={ButtonAppearance.BUTTON_APPEARANCE_PRIMARY}
                        className="flex-1 py-4"
                        onClick={() => setShowModalMissingMandatoryFields(null)}
                    >
                        {t('listing_program.must_select_shopping_hub_owner_button')}
                    </Button>
                </div>
            </Modal>
        </>
    )
}

const searchOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Initialized',
        value: 'initialized',
    },
]

export default withShoppingHubOwner(Listing)
