import React, { createRef, AllHTMLAttributes, useCallback } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import './FileInput.css'
import { useTranslation } from 'react-i18next'

export type FileInputProps = Omit<AllHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    file: File | null | undefined
    onChange: (type: string, file: File | null) => void
    error?: boolean
    uploading?: boolean
}

const FileInput: React.FC<FileInputProps> = function ({
    children = undefined,
    onChange,
    file = null,
    type = 'default',
    placeholder = 'Upload',
    className = '',
    error = false,
    uploading = false,
    disabled = false,
    ...props
}) {
    const { t } = useTranslation('common')
    const input = createRef<HTMLInputElement>()
    const classes = [
        'text-left bg-white relative border border-1 px-6 py-5 w-full text-base focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-black pr-custom disabled:opacity-80 placeholder-none',
        error
            ? 'border-error focus:ring-error'
            : disabled
            ? 'border-color-none text-none'
            : 'border-black focus:ring-transparent',
        !file || uploading || disabled ? 'text-none' : '',
        className,
    ]

    const onClickCallback = useCallback(() => {
        input.current?.click()
    }, [input])

    const onChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(type, e.target.files?.length ? e.target.files[0] : null)
        },
        [onChange, type]
    )

    return (
        <>
            <button onClick={onClickCallback} disabled={disabled || uploading} className={classes.join(' ')}>
                <span className={file && !uploading ? 'text-black' : 'text-none'}>
                    {file && !uploading ? file.name : placeholder}
                </span>
                {uploading && <span>...</span>}
                <span className="absolute right-3 flex inline-flex">
                    {file && <span className={disabled ? 'text-none' : 'text-black'}>{t('file_input.update')}</span>}
                    <ChevronRightIcon className={'h-6 ' + (disabled ? 'text-none' : 'text-black')} />
                </span>
            </button>
            <input
                {...props}
                className="hidden"
                disabled={disabled}
                ref={input}
                type="file"
                onChange={onChangeCallback}
            />
        </>
    )
}

export default FileInput
