import React from 'react'

function IPhoneCellularIcon() {
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.90829 0.3125C6.54272 0.3125 6.24636 0.608855 6.24636 0.974428V4.37233C6.24636 4.7379 6.54272 5.03426 6.90829 5.03426C7.27386 5.03426 7.57022 4.7379 7.57022 4.37233V0.974428C7.57022 0.608855 7.27386 0.3125 6.90829 0.3125ZM4.17183 2.03352C4.17183 1.66794 4.46819 1.37159 4.83376 1.37159C5.19933 1.37159 5.49569 1.66794 5.49569 2.03352V4.37233C5.49569 4.73791 5.19933 5.03426 4.83376 5.03426C4.46819 5.03426 4.17183 4.73791 4.17183 4.37233V2.03352ZM2.80431 2.38656C2.43874 2.38656 2.14238 2.68291 2.14238 3.04848V4.37234C2.14238 4.73791 2.43874 5.03427 2.80431 5.03427C3.16989 5.03427 3.46624 4.73791 3.46624 4.37234V3.04848C3.46624 2.68291 3.16989 2.38656 2.80431 2.38656ZM0.686343 3.26912C0.32077 3.26912 0.0244141 3.56548 0.0244141 3.93105V4.37234C0.0244141 4.73791 0.32077 5.03427 0.686343 5.03427C1.05192 5.03427 1.34827 4.73791 1.34827 4.37234V3.93105C1.34827 3.56548 1.05192 3.26912 0.686343 3.26912Z"
                fill="black"
            />
        </svg>
    )
}

export default IPhoneCellularIcon
