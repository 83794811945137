import Modal from '../../../../../components/Modal'
import Button, { ButtonAppearance } from '../../../../../components/Button'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Request } from '../../../../../helpers'
import ErrorManagementContext from '../../../../../contexts/ErrorManagementContext'
import Input, { InputType } from '../../../../../components/Input'
import { message } from 'antd'
import YLogoLoading from '../../../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../../../tailwind.config'
import Datepicker from '../../../../../components/Datepicker'
import { useParams } from 'react-router-dom'

type SpendPointsModalProps = {
    show: boolean
    onClose: () => void
    onCreated: () => void
}

function SpendPointsModal({ show, onClose, onCreated }: SpendPointsModalProps) {
    const { customerId } = useParams<{ customerId: string }>()
    const uriSpendPoints = '/backoffice/v3/customers/points/spend'
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)
    const [value, setValue] = useState<string>('0')
    const [operationDate, setOperationDate] = useState<Date>(new Date())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (show) setOperationDate(new Date())
    }, [show])

    const customOnClose = useCallback(() => {
        setValue('0')
        onClose()
    }, [onClose])

    const onValidate = () => {
        setLoading(true)
        Request.post(
            uriSpendPoints,
            {
                customerId,
                value: parseInt(value) * -1,
                operationDate,
            },
            {},
            null,
            true
        )
            .then(() => {
                customOnClose()
                onCreated()
                message.success(t('shopperProfile.pointsHistory.spendPoints.success', { points: value }))
            })
            .catch(handleError('errors.shopperProfile.pointsHistory.spendPoints'))
            .finally(() => setLoading(false))
    }

    const valueValid = useMemo(() => !isNaN(parseInt(value)), [value])

    return (
        <Modal show={show} onClose={customOnClose}>
            <h3 className="text-xl md:ml-6 text-center mb-8">{t('shopperProfile.pointsHistory.spendPoints.button')}</h3>
            <div className="px-4">
                <div>{t('shopperProfile.pointsHistory.spendPoints.value')}</div>
                <Input type={InputType.TEXT} value={value} onChange={setValue} />
            </div>

            <div className="px-4 mt-4">
                <div>{t('shopperProfile.pointsHistory.spendPoints.operationDate')}</div>
                <Datepicker onChange={setOperationDate} value={operationDate} />
            </div>

            <div className="flex justify-center mt-4">
                <Button
                    appearance={loading ? ButtonAppearance.BUTTON_APPEARANCE_OUTLINE : undefined}
                    className="mr-3 relative"
                    disabled={!valueValid}
                    onClick={() => !loading && onValidate()}
                >
                    {loading ? (
                        <div className="w-12 flex justify-center">
                            <YLogoLoading
                                percentage={50}
                                width={15}
                                height={20}
                                border="white"
                                color={(Tailwind as any).theme.extend.colors.primary}
                            />
                        </div>
                    ) : (
                        t('shopperProfile.pointsHistory.spendPoints.spendButton')
                    )}
                </Button>

                <Button appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE} onClick={customOnClose}>
                    {t('creation_user.button.cancel')}
                </Button>
            </div>
        </Modal>
    )
}

export default SpendPointsModal
