import React from 'react'
import { ArrowNarrowUpIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { EKpi } from './kpi-types'
import './IconKpi.css'

type IconKpiProps = {
    type: EKpi
    descriptionKey?: string
    value: number
    increasing: boolean
}

function IconKpi({ type, descriptionKey, value, increasing }: IconKpiProps) {
    const { t } = useTranslation('common')

    const typeToIcon: Partial<Record<EKpi, React.ReactNode>> = {
        /*[EKpi.AVERAGE_BASKET]: <KpiAverageBasket className="flex-1 h-32" />,
        [EKpi.PURCHASE_FREQUENCY]: <KpiPurchaseFrequency className="flex-1 h-24" />,
        [EKpi.OVERALL_BUDGET]: <KpiOverallBudget className="flex-1 h-20" />,*/
    }

    const typeToUnit: Partial<Record<EKpi, string>> = {
        /*[EKpi.AVERAGE_BASKET]: t('audience.kpis.averageBasket.unit'),
        [EKpi.OVERALL_BUDGET]: t('audience.kpis.overallBudget.unit'),*/
    }

    const displayClasses = increasing ? 'text-success' : 'text-error rotate-180'

    return (
        <div className="h-36 mx-8">
            <div className="h-full grid grid-cols-2 grid-rows-2 gap-1">
                <div className="row-span-2 flex items-center justify-end">{typeToIcon[type]}</div>
                <div className="flex items-end">
                    <ArrowNarrowUpIcon className={'inline-block h-8 ' + displayClasses} />
                    <span className="font-bold text-3xl basis-full">{value + (typeToUnit[type] || '')}</span>
                </div>
                {descriptionKey && <span className="break-words text-none">{t(descriptionKey)}</span>}
            </div>
        </div>
    )
}

export default IconKpi
