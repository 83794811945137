import { EKpi, ETypeKpi, KpiData } from '../kpi-types'
import { useContext, useEffect, useState } from 'react'
import { Request } from '../../../../../helpers'
import ErrorManagementContext from '../../../../../contexts/ErrorManagementContext'

export type KpiDisplayData = {
    type: ETypeKpi
    titleKey: string
    analyticsUrl?: string
    tooltipKey?: string
    descriptionKey?: string
    colors?: string[]
}

const displayData: Record<EKpi, KpiDisplayData> = {
    [EKpi.MEMBERS]: {
        titleKey: 'audience.kpis.members.title',
        type: ETypeKpi.DONUT,
        analyticsUrl: 'analytics/analytics.audienceAnalysis',
        colors: ['#3F65BF', '#C4C4C4'],
    },
    /*[EKpi.DISTRIBUTION]: {
        titleKey: 'audience.kpis.repartition.title',
        tooltipKey: 'audience.kpis.repartition.info',
        type: ETypeKpi.DONUT,
        analyticsUrl: 'analytics/analytics.audienceAnalysis',
        colors: ['#FF7700', '#ECBFD0', '#3F65BF'],
    },
    [EKpi.AVERAGE_BASKET]: {
        titleKey: 'audience.kpis.averageBasket.title',
        type: ETypeKpi.ICON,
        descriptionKey: 'audience.kpis.averageBasket.description',
        analyticsUrl: 'analytics/analytics.hubPerformance',
    },
    [EKpi.PURCHASE_FREQUENCY]: {
        titleKey: 'audience.kpis.purchaseFrequency.title',
        type: ETypeKpi.ICON,
        descriptionKey: 'audience.kpis.purchaseFrequency.description',
        analyticsUrl: 'analytics/analytics.hubPerformance',
    },
    [EKpi.OVERALL_BUDGET]: {
        titleKey: 'audience.kpis.overallBudget.title',
        type: ETypeKpi.ICON,
        descriptionKey: 'audience.kpis.overallBudget.description',
    },*/
}

export type KpisData = Record<EKpi, KpiData>

const defaultData: KpisData = {
    [EKpi.MEMBERS]: {
        withoutTransaction: 0,
        withTransaction: 0,
    },
    /*[EKpi.DISTRIBUTION]: {
        accountLinking: 0,
        scan: 0,
        cardLinking: 0,
    },
    [EKpi.AVERAGE_BASKET]: { value: 0, increasing: true },
    [EKpi.PURCHASE_FREQUENCY]: { value: 0, increasing: true },
    [EKpi.OVERALL_BUDGET]: { value: 0, increasing: true },*/
}

/**
 * Retrieve data for all KPIs
 * @param shoppingHubId
 */
export const useKpiData = (shoppingHubId?: string) => {
    const [serverData, setServerData] = useState<KpisData>(defaultData)
    const [loading, setLoading] = useState(false)
    const { handleError } = useContext(ErrorManagementContext)

    useEffect(() => {
        const url = '/backoffice/v3/audience/kpi' + (shoppingHubId ? `?shoppingHubId=${shoppingHubId}` : '')
        setLoading(true)
        Request.get(url)
            .then((serverData) => setServerData(serverData as KpisData))
            .catch(handleError('errors.audience.kpi'))
            .finally(() => setLoading(false))
    }, [shoppingHubId])

    return {
        displayData,
        serverData,
        loading,
    }
}
