import { useEffect, useMemo, useState } from 'react'
import ErrorAlert from '../../components/ErrorAlert'

export const useErrorAlert = () => {
    const [error, setError] = useState(null)

    useEffect(() => {
        const intervalId = error
            ? setTimeout(() => {
                  setError(null)
              }, 5000)
            : null

        return () => {
            if (intervalId) {
                clearTimeout(intervalId)
            }
        }
    }, [error])

    return useMemo(() => {
        return {
            setError,
            content: error && <ErrorAlert>{error.message}</ErrorAlert>,
        }
    }, [error])
}
