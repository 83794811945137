import { Request } from '../helpers'

class Tableau {
    getToken() {
        return Request.get('backoffice/v3/tableau/token')
    }
}

const TableauFetcher = new Tableau()

export { TableauFetcher }
