import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import DropdownInput from './DropdownInput'
import FiltersDropdown from './FiltersDropdown'
import Button from './Button'
import { useDeepCompareWithRef } from '../helpers'
import getCountryName from '../utils/GetCountryName'
import ProgramContext from '../contexts/ProgramContext'

/**
 *
 * @example: the data obj should have the following format
 * { 'country_1': ['city1', 'city2'], 'country_2': ['city3', 'city4']}
 */
function StoreFilter({ onChange, data, initialSearch }) {
    const { t } = useTranslation('common')
    const { currentProgram } = useContext(ProgramContext)
    const debouncedOnChange = debounce((input) => {
        setSearch(input)
        onChange(getDropdownFilter(dropdownCountryLabel), getDropdownFilter(dropdownCityLabel), input)
    }, 1000)
    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            debouncedOnChange.cancel()
        }
    }, [])

    const defaultAllCityOption = {
        label: t('filter.dropdown.allCities.header'),
        value: 'all',
        color: '#fffff',
        isDisabled: true,
    }
    const defaultAllCountryOption = {
        label: t('filter.dropdown.allCountries.header'),
        value: 'all',
        color: '#fffff',
        isDisabled: true,
    }
    const [search, setSearch] = useState(null)
    const [dropdownCityLabel, setDropdownCityLabel] = useState([defaultAllCityOption])
    const [dropdownCountryLabel, setDropdownCountryLabel] = useState([defaultAllCountryOption])
    const [dropdownCityOptions, setDropdownCityOptions] = useState([])
    const [dropdownCountryOptions, setDropdownCountryOptions] = useState([])
    const [initializingData, setInitializingData] = useState(true)

    useEffect(() => {
        if (data) {
            setInitializingData(false)
            getCountriesFromResult(Object.keys(data))
            getCitiesFromResult(data, Object.keys(data))
        }

        if (initialSearch) setSearch(initialSearch)
    }, [useDeepCompareWithRef(data, useRef), currentProgram])

    const getDropdownFilter = (label) => {
        const isAllSelected = label.length === 1 && label[0].value === 'all'
        return isAllSelected ? [] : label.filter((c) => c.value !== 'all').map((c) => c.value)
    }

    const getCountriesFromResult = (countries) => {
        if (!countries.length) {
            setDropdownCountryOptions([defaultAllCountryOption])
            setDropdownCountryLabel([defaultAllCountryOption])
            return
        }
        const countryOptions = [
            defaultAllCountryOption, // default option
        ]
        // main program country
        if (currentProgram?.country) {
            countryOptions.push({ label: getCountryName(currentProgram.country), value: currentProgram.country })
        }
        countryOptions.push(
            ...countries
                ?.filter((country) => country !== currentProgram?.country) // remove main program country
                ?.sort((c1, c2) => getCountryName(c1).localeCompare(getCountryName(c2))) // sort countries by name
                ?.map((country) => ({ label: getCountryName(country), value: country })) // country shortcut to full name (e.g. UA -> Ukraine)
        )
        setDropdownCountryOptions(countryOptions)
        setDropdownCountryLabel([defaultAllCountryOption])
    }

    const getCitiesFromResult = (cityData, selectedCountries) => {
        let cityOptions = [defaultAllCityOption] // default option

        if (selectedCountries.includes(currentProgram?.city)) {
            cityOptions = [...cityOptions, { label: currentProgram?.city, value: currentProgram?.city }] // main program country
        }

        selectedCountries.forEach((c) => {
            if (cityData[c]) {
                cityData[c]
                    .sort((c1, c2) => c1.localeCompare(c2)) // sort all cities of a selected country by name
                    .forEach((d) => d && d !== currentProgram?.city && cityOptions.push({ label: d, value: d })) // add city to the dropdown excluding main program city
            }
        })
        setDropdownCityOptions(cityOptions)
        setDropdownCityLabel([defaultAllCityOption])
    }

    const filterHelper = (
        newSelectedOptions,
        previousSelectedOptions,
        defaultOptions,
        setDropdownLabel,
        setDropdownOptions,
        defaultOptionAll,
        isCountry
    ) => {
        const hasAllInOptions = (arr) => arr.findIndex((e) => e.value === 'all') > -1
        const newlySelectedHasAll = hasAllInOptions(newSelectedOptions)
        const previousHasAll = hasAllInOptions(previousSelectedOptions)
        const selectedWithoutDefault = newSelectedOptions.filter((e) => e.value !== 'all')
        const optionsWithoutDefault = defaultOptions.filter((e) => e.value !== 'all')
        const isAllItemsUnchecked = previousSelectedOptions.length === 1 && newSelectedOptions.length === 0
        const hasTwoOptionsOnly = defaultOptions.length === 2 && previousSelectedOptions.length === 2
        // Only one item left and it's selected, we do nothing
        if (isAllItemsUnchecked || hasTwoOptionsOnly) {
            return null
        }

        const isAllSelected =
            (!previousHasAll && newlySelectedHasAll) ||
            (!newlySelectedHasAll && optionsWithoutDefault.length === selectedWithoutDefault.length)
                ? true
                : false

        let dropdownLabel
        let dropdownOptions

        if (isAllSelected) {
            dropdownLabel = [defaultOptionAll]
            dropdownOptions = defaultOptions
            if (isCountry) {
                getCitiesFromResult(data, Object.keys(data))
            }
        } else {
            const newDefaultOption = { ...defaultOptionAll, isDisabled: false }
            const newOptions = [...selectedWithoutDefault]
            dropdownLabel = newOptions
            dropdownOptions = [newDefaultOption, ...optionsWithoutDefault]
            if (isCountry) {
                getCitiesFromResult(
                    data,
                    selectedWithoutDefault.map((c) => c.value)
                )
            }
        }
        setDropdownLabel(dropdownLabel)
        setDropdownOptions(dropdownOptions)
        return dropdownLabel
    }

    const handleCountryChange = (newSelectedOptions) => {
        const newCountryLabel = filterHelper(
            newSelectedOptions,
            [...dropdownCountryLabel],
            dropdownCountryOptions,
            setDropdownCountryLabel,
            setDropdownCountryOptions,
            defaultAllCountryOption,
            true
        )
        if (!newCountryLabel) return
        onChange(getDropdownFilter(newCountryLabel), getDropdownFilter(dropdownCityLabel), search)
    }

    const handleCityChange = (newSelectedOptions) => {
        const newCityLabel = filterHelper(
            newSelectedOptions,
            [...dropdownCityLabel],
            dropdownCityOptions,
            setDropdownCityLabel,
            setDropdownCityOptions,
            defaultAllCityOption,
            false
        )
        if (!newCityLabel) return
        onChange(getDropdownFilter(dropdownCountryLabel), getDropdownFilter(newCityLabel), search)
    }

    const resetFilters = () => {
        setDropdownCountryLabel([defaultAllCountryOption])
        setDropdownCityLabel([defaultAllCityOption])
        setSearch(null)
        onChange(getDropdownFilter([defaultAllCountryOption]), getDropdownFilter([defaultAllCityOption]), null)
    }

    return (
        <div data-testid={'store_filters'} className="flex items-center gap-2 my-2 sm:my-0 justify-end">
            <div className="space-x-2 px-4">
                <FiltersDropdown
                    isDisabled={initializingData}
                    options={dropdownCountryOptions}
                    value={dropdownCountryLabel}
                    onChange={handleCountryChange}
                    filterName="countries"
                    isMulti
                />
            </div>

            <div className="space-x-2 px-4">
                <FiltersDropdown
                    isDisabled={initializingData}
                    options={dropdownCityOptions}
                    value={dropdownCityLabel}
                    onChange={handleCityChange}
                    filterName="cities"
                    isMulti
                />
            </div>

            <div className="w-1/3">
                <DropdownInput
                    onChange={debouncedOnChange}
                    placeholder={t('listing_program.input_search_btn')}
                    value={search}
                    asSearchInput
                    withoutMenu
                />
            </div>
            <Button disabled={false} appearance="outline" className="" onClick={resetFilters}>
                {t('listingStore.filter.button.clear')}
            </Button>
        </div>
    )
}

StoreFilter.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    initialSearch: PropTypes.string,
}

export default StoreFilter
