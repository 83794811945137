import 'rc-collapse/assets/index.css'
import CheckboxFilter from './shared/CheckboxFilter'
import { CommonFilterProps } from './shared/audience-filters-types'

type ShopperStatusFilterProps = CommonFilterProps & {}

function ShopperStatusFilter(props: ShopperStatusFilterProps) {
    return (
        <>
            <CheckboxFilter {...props} id="shopperStatus.synchronized" />
            <CheckboxFilter {...props} id="shopperStatus.desynchronized" />
            <CheckboxFilter {...props} id="shopperStatus.soonDesynchronized" />
        </>
    )
}

export default ShopperStatusFilter
