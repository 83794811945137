import Button from '../../components/Button'
import Heading from '../../components/Heading'
import InputValidation from '../../components/InputValidation'
import React, { useEffect, useState } from 'react'
import Label from '../../components/Label'
import { Password } from '../../validators/PasswordValidator'
import { User } from '../../data/UserFetcher'
import ErrorText from '../../components/ErrorText'
import { useLocation } from 'react-router-dom'
import InputPassword from '../../components/InputPassword'
import { useTranslation } from 'react-i18next'
import ErrorAlert from '../../components/ErrorAlert'
import { isSessionValid } from '../../helpers'

function PasswordReset() {
    const { t } = useTranslation('common')
    const [serverErrorMessage, setServerErrorMessage] = useState('')

    const search = useLocation().search
    useEffect(() => {
        const token = new URLSearchParams(search).get('t')
        if (token === null) {
            setServerErrorMessage('Reset_password.tokenNotFound')
        } else if (!isSessionValid()) {
            setServerErrorMessage('Reset_password.tokenExpired')
        }
    }, [])

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isEmailSent, setIsEmailSent] = useState(false)

    const updatePassword = (value) => {
        setErrorMessage('')
        setPassword(value)
    }

    const patchPassword = () => {
        setIsLoading(true)
        User.postAccountUpdate({ password: password })
            .then((e) => {
                setIsEmailSent(true)
                setIsLoading(false)
            })
            .catch((e) => {
                setErrorMessage(t(e.code))
                setIsLoading(false)
            })
    }

    const items = [
        { label: '8 char. min.', completed: Password.isLongEnough(password) },
        { label: '1 cap', completed: Password.hasOneUppercase(password) },
        { label: '1 digit', completed: Password.hasOneDigit(password) },
        { label: '1 special char.', completed: Password.hasOneOWASPSpecialCharacter(password) },
    ]

    return (
        <div className="w-96 flex flex-col gap-8 m-auto">
            <Heading className="mb-6">{t('Reset_password.title')}</Heading>

            <div className={'xl:py-12'}>
                {serverErrorMessage && serverErrorMessage !== 'Reset_password.tokenExpired' && (
                    <ErrorAlert>{t(serverErrorMessage)}</ErrorAlert>
                )}
                {serverErrorMessage === 'Reset_password.tokenExpired' && (
                    <div className="py-4 flex justify-center">
                        <ErrorText>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('Reset_password.linkExpired', {
                                        text1: t('Reset_password.linkExpired_text1'),
                                        aOpen: '<a href="/password-forgotten" class="text-primary cursor-pointer underline">',
                                        aText: t('Reset_password.linkExpired_linkText'),
                                        aClose: '</a>',
                                        text2: t('Reset_password.linkExpired_text2'),
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            />
                        </ErrorText>
                    </div>
                )}
                {isEmailSent && (
                    <div className="py-4 text-success">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('Reset_password.emailSentMessage', {
                                    text1: t('Reset_password.emailSentMessage_text1'),
                                    aOpen: '<a href="login" class="text-primary cursor-pointer underline">',
                                    aText: t('Reset_password.emailSentMessage_linkText'),
                                    aClose: '</a>',
                                    text2: t('Reset_password.emailSentMessage_text2'),
                                    interpolation: { escapeValue: false },
                                }),
                            }}
                        />
                    </div>
                )}

                {!isEmailSent && !serverErrorMessage && (
                    <div className="mx-auto max-w-sm">
                        <div>
                            <div>
                                <div className="flex justify-between items-center">
                                    <Label className="inputLabel" inputId="password">
                                        {t('Reset_password.passwordField')}
                                    </Label>
                                    <InputValidation items={items} />
                                </div>
                                <InputPassword
                                    className="mt-3.5"
                                    value={password}
                                    onChange={updatePassword}
                                    error={items.find((e) => !e.completed) || errorMessage !== ''}
                                />
                            </div>
                            <Button
                                type="submit"
                                className="mt-8 py-4 w-full"
                                disabled={
                                    isLoading ||
                                    items.find((e) => {
                                        return !e.completed
                                    })
                                }
                                onClick={patchPassword}
                            >
                                {isLoading ? t('Reset_password.buttonLoadingLabel') : t('Reset_password.buttonLabel')}
                            </Button>
                            <ErrorText>{errorMessage}</ErrorText>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PasswordReset
