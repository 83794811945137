import React, { forwardRef, useState } from 'react'

const OfferContext = React.createContext({
    offers: null,
    setOffers: () => {},
})

export function OfferProvider({ children }) {
    const [offers, setOffers] = useState(null)
    return <OfferContext.Provider value={{ offers, setOffers }}>{children}</OfferContext.Provider>
}

export function withOffer(Component) {
    return forwardRef((props, ref) => (
        <OfferContext.Consumer>
            {(value) => <Component {...{ ...props, ref, offers: value.offers, setOffers: value.setOffers }} />}
        </OfferContext.Consumer>
    ))
}

export default OfferContext
