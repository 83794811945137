import { Dropdown as AntdDropdown, DropdownProps as AntdDropdownProps, Menu } from 'antd'
import { useMemo } from 'react'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import './AccountDropdown.css'

export type AccountDropdownProps = {
    items: ItemType[]
} & Omit<AntdDropdownProps, 'overlay'>

function AccountDropdown({ items, children, trigger = ['click'], ...props }: AccountDropdownProps) {
    const menu = useMemo(() => <Menu items={items} />, [items])

    return (
        <AntdDropdown trigger={trigger} overlay={menu} {...props} className={'account-dropdown ' + props.className}>
            {children}
        </AntdDropdown>
    )
}

export default AccountDropdown
