import PropTypes from 'prop-types'
import React from 'react'

export const PlusIcon = ({ onClick }) => {
    return (
        <div className="plus-icon" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
            </svg>
        </div>
    )
}

PlusIcon.propTypes = {
    onclick: PropTypes.func,
}
