import { useState } from 'react'
import { FilterValue } from '../FilterPanel/shared/audience-filters-types'

const retrievalMethodPrefix = 'dataRetrievalMethod.'
const accountLinkingFilterId = retrievalMethodPrefix + 'al'
const memberTypePrefix = 'memberType.'
const memberTypeNotFullyRegistered = 'memberType.notFullyRegistered'
const accountLinkingRelatedFilters: string[] = [
    'shopperStatus.synchronized',
    'shopperStatus.desynchronized',
    'shopperStatus.soonDesynchronized',
]

/**
 * Handle audience's filters
 */
export const useAudienceFilters = () => {
    const [values, setValues] = useState<FilterValue[]>([])

    /**
     * Retrieve a FilterValue from its id
     * @param id
     */
    const getFilterValue = (id: string) => values.find((v) => v.id === id)

    /**
     * Set a FilterValue, create a new one if missing using its id
     * @param id
     * @param value
     */
    const setFilterValue = (id: string, value: any) => {
        if (!value) return removeFilterValue(id)

        let filterValue = values.find((v) => v.id === id)
        if (!filterValue) {
            filterValue = { id, value }
        } else {
            filterValue = { ...filterValue, value }
        }

        let newValues = [...values.filter((v) => v.id !== id), filterValue]

        // In case of data retrieval method other than AL, remove some other filters
        if (id.startsWith(retrievalMethodPrefix) && id !== accountLinkingFilterId) {
            newValues = newValues.filter((v) => !accountLinkingRelatedFilters.includes(v.id))
        }

        // Only one data retrieval method can be active
        if (id.startsWith(retrievalMethodPrefix)) {
            newValues = newValues.filter((v) => !v.id.startsWith(retrievalMethodPrefix) || v.id === id)
        }

        // Only one member type can be active
        if (id.startsWith(memberTypePrefix)) {
            newValues = newValues.filter((v) => !v.id.startsWith(memberTypePrefix) || v.id === id)

            // In case of "not fully registered", remove filters on the data retrieval method
            if (id === memberTypeNotFullyRegistered) {
                newValues = newValues.filter((v) => !v.id.startsWith(retrievalMethodPrefix) || v.id === id)
            }
        }

        setValues(newValues)
    }

    /**
     * Remove a FilterValue from with id
     * @param id
     */
    const removeFilterValue = (id: string) => setValues(values.filter((v) => v.id !== id))

    /**
     * Get the activation state of a filter
     * @param id
     */
    const isEnabled = (id: string) => {
        if (accountLinkingRelatedFilters.includes(id)) return getFilterValue(accountLinkingFilterId)?.value === true
        else if (id.startsWith(retrievalMethodPrefix))
            return getFilterValue(memberTypeNotFullyRegistered)?.value !== true
        else return true
    }

    return {
        values,
        getFilterValue,
        setFilterValue,
        removeFilterValue,
        isEnabled,
    }
}
