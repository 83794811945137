import InProgramIcon from '../../../../../../components/Icons/InProgramIcon'
import { ShopperProfileKpis } from '../shopper-profile-behavior-types'
import { useTranslation } from 'react-i18next'

type FavoriteStoresProps = {
    favoriteStores: ShopperProfileKpis['favorite_stores']
}

function FavoriteStores({ favoriteStores }: FavoriteStoresProps) {
    const { t } = useTranslation('common')

    return (
        <div className="bg-white pt-8 2xl:pl-10 xl:pl-8 pl-6 pr-6 h-full">
            <div className="w-full text-xl font-black inline-flex">
                {t('shopperProfile.favoriteStores.title')}
                <InProgramIcon className="ml-2 h-6" />
            </div>
            {favoriteStores.map((store) => (
                <div className="mt-8" key={store.id}>
                    <div className="text-xl font-bold text-primary">{store.store_name}</div>
                    <div className="font-normal text-gray-600 mt-2">
                        {t('shopperProfile.favoriteStores.averageBasket')}:{' '}
                        <span className="font-bold">{store.average_basket + t('shopperProfile.currency')}</span>
                    </div>
                    <div className="font-normal text-gray-600 mt-2">
                        {t('shopperProfile.favoriteStores.overallBudget')}:{' '}
                        <span className="font-bold">{store.overall_budget + t('shopperProfile.currency')}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FavoriteStores
