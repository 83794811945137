import React from 'react'

function IPhoneWifiIcon() {
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.17445 1.23759C5.16047 1.23764 6.10878 1.6165 6.82338 2.29589C6.87719 2.34834 6.9632 2.34768 7.0162 2.29441L7.53059 1.77527C7.55743 1.74825 7.57239 1.71165 7.57216 1.67357C7.57194 1.63549 7.55655 1.59907 7.5294 1.57236C5.65379 -0.22513 2.69481 -0.22513 0.819203 1.57236C0.792033 1.59905 0.77662 1.63546 0.77637 1.67354C0.776117 1.71162 0.791054 1.74823 0.817869 1.77527L1.33241 2.29441C1.38537 2.34776 1.47145 2.34842 1.52523 2.29589C2.23992 1.61646 3.18834 1.23759 4.17445 1.23759ZM4.17495 2.92656C4.7167 2.92653 5.23912 3.12789 5.6407 3.49153C5.69501 3.54313 5.78057 3.54202 5.83352 3.48901L6.34731 2.96987C6.37437 2.94264 6.38938 2.9057 6.389 2.86731C6.3886 2.82893 6.37284 2.7923 6.34524 2.76563C5.12236 1.6281 3.22858 1.6281 2.00571 2.76563C1.97808 2.7923 1.96232 2.82895 1.96196 2.86734C1.9616 2.90574 1.97666 2.94268 2.00378 2.96987L2.51743 3.48901C2.57037 3.54202 2.65593 3.54313 2.71025 3.49153C3.11156 3.12813 3.63356 2.92678 4.17495 2.92656ZM5.16225 4.16551C5.18973 4.13854 5.20486 4.10143 5.20408 4.06294C5.20329 4.02444 5.18666 3.98798 5.1581 3.96216C4.5905 3.48208 3.75921 3.48208 3.19161 3.96216C3.16304 3.98796 3.14637 4.02441 3.14556 4.0629C3.14475 4.1014 3.15985 4.13852 3.18731 4.16551L4.07622 5.06243C4.10227 5.08879 4.13779 5.10362 4.17486 5.10362C4.21192 5.10362 4.24744 5.08879 4.27349 5.06243L5.16225 4.16551Z"
                fill="black"
            />
        </svg>
    )
}

export default IPhoneWifiIcon
