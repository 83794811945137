import DropdownInput from '../../../components/DropdownInput'
import Button, { ButtonAppearance } from '../../../components/Button'
import RestTable from '../../../components/Table'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'

const url = '/backoffice/v3/pinpoint/transactions'
const CUSTOMER_ID_STORAGE_KEY = 'PINPOINT_CUSTOMER_ID'

const AnalyseByCustomer = () => {
    const location = useLocation()
    const locationData = location.state as any
    const [search, setSearch] = useState<string>(locationData?.customerId || '')
    const navigate = useNavigate()

    useEffect(() => {
        const customerId = sessionStorage.getItem(CUSTOMER_ID_STORAGE_KEY)
        if (customerId) setSearch(customerId)
    }, [])

    const tableQueryUrl = useMemo(() => {
        return search ? url + `?customerId=${encodeURIComponent(search)}` : ''
    }, [search])

    const columns: ColumnsType<any> = [
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Transaction Date</div>,
            render: (s) => moment(s.transaction_date).format('MM/DD/YYYY'),
            defaultSortOrder: 'descend',
            key: 'transaction_date',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Amount</div>,
            render: (s) => -s.amount + ' ' + s.currency,
            key: 'amount',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Label</div>,
            render: (s) => s.label,
            key: 'label',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Retailer</div>,
            render: (s) => s.retailer_name,
        },
        {
            title: <div className="whitespace-nowrap overflow-hidden text-ellipsis">Store</div>,
            render: (s) => s.store_name,
        },
    ]

    return (
        <>
            <div className="grid grid-cols-1 gap-4">
                <div className="w-full flex justify-between gap-2">
                    <div className="2xl:w-1/3 lg:w-3/4 w-full flex gap-2">
                        <DropdownInput
                            onChange={(e) => setSearch(e.value)}
                            options={[]}
                            placeholder={'Customer id'}
                            value={search}
                            asSearchInput
                            withoutMenu
                            className="h-full"
                            data-cy="search_input"
                        />
                        <Button
                            disabled={false}
                            appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                            onClick={() => setSearch('')}
                            data-cy="search_clear_button"
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            </div>
            {search && (
                <div className="mt-8 overflow-x-scroll">
                    <RestTable
                        queryBaseUrl={tableQueryUrl}
                        columns={columns}
                        pageSize={10}
                        errorMessageKey="errors.pinpoint.list"
                        tableLayout="fixed"
                        onDataReceived={(page) => {
                            if (page.count > 0) sessionStorage.setItem(CUSTOMER_ID_STORAGE_KEY, search)
                        }}
                        onRowClicked={(t) => navigate(`/pinpoint/analyze/${t.id}`, { state: { transaction: t } })}
                    />
                </div>
            )}
        </>
    )
}

export default AnalyseByCustomer
