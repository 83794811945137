import PropTypes from 'prop-types'
import { useState } from 'react'
import './Input.css'

function Input({ className = '', onChange, onBlur, value = '', placeholder = '', error = false, ...rest }) {
    const [visibility, setVisibility] = useState(false)

    return (
        <div className={['relative', className].join(' ')}>
            <input
                type={visibility ? 'text' : 'password'}
                data-cy={rest['data-cy']}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                className={[
                    'border-1 px-6 py-5 text-base w-full focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-black placeholder-none',
                    error ? 'border-error focus:ring-error' : 'border-black focus:ring-transparent',
                ].join(' ')}
                id="password"
                placeholder={placeholder}
            />
            {!visibility ? (
                <svg
                    className={'absolute right-4 inset-y-0 my-auto cursor-pointer'}
                    onClick={() => setVisibility(!visibility)}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.4367 16.5487C13.8111 17.5798 11.9251 18.1258 10 18.1227C5.01618 18.1227 0.869766 14.5365 0 9.80405C0.397476 7.65109 1.48039 5.68435 3.08716 4.19725L0.195027 1.30788L1.50291 0L19.8031 18.3011L18.4952 19.6081L15.4358 16.5487H15.4367ZM4.39412 5.50605C3.138 6.64811 2.26298 8.14843 1.88742 9.80405C2.17607 11.0671 2.75558 12.2453 3.57982 13.2448C4.40405 14.2444 5.45025 15.0378 6.63514 15.5618C7.82002 16.0858 9.11087 16.326 10.4049 16.2632C11.699 16.2004 12.9605 15.8364 14.0891 15.2001L12.2146 13.3256C11.4167 13.8283 10.4716 14.0448 9.53437 13.9398C8.59717 13.8347 7.72349 13.4143 7.05663 12.7474C6.38978 12.0806 5.96934 11.2069 5.86428 10.2697C5.75922 9.33247 5.97577 8.38738 6.47842 7.58943L4.39412 5.50605ZM10.8448 11.9558L7.84823 8.95924C7.68376 9.37789 7.64506 9.83543 7.73686 10.2758C7.82866 10.7161 8.04697 11.12 8.36502 11.4381C8.68308 11.7562 9.08704 11.9745 9.52736 12.0663C9.96769 12.1581 10.4252 12.1194 10.8439 11.9549L10.8448 11.9558ZM18.1403 14.0484L16.8176 12.7267C17.4355 11.8461 17.8754 10.8533 18.1126 9.80405C17.8614 8.70388 17.3891 7.66628 16.7244 6.75432C16.0597 5.84235 15.2166 5.07508 14.2461 4.49913C13.2757 3.92317 12.1983 3.55058 11.0793 3.40398C9.96042 3.25738 8.82337 3.33985 7.73731 3.64636L6.27877 2.18782C7.43137 1.73491 8.68749 1.48535 10 1.48535C14.9838 1.48535 19.1302 5.07163 20 9.80405C19.7168 11.3437 19.0802 12.7965 18.1403 14.0484ZM9.74397 5.65209C10.3323 5.61574 10.9216 5.70484 11.4729 5.9135C12.0242 6.12216 12.5248 6.44561 12.9416 6.86242C13.3584 7.27922 13.6819 7.77986 13.8905 8.33114C14.0992 8.88242 14.1883 9.47175 14.152 10.0601L9.74304 5.65209H9.74397Z"
                        fill="#333333"
                    />
                </svg>
            ) : (
                <svg
                    className={'absolute right-4 inset-y-0 my-auto cursor-pointer'}
                    onClick={() => setVisibility(!visibility)}
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 0.5C14.9838 0.5 19.1302 4.08628 20 8.8187C19.1312 13.5511 14.9838 17.1374 10 17.1374C5.01618 17.1374 0.869766 13.5511 0 8.8187C0.868842 4.08628 5.01618 0.5 10 0.5ZM10 15.2888C11.8851 15.2884 13.7142 14.6481 15.188 13.4727C16.6617 12.2973 17.6929 10.6565 18.1126 8.8187C17.6913 6.9824 16.6595 5.34334 15.1859 4.16949C13.7123 2.99565 11.884 2.35648 10 2.35648C8.116 2.35648 6.28772 2.99565 4.8141 4.16949C3.34048 5.34334 2.30869 6.9824 1.88742 8.8187C2.30714 10.6565 3.33827 12.2973 4.81204 13.4727C6.28581 14.6481 8.11492 15.2884 10 15.2888ZM10 12.978C8.89687 12.978 7.83893 12.5398 7.0589 11.7598C6.27887 10.9798 5.84065 9.92183 5.84065 8.8187C5.84065 7.71557 6.27887 6.65762 7.0589 5.87759C7.83893 5.09757 8.89687 4.65935 10 4.65935C11.1031 4.65935 12.1611 5.09757 12.9411 5.87759C13.7211 6.65762 14.1593 7.71557 14.1593 8.8187C14.1593 9.92183 13.7211 10.9798 12.9411 11.7598C12.1611 12.5398 11.1031 12.978 10 12.978ZM10 11.1294C10.6128 11.1294 11.2006 10.886 11.6339 10.4526C12.0673 10.0193 12.3108 9.43155 12.3108 8.8187C12.3108 8.20585 12.0673 7.6181 11.6339 7.18475C11.2006 6.7514 10.6128 6.50795 10 6.50795C9.38715 6.50795 8.7994 6.7514 8.36605 7.18475C7.9327 7.6181 7.68925 8.20585 7.68925 8.8187C7.68925 9.43155 7.9327 10.0193 8.36605 10.4526C8.7994 10.886 9.38715 11.1294 10 11.1294Z"
                        fill="#333333"
                    />
                </svg>
            )}
        </div>
    )
}

Input.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
}

export default Input
