import YLogoLoading from './Icons/YLogoLoading'
import PropTypes from 'prop-types'
import * as Tailwind from '../../tailwind.config'

function Loading({ fullScreen = true }) {
    const className = fullScreen
        ? 'fixed w-screen h-screen inset-0 bg-guest-primary flex items-center justify-center flex-col'
        : 'absolute inset-0 flex items-center justify-center'

    return (
        <div data-testid="loading" className={className}>
            <YLogoLoading width="40" border={Tailwind.theme.extend.colors.primary} color="light-grey" percentage={50} />
        </div>
    )
}

Loading.fullScreen = {
    fullScreen: PropTypes.bool,
}

export default Loading
