import React, { useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

type NavButtonProps = {
    children: React.ReactNode
    url: string
    'data-cy'?: string
    disabled?: boolean
    overridingHightlightCondition?: boolean
    active?: boolean
}
function NavButton({ children, url, active = false, disabled = false, ...rest }: NavButtonProps) {
    const navigate = useNavigate()

    const getCssClasses = useCallback(
        (isActive: boolean) => {
            if (disabled) {
                return 'opacity-20 text-black'
            } else {
                return isActive
                    ? 'bg-primary bg-opacity-10 text-primary border-primary font-bold'
                    : 'text-black hover:text-dark'
            }
        },
        [disabled]
    )

    return (
        <NavLink to={url}>
            {({ isActive }) => (
                <button
                    data-cy={rest['data-cy']}
                    disabled={disabled}
                    className={[
                        'w-full flex items-center gap-x-4 h-11 text-sm px-3 py-4',
                        getCssClasses(isActive || active),
                    ].join(' ')}
                    onClick={() => navigate(url)}
                >
                    {children}
                </button>
            )}
        </NavLink>
    )
}

export default NavButton
