import React, { useMemo } from 'react'
import CopyToClipboardIcon from '../../../../../components/Icons/CopyToClipboardIcon'
import { useTranslation } from 'react-i18next'
import InfoTooltip from '../../../../../components/InfoTooltip'

type PersonalInformationElementProps = {
    titleKey: string
    dataToCopy?: string
    children: React.ReactNode
    noFlex?: boolean
}

function PersonalInformationElement({ titleKey, children, noFlex, dataToCopy = '' }: PersonalInformationElementProps) {
    const { t } = useTranslation('common')

    const handleCopyToClipboard = async () => {
        try {
            if (dataToCopy) await navigator.clipboard.writeText(dataToCopy)
        } catch (err) {}
    }

    const content = useMemo(() => {
        if (children && !dataToCopy) return children
        else if (children && dataToCopy)
            return (
                <InfoTooltip
                    text={t('audience.usersList.successCopied')}
                    className="text-black"
                    element={
                        <div className={!noFlex ? 'flex items-center' : ''}>
                            {children}
                            <CopyToClipboardIcon className="inline-block ml-2" />
                        </div>
                    }
                />
            )
        else return <span className="text-none">{t('shopperProfile.personalInformation.noData')}</span>
    }, [dataToCopy, children, t])

    return (
        <div>
            <div className="text-none">{t(titleKey)}</div>
            <div
                className={(!noFlex ? 'flex items-center' : '') + ' relative ' + (!children ? 'italic text-sm' : '')}
                onClick={() => handleCopyToClipboard()}
            >
                {content}
            </div>
        </div>
    )
}

export default PersonalInformationElement
