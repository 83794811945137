import ProgramContext, { withProgram } from '../../../../contexts/ProgramContext'
import Kpi from './Kpi'
import { EKpi } from './kpi-types'
import { useKpiData } from './hook/useKpiData'
import { useContext } from 'react'
import * as Tailwind from '../../../../../tailwind.config'
import YLogoLoading from '../../../../components/Icons/YLogoLoading'

function Kpis() {
    const { currentProgram } = useContext<any>(ProgramContext)
    const shoppingHubId = currentProgram?.id

    const { displayData, serverData, loading } = useKpiData(shoppingHubId)

    return (
        <>
            {loading ? (
                <div className="bg-white flex items-center justify-center h-40">
                    <YLogoLoading
                        width={40}
                        border={(Tailwind.theme?.extend?.colors as any).primary}
                        color="light-grey"
                        percentage={50}
                    />
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-3 audience-kpis">
                    {Object.values(EKpi).map((type) => (
                        <Kpi
                            key={type}
                            type={type}
                            data={serverData[type]}
                            displayData={displayData[type]}
                            isGlobalKpi={!shoppingHubId}
                        />
                    ))}
                </div>
            )}
        </>
    )
}

export default withProgram(Kpis)
