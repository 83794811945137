import 'rc-collapse/assets/index.css'
import { useTranslation } from 'react-i18next'
import Datepicker from '../../../../../components/Datepicker'
import { CommonFilterProps } from './shared/audience-filters-types'

type RegistrationDateFilterProps = CommonFilterProps & {}

function RegistrationDateFilter({ getFilterValue, setFilterValue }: RegistrationDateFilterProps) {
    const { t } = useTranslation('common')
    return (
        <>
            <Datepicker
                data-cy="registrationDateFrom"
                value={getFilterValue('registrationDateFrom')?.value}
                onChange={(e: any) => setFilterValue('registrationDateFrom', e)}
                placeholder={t('audience.filters.registrationDate.from.placeholder')}
                className="mb-2"
                maxDate={getFilterValue('registrationDateTo')?.value}
            />

            <Datepicker
                data-cy="registrationDateTo"
                value={getFilterValue('registrationDateTo')?.value}
                onChange={(e: any) => setFilterValue('registrationDateTo', e)}
                placeholder={t('audience.filters.registrationDate.to.placeholder')}
                minDate={getFilterValue('registrationDateFrom')?.value}
            />
        </>
    )
}

export default RegistrationDateFilter
