import { TimePicker } from 'antd'
import moment, { Moment } from 'moment'
import './Timepicker.css'

type TimepickerInputProps = {
    format?: string
    className?: string
    onChange?: (e: any) => void
    value: Date
    disabled?: boolean
    error?: boolean
    placeholder?: string
    minuteStep?: number
    'data-cy'?: string
}

function TimepickerInput({
    className,
    onChange,
    value,
    placeholder,
    disabled,
    error,
    format = 'h:mm a',
    minuteStep,
    ...rest
}: TimepickerInputProps) {
    return (
        <div className={'relative'} data-cy={rest['data-cy']}>
            <TimePicker
                format={format}
                value={moment(value)}
                onChange={(e: Moment | null) => onChange?.(e ? e.toDate() : null)}
                className={[
                    'sp-timepicker',
                    error
                        ? 'border-error focus:ring-error'
                        : disabled
                        ? 'border-color-none text-none placeholder-none'
                        : 'border-black focus:ring-transparent',
                    className,
                ].join(' ')}
                placeholder={placeholder}
                disabled={disabled}
                showNow={false}
                allowClear={false}
                minuteStep={minuteStep}
            />
        </div>
    )
}

export default TimepickerInput
